<template>
  <section id="login_container">
    <h1 class="login_small_title_wrap"> <img src="@/assets/img/logo.png" style="">암호 재설정</h1>
      <ul class="filter_list left">
        <li>
          <dl class="w100">
            <dt>아이디(이메일)을 입력해 주세요.</dt>
            <dd class="w100">
              <div class="f_r w30">
                <button type="submit" 
                        id="duplicateSubmit" 
                        class="large_btn"
                        style="margin-top:-2px" 
                        v-bind:disabled="isButtonDisabled" @click="sendAuth()">인증메일</button>
              </div>
              <span class="k-widget k-maskedtextbox w70">
                <input type="hidden" :v-model="unqUserNo"/>
                <kendo-maskedtextbox
                        ref="userIdRef"
                        name="userId"
                        :placeholder="'ID@etners.com'"
                        :style="'width: 100%;'"
                >
                </kendo-maskedtextbox>
                <span class="k-icon k-i-warning"></span>
              </span>
            </dd>
          </dl>
        </li>
        <li class="w100">
          <dl class="w100">
            <dt>입력하신 아이디(이메일)로 전송된 인증번호를 입력해 주세요.</dt>
            <dd class="w100">
              <span class="k-widget k-maskedtextbox w100" style="">
                <kendo-maskedtextbox
                        ref="mailVerificationNoRef"
                        name="mailVerificationNo"
                        :placeholder="'인증번호 4자리를 입력해 주세요.'"
                        :style="'width: 100%;'"
                        :class="'w100'"
                >
                </kendo-maskedtextbox>
                <span class="k-icon k-i-warning"></span>
              </span>
            </dd>
          </dl>
        </li>
      </ul>
      <button type="submit" class="large_btn orange" @click="onSubmit(mailVerificationNo)">확인</button>
      <div class="mgt20">
        <ul>
          <li> 이메일을 받지 못하셨나요?
            <span class="txt_link mgl10" @click="sendAuth()">인증메일 재전송</span>
          </li>
        </ul>
      </div>
  </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'



export default {
name : 'UserFindPassStep1',
methods: {
  getDefaultRules: function(){
    const resultMsg = { key: true, msg: '' }
    const userId = this.$refs.userIdRef.kendoWidget().value()
    const matched = /.+@.+/.test(userId)


    if ( EtnersCommonUtil.isEmpty(userId)) {
      resultMsg.key = false
      resultMsg.msg = '이메일(ID)을 입력해주세요.'
    } else if (!matched) {
      resultMsg.key = false
      resultMsg.msg = '이메일 형식을 맞춰주세요.'
    }

    return resultMsg
  },
  getDefaultRules2: function(){
    const resultMsg = { key: true, msg: '' }
    const mailVer = this.$refs.mailVerificationNoRef.kendoWidget().value()

    if ( EtnersCommonUtil.isEmpty(mailVer)) {
      resultMsg.key = false
      resultMsg.msg = '인증번호를 입력해주세요.'
    }

    return resultMsg
  },
  sendAuth: function(){

    const resultMsg = this.getDefaultRules()
    this.authParam = {
      'userId' : this.$refs.userIdRef.kendoWidget().value().toLowerCase()
    }

    if(resultMsg.key){
        this.$store.dispatch('FETCH_SEND_AUTH', this.authParam).then(data => {
          kendo.alert('인증메일이 전송되었습니다.유효시간은 3분 입니다.')
          this.isButtonDisabled = true
          this.unqUserNo = data.resultData.unqUserNo
        })
    }else{
      kendo.alert(resultMsg.msg)
      this.isButtonDisabled = false
      return false
    }
  },
  onSubmit: function(mailVerificationNo){
    const resultMsg = this.getDefaultRules()
    const userId = this.$refs.userIdRef.kendoWidget().value()

    if(resultMsg.key){
        this.$store.dispatch('FETCH_CONFIRM_AUTH_NUMBER', {
          'userId': userId,
          'unqUserNo': this.unqUserNo,
          'mailVerificationNo': this.$refs.mailVerificationNoRef.kendoWidget().value()

        }).then(data => {
          this.resultData = data.resultData
          kendo.alert('인증되었습니다.')
          this.$router.push(
              {
                name: 'InitPassword',
                params : {
                  'userId' : userId
                  ,'unqUserNo' : this.unqUserNo
                }
              },
          )
          //EventBus.$emit('child', this.resultData)
        })
    }else{
      kendo.alert(resultMsg.msg)
      return false
    }
  },
},
data: function(){
  return {
    userId: null,
    unqUserNo: null,
    mailVerificationNo: null,
    authParam: {},
    isButtonDisabled:false,
  }
}
}

</script>

<template>
  <div>
    <form ref="ReportFormRef" v-kendo-validator @submit.prevent="">
      <div class="contents_wrap">
        <ul class="filter_list">
          <li>
            <dd class="w100">
              <label>발송 구분
                <span class="forg">*</span>
              </label>
              <ul class="w100">
                <li>
                  <common-radio-group v-model="detailData.answerTypeCd" 
                    :data-items='[{label: "이메일 / 알림톡 발송", value: "01",},
                    {label: "미발송 (답변만 저장)", value: "02"}]'>
                  </common-radio-group>
                </li>
              </ul>
            </dd> 
          </li>
          <li>
            <label>답변 날짜
                <span class="forg">*</span>
              </label>
              <ul class="w30">
                <li>
                  <kendo-datepicker ref="answerDtPickerRef"
                                    id="answerDtPicker"
                                    :value="detailData.fmtAnswerDt"
                                    class="w95"
                                    placeholder="0000/00/00"
                                    :format="'yyyy/MM/dd'">
                  </kendo-datepicker>
                </li>
              </ul>
          </li>
          <li>
            <label>답변 제목 <span class="forg">*</span></label>
            <kendo-maskedtextbox
              ref="qnaAnswerTitleTxRef"
              id="qnaAnswerTitleTx"
              :style="'width: 80%;'"
              v-model="detailData.answerTitleTx"
              :placeholder="'이메일 / 알림톡 제목을 입력하세요.'"
              maxlength="50">
            </kendo-maskedtextbox>
          </li>
          <li>
            <label>첨부파일</label>
            <ul class="w80 icon_file">
              <li>
                <div class="upload">
                  <file-upload-answer v-bind:file="uploads"
                                      @remove="removeFiles"
                                      @child="bindingFiles">
                  </file-upload-answer>
                </div>
              </li>
            </ul>
          </li>
          <li>
            <label>답변 내용
              <span class="forg">*</span>
            </label>
            <div id="overlay" v-bind:style="'0'">
            </div>
            <editor ref="qnaAnswerTxRef"
                    id="qnaAnswerTx"
                    :tools="editor.settings.editorTools"
                    :resizable-content="true"
                    :resizable-toolbar="true"
                    v-model ="detailData.answerTx"
                    style="height:350px;"
                    rows="10"
                    cols="30"
                    validationMessage="내용을 입력해주세요.">
            </editor>
          </li>
        </ul>
      </div>
    </form>
  <div class="button_area w25">
    <ul>
      <li class="w30">
        <button class="large_btn dgray" @click="onClickDeleteAnswer">삭제</button>
      </li>
      <li class="w30">
        <button class="large_btn" @click="onClickWindowClose">취소</button>
      </li>
      <li class="w30">
        <button class="large_btn orange" id="confm_btn" @click="onClickSaveAnswer">저장</button>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import CommonRadioGroup from '@/components/CommonRadioGroup'
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import { Editor } from '@progress/kendo-editor-vue-wrapper';
import FileUploadAnswer from '@/components/FileUploadAnswer'
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersDateTimeUtil from '@/common/etners.datetime.util'

export default {
  name: 'AnswerWindow',
  props: [ 'detailData'],
  components: { 'editor': Editor, CommonRadioGroup, FileUploadAnswer },
  data() {
    const vm = this
    return {
      uploads : [],
      editor: {
        settings: {
          editorTools: [
            "viewHtml",
            "fontSize",
            "fontName",
            "foreColor",
            "backColor",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "createLink",
            "tableWizard",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn"
          ]
        }
      }
    }
  },
  mounted() {
    const vm = this
    vm.initFileDataHandler()
  },
  watch: {
    detailData: {
      handler: 'initFileDataHandler'
    }
  },
  methods: {
    onClickWindowClose(){
      this.$emit('onClickWindowClose')
    },
    onClickSaveAnswer() {
      const vm = this 
      let btnSubmitDis = document.querySelector('#confm_btn')
      let answerDt = EtnersDateTimeUtil.dateToYYYYMMDD(this.$refs.answerDtPickerRef.kendoWidget().value())

      if (EtnersCommonUtil.isEmpty(answerDt)){
        kendo.alert('답변 날짜를 입력해주세요.')
        return false
      }

      let param = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        qnaId: this.detailData.qnaId,
        answerId: JSON.parse(sessionStorage.getItem('adminLoginUserData')).userId,
        answerTitleTx: this.detailData.answerTitleTx,
        answerTx: this.detailData.answerTx,
        answerTypeCd: this.detailData.answerTypeCd,
        answerDt,
        answerFileId : ''
      }

      kendo.confirm("답변을 등록하시겠습니까?").done(function () {
        btnSubmitDis.setAttribute('disabled', 'disabled')
        let filesParam = {
            loginUserToken: sessionStorage.adminLoginUserToken,
            wmGbn: '01',
            existFileId: vm.existFileId,
            existSeqs: [],
            fileNames: []
        }

        const formData = new FormData()

        vm.uploads.forEach(function(v) {
          formData.append("answer", v.rawFile)

          if (v.seq !== undefined) {
            filesParam.existSeqs.push(v.seq)
            filesParam.existFileId = v.fileId
          }
        })

        formData.append("uploadAuthParam", new Blob([JSON.stringify(filesParam)], {
          type: "application/json"
        }))

        vm.$Progress.start()
        
        ApiUtil.postIncludeUploadFile(`${ApiConfig.ethDomain}/v1/eth/qna/saveFileUpload.json`, formData).then(response => {
          if (response.data.resultStatus.messageCode != "2000") {
            kendo.alert(`${response.data.resultStatus.messageText}`)
            vm.$Progress.fail()
            return
          }
          param.answerFileId = response.data.resultData

          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/question/saveQnaAnswer.json`, param).then(function(response){
            if (response.data === undefined) {
              kendo.alert('정상적으로 처리되지 않았습니다.')
              return false
            }else {
              if (param.answerTypeCd == "01") {
                vm.sendQnaAnswerAlimTalkAndSms()
              }
              kendo.alert('등록 되었습니다.')
              vm.$emit('onClickWindowClose')

              vm.$router.push({
                name:'HomeQnaManagement'
              })
            }
          }) 
        })
      })
    },
    removeFiles () {
      const vm = this
      let idx = 0
      vm.uploads.forEach(upload => {
        upload.seq = idx
        idx++
      })
    },
    bindingFiles(files) {
      const vm = this
      files.forEach(function(fileInfo) {
        vm.uploads.push(fileInfo)
      })
    },
    initFileDataHandler: function(){
      const vm = this
      const attachFiles = vm.detailData.answerFiles

      vm.uploads = []

      if(EtnersCommonUtil.isNotEmpty(attachFiles)){
        const fileId = attachFiles[0].fileId
        vm.existFileId = fileId
        attachFiles.forEach(function(v) {
          const fileInfo = {
            'name': v.orgFileNameTx,
            'size': v.fileSizeTx,
            'extension': v.fileExtTx,
            'seq': v.fileSeqNo,
            'fileId': v.fileId,
            'uid': v.fileId + v.fileSeqNo,
            'url': `${ApiConfig.ethDomain}/files/${v.fileId}`
          }
          vm.uploads.push(fileInfo)
        })
      }
    },
    onClickDeleteAnswer() {
      const vm = this 

      let param = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        qnaId: this.detailData.qnaId
      }

      kendo.confirm("답변을 삭제하시겠습니까?").done(function () {
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/question/deleteQnaAnswer.json`, param).then(function(response){

            if (response.data === undefined) {
              kendo.alert('정상적으로 처리되지 않았습니다.')
              return false
            }else {
              kendo.alert('삭제 되었습니다.')
              vm.$emit('onClickWindowClose')
              vm.$router.push({
                name:'HomeQnaManagement'
              })
            }
          }) 
        })
    },
    sendQnaAnswerAlimTalkAndSms() {
      const vm = this
      const { phoneNoTx, solTx, answerTitleTx } = vm.detailData

      let messageParams = {
        solCd:"2014",
        toNumber: phoneNoTx,
        tmpltSn:"1",
        cmpyCd:"00001",
        messageParams:[ solTx, answerTitleTx ],
        fromNumber:"",
        sendOnlyAlimTalk:false,
        reservationSendYYYYMMDDHHMI:"",
        alimTalkEventNm:"사용자 문의 답변 알림"
      }

      ApiUtil.post(`https://fcm-api.etnersplatform.com/v1/ebmp/kakao/alimtalk/sendAlimTalkAndSms.json`, messageParams).then(function (response) {
        if (response.data.resultStatus.messageCode != "2000") {
          kendo.alert(`${response.data.resultStatus.messageText}`)
          return
        }
      })
    }
  }
}
</script>

<style scoped>
  #overlay{
    top:100;
    position:absolute;
    /* background-color:black; */
    opacity:0.07;
    width:800px;
    height:400px;
  }
  #reportContent{
    top:100;
    position:absolute;

  }
</style>

<template>
  <div id="container_home">
    <div class="inner">
      <h1 class="page_tit animated on d-1s">특허·인증</h1>
      <div class="board-view story-view animated on d-2s">
        <div class="board-view-tit">
            <h1 class="title">
              <span class="dh_orange">
                [ {{patentDetailData.patentYn == 'Y' ? '특허' : '인증'}} ]
              </span>[{{patentDetailData.patentCertNoTx}}] {{patentDetailData.patentNmTx}}</h1>
            <div class="num">{{fmtPatentCertDt}}</div>
        </div>
        <div class="board-view-ct">
            <p style="text-align:center">
                <img :src="patentDetailData.imageSrc" style="border:1px solid #ccc;">
            </p>
        </div>
        <div class="board-view-btn" style="cursor:pointer;" @click="goPatentList"><a>목록으로</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  const ethDomain = ApiConfig.ethDomain
  import $ from 'jquery'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'

  export default {
    name: 'CertificationDetail',
    props: [ 'patentId' ,'fmtPatentCertDt','currentPage'],
    mounted () {
      const vm = this

      vm.pageNum = this.$route.params.currentPage
      let param = {
        patentId: this.$route.params.patentId,
      }

      ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/patentDetail.json`, param).then(function(response) {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          return false
        }
        vm.patentDetailData = response.data.resultData.tbPatentMst
        vm.patentDetailData.imageSrc = `${ApiConfig.ethDomain}/files/${vm.patentDetailData.patentCertificationFileId}`
      })
    },
    methods: {
      goPatentList() {
        let currentPage = this.$route.params.currentPage
        this.$router.push({ name: 'Certification',params:{currentPage : currentPage}})
      }
    },
    data: function () {
      const vm = this
      return {
        patentDetailData: {},
        pageNum:0
      }
    }
  }
</script>

<template>
  <div id="container_home">
    <div class="inner">
      <h1 class="page_tit animated on d-1s">News</h1>
      <div class="clearfix">
        <div class="inside_total float-r"><span class="dh_orange">{{total}}</span> Total</div>
      </div>
      <ul class="inside_list animated on d-2s">
        <li v-for="item in reportList" @click="reportDetail(item.reportId)" class="animated on">
          <a href="">
            <div class="list__thumb">
              <img :src='item.imageSrc' @error="replaceByDefault">
            </div>
            <div class="list_text">
              <div class="tit text_ov">{{item.reportTitleTx}}</div>
              <div class="sub ne_date text_ov2">
									{{item.fmtLastMdfyDt}}
								</div>
            </div>
          </a>
        </li>

      </ul>
      <div class="board-pager">
        <button :disabled="pageNum === 0" @click="prevPage" class="page-btn prev">

        </button>
        <a class="page-count">{{ pageNum + 1 }} / {{ pageCount }} Page</a>
        <button :disabled="pageNum >= pageCount - 1" @click="nextPage" class="page-btn next">

        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  const ethDomain = ApiConfig.ethDomain
  import $ from 'jquery'
  export default {
    name: 'ReportEn',
    mounted () {
      const vm = this
      var currentPage = this.$route.params.currentPage
      if(currentPage === null || currentPage === '' || currentPage === undefined){
        vm.page = 0
      }else{
        vm.page = Number(currentPage)
        vm.pageNum = Number(currentPage)
      }

      vm.getReportList(vm.page)

    },
    props:{
      pageSize: {
        type: Number,
        required: false,
        default: 8
      },
    },
    methods: {
      getReportList(page=0) {
        const vm = this

        let param = {
          page: page+1,
          pageSize: vm.pageSize,
          requestType: 'PAGING'

        }
        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/reportList.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('Failed to retrieve data.')
            return false
          }
          vm.reportList = response.data.resultData
          vm.reportList.forEach(item =>item.imageSrc = `${ApiConfig.ethDomain}/files/${item.reportFileId}`)
          vm.total = response.data.total

          let listLeng = response.data.total,
              listSize = vm.pageSize,
              page = Math.floor(listLeng / listSize)
          if (listLeng % listSize > 0) page += 1
          vm.pageCount = page
        })
      },
      reportDetail(reportId) {
        let currentPage = this.pageNum
        this.$router.push({ name: 'ReportDetailEn', params : { reportId, currentPage}})
      },
      nextPage () {
        this.pageNum += 1
        this.getReportList(this.pageNum)
      },
      prevPage () {
        this.pageNum -= 1;
        this.getReportList(this.pageNum)
      },
      replaceByDefault(e) {
        e.target.src = "//etners.com/img/no-photo-available.png"
      }
    },
    data: function () {
      const vm = this
      return {
        total: 0,
        currPage:
        vm.currPage = 1,
        page:0,
        currPage: 0,
        reportList: [],
        pageNum: 0,
        pageCount: 0

      }
    }
  }
</script>

<template>
  <div id="container_home" class="eng">
    <div class="company_inner">
        <h1 class="page_tit">LOCATION</h1>
        <ul class="csr_list etmap">
          <li class="animated">
            <div class="etners_map animated d-1s">
              <NaverMap></NaverMap>
            </div>
          </li>
          <li class="animated on">
            <div class="flex_wrap animated">
              <div class="text animated d-1s ml0">
                <div class="big_tit">Directions</div>
                <div class="cs_text"><b>Address(Head office)</b>
                   5F, Urban-Hub Building A, 60 Gwacheon-daero 7na-gil, Gwacheon-si, Gyeonggi-do<br/>
                  <span class="dh_orange">Lot number address</span> Gyeonggi-do, Seongnam-si, Bundang-gu, Sampyeond-dong 682, USpace2 B-dong 7F</div>
              </div>
              <div class="text animated d-1s ml5 matx2">
                <div class="cs_text">
                   <b>Public Transfortation</b>
                   <span class="dh_orange">Bus</span>1-1, 9-3, 11-2, 103, 777, 441 (Get off at Gwacheon Information Town stop and walk 870m)<br/>
                    <span class="dh_orange">Subway</span><br>[Government Complex Gwacheon Station]<br/> -Take a bus from Exit 9 and get off at Gwacheon  Information Town stop
                    <br>
                    [Indeogwon Station]
                    <br>
                    -Take a bus from Exit 3 and get off at Gwacheon Prugio Labien haut Apt stop
                  <b class="mt10">Parking Information & Usage Guidelines</b>
                  <span class="dh_orange">Car</span> (Use B2F~B4F) Urban-Hub Building A, 492 Galhyeon0dong, Gwacheon-si, Gyeonggi-do<br/>
                  </div>
              </div>
            </div>
          </li>
        </ul>
    </div>
    <!-- //문의  -->
    <div class="clearfix"></div>
  </div>

</template>

<script>
  import NaverMap from '@/components/NaverMap.vue'

  export default {
    name: 'LocationEn',
    components:{
      NaverMap
    },
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
    },
    data: function () {
      const vm = this
      return {

      }
    }
  }
</script>

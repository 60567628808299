<template>
  <div id="container_home">
      <div class="inner">
          <h1 class="page_tit animated on">보도자료</h1>
          <div class="board-view story-view animated on">
              <div class="board-view-tit">
                  <h1 class="title">{{reportDetailData.reportTitleTx}}</h1>
                  <div class="num">{{reportDetailData.fmtLastMdfyDt}}</div>
              </div>
              <div class="board-view-ct" v-html="reportDetailData.reportContentsTx">

              </div>
              <div class="board-view-btn" style="cursor:pointer;">
                  <a @click="goReportList">목록으로</a>
              </div>
          </div>
      </div>
  </div>

</template>

<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import EtnersParseUtil from '@/common/etners.parse.util'

  export default {
    name: 'ReportDetail',
    props: [ 'reportId','currentPage'],
    mounted () {      
      const vm = this

      let param = {
        reportId: this.reportId
      }

      ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/reportDetail.json`, param).then(function(response) {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          return false
        }
        vm.reportDetailData = response.data.resultData
        vm.reportDetailData.imageSrc = `${ApiConfig.ethDomain}/files/${vm.reportDetailData.reportFileId}`
      })
    },
    methods: {
      goReportList() {
        this.$router.push({ name: 'Report',params:{currentPage : this.$route.params.currentPage}})
      },
    },
    data: function () {
      const vm = this
      return {
        reportDetailData: {},
      }
    }
  }
</script>

<template>
  <section id="login_container">
        <h1 class="login_small_title_wrap"> <img src="@/assets/img/logo.png" style="">비밀번호 초기 설정</h1>
        <ul class="filter_list left">
          <input type="hidden" name="userId" :v-model="userId" />
          <li>
            <dl class="w100">
              <dt>
                <span class="f_r f_s">※ 8~50자 이내의 영문, 숫자, 특수문자 조합</span>
                비밀번호
              </dt>
              <dd class="w100">

                <span class="k-widget k-maskedtextbox w100" style="">
                  <kendo-maskedtextbox
                          ref="passWordNoRef"
                          name="passWordNoChk"
                          id="passWordNoChk"
                          type="password"
                          :placeholder="'비밀번호를 입력해주세요.'"
                          :style="'width: 100%;'"
                  >
                  </kendo-maskedtextbox>
                  <span class="k-icon k-i-warning"></span>
                </span>
                <!--PW//양식에 맞지 않았을떄-->
                <!-- <div class="fieldset_txt" :style="{display: passValidate}" id="passwordWordsOfGuidance">비밀번호는 8~50글자 영문, 숫자, 특수문자 조합으로 작성해주세요.</div> -->
                <!--<div class="fieldset_txt">연속된 숫자, 문자가 3가지 이상 중복되면 안됩니다.</div>-->
              </dd>
            </dl>
            <dl class="w100">
              <dt>비밀번호 재입력
              </dt>
              <dd class="w100">
                <span class="k-widget k-maskedtextbox w100" style="">
                    <kendo-maskedtextbox
                            ref="passWordTxRef"
                            name="passWordTx"
                            id="passWordTx"
                            type="password"
                            :placeholder="'비밀번호를 한번 더 입력해주세요.'"
                            :style="'width: 100%;'"
                            @change="newPwConfirmKeyUp"
                    >
                    </kendo-maskedtextbox>
                </span>
                <div class="msg_red" :style="{display: passwordFail}">비밀번호가 일치하지 않습니다. </div>
                <div class="msg_green" :style="{display: passwordSuccess}">비밀번호가 일치 합니다. </div>
              </dd>
            </dl>
          </li>
        </ul>
        <button type="submit" class="mid_btn orange login_btn" @click="onSubmit()" >확인</button>
    </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'

export default {
  name : 'InitPassword',
  created() {

    this.userId = this.$route.params.userId
    this.unqUserNo = this.$route.params.unqUserNo
  },
  mounted(){
    let vm = this
    let elementPW = document.getElementById('passWordNoChk')

    elementPW.onkeyup = function (){
      vm.newPwKeyUp(elementPW.value)
    }

    let elementChkPW = document.getElementById('passWordTx')

    elementChkPW.onkeyup = function (){
      vm.newPwConfirmKeyUp(elementChkPW.value)
    }

  },
  methods: {
    newPwKeyUp: function(value){
      const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{8,50}$/.test(value)
      if (!matched) {
        this.passValidate='inline-block'
      }else{
        this.passValidate='none'
      }
    },
    newPwConfirmKeyUp: function (value) {
      console.log(value)
      const newPassword = this.$refs.passWordNoRef.kendoWidget().value()
      console.log(newPassword)

      if (newPassword !== value) {

        this.passwordFail='block'
        this.passwordSuccess='none'

      }else{
        this.passwordFail='none'
        this.passwordSuccess='block'

      }
    },
    getPWRules: function () {
      const resultMsg = { key: true, msg: '' }
      const newPassword = this.$refs.passWordNoRef.kendoWidget().value()
      const newPasswordConfirm = this.$refs.passWordTxRef.kendoWidget().value()
      const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{8,50}$/.test(newPassword)

      if ( EtnersCommonUtil.isEmpty(newPassword) || EtnersCommonUtil.isEmpty(newPasswordConfirm)) {
        resultMsg.key = false
        resultMsg.msg = '새 비밀번호를 입력하여 주세요'
      } else if (!matched) {
        resultMsg.key = false
        resultMsg.msg = '비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.'
      } else if (newPassword !== newPasswordConfirm) {
        resultMsg.key = false
        resultMsg.msg = '비밀번호가 일치하지 않습니다. 기재한 비밀번호를 확인해주세요.'
      }
      return resultMsg
    },
    onSubmit: function(){
      const resultMsg = this.getPWRules()

      if(resultMsg.key){
        const params = {
          'unqUserNo' : this.unqUserNo
          ,'passwordTx' : this.$refs.passWordTxRef.kendoWidget().value()
        }
        this.$store.dispatch('FETCH_UPDATE_PASSWORD',params).then(data => {
          this.resultData = data.resultData

          const adminLoginUserData = JSON.parse(sessionStorage.getItem('adminLoginUserData'))

          if (EtnersCommonUtil.isEmpty(adminLoginUserData)) {
            this.$router.push({name: 'AdminLogin' })
          }else if (adminLoginUserData.authCd != '99'){
            this.$router.push({ name: 'HomeQnaManagement'})
          }else {
            this.$router.push({ name: 'AdminDashboard'})
          }
        })

      }else{
        kendo.alert(resultMsg.msg);
        return false
      }

    },
  },
  data: function(){
    return {
      userId: null,
      unqUserNo: null,
      passValidate: 'inline-block',
      passwordFail: 'none',
      passwordSuccess : 'none',
      passWordTx: null
    }
  }
}

</script>

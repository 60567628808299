import Vue from 'vue'
import Vuex from 'vuex'
import userStoreModule from './store.user'
import urlStoreModule from './store.url'
import codeRequestStoreModule from './store.code.request'
import codeManagementRequestStoreModule from './store.code.management.request'
import codeRequestStoreMstModule from './store.code.managementgroupid.request'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    userStore: userStoreModule,
    // urlStore: urlStoreModule,
    codeRequestStore: codeRequestStoreModule,
    codeManagementRequestStore : codeManagementRequestStoreModule,
    codeRequestStoreMstModule : codeRequestStoreMstModule
  }
})

<template>
  <kendo-window :title="'약관 등록'" 
              ref="termsRef"
              :modal="true" 
              :width="'1200px'" 
              :height="'70%'"
              style="box-sizing:border-box;"
              :visible="false"
              @close="onClickWindowClose"
              >
    <template>
      <div>
        <form ref="termsFormRef" v-kendo-validator @submit.prevent="">
          <div class="contents_wrap">
            <ul class="filter_list">
              <li>
                <dd>
                  <label>카테고리
                    <span class="forg">*</span>
                  </label>
                  <ul class="w25">
                    <li>
                      <kendo-dropdownlist
                          ref="termsSolCdRef"
                          :data-source="$store.state.codeRequestStore.search.combobox.termsSolCd.data"
                          :data-text-field="'cdNmTx'"
                          v-model="termsRowData.termsSolCd"
                          :data-value-field="'cdId'"
                          :option-label="'선택'"
                          :index="0"
                          :style="'width: 200%;'"
                      >
                      </kendo-dropdownlist>
                    </li>
                  </ul>
                </dd>
                <dd>
                  <label> 구분
                    <span class="forg">*</span>
                  </label>
                  <ul class="w25">
                    <li>
                      <kendo-dropdownlist
                          ref="termsCdRef"
                          :data-source="$store.state.codeRequestStore.search.combobox.termsCd.data"
                          :data-text-field="'cdNmTx'"
                          v-model="termsRowData.termsCd"
                          :data-value-field="'cdId'"
                          :option-label="'선택'"
                          :index="0"
                          :style="'width: 200%;'"
                      >
                      </kendo-dropdownlist>
                    </li>
                  </ul>
                </dd>
              </li>
              <li>
                <dd>
                  <label>적용 날짜
                    <span class="forg">*</span>
                  </label>
                  <ul class="w75">
                    <li>
                      <kendo-datepicker
                          ref="startDtRef"
                          :format="'yyyy/MM/dd'"
                          v-model="termsRowData.termsStartDt"
                          placeholder="0000/00/00"
                      >
                      </kendo-datepicker>
                    </li>
                  </ul>
                </dd>
                <dd>
                  <label>
                    사용 유무
                    <span class="forg">*</span>
                  </label>
                  <ul class="w80">
                    <li>
                      <common-radio-group
                          v-model="termsRowData.useYn"
                          :data-items='[{ label: "Y", value: "Y", },
                          { label: "N", value: "N" }]'>
                      </common-radio-group>
                    </li>
                  </ul>
                </dd>
              </li>
              <dd>
              <label>
                최근 디자인
              </label>
              <li>
                  <ul class="w80">
                    <li>
                      <button class="mid_btn orange" @click="onClicklatestDesign">가져오기</button>
                    </li>
                  </ul>
              </li>
            </dd>
              <label>
                내용 입력
                <span class="forg">*</span>
              </label>
              <li>
                <ul class="w100">
                  <div v-bind:style="{ 'z-index': index }">
                  </div>
                  <editor ref="termsContentRef"
                  v-model="termsRowData.termsContents"
                          :tools="editor.settings.editorTools"
                          :resizable-content="true"
                          :resizable-toolbar="true"
                          style="height:1000px;"
                          rows="10"
                          cols="30"
                          validationMessage="내용을 입력해주세요.">
                  </editor>
                </ul>
              </li>
            </ul>
          </div>
        </form>
        <div class="button_area">
          <ul>
            <li class="w40">
              <button class="large_btn" @click="$refs.termsRef.kendoWidget().close()">취소</button>
            </li>
            <li class="w40">
              <button class="large_btn orange" @click="onClickSave">
                {{ termsRowData.updateYn !== 'Y' ? '저장' : '수정' }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </kendo-window>
  
</template>
<script>
import ApiUtil from '@/api/api.util'
import {Editor} from "@progress/kendo-editor-vue-wrapper";
import FileUploadPost from "./FileUploadPost";
import ApiConfig from '@/api/api.config'
import CommonRadioGroup from "./CommonRadioGroup";
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersDateTimeUtil from '@/common/etners.datetime.util'
import $ from 'jquery'

export default {
  name: 'TermsManageWindow',
  props: ['editMode', 'rowData'],
  components: { 'editor': Editor, FileUploadPost, CommonRadioGroup },
  watch: {
    rowData: {
      handler: 'CopyRowDataHandler',
      // handler: "UpdateRowDataHandler"
    }
  },
  created() {
    const vm = this
    this.$store.dispatch('initSearchDataForCodeRequest')
    
  },
  mounted() {
    

  },
  methods: {
    //최신 디자인 호출
    onClicklatestDesign() {
      const vm = this      
      const refs = vm.$refs
      const termsRowData = vm.termsRowData;

      kendo.confirm('최근 디자인을 가져오시겠습니까?').done(function () {
        if (termsRowData.termsSolCd === undefined || termsRowData.termsSolCd === null || termsRowData.termsSolCd === '') {
          kendo.alert('카테고리를 선택하세요')
          return;
        }
        if (termsRowData.termsCd === undefined || termsRowData.termsCd === null || termsRowData.termsCd === '') {
          kendo.alert('구분을 선택하세요')
          return;
        }
        const url = `${ApiConfig.ethDomain}/v1/eth/terms/termsDetail.json`

        const params = {
          loginUserToken: sessionStorage.adminLoginUserToken,
          termsSolCd: vm.termsRowData.termsSolCd,
          termsCd: vm.termsRowData.termsCd,
        }
        ApiUtil.post(url, params).then(response => {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            this.$Progress.fail()
            return false
          }
          const resultStatus = response.data.resultStatus
          vm.termsRowData.termsContents = response.data.resultData.termsContents

          if (resultStatus.messageCode !== '2000') {
            kendo.alert(resultStatus.messageText)
            return false
          }
        })
      }) 
    },

    //copy 등록 시 사용
    CopyRowDataHandler() {
      const vm = this
      vm.termsRowData.termsId = vm.rowData.termsId
      vm.termsRowData.termsSolCd = vm.rowData.termsSolCd
      vm.termsRowData.termsCd = vm.rowData.termsCd
      vm.termsRowData.useYn = vm.rowData.useYn
      vm.termsRowData.endDt = new Date(vm.rowData.endDt)
      vm.termsRowData.termsContents = vm.rowData.termsContents
      vm.termsRowData.updateYn = vm.rowData.updateYn
      vm.termsRowData.copyYn = vm.rowData.copyYn
      if (vm.termsRowData.copyYn == 'Y') {
        vm.termsRowData.termsStartDt = new Date()
      } else {
        vm.termsRowData.termsStartDt = new Date(vm.rowData.termsStartDt)
      }
    },
    
    //취소 버튼 시 기존 값 초기화
    onClickWindowClose() {
      const vm = this
      this.$emit('onClickWindowClose2')
    },

    //필수 값 체크
    onClickSave() {
      const vm = this
      const refs = vm.$refs
      const termsRowData = vm.termsRowData;

      if (termsRowData.termsSolCd === undefined || termsRowData.termsSolCd === null || termsRowData.termsSolCd === '') {
        kendo.alert('카테고리를 선택하세요')
        return;
      }
      if (termsRowData.termsCd === undefined || termsRowData.termsCd === null || termsRowData.termsCd === '') {
        kendo.alert('구분을 선택하세요')
        return;
      }
      if (termsRowData.useYn === undefined || termsRowData.useYn === null || termsRowData.useYn === '') {
        kendo.alert('사용유무를 선택하세요')
        return;
      }
      if (termsRowData.termsContents === undefined || termsRowData.termsContents === null || termsRowData.termsContents === '') {
        kendo.alert('내용을 입력하세요')
        return;
      }
      const url = `${ApiConfig.ethDomain}/v1/eth/terms/termsSave.json`
     
      const params = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        termsSolCd: vm.termsRowData.termsSolCd,
        termsCd : vm.termsRowData.termsCd,
        useYn: vm.termsRowData.useYn,
        termsContents: vm.termsRowData.termsContents,
        startDt: EtnersDateTimeUtil.dateToYYYYMMDD(vm.termsRowData.termsStartDt),
        copyYn: vm.termsRowData.copyYn,
        updateYn: vm.termsRowData.updateYn,
        termsId:vm.termsRowData.termsId
      }
      ApiUtil.post(url, params).then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          return false
        }
        const resultStatus = response.data.resultStatus
        if (resultStatus.messageCode !== '2000') {
          kendo.alert(resultStatus.messageText)
          return false
        }
        kendo.alert('등록되었습니다.')
        vm.$refs.termsRef.kendoWidget().close()
      })
    },
  },
  data() {
    const vm = this
    return {
      loginUserToken: sessionStorage.adminLoginUserToken,
      termsRowData: {
        termsSolCd: "",
        termsCd: "",
        useYn: "Y",
        termsContents: '',
        termsStartDt: '',
        endDt: '',
        copyYn:''
      },
      index: 0,
      editor: {
        settings: {
          editorTools: [
            "viewHtml",
            "fontSize",
            "fontName",
            "foreColor",
            "backColor",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "createLink",
            "tableWizard",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn"
          ]
        }
      },
    }
  }
}
</script>

<template>
  <div id="container">
    <div class="title_wrap">
      <h1>페이롤 뉴스레터</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">
            <li>
              <dd>
                  <label>뉴스레터 제목</label>
                  <ul class="w60">
                    <kendo-maskedtextbox
                            ref="newsletterTitleTxRef"
                            id="newsletterTitleTx"
                            :style="'width: 100%;'"
                            :value="lastRouteParams.filter.searchNewsletterTitleTx"
                    >
                    </kendo-maskedtextbox>
                  </ul>
              </dd>
              <dd>
                  <label>작성일</label>
                  <ul class="w60">
                      <kendo-daterangepicker
                              ref="lastMdfyDtRef"
                              :class="'w60 fl mr5'"
                              :format="'yyyy/MM/dd'"
                              :range="rgstDtRange"
                              @change="commonOnChangeEvent"
                      >
                      </kendo-daterangepicker>

                  </ul>
              </dd>
            </li>
            <li>
              <dd>
                  <label>작성자</label>
                  <ul class="w60">
                    <kendo-maskedtextbox
                            ref="lastMdfyIdRef"
                            id="lastMdfyId"
                            :style="'width: 100%;'"
                            :value="lastRouteParams.filter.searchUserNmKoTx"
                    >
                    </kendo-maskedtextbox>
                  </ul>
              </dd>
              <dd>
                <label>카테고리</label>
                <ul class="w30">
                  <kendo-dropdownlist
                      ref="categoryCdRef"
                      :data-source="$store.state.codeRequestStore.search.combobox.categoryCd.data"
                      :data-text-field="'cdNmTx'"
                      :data-value-field="'cdId'"
                      :option-label="'전체'"
                      :index="0"
                      :style="'width: 100%;'"
                      :value="lastRouteParams.filter.searchTemplateGbn"
                      @change="commonOnChangeEvent"
                  >
                  </kendo-dropdownlist>
                </ul>
              </dd>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div class="contents_top_sec">
          <button class="mid_btn orange fl" @click="onClickCreateNewNewsletter">
            <span class="k-icon k-i-plus"></span> 뉴스레터 등록
          </button>
        </div>
        <kendo-grid
            ref="newsletterListGridRef"
            style="cursor: pointer;"
            :data-source="computedNewsletterListDataSource"
            :pageable-always-visible="true"
            :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
            :reorderable="true"
            :column-menu="false"
            :resizable="true"
            :selectable="true"
            :sortable="true"
            :columns="newsletterGridRefColumns"
            :dataBound="dataBoundForNewsletter"
            @change="onChangeNewsletterListGrid"
            :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        >
        </kendo-grid>

        <kendo-window :title="editMode == 'INSERT' ? '뉴스레터 등록' : '뉴스레터 수정'"
                      ref="newsletterRef"
                      :modal="true"
                      :width="'1200px'"
                      :height="'70%'"
                      style="box-sizing:border-box;"
                      :visible="false"
                     >
          <payroll-newsletter-manage-window ref="newsletterWindowRef"
                                :newsletterRowData="newsletterRowData"
                                :newsletterAttachmentFileData="newsletterAttachmentFileData"
                                :newsletterThumbnailFileData="newsletterThumbnailFileData"
                                :editMode='editMode'
                                @editModeUpdate="editModeUpdate"
                                @onClickWindowClose="onClickWindowClose">
          </payroll-newsletter-manage-window>
        </kendo-window>
      </div>
    </section>
  </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersKendoGridUtil from '@/common/kendo.grid.util'
  import EtnersParseUtil from '@/common/etners.parse.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'
  //import { saveExcel } from '@progress/kendo-vue-excel-export'
  import PayrollNewsletterManageWindow from '../../../components/PayrollNewsletterManageWindow.vue'

  import $ from 'jquery'

  export default {
    name: 'PayrollNewsLetterManagement',
    components:{
      PayrollNewsletterManageWindow
    },
    created () {
      this.$store.dispatch('initSearchDataForCodeRequest')

      let defaultLastRouteParams = {
        page: 1,
        filter: {
          searchRgstNm: ''
        },
      }

      let jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')
      this.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'newsletterDetail' ? (jsonStrLastRouteParams === undefined
          ? defaultLastRouteParams
          : JSON.parse(jsonStrLastRouteParams)) : defaultLastRouteParams

      if (EtnersCommonUtil.isNotEmpty(this.lastRouteParams)) {
        this.lastRouteParams.page = this.lastRouteParams.filter.page
      }

    },
    mounted () {
      const vm = this

      //엔터키 이벤트 자동 세팅
      const enterkeyEventTargetIdArray = [
         'newsletterTitleTx','lastMdfyId'
      ]

      for (let elementId of enterkeyEventTargetIdArray) {
        let element = document.getElementById(elementId)
        if (element !== undefined) {
          element.onkeyup = function (e) {
            const ENTER_KEY_CODE = 13
            let currentKeyCode = window.event.keyCode || e.which
            if (currentKeyCode === ENTER_KEY_CODE) {
              vm.commonOnChangeEvent(e)
            }
          }
        }
      }
      this.$refs.lastMdfyDtRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      });
      this.$refs.lastMdfyDtRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      });

    },
    computed: {
      computedNewsletterListDataSource: function () {
        const vm = this

        let lastRequestParameterPage = vm.lastRouteParams.page !== undefined ? vm.lastRouteParams.page : 1

        const newsletterListDataSource = new kendo.data.DataSource({
          name: 'newsletterListDataSource',
          page: lastRequestParameterPage,
          pageSize: 10,
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
          transport: {
            read: {
              url: `${ApiConfig.ethDomain}/v1/eth/newsletter/newsletterList.json`,
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              cache: false,
            },
            parameterMap: function (options, operation) {
              if (operation === 'read') {
                const filter = vm.currentPageFilterOption()
                let loginUserToken = sessionStorage.adminLoginUserToken

                vm.replaceSortFieldStr(options)

                let params = {
                  loginUserToken: loginUserToken,
                  searchLastMdfyId: filter.lastMdfyId,
                  searchNewsletterTitleTx: filter.newsletterTitleTx,
                  searchLastMdfyDtStartYYYYMMDD : filter.searchLastMdfyDtStartYYYYMMDD,
                  searchLastMdfyDtEndYYYYMMDD : filter.searchLastMdfyDtEndYYYYMMDD,
                  searchCategoryCd : filter.searchCategoryCd,
                  skip: options.skip,
                  take: options.take,
                  page: options.page,
                  pageSize: options.pageSize,
                  sort: options.sort,
                  requestType: 'PAGING', //PAGING or EXPORT
                  solCd: "02",
                  cdGrupId: '006',

                }

                sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

                return kendo.stringify(params)
              }

              if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) }
              }
            },
          },
          schema: {
            type: 'json',
            parse: function (response) {

                let changeResultData = response.resultData
                let rowNumber = response.total + 1 - (vm.$refs.newsletterListGridRef.dataSource.page() - 1)
                    * vm.$refs.newsletterListGridRef.dataSource.pageSize()
                if(changeResultData!=null) {

                  for (let i = 0; i < changeResultData.length; i++) {
                    let row = changeResultData[i]
                    let newsletterId = changeResultData[i].newsletterId
                    row.newsletterId = newsletterId
                    row.rowNumber = --rowNumber;
                }
                response.resultData = changeResultData
              }
              return response
            },
            total: function (response) {
              return response.total
            },
            data: function (response) {
              if (response.resultStatus.messageCode === '2000') {
                return response.resultData
              } else {
                kendo.alert('message: ' + response.resultStatus.messageText)
                return ''
              }
            },
          },

          error: function (e) {
            console.log('error event handler', e.errors[0])
          },
        })

        return newsletterListDataSource
      },
    },
    methods: {
      commonOnChangeEvent: function () {
        const vm = this
        let gridRef = vm.$refs.newsletterListGridRef

        // 마지막 검색 데이터 page 초기화
        this.lastRouteParams.page = 1

        if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
          if (gridRef.kendoWidget().dataSource !== undefined) {
            gridRef.kendoWidget().dataSource.page(1)
          }
        }
      },
      currentPageFilterOption: function () {
        const vm = this
        const refs = vm.$refs

        let currentSearchDataObj = {}

        const filterData = vm.lastRouteParams.filter

        //카테고리
        if (refs.categoryCdRef !== undefined) {
          const searchCategoryCd = refs.categoryCdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchCategoryCd)) {
            currentSearchDataObj.searchCategoryCd = searchCategoryCd
          }
        }

        //제목
        if (refs.newsletterTitleTxRef !== undefined) {
          const searchNewsletterTitleTx = refs.newsletterTitleTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchNewsletterTitleTx)) {
            currentSearchDataObj.newsletterTitleTx = searchNewsletterTitleTx
          }
        }

        //등록자
        if (refs.lastMdfyIdRef !== undefined) {
          const searchLastMdfyId = refs.lastMdfyIdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchLastMdfyId)) {
            currentSearchDataObj.lastMdfyId = searchLastMdfyId
          }
        }

        // 요청일자
      if (refs.lastMdfyDtRef !== undefined) {
        const rgstDtRangeValue = refs.lastMdfyDtRef.kendoWidget().range()

        if (EtnersCommonUtil.isNotEmpty(rgstDtRangeValue)) {
          const startYYYYMMDD = (rgstDtRangeValue.start !== undefined && rgstDtRangeValue.start !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(rgstDtRangeValue.start)
                              : undefined
          const endYYYYMMDD = (rgstDtRangeValue.end !== undefined && rgstDtRangeValue.end !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(rgstDtRangeValue.end)
                              : undefined

          currentSearchDataObj.searchLastMdfyDtStartYYYYMMDD = startYYYYMMDD
          currentSearchDataObj.searchLastMdfyDtEndYYYYMMDD = endYYYYMMDD
        }
      }
        vm.filterData = currentSearchDataObj

        return currentSearchDataObj
      },
      onClickCreateNewNewsletter: function (e) {
        const vm = this
        vm.editMode = 'INSERT'
        vm.newsletterRowData.newsletterId = ''
        vm.newsletterRowData.newsletterTitleTx = ''
        vm.newsletterRowData.newsletterContentsTx = ''
        vm.newsletterRowData.newsletterDescription = ''
        vm.newsletterRowData.newsletterKeyword = ''
        vm.newsletterRowData.categoryCd = ''
        vm.newsletterRowData.solCd = '02'
        vm.uploads = ''
        vm.newsletterAttachmentFileData = ''
        vm.newsletterThumbnailFileData = ''
        let newsletterManageWindow = this.$refs.newsletterRef.kendoWidget()
        newsletterManageWindow.center().open()
      },
      dataBoundForNewsletter: function (evt) {
         let vm = this
         let isDragging = false;

         $(".k-grid td")
         .mousedown(function() {
            $(window).mousemove(function() {
                isDragging = true;
                $(window).unbind("mousemove");
            });
         })
         .mouseup(function() {
            let wasDragging = isDragging;
            isDragging = false;
            $(window).unbind("mousemove");
            if (!wasDragging) {
              let row = evt.sender.selectable.userEvents.currentTarget
              let selectedRow = evt.sender.select();
            }
         });
     },
      //row 클릭 이벤트
      onChangeNewsletterListGrid: function (e) {
        const vm = this

        let selectedRow = e.sender.select();
        const rowData = e.sender.dataItem(selectedRow);
        vm.editMode = 'DETAIL'

        let param = {
          loginUserToken: sessionStorage.adminLoginUserToken,
          newsletterId: rowData.newsletterId,
          cdGrupId : '006'
        }

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/newsletter/newsletterDetail.json`, param).then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              let result = response.data.resultData
              vm.newsletterRowData.newsletterId = result.newsletterId
              vm.newsletterRowData.categoryCd = result.categoryCd
              vm.newsletterRowData.newsletterTitleTx = result.newsletterTitleTx
              vm.newsletterRowData.lastMdfyId = result.lastMdfyId
              vm.newsletterRowData.newsletterContentsTx = result.newsletterContentsTx
              vm.newsletterRowData.newsletterDescription = result.newsletterDescription
              vm.newsletterRowData.newsletterKeyword= result.newsletterKeyword
              vm.newsletterFileData = response.data.resultData.newsletterFiles
              vm.newsletterRowData.fmtFrstRgstDt = rowData.fmtFrstRgstDt
              vm.newsletterAttachmentFileData = response.data.resultData.newsletterAttachmentFiles
              vm.newsletterThumbnailFileData = response.data.resultData.newsletterThumbnailFiles

            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
        )
        let newsletterManageWindow = this.$refs.newsletterRef.kendoWidget()
        newsletterManageWindow.center().open()
      },
      // 초기화 버튼 클릭 이벤트
      cleanSearchDataEvent (e) {
        const refs = this.$refs

        //카테고리
        if (refs.categoryCdRef !== undefined) {
          refs.categoryCdRef.kendoWidget().select(0)
        }

        if (refs.lastMdfyDtRef !== undefined) {
          refs.lastMdfyDtRef.kendoWidget().range({start: undefined, end: undefined})
        }

        if (refs.newsletterTitleTxRef !== undefined) {
          refs.newsletterTitleTxRef.kendoWidget().value('')
        }

        if (refs.lastMdfyIdRef !== undefined) {
          refs.lastMdfyIdRef.kendoWidget().value('')
        }

        sessionStorage.removeItem('LAST_ROUTE_PARAMS')

        this.commonOnChangeEvent(e)
      },
      replaceSortFieldStr (options) {
        if (options.sort != undefined && options.sort.length != 0){
          let sortField = options.sort[0].field
          let replaceField

          switch (sortField) {
            case "rowNumber":
              replaceField = "seqNo"
              break;
            case "lastMdfyDtStr":
              replaceField = "lastMdfyDt"
              break;
            default:
              replaceField = sortField
          }
          options.sort[0].field = replaceField
        }
      },
      onClickWindowClose() {
        this.$refs.newsletterRef.kendoWidget().close()
        this.$refs.newsletterListGridRef.kendoWidget().dataSource.read()
      },
      editModeUpdate(editMode) {
        const vm =this
        vm.editMode = editMode
      }
    },
    data: function () {
      const kendoGridHeaderAttributes = {
        style: 'font-weight: bold;',
      }

      const vm = this

      return {
        newsletterAttachmentFileData: {},
        newsletterThumbnailFileData: {},
        rowNumber: 0,
        newsletterRowData: {
          newsletterId: '',
          newsletterTitleTx: '',
          newsletterContentsTx: '',
          newsletterDescription: '',
          newsletterKeyword: '',
          categoryCd: '',
          solCd: '',
        },  
        newsletterDescription: '',
        newsletterKeyword:'',
        newsletterFileData: {},
        editMode: '',
        val: '',
        rgstDtRange: {start: undefined, end: undefined},
        messages: {
          year: '년',
          month: '월',
          day: '일',
        },
        filterData: {},
        lastRouteParams: {
          page: 1,
          filter: {
            searchRgstNm: '',
            searchTemplateGbn: '',
            searchTemplateNm: ''
          },
        },
        newsletterGridRefColumns: [
          {
            field: 'rowNumber',
            title: 'No.',
            width: '8%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'categoryNm',
            title: '카테고리',
            width: '10%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'newsletterTitleTx',
            title: '뉴스레터 제목',
            width: '50%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'fmtFrstRgstDt',
            title: '작성일',
            width: '20%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'lastMdfyId',
            title: '작성자',
            width: '20%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
        ],
      }
    },
  }
</script>

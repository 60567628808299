<template>
  <div>
    <form ref="ReportFormRef" v-kendo-validator @submit.prevent="">
      <div class="contents_wrap">
        <ul class="filter_list">
          <li>
              <label>제목
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <li>
                  <kendo-maskedtextbox
                      ref="reportTitleTxRef"
                      :disabled="boolReadonly"
                      v-model ="reportRowData.reportTitleTx"
                      id="reportTitleTx"
                      :placeholder="'제목을 입력하세요.'"
                      :style="'width: 100%;'"
                      maxlength="60"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
          </li>
          <li v-show = "editMode == 'INSERT' || editMode == 'UPDATE'">
            <label>썸네일 이미지
              <span class="forg">*</span>
            </label>
            <ul class="w80 icon_file">
              <li>
                <div class="upload">
                  <file-upload-post v-bind:file="uploads"
                                      @remove="removeFiles"
                                      @child="bindingFiles">
                  </file-upload-post>
                </div>
              </li>
            </ul>
          </li>
          <li v-show = "editMode == 'DETAIL'">
            <label>썸네일 이미지
              <span class="forg">*</span>
            </label>
            <ul class="w80 icon_file">
              <li v-for="file in reportFileData">
                <div class="btn_file"><span class="k-icon k-i-download"></span>
                  <a v-bind:href='makeFileUrl(file)'>{{file.orgFileNameTx}}</a>
                </div>
              </li>
            </ul>
          </li>
          <li v-if = "editMode == 'DETAIL'">
              <label>등록일
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <li>
                  <kendo-maskedtextbox
                      ref="reportFrstRgstDtRef"
                      v-model ="reportRowData.fmtLastMdfyDt"
                      :disabled="boolReadonly"
                      id="reportTitleTx"
                      :style="'width: 100%;'"
                      maxlength="50"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
          </li>
          <li v-if = "editMode == 'DETAIL'">
              <label>등록자
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <li>
                  <kendo-maskedtextbox
                      ref="reportFrstRgstIdRef"
                      v-model ="reportRowData.lastMdfyId"
                      :disabled="boolReadonly"
                      id="reportFrstRgstId"
                      :style="'width: 100%;'"
                      maxlength="50"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </li>
          <li>
            <label>내용
              <span class="forg">*</span>
            </label>
              <ul class="w80" v-show="editMode == 'INSERT' || editMode == 'UPDATE'">
                <div id="overlay" v-bind:style="{'z-index' : index}">
                </div>
                <editor ref="reportContentRef"
                        id="reportContent"
                        :tools="editor.settings.editorTools"
                        :disabled="boolReadonly"
                        :resizable-content="true"
                        :resizable-toolbar="true"
                        v-model ="reportRowData.reportContentsTx"
                        style="height:450px;"
                        rows="10"
                        cols="30"
                        validationMessage="내용을 입력해주세요.">
                </editor>
              </ul>
              <ul class="w80" v-show="editMode == 'DETAIL'">
                <li v-html="reportRowData.reportContentsTx" ref="reportContentsTxRef"></li>
              </ul>
            </li>
          </li>
        </ul>
      </div>
    </form>
  <div class="button_area">

  <template v-if = "editMode == 'INSERT' || editMode == 'UPDATE'">
    <ul>
      <li class="w40">
        <button class="large_btn" @click="onClickWindowClose">취소</button>
      </li>
      <li class="w40">
        <button class="large_btn orange" @click="onClickReportSave">저장</button>
      </li>
    </ul>
  </template>

  <template v-else-if = "editMode == 'DETAIL'">
    <ul>
      <li class="w40">
        <button class="large_btn" @click="onClickReportDelete">삭제</button>
      </li>
      <li class="w40">
        <button class="large_btn orange" @click="onClickReportModify">수정</button>
      </li>
    </ul>
  </template>
  </div>
</div>
</template>

<script>
import $ from 'jquery'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersParseUtil from '@/common/etners.parse.util'
import Constant from '@/common/constant'
import { Editor } from '@progress/kendo-editor-vue-wrapper';
import FileUploadPost from '@/components/FileUploadPost'

export default {
  name: 'reportManageWindow',
  props: [ 'reportRowData', 'editMode', 'reportFileData'],
  components: {'editor': Editor, FileUploadPost},
  data() {
    const vm = this
    return {
      mms:0,
      templateGbnVal: '',
      reportInfo:{},
      htmlText: ``,
      boolReadonly : false,
      index :0,
      uploads : [],
      existFileId : '',

      editor: {
        settings: {
          editorTools: [
            "viewHtml",
            "fontSize",
            "fontName",
            "foreColor",
            "backColor",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "createLink",
            "tableWizard",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn"
          ]
        }
      },


    }
  },
  mounted() {
    const vm = this
    vm.initFileDataHandler()
  },
  watch: {
    reportFileData: {
      handler: 'initFileDataHandler'
    },
    editMode: {
      handler: 'initDataHandler'
    }
  },
  methods: {
    initDataHandler() {
      const vm = this
      vm.boolReadonly = false
      vm.index = 0
      if (this.editMode == 'DETAIL'){
        vm.boolReadonly = true
        vm.index = 2
      }else if (this.editMode == 'INSERT'){
        this.reportRowData.reportId = ''
        this.reportRowData.reportTitleTx = ''
        this.reportRowData.reportContentsTx = ''
        this.$refs.reportContentRef.kendoWidget().value('')
        vm.uploads= []
      }
    },
    onClickReportSave() {
      const vm = this
      const userName = JSON.parse(sessionStorage.getItem('adminLoginUserData')).userNmKoTx

      let confirmKeyword = (vm.editMode === 'INSERT' ? '등록' : '수정')
      let reportTitleTx = this.$refs.reportTitleTxRef.kendoWidget().value()
      let reportContentsTx = vm.reportRowData.reportContentsTx
      let reportId = vm.reportRowData.reportId

      let param = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        reportId,
        reportTitleTx,
        reportContentsTx,
        frstRgstId: userName,
        lastMdfyId: userName,
        reportFileId : undefined,
      }

      if (EtnersCommonUtil.isEmpty(reportTitleTx)){
        kendo.alert('제목을 입력해주세요.')
        return false
      }
      if (vm.uploads.length == 0) {
        kendo.alert("썸네일 파일을 선택해주세요.")
        return
      }
      if (vm.uploads.length > 1) {
        kendo.alert("첨부파일은 하나만 업로드 가능합니다.")
        return
      }

      if (this.validateReport()) {
        kendo.confirm(confirmKeyword + '하시겠습니까?').done(function() {
          let filesParam = {
              loginUserToken: sessionStorage.adminLoginUserToken,
              wmGbn: '01',
              existFileId: vm.existFileId,
              existSeqs: []
          }

          const formData = new FormData()

          vm.uploads.forEach(function(v) {
            formData.append("file", v.rawFile)

            if (v.seq !== undefined) {
              filesParam.existSeqs.push(v.seq)
              filesParam.existFileId = v.fileId
            }
          })

          formData.append("uploadAuthParam", new Blob([JSON.stringify(filesParam)], {
            type: "application/json"
          }))

          vm.$Progress.start()

          ApiUtil.postIncludeUploadFile(`${ApiConfig.ethDomain}/v1/eth/post/saveFileUpload.json`, formData).then(response => {
            if (response.data.resultStatus.messageCode != "2000") {
              kendo.alert(`${response.data.resultStatus.messageText}`)
              vm.$Progress.fail()
              return
            }
            param.reportFileId = response.data.resultData
            vm.editReport(param)
          })
        })
      }
    },
    editReport(param) {
      const vm =this

      if (vm.editMode === 'INSERT') {
        vm.$Progress.start()

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/addReport.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            vm.$Progress.fail()
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            vm.$Progress.fail()
            return false
          }

          vm.$Progress.finish()
          vm.onClickWindowClose()
          kendo.alert('등록 되었습니다.')
          vm.initDataHandler()
        })
      } else if (vm.editMode === 'UPDATE') {
        vm.$Progress.start()

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/modifyReport.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            vm.$Progress.fail()
            return false
          }

          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            vm.$Progress.fail()
            return false
          }

          vm.$Progress.finish()
          vm.onClickWindowClose()
          kendo.alert('수정 되었습니다.')
        })
      }
    },
    onClickReportModify() {
      this.$emit("editModeUpdate" , 'UPDATE')
    },
    initFileDataHandler: function() {
      const vm = this
      const attachFiles = vm.reportFileData

      vm.uploads = []

      if(EtnersCommonUtil.isNotEmpty(attachFiles)){
        const fileId = attachFiles[0].fileId
        vm.existFileId = fileId
        attachFiles.forEach(function(v) {
          const fileInfo = {
            'name': v.orgFileNameTx,
            'size': v.fileSizeTx,
            'extension': v.fileExtTx,
            'seq': v.fileSeqNo,
            'fileId': v.fileId,
            'uid': v.fileId + v.fileSeqNo,
            'url': `${ApiConfig.ethDomain}/files/${v.fileId}`
          }
          vm.uploads.push(fileInfo)
        })
      }
    },
    removeFiles () {
      const vm = this
      let idx = 0
      vm.uploads.forEach(upload => {
        upload.seq = idx
        idx++
      })
    },
    bindingFiles(files) {
      const vm = this
      files.forEach(function(fileInfo) {
        vm.uploads.push(fileInfo)
      })
    },
    validateReport() {
      const vm = this

      let reportTitleTx = this.$refs.reportTitleTxRef.kendoWidget().value()
      let reportContentsTx = vm.reportRowData.reportContentsTx

      if (EtnersCommonUtil.isEmpty(reportTitleTx)){
        kendo.alert('제목을 입력해주세요.')
        return false
      }

      return true
    },
    onClickWindowClose(){
      this.$emit('onClickWindowClose')
    },
    onClickReportDelete(){
      const vm = this

      let reportId = vm.reportRowData.reportId

        kendo.confirm('삭제 하시겠습니까?').done(function() {
          let param = {
            loginUserToken: sessionStorage.adminLoginUserToken,
            reportId: reportId
          }

          vm.$Progress.start()
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/deleteReport.json`, param).then(function(response) {
            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
              vm.$Progress.fail()
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              vm.$Progress.fail()
              return false
            }
            vm.$Progress.finish()

            kendo.alert('삭제 되었습니다.')

            vm.onClickWindowClose()

          })
        })
    },
    makeFileUrl: function(file) {
      return `${ApiConfig.ethDomain}/download/${file.fileId}/${file.fileSeqNo}`
    }
  }
}
</script>

<style scoped>
  #overlay{
    top:100px;
    position:absolute;
    /* background-color:black; */
    opacity:0.07;
    width:800px;
    height:400px;
  }
  #reportContent{
    top:100px;
    position:absolute;

  }
</style>

import Vue from 'vue'
import App from './App.vue'
import VueProgressBar from 'vue-progressbar'
import { router } from '@/routes' // vue-router
import { store } from '@/store' // vuex
import ApiUtil from './api/api.util' // Etners API Util
import ErrorFilter from './common/error.filter' // Error Filter(vue)

import '@progress/kendo-ui'

import ApiConfig from './api/api.config'
import VueI18n from 'vue-i18n'
import { DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper'
import { Upload, UploadInstaller } from '@progress/kendo-upload-vue-wrapper'


import { Validator, ValidatorInstaller } from '@progress/kendo-validator-vue-wrapper'
/* Kendo Inputs */
import {
  MaskedTextBox,
  NumericTextBox,
  InputsInstaller,
  Switch,
} from '@progress/kendo-inputs-vue-wrapper'

import LoadScript from 'vue-plugin-load-script'

/* Kendo Date-Picker */
import {
  DateRangePicker,
  DateinputsInstaller,
} from '@progress/kendo-dateinputs-vue-wrapper'


/* Kendo Button */
import {
  Button,
  ButtonGroup,
  ButtonGroupButton,
  ToolBar,
  ToolBarItem,
  ButtonsInstaller,
} from '@progress/kendo-buttons-vue-wrapper'

/* Kendo Wrapper Grid */
import {
  Grid,
  GridInstaller,
} from '@progress/kendo-grid-vue-wrapper'

/* Kendo Dropdowns */
import {
  AutoComplete,
  ComboBox,
  DropDownList,
  MultiSelect,
  MultiColumnComboBox,
  MultiColumnComboBoxColumn,
  DropdownsInstaller,
} from '@progress/kendo-dropdowns-vue-wrapper'

/* Kendo Window */
import { Window, WindowInstaller } from '@progress/kendo-window-vue-wrapper'

/* Kendo Chart */
import { ChartInstaller } from '@progress/kendo-charts-vue-wrapper'

/* Kendo Editor */
import { Editor, EditorTool, EditorInstaller } from '@progress/kendo-editor-vue-wrapper'

/* Kendo Layout */
import { ContextMenu, Menu, PanelBar, Splitter, TabStrip, LayoutInstaller } from '@progress/kendo-layout-vue-wrapper'

import '@progress/kendo-ui/js/cultures/kendo.culture.ko-KR.js'

kendo.culture('ko-KR')
kendo.calendar.views[0].title = function (date) {
  const culture = kendo.culture()

  const month = culture.calendar.months.names[date.getMonth()]
  const year = date.getFullYear()

  let title
  title = year + ' ' + month

  return title
}

const vueProgressBarOptions = {
  color: '#fe5f40',
  failedColor: '#bffaf3',
  thickness: '3px',
  transition: {
    speed: '0.3s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  inverse: false,
}

Vue.use(VueProgressBar, vueProgressBarOptions)

Vue.use(DataSourceInstaller)
Vue.use(UploadInstaller)
Vue.use(ButtonsInstaller)
Vue.use(DropdownsInstaller)
Vue.use(DateinputsInstaller)

Vue.use(ValidatorInstaller)
Vue.use(InputsInstaller)
Vue.use(GridInstaller)
Vue.use(WindowInstaller)
Vue.use(InputsInstaller)

Vue.use(ChartInstaller)

Vue.use(EditorInstaller)

Vue.use(LayoutInstaller)

Vue.use(LoadScript)

Vue.config.productionTip = false
Vue.filter('error', ErrorFilter)

Vue.use(VueI18n)

ApiUtil.init()

Vue.directive('focus', {
  // 바인딩 된 엘리먼트가 DOM에 삽입되었을 때...
  inserted: function (el) {
    // 엘리먼트에 포커스를 줍니다
    el.focus()
  },
})

new Vue({
  i18n: new VueI18n({
    locale: 'ko', // 기본언어는 ko로 유지하지만 브라우저 언어를 체크해서 변경
    fallbackLocale: 'ko',
    messages: [],
  }),
  beforeCreate () {
    // this.$store.dispatch('FETCH_URL')
    const vm = this

  },
  components: {
    directives: {
      focus: {
        inserted: function (el) {
          el.focus()
        },
      },
    },
    Validator,
    /* Upload */
    Upload,

    /* Input */
    MaskedTextBox,
    NumericTextBox,
    /* Date-Picker */
    DateRangePicker,

    /* Button */
    Button,
    ButtonGroup,
    ButtonGroupButton,
    ToolBar,
    ToolBarItem,
    DropDownList,
    /* Wrapper Grid */
    Grid,

    /* Window */
    Window,

    /* Editor */
    Editor,

    /* Layout */
    ContextMenu,
    Menu,
    PanelBar,
    Splitter,
    TabStrip,
  },
  store,
  router,
  render: h => h(App),
}).$mount('#app')

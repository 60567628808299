<template>
  <div class="k-form-field">
    <span v-for="(item, index) in dataItems" :key="item.value">
      <input type="radio"
             ref="radioRef"
             class="k-radio"
             :value="dataValueField ? item[dataValueField] : item.value"
             :id="id ? id+'0'+index : randomId+'0'+index"
             :name="(name !== undefined ? name : randomName )"
             v-bind:checked="value === null || value === undefined ?
                             (dataValueField ? item[dataValueField] : item.value) === defaultValue :
                             (dataValueField ? item[dataValueField] : item.value) === value"
             @change="$emit('custom-change', $event.target.value)"
             @click="onClickCustom"
             :disabled="disabled">
      <label :for="id ? id+'0'+index : randomId+'0'+index"
             :class="(index !== dataItems.length-1 ? 'k-radio-label w30 mr10' : 'k-radio-label w30 ') + (focusClass ? ' txt_focus' : '')" >
        {{ dataTextField ? item[dataTextField] : item.label}}
      </label>
    </span>
  </div>
</template>

<script>
export default {
  name: 'RadioGroup',
  model: {
    prop: 'value',
    event: 'custom-change'
  },
  props: {
    id : {
      type : String,
    },
    name: {
      type : String,
    },
    disabled : {
      type : Boolean,
      default : false
    },
    value : {},
    defaultValue : {},
    dataItems: {
      type : Array,
      default: function (){
        return[
          {label: "예", value: "Y",},
          {label: "아니오", value: "N",},
        ]
      }
    },
    dataTextField : {
      type : String,
      default : 'label'
    },
    dataValueField : {
      type : String,
      default : 'value'
    }
  },
  mounted () {
    this.randomId = Math.random() * 1000000000000
    this.randomName = Math.random() * 1000000000000
  },
  data () {
    return {
      randomId : '',
      randomName : '',
      focusClass : false
    }
  },
  methods: {
    onClickCustom: function(e) {
      let name = this.name
      let value = e.target.value

      this.focusClass = false

      this.$emit('click', { value, name })
    },
    widget(){
      const vm = this
      let methods ={
        focus: function() {
          vm.focusClass = true
        },
      }
      return methods
    },
  },
}
</script>

<style scoped>
</style>

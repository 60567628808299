<template>
  <main id="main" class="eng">
    <!-- //header-->
		<div id="container_home" style="background: #ffffff;">
			<!-- 문의 -->
			<div class="company_inner">
        <submit-question-window ref="submitQuestionWindowRef" :style="popupCss"
                                :submitData="submitData"
                                :popType="popType"
                                @onClickWindowClose="onClickWindowClose">
        </submit-question-window>
				<h1 class="page_tit animated on d-1s">Service Inquiry</h1>
				<!--태그선택시 등록 입력폼 활성화-->
				<div class="bundle_tag animated on d-3s">
					<a :class="[serviceType==='01'? 'on':'']" @click="setClassOn('service','01')"># Shared Service</a>
					<a :class="[serviceType==='02'? 'on':'']" @click="setClassOn('service','02')"># Payroll</a>
					<a :class="[serviceType==='05'? 'on':'']" @click="setClassOn('service','05')"># Curation</a>
					<a :class="[serviceType==='11'? 'on':'']" @click="setClassOn('service','11')"># ESOP</a>
					<a :class="[serviceType==='06'? 'on':'']" @click="setClassOn('service','06')"># Mall</a>
					<a :class="[serviceType==='07'? 'on':'']" @click="setClassOn('service','07')"># Moving</a>
					<a :class="[serviceType==='04'? 'on':'']" @click="setClassOn('service','04')"># Housing</a>
					<a :class="[serviceType==='08'? 'on':'']" @click="setClassOn('service','08')"># Relocation</a>
					<a :class="[serviceType==='03'? 'on':'']" @click="setClassOn('service','03')"># Bidding</a>
					<a :class="[serviceType==='09'? 'on':'']" @click="setClassOn('service','09')"># Shop</a>
					<a :class="[serviceType==='10'? 'on':'']" @click="setClassOn('service','10')"># EFS</a>
					<a :class="[serviceType==='13'? 'on':'']" @click="setClassOn('service','13')"># ESRM</a>
					<a :class="[serviceType==='00'? 'on':'']" @click="setClassOn('service','00')"># ETC</a>
				</div>
				<!--등록-->
				<div class="wrap_vision animated on d-2s">
					<div>
						<div class="fl_input">
							<label>Company Name <span class="dh_orange">*</span></label>
              <kendo-maskedtextbox
                                  ref="companyNmTxRef"
                                  maxlength="14"
                                  name="companyNmTx"
                                  :style="'width: 100%;'"
                          ></kendo-maskedtextbox>
						</div>
						<div class="fl_input">
							<label>Contact No.<span class="dh_orange">*</span></label>
              <kendo-maskedtextbox
                                  ref="phoneNoTxRef"
                                  maxlength="30"
                                  name="phoneNoTx"
                                  id="phoneNoTx"
                                  :placeholder="'01012341234'"
                                  :style="'width: 100%;'"
                          ></kendo-maskedtextbox>
						</div>
						<div class="fl_input">
							<label>E-mail <span class="dh_orange">*</span></label>
              <kendo-maskedtextbox
                                  ref="emailTxRef"
                                  maxlength="33"
                                  name="emailTx"
                                  id="emailTx"
                                  :placeholder="'ex@etners.com'"
                                  :style="'width: 100%;'"
                          ></kendo-maskedtextbox>
						</div>
						<div class="fl_input">
							<label>Name <span class="dh_orange">*</span></label>
              <kendo-maskedtextbox
                                  ref="managerNmTxRef"
                                  maxlength="14"
                                  name="managerNmTx"
                                  :style="'width: 100%;'"
                          ></kendo-maskedtextbox>
						</div>
           <div class="fl_input" :style="payrollCss">
             <label>Number of Employees<span class="dh_orange">*</span></label>
             <kendo-maskedtextbox
                                 ref="compUserCntRef"
                                 maxlength="10"
                                 name="compUserCnt"
                                 :style="'width: 100%;'"
                         ></kendo-maskedtextbox>
           </div>
           <div class="diclear" :style="payrollCss">
             <label>Types of Companies<span class="dh_orange">*</span></label>
             <ul class="comp_group">
               <li
                   v-for="(item, index) in compType"
                  :key="index"
                  :id="item.id"
                  :class="{on:getActive(item.id,'comp')}"
                  @click ="setSelectedList(item.id,'comp')">
                    {{item.value}}
                  </li>
               </ul>
           </div>

           <div class="diclear" :style="payrollCss">
             <label>Interests<span class="dh_orange">*</span></label>
             <ul class="comp_group">
              <li
                   v-for="(item, index) in interestType"
                   :key="index"
                   :id="item.id"
                   :class="{on:getActive(item.id,'inte')}"
                   @click ="setSelectedList(item.id,'inte')">
                 {{item.value}}
               </li>
             </ul>
           </div>
						<div><label>Inquiry <span class="dh_orange">*</span></label>
							<textarea v-model="vModel.qnaContents" id="qnaContentsRef" name="qnaContents" style="height:120px;"></textarea>
						</div>
            <div>
              <div>
                <input type="checkbox" v-model="vModel.agree_pledge" id="agree_pledge" class="k-checkbox" style="display: none;">
                <label for="agree_pledge" class="k-checkbox-label">&nbsp;<span class="dh_orange mr10"><b>*</b></span>I hereby agree and confirm that all my Personal Information can be used and collected.
                  <a :href="'/../privacy_en.html?solCd='+serviceType" target="_blank" class="dh_orange">[View All]</a>
                </label>
              </div>
              <div>
                <input type="checkbox" v-model="vModel.agree_marketing" id="agree_marketing" class="k-checkbox" style="display: none;">
                <label for="agree_marketing" class="k-checkbox-label">ㆍ[Select] I agree to use marketing.
                  <a :href="'/../marketing_en.html?solCd='+serviceType" target="_blank" class="dh_orange">[View All]</a>
                </label>
              </div>
            </div>
						<div class="confm">
							<button @click="fnSubmit">Register your Inquiry</button>
						</div>
					</div>
				</div>
				<!--//등록-->
			</div>
			<!-- //문의  -->
		</div>
  </main>
</template>
<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import SubmitQuestionWindow from '@/components/SubmitQuestionWindow'
  const ethDomain = ApiConfig.ethDomain
  import $ from 'jquery'


  export default {
    name: 'SubmitQuestion',
    components: {
      SubmitQuestionWindow
    },

    created() {
    },
    mounted () {
      const vm = this

      let elementPhone = document.getElementById('phoneNoTx')
      elementPhone.onkeyup = function (){
        vm.setMatchedPhone(elementPhone.value)
      }
      elementPhone.onkeydown = function (){
        vm.setMatchedPhone(elementPhone.value)
      }

    },
    computed: {

    },
    methods: {
      setSelectedList: function(item,type){
        let list = []
        if(type === 'inte'){
          list = this.interestSelected
        }else{
          list = this.compSelected
        }

        if(!list.includes(item)){
          list.push(item)
        }else{
          list.splice(list.indexOf(item),1)
        }
      },
      getActive: function(item,type){
        let isActive = false
        let list = []
        if(type === 'inte'){
          list = this.interestSelected
        }else{
          list = this.compSelected
        }

        list.forEach(id => {
          if(item === id){
            isActive = true
          }
        })
        return isActive
      },
      setClassOn: function(type,service){
        const vm = this
        vm.serviceType = service
        if(service==='02'){
          vm.payrollCss = 'display: inline-block;'
        }else{
          vm.payrollCss = 'display: none;'
        }

      },
      setMatchedPhone: function(value){
      const matchedPhone = /[0-9]$/.test(value)

      if(!matchedPhone){
        this.$refs.phoneNoTxRef.kendoWidget().value('')
        return false
      }else{
        this.$refs.phoneNoTxRef.kendoWidget().value(value)
        return true
      }

     },
      getDefaultRules: function(){
        const vm = this
        let type = vm.serviceType

        const resultMsg = { key: true, msg: '' }
        const companyNmTx = this.$refs.companyNmTxRef.kendoWidget().value()
        const managerNmTx = this.$refs.managerNmTxRef.kendoWidget().value()
        const emailId = this.$refs.emailTxRef.kendoWidget().value()
        const phoneNoTx = this.$refs.phoneNoTxRef.kendoWidget().value()
        const qnaContents = vm.vModel.qnaContents

        if(type === '02'){
          const compUserCnt = this.$refs.compUserCntRef.kendoWidget().value()
          if ( EtnersCommonUtil.isEmpty(compUserCnt)) {
            resultMsg.key = false
            resultMsg.msg = 'Please enter the number of employees.'
          }
          else if(this.compSelected.length < 1){
            resultMsg.key = false
            resultMsg.msg = 'Please click your types of companies.'
          }
          else if(this.interestSelected.length < 1){
            resultMsg.key = false
            resultMsg.msg = 'Please click your interests.'
          }
          else if ( EtnersCommonUtil.isEmpty(qnaContents)) {
            resultMsg.key = false
            resultMsg.msg = 'Please enter your inquiry.'
          }

        }else{
          if ( EtnersCommonUtil.isEmpty(qnaContents)) {
            resultMsg.key = false
            resultMsg.msg = 'Please enter your inquiry.'
          }
        }

        if ( EtnersCommonUtil.isEmpty(companyNmTx)) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter the company name.'
        }else if ( EtnersCommonUtil.isEmpty(phoneNoTx)) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter your contact number.'
        }else if ( EtnersCommonUtil.isEmpty(emailId)) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter your email'
        }else if ( EtnersCommonUtil.isEmpty(managerNmTx)) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter your name.'
        }

        return resultMsg

      },
      getEmailRules: function () {
        const resultMsg = { key: true, msg: '' }
        const emailId = this.$refs.emailTxRef.kendoWidget().value()
        const matched = /^([\w-\.]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i.test(emailId)

        if ( EtnersCommonUtil.isEmpty(emailId)) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter your email.'
        } else if (!matched) {
          resultMsg.key = false
          resultMsg.msg = 'Please match the email format.'
        }
        return resultMsg
      },
      fnSubmit: function(){
        const vm = this
        const vModel = vm.vModel
        const defaultResultMsg = this.getDefaultRules()
        const emailRules = this.getEmailRules()
        let solCd = vm.serviceType
        let params = {}

        if(defaultResultMsg.key){
          if(emailRules.key){
            if (!vModel.agree_pledge) {
              kendo.alert('Please agree to the contents of personal information use. ')
              return false
            }

            kendo.confirm("Would you like to inquire?").done(function() {

              if(solCd==='02'){
                params = {
                  solCd                   : solCd,
                  faqCategoryCd           : '01', //service inquiry
                  companyNmTx             : vm.$refs.companyNmTxRef.kendoWidget().value(),
                  managerNmTx             : vm.$refs.managerNmTxRef.kendoWidget().value(),
                  emailTx                 : vm.$refs.emailTxRef.kendoWidget().value(),
                  qnaContentsTx           : vm.vModel.qnaContents,
                  phoneNoTx               : vm.$refs.phoneNoTxRef.kendoWidget().value(),
                  compUserCnt             : vm.$refs.compUserCntRef.kendoWidget().value(),
                  personalInfoAgreeYn     : vm.vModel.agree_pledge ? 'Y' : 'N',
                  marketingAgreeYn        : vm.vModel.agree_marketing ? 'Y' : 'N',
                  compType                : vm.compSelected.join('|'),
                  compInte                : vm.interestSelected.join('|')
                }

              }else{
                params = {
                  solCd                   : solCd,
                  faqCategoryCd           : '01', //service inquiry
                  companyNmTx             : vm.$refs.companyNmTxRef.kendoWidget().value(),
                  managerNmTx             : vm.$refs.managerNmTxRef.kendoWidget().value(),
                  emailTx                 : vm.$refs.emailTxRef.kendoWidget().value(),
                  phoneNoTx               : vm.$refs.phoneNoTxRef.kendoWidget().value(),
                  personalInfoAgreeYn     : vm.vModel.agree_pledge ? 'Y' : 'N',
                  marketingAgreeYn        : vm.vModel.agree_marketing ? 'Y' : 'N',
                  qnaContentsTx           : vm.vModel.qnaContents
                }
              }
              // // 저장
              ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/question/questionInsert.json`, params).then(response => {

                if (response.data === undefined || response.data.resultStatus === undefined) {
                  kendo.alert('Data was not processed properly.')
                  return false
                }
                if (response.data.resultStatus.messageCode !== '2000') {
                  kendo.alert(response.data.resultStatus.messageText)
                  return false
                }

                vm.submitData = params

                vm.popupCss="display:block;"
              })
            })

          }else{
            kendo.alert(emailRules.msg)
            return false
          }
        }else{
          kendo.alert(defaultResultMsg.msg)
          return false
        }


      },
      onClickWindowClose() {
        this.popupCss="display:none;"
        this.$router.go()

      },
    },
    data: function () {
      const vm = this
      return {
        payrollCss:'display: none;',
        serviceType: '01',
        compType:[
            {
              id:"01",
              value:"Manufacturing"
            },
          {
            id:"02",
            value:"Wholesale & Retail"
          },
          {
            id:"03",
            value:"Public Administration"
          },
          {
            id:"04",
            value:"Education Service"
          },
          {
            id:"05",
            value:"Finance & Insurance"
          },
          {
            id:"06",
            value:"IT"
          },
          {
            id:"07",
            value:"Professional, Science, Technology Service"
          },
          {
            id:"08",
            value:"Health & Social welfare"
          },
          {
            id:"09",
            value:"Construction"
          },
          {
            id:"10",
            value:"ETC"
          }
        ],
        interestType:[
          {
            id:"01",
            value:"Payroll service"
          },
          {
            id:"02",
            value:"The 4 major insurance Diagnosis"
          },
          {
            id:"03",
            value:"Year-end tax adjustment"
          },
          {
            id:"04",
            value:"Payroll consulting"
          },
          {
            id:"05",
            value:"Tax service"
          },
          {
            id:"06",
            value:"52hr Absenteeism and Tardiness management"
          },
          {
            id:"07",
            value:"ETC"
          }
        ],
        submitData:{},
        popType : "enQna",
        popupCss: 'display:none;',
        compSelected : [],
        interestSelected : [],
        vModel: {
          agree_pledge: null,
          qnaContents: null,
          agree_marketing: null
        },
      }
    }
  }
</script>

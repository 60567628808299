<template>
  <div id="container_home">
    <div class="company_visual">
      <wait-service-window ref="waitServiceWindowRef" :style="popupCss"
                              @onClickWindowClose="onClickWindowClose">
      </wait-service-window>
      <!-- 스크롤 효과 -->
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <!-- END 스크롤 효과 -->
      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          BUSINESS
        </h2>
        <h2 class="company_tit manytext animated d-2s">
          경영지원 분야의 다양한 업무를 플랫폼 서비스로 제공하여<br />
          기업의 경쟁력 강화에 기여합니다.
        </h2>
        <div class="biz_img_company"><img src="@/assets/img/biz_top_img.png" alt=""></div>
      </div>
    </div>
    <!--비즈니스-->
    <div class="sub_wrap">
      <div class="company_inner animated">
        <!--경영지원-->
        <div class="biz_text">
          <div class="biz_img">
            <div class="list_btext">
              <div class="bs_tit text_ov">경영지원
              </div>
              <img src="@/assets/img/buss_b03.png" alt="">
            </div>
          </div>
          <div class="right">
            <p>
            <div class="tit">Shared Service</div>
            <div class="sub">
              회사경영에 필요한 인사 · 총무 등의 경영지원 자원을 통합하는 전략적 서비스입니다.
            </div>
            </p>
            <p>
            <div class="tit">ESOP</div>
            <div class="sub">
              디지털 플랫폼이 결합된 클라이언트 맞춤형 공유 오피스로 공간대여부터 업무 편의 서비스까지 제공합니다.
            </div>
            </p>
            <p>
            <div class="tit">Payroll</div>
            <div class="sub">
              클라이언트 규모·업종별 전문가의 종합적인 진단 ·점검을 통해 최적의 급여 솔루션을 제공합니다.
            </div>
            </p>
            <p>
            <div class="tit">감동타임</div>
            <div class="sub">
              다양한 상품 리스트로 임직원들이 원하는 선물을 직접 선택할 수 있는 기업선물 특화 플랫폼입니다.
            </div>
            </p>
            <div class="biz_btn">
              <a href="//sharedservice.etners.com" target="_blank">SHARED SERVICE<img src="@/assets/img/biz_arrow.png" alt=""> </a>
              <a href="//esop.etners.com" target="_blank">ESOP<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//etnerspayroll.com" target="_blank">PAYROLL<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//gamdongtime.etnersplatform.com/" target="_blank">감동타임<img src="@/assets/img/biz_arrow.png" alt=""></a>
            </div>
          </div>
        </div>
        <!--글로벌-->
        <div class="biz_text">
          <div class="biz_img">
            <div class="list_btext">
              <div class="bs_tit text_ov">글로벌
              </div>
              <img src="@/assets/img/buss_b06.png" alt="">
            </div>
          </div>
          <div class="right">
            <p>
            <div class="tit">Mall</div>
            <div class="sub">
              해외주재원의 생활 수준 향상과 안정적인 해외 정착을 돕는 해외 주재원 복지 서비스 입니다.
            </div>
            </p>
            <p>
            <div class="tit">Housing</div>
            <div class="sub">
              철저한 이력관리를 통해 입주부터 퇴거까지 책임지는 사택 토탈서비스입니다.
            </div>
            </p>
            <p>
            <div class="tit">Moving</div>
            <div class="sub">
              전세계 260여 개 지역 업체별 단가를 확인할 수 있는 온라인 이사 견적 비교 플랫폼입니다.
            </div>
            </p>
            <p>
            <div class="tit">Relocation</div>
            <div class="sub">
              입사 전부터 퇴사까지 임직원의 Life-cycle에 맞춘 외국인 임직원 정착 프리미엄 서비스를 제공합니다.
            </div>
            </p>
            <div class="biz_btn">
              <a href="//mall.etners.com" target="_blank">MALL<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//housing.etners.com" target="_blank">HOUSING<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//moving.etners.com" target="_blank">MOVING<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//relocation.etners.com" target="_blank">RELOCATION<img src="@/assets/img/biz_arrow.png" alt=""></a>
            </div>
          </div>
        </div>
        <!--유통-->
        <div class="biz_text">
          <div class="biz_img">
            <div class="list_btext">
              <div class="bs_tit text_ov">유통
              </div>
              <img src="@/assets/img/buss_b11.png" alt="">
            </div>
          </div>
          <div class="right">
            <p>
            <div class="tit">Bidding</div>
            <div class="sub">
              유휴·불용 자산의 온라인 실시간 입찰을 통해 합리적인 가격으로 기업자산을 매각합니다.
            </div>
            </p>
            <p>
            <div class="tit">EFS</div>
            <div class="sub">
              교육산업에 특화된 유통·물류 브랜드로 IT Device를 제안·공급·리퍼비시·체험운영 등 물류 서비스를 운영합니다.
            </div>
            </p>
            <p>
            <div class="tit">Shop</div>
            <div class="sub">
              기업이 필요로 하는 제품과 서비스를 합리적인 가격에 ONE-STOP으로 제공합니다.
            </div>
            </p>
            <div class="biz_btn">
              <a href="//bidding.etners.com/" target="_blank">BIDDING<img src="@/assets/img/biz_arrow.png" alt=""> </a>
              <a href="//etnersefs.com/landing" target="_blank">EFS<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//shop.etners.com" target="_blank">SHOP<img src="@/assets/img/biz_arrow.png" alt=""></a>
            </div>
          </div>
        </div>
        <br>
        <!--사업이미지-->
        <h2 class="company_tit manytext align-c animated d-2s">
          업무 요청 관리 시스템 ESRM(ETNERS Smart Request Manager)을 통해<br />
          스마트한 이트너스 비즈니스를 경험해보세요. <a href="//esrm.etners.com/" target="_blank"><img src="@/assets/img/arrow-right-circle.png"></a>
        </h2>
        <div class="biz_img_con">
          <img src="@/assets/img/biz_circle_img.png" alt="">
        </div>
        <div class="biz_img_mcon">
          <img src="@/assets/img/biz_circle_mimg.png" alt="">
        </div>
      </div>
    </div>
    <!--//비즈니스-->
  </div>

</template>

<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import WaitServiceWindow from '@/components/WaitServiceWindow'
  const ethDomain = ApiConfig.ethDomain

  export default {
    name: 'Business',
    components:{
      WaitServiceWindow
    },
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
      fnPopupOpen: function(){
        const vm = this
        // let submitPopWindow = vm.$refs.popupWindowRef.kendoWidget()
        // submitPopWindow.center().open()
        vm.popupCss = "display:block;"
      },
      onClickWindowClose() {
        this.popupCss = "display:none;"
      },

    },
    data: function () {
      const vm = this
      return {
        popupCss: 'display:none;'

      }
    }
  }
</script>

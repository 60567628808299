<template>
  <div id='wrap' class='section'>
    <div id="naverMap">
    </div>
  </div>
</template>

<script>
export default {
  name: 'NaverMap',
  data() {
    return {

    }
  },
  mounted: function() {

    var markers = []
    var infoWindows = []
    initMap()
    function initMap() {
//37.4021, 127.1072
      var local = new naver.maps.LatLng(37.4175260, 126.9745263),
          map   = new naver.maps.Map(document.getElementById('naverMap'), {
            center :  local.destinationPoint(330, -700),
            zoom: 15,
            zoomcontroloptions: naver.maps.Position.TOP_RIGHT
         }),
         marker = new naver.maps.Marker({
           position: local,
           map: map
         })

      var contentString = [
        '<div class="iw_inner">',
        '   <h3>이트너스</h3>',
        '</div>'
      ].join('')

      // var infoWindow = new naver.maps.InfoWindow({
      //   content: contentString
      // })
      //
      // naver.maps.Event.addListener(marker, 'click', function(e){
      //   if (infowindow.getMap()) {
      //       infowindow.close()
      //   } else {
      //       infowindow.open(map, marker)
      //   }
      // })
      //
      // infowindow.open(map, marker)
    }
  } // end_mount
}


</script>


<style>
#naverMap {
  height: 100vh;
  min-height: 100vh;
  width: 100%;
}
</style>

<template>
  <div>

      <!--//////////POP-UP START//////////-->
      <div class="pop_dim">
        <div class="pop_area" style="height: 430px;">
          <div class="float-r" style="cursor: pointer;" @click="goPrevPage"><img src="@/assets/img/close_btn.png"></div>
          <div style="text-align: center;margin-bottom: 20px;margin-top: 50px;"><img src="@/assets/img/pop_img.png"></div>
          <div class="pop_tit" v-if="popType == 'down'">
            다운로드 감사드립니다.<br>
            <span >아래 버튼을 클릭하여 소개서를 다운받으실 수 있습니다.</span>
          </div>
          <div class="pop_tit" v-else-if="popType == 'qna'">
            서비스문의가 완료되었습니다.<br>
            <span >담당자가 빠른 시간내에 연락드리겠습니다.</span>
          </div>
          <div class="pop_tit" v-else-if="popType == 'enQna'">
            Your service inquiry has been completed.<br>
            <span >A representative will contact you as soon as possible.</span>
          </div>
          <div class="pop_btn w70 mauto" v-if="popType !== 'enQna'">
            <button @click="fnProposalDownload">소개서 다운로드</button>
          </div>
        </div>
      </div>
    <!--//////////POP-UP END//////////-->
  </div>
</template>
<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import $ from 'jquery'


  export default {
    name: 'SubmitQuestionWindow',
    props: ['submitData','popType'],
    components: {

    },
    created() {
    },
    mounted () {
      var type = this.popType

      if(type=="qna"){
        this.qnaCss = "color: #333; display:block;"
        this.downCss = "color: #333; display:none;"
      }else{
        this.qnaCss = "color: #333; display:none;"
        this.downCss = "color: #333; display:block;"
      }

    },
    computed: {

    },
    methods: {
      goPrevPage: function(){
        this.$emit('onClickWindowClose')
      },
      fnProposalDownload: function(){
        const vm = this

        kendo.confirm("소개서를 다운로드 하시겠습니까? ").done(function() {
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/proposal/downloadInsert.json`,vm.submitData).then(response => {

            let resultData = response.data.resultData

            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }

            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }

            setTimeout(function () {
              window.location.href = `${ApiConfig.ethDomain}/download/${resultData.fileId}/${resultData.fileSeqNo}`
            }, 300)

          })
        })
      }
    },
    data: function () {
      const vm = this
      return {
        downCss : "color: #333; display:none;",
        qnaCss : "color: #333; display:none;"
      }
    }
  }
</script>

<template>
  <div id="container_home" class="eng">
    <div class="company_visual">
      <!-- 스크롤 효과 -->
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <!-- END 스크롤 효과 -->

      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          HISTORY
        </h2>
        <h2 class="company_tit animated d-2s on">
          SINCE 1998, Running for a better tomorrow than today
        </h2>
      </div>
      <div class="company_img animated">
        <img src="@/assets/img/company_03.png" class="animated d-1s" alt="">
      </div>

    </div>
    <div class="sub_wrap">
			<div class="company_inner">
    <div class="history_section">
      <div class="default_section">
        <div class="history_wrap">
          <div class="history_title sub_wrap_tit animated">
            <div class="sub">History</div>
            <div class="eg">1998~Present</div>
          </div>
          <!--//히스토리타이틀-->
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h30.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2022</div>
                <div class="tit">Selected as a ‘Venture Billion Company’</div>
                <div class="tit">Awarded Samsung Electronics B2B STAR Partner 4STAR</div>
                <div class="tit">Open ESOP Work Bundang Seohyeon Station Center</div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h12.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2021</div>
                <div class="tit">Awarded Industrial Service Medal from Korea Federation of SMEs</div>
                <div class="tit">Selected as Youth-Friendly Small Giants Corporation from Ministry of Employment and Labor</div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-2s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h13.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2020</div>
                <div class="tit">Awarded President’s Commendation of Family-friendly Corporation</div>
                <div class="tit">Selected as ATC(Advanced Technology Center) Designated Ministry of Science and ICT</div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-3s"> <span class="icon"><img src="@/assets/img/history_ic.png" alt=""></span>
              <div class="img"><img src="@/assets/img/h14.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2019</div>
                <div class="tit">Awarded 52nd Seongnam Chamber of Commerce&Industry Awards for Corporate Management</div>
                <div class="tit">Awarded Prime Minister’s Commendation from 43rd National Productivity Award</div>
                <div class="tit">Certificated as Family-friendly Corporation from Ministry of Gender Equality and Family</div>
                <div class="tit">Development of "Etners 52-hour T&A Management Solution"</div>
                <div class="tit">Develoment of "Etners office equipment management Solution"</div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-4s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h15.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2015</div>
                <div class="tit">Awarded Presidential Commendation for Job Creation</div>
                <div class="tit">Awarded a prize for the promotion of science and technology</div>
              </div>
            </li>
          </ul>
          <ul class="history_list">
            <li class="animated d-4s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h16.png" alt=""></div>
              <div class="text">
                <div class="dh_gray">1998</div>
                <div class="tit">Etners established</div>
              </div>
            </li>
          </ul>
        </div>
        <!---//히스토리요약--->
        <!--전체보기버튼-->
        <a href="javascript:void(0)" class="btn_all" onclick="toggleSeeAll()">View all</a>
        <!--//전체보기버튼-->
      </div>
      <div class="see_all_section">
        <!---히스토리전체보기--->
        <!--히스토리1-->
        <div class="history_wrap animated">
          <!--히스토리1타이틀-->
          <div class="history_title sub_wrap_tit animated d-1s">
            <div class="sub">Forward of being the specialized
									Company<br/> for business support platform</div>
            <div class="eg">2018~Present</div>
          </div>
          <!--//히스토리1타이틀-->
          <ul class="history_list animated">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h30.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2022</div>
                <div class="sub gray1">Selected as a ‘Venture Billion Company’</div>
                <div class="sub gray1">Open ESOP Work Bundang Seohyeon Station Center</div>
                <div class="sub gray1">Open ESOP Edu Dongtan Center</div>
                <div class="sub gray1">Open Edu Pyeongtaek Centner</div>
                <div class="sub gray1">Awarded Samsung Electronics B2B STAR Partner 4STAR</div>
                <div class="sub gray1">Awarded Excellent STAR in Samsung Electronics STAR Partner’s day</div>
                <div class="sub gray1">Awarded long-range management(10yrs) in Samsung Electronics STAR Partner’s day</div>
              </div>

            </li>
            <li class="animated d-2s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h02.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2021</div>
                <div class="sub gray1">Awarded 2021 Industrial Service Medal from Korea Federation of SMEs</div>
                <div class="sub gray1">Launch “Curation Service” for Impressive Time for gift</div>
                <div class="sub gray1">Selected as Youth-Friendly Small Giants Corporation (from Ministry of Employment and Labor)</div>
                <div class="sub gray1">Launch a shared working space Pyeongtaek SAC ESOP Center</div>
              </div>
            </li>
            <li class="animated d-3s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h03.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2020</div>
                <div class="sub gray1">Awarded President’s Commendation of Family-friendly Corporation</div>
                <div class="sub gray1">Selected as ATC(Advanced Technology Center) Designated Ministry of Science and ICT</div>
                <div class="sub gray1">Launch a shared working space ESOP Tera Center</div>
                <div class="sub gray1">Launch a total logistics operation solution EFS(Etners Fulfillment Solution)</div>
              </div>
            </li>
            <li class="animated d-4s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h04.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2019</div>
                <div class="sub gray1">Awarded 52nd Seongnam Chamber of Commerce&Industry Awards for Corporate Management</div>
                <div class="sub gray1">Awarded Prime Minister’s Commendation from 43rd National Productivity Award</div>
                <div class="sub gray1">Certificated as Family-friendly Corporation from Ministry of Gender Equality and Family</div>
                <div class="sub gray1">Development of "Etners 52-hour T&A Management Solution"</div>
                <div class="sub gray1">Development of "Etners Office Equipment Management Solution</div>
              </div>
            </li>
            <li class="animated d-5s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h20.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2018</div>
                <div class="sub gray1">Open foreigners’ settlement support shopping mall “Etners Housingshop”</div>
                <div class="sub gray1">Renewal of expatriates welfare mall 'ETNERS Mall 2.0'</div>
                <div class="sub gray1">Selected as medical tourism partner company from Seongnam City</div>
                <div class="sub gray1">Application for a patent: Special Shoping Mall for International Purchase and Delivery</div>
                <div class="sub gray1">Application for a patent: System for Checking the Inventory Inside the Box Using QR Code</div>
                <div class="sub gray1">Acquired ISO9001 Certification</div>
              </div>
            </li>
          </ul>
        </div>
        <!--//히스토리1-->
        <!--히스토리2-->
        <div class="history_wrap">
          <!--히스토리2타이틀-->
          <div class="history_title mt45 sub_wrap_tit animated">
            <div class="sub">Challenge for tomorrow</div>
            <div class="eg">2012~2017</div>
          </div>
          <!--//히스토리2타이틀-->
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h06.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2017</div>
                <div class="sub gray1">Selected as Samsung B2B Star Partner</div>
              </div>

            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h07.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2016</div>
                <div class="sub gray1">Appointed as Excellent Employment Corporation for Women</div>
                <div class="sub gray1">Open Business only online market “Etners Shop”</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h08.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2015</div>
                <div class="sub gray1">Awarded Presidential Commendation for Job Creation</div>
                <div class="sub gray1">Awarded a prize for the promotion of science and technology</div>
                <div class="sub gray1">Development of a total logistics solution “Etners DMS”</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h09.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2014</div>
                <div class="sub gray1">Certificated as an excellent human resource development agency</div>
                <div class="sub gray1">Awarded Best Encouragement Prize for Smart Work</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h17.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2013</div>
                <div class="sub gray1">Selected as a good company to work in Gyeonggi-do</div>
                <div class="sub gray1">Acquire “Chongmudotcom”</div>
                <div class="sub gray1">Development of a corporate asset matching solution “Etners Bidding”</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h19.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2012</div>
                <div class="sub gray1">Awarded the prime minister prize for corporate innovation</div>
                <div class="sub gray1">Selected as a company that wants to work in “Maeil Kyungjae”</div>
              </div>
            </li>
          </ul>
        </div>
        <!--//히스토리2-->
        <!--히스토리3-->
        <div class="history_wrap">
          <!--히스토리3타이틀-->
          <div class="history_title mt45 sub_wrap_tit animated">
            <div class="sub">Realization of SMART WORK</div>
            <div class="eg">2007~2011</div>
          </div>
          <!--//히스토리3타이틀-->
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h05.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2011</div>
                <div class="sub gray1">Development of a housing management solution “Etners Housing”</div>
                <div class="sub gray1">Development of a international moving solution “Etners moving</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h21.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2010</div>
                <div class="sub gray1">Development of an asset management solution “Etners Asset"</div>
                <div class="sub gray1">Development of expatriates welfare mall “Etners Mall”</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h22.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2009</div>
                <div class="sub gray1">“Etners Design” established</div>
                <div class="sub gray1">"A company affiliated research institute" established</div>
                <div class="sub gray1">Introduction of a new employee ownership system</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h18.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2008</div>
                <div class="sub gray1">Publication of the general affairs specialized book “Business-oriented strategic general affairs”</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h26.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2007</div>
                <div class="sub gray1">Certificated as a technologically innovative small business</div>
              </div>
            </li>
          </ul>
        </div>
        <!--//히스토리3-->
        <!--히스토리4-->
        <div class="history_wrap">
          <!--히스토리4타이틀-->
          <div class="history_title mt45 sub_wrap_tit animated">
            <div class="sub">Presentation of a new paradigm
									of management</div>
            <div class="eg">1998~2006</div>
          </div>
          <!--//히스토리4타이틀-->
          <ul class="history_list">
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h24.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2005</div>
                <div class="sub gray1">Selected as an excellent company for economic management innovation in Korea</div>
                <div class="sub gray1">Start B2B special edition service</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h28.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2004</div>
                <div class="sub gray1">Development of Intranet “Utopia”</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h27.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2003</div>
                <div class="sub gray1">Start a payroll service</div>
                <div class="sub gray1">Start GHD(Global Help Desk) Service</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h25.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">2002</div>
                <div class="sub gray1">Start an office environment business</div>
                <div class="sub gray1">Start an asset inspection</div>
              </div>
            </li>
            <li class="animated d-1s"> <span class="icon"><img src="@/assets/img/history_ic.png"
                  alt=""></span>
              <div class="img"><img src="@/assets/img/h16.png" alt=""></div>
              <div class="text">
                <div class="dh_orange">1998</div>
                <div class="sub gray1">Etners established</div>
              </div>
            </li>
          </ul>
        </div>
        <!--//히스토리4-->
        <!---//히스토리전체보기--->
        <!--닫기버튼--><a href="javascript:void(0)" class="btn_all" onclick="toggleSeeAll()">Close</a>
      </div>
    </div>
  </div>
</div>
</div>

</template>

<script>
  import ApiConfig from '@/api/api.config'

  export default {
    name: 'CompanyHistoryEn',
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
    },
    data: function () {
      const vm = this
      return {

      }
    }
  }
</script>

<template>
  <div id="container_home">
    <div class="inner">
      <h1 class="page_tit animated on d-1s">Certification</h1>
      <div class="clearfix">
        <div class="inside_total float-r"><span class="dh_orange">{{total}}</span> Total</div>
      </div>
      <ul class="board-list animated on d-2s">
        <li class="head">
          <p class="num">Patent·Certification</p>
          <p class="tit">Name</p>
          <div class="list-info">
            <p class="date">Date</p>
          </div>
        </li>
        <li v-for="item in patentList" @click="patentDetail(item.patentId, item.fmtPatentCertDt)">
            <p v-bind:class="item.patentYn == 'Y' ? 'num dh_orange' : 'num'"> {{item.patentYn == 'Y' ? 'Patent' : 'Certification'}}</p>
            <p class="tit">[{{item.patentCertNoTxEn}}] {{item.patentNmTxEn}}</p>
            <div class="list-info">
              <p class="date">{{item.fmtPatentCertDt}}</p>
            </div>
        </li>
      </ul>
      <div class="clearfix"></div>
      <div class="board-pager">
        <button :disabled="pageNum === 0" @click="prevPage" class="page-btn prev">

        </button>
        <a class="page-count">{{ pageNum + 1 }} / {{ pageCount }} Page</a>
        <button :disabled="pageNum >= pageCount - 1" @click="nextPage" class="page-btn next">

        </button>
      </div>
    </div>
  </div>

</template>

<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'

  export default {
    name: 'CertificationEn',
    mounted () {
      const vm = this
      var currentPage = this.$route.params.currentPage
      if(currentPage === null || currentPage === '' || currentPage === undefined){
        vm.page = 0
      }else{
        vm.page = Number(currentPage)
        vm.pageNum = Number(currentPage)
      }

      vm.getPatentList(vm.page)
    },
    props:{
      pageSize: {
        type: Number,
        required: false,
        default: 10
      }
    },
    methods: {
      getPatentList(page) {
        const vm = this

        let param = {
          page: page+1,
          pageSize: vm.pageSize,
          requestType: 'PAGING'
        }

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/patentList.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('Failed to retrieve data.')
            return false
          }
          vm.patentList = response.data.resultData
          vm.total = response.data.total

          let listLeng = response.data.total,
              listSize = vm.pageSize,
              page = Math.floor(listLeng / listSize)
          if (listLeng % listSize > 0) page += 1
          vm.pageCount = page
        })
      },
      patentDetail(patentId, fmtPatentCertDt) {
        let currentPage = this.pageNum
        this.$router.push({ name: 'CertificationDetailEn', params : { patentId , fmtPatentCertDt, currentPage}})
      },
      nextPage () {
        this.pageNum += 1
        this.getPatentList(this.pageNum)
      },
      prevPage () {
        this.pageNum -= 1;
        this.getPatentList(this.pageNum)
      }
    },
    data: function () {
      const vm = this
      return {
        patentList: [],
        total: 0,
        page:0,
        pageNum: 0,
        pageCount: 0
      }
    }
  }
</script>

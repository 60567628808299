<template>
  <div id="container_home">
    <!-- 문의 -->
    <div class="company_inner">
      <h1 class="page_tit">LOCATION</h1>
      <ul class="csr_list etmap">
        <li class="animated">
          <div class="etners_map animated d-1s"><!--지도영역-->
            <NaverMap></NaverMap>
          </div>
        </li>
        <li class="animated on">
          <div class="flex_wrap animated">
            <div class="text animated d-1s ml0">
              <div class="big_tit">찾아오시는 길</div>
              <div class="cs_text"><b>주소(본사)</b>
                <span class="dh_orange">도로명</span> 경기도 과천시 과천대로7나길 60, 과천어반허브 A동 5층<br />
                <span class="dh_orange">지번</span> 경기도 과천시 갈현동 492, 과천어반허브 A동 5층
              </div>
            </div>
            <div class="text animated d-1s ml5 matx2">
              <div class="cs_text">
                <b>대중교통 안내</b>
                <span class="dh_orange">버스</span> 1-1, 9-3, 11-2, 103, 777, 441 (과천지식정보타운 정류장 하차 후 걸어서 870m 이동)<br />
                <span class="dh_orange">지하철</span> 정부과천청사역 9번출구/인덕원역 3번 출구에서 버스 승차 후 과천지식정보타운 정류장 하차<br />
                <b class="mt10">주차정보 및 이용안내</b>
                <span class="dh_orange">자가용</span> 자가용 경기도 과천시 과천대로7나길 60, 과천어반허브 A동 (B2~B4이용)<br />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- //문의  -->
    <div class="clearfix"></div>
  </div>

</template>

<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import NaverMap from '@/components/NaverMap.vue'
  const ethDomain = ApiConfig.ethDomain
  import $ from 'jquery'

  export default {
    name: 'Location',
    components:{
      NaverMap
    },
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
    },
    data: function () {
      const vm = this
      return {

      }
    }
  }
</script>

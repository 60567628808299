<template>
  <section id="container">
    <div class="title_wrap">
      <div class="title_wrap">
        <h1>상세 코드 {{ saveMode === 'INSERT' ? '등록' : '수정' }}</h1>
      </div>
    </div>
    <form ref="formRef" v-kendo-validator @submit.prevent="">
      <div class="contents_wrap">
        <div class="small_title_wrap">
          <h2>상세 그룹 코드 정보</h2>
        </div>
        <ul class="filter_list">
          <li>
            <dd>
              <label>회사코드
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                          ref="cmpyCdRef"
                          v-model="vModel.cmpyCd"
                          name="cmpyCd"
                          id="cmpyId"
                          :style="'width: 100%;'"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>그룹 코드
                <span class="forg">*</span>
              </label>
              <ul>
                <kendo-dropdownlist
                    ref="codeManagementGbnRef"
                    :data-source="$store.state.codeRequestStoreMstModule.search.combobox.codeGrupCd.data"
                    :data-text-field="'cdGrupId'"
                    :data-value-field="'cdGrupId'"
                    :option-label="'전체'"
                    :index="0"
                    :style="'width: 100%;'"
                    :value="vModel.cdGrupId"
                    @change="commonOnChangeEvent"
                >
                </kendo-dropdownlist>
              </ul>
            </dd>
            <dd>
              <label>그룹 코드 명
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                    <kendo-maskedtextbox
                            ref="cdGrupNmTxRef"
                            v-model="vModel.cdGrupNmTx"
                            name="cdGrupNmTx"
                            :style="'width: 100%;'"
                            :readonly="true"
                    >
                    </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>상세 코드
                <span class="forg">*</span>
              </label>
              <ul>
                <kendo-maskedtextbox
                        ref="cdIdRef"
                        v-model="vModel.cdId"
                        name="cdId"
                        id="cdGrupId"
                        :style="'width: 100%;'"
                >
                </kendo-maskedtextbox>
              </ul>
            </dd>
            <dd>
              <label>상세 코드 명
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                    <kendo-maskedtextbox
                            ref="cdNmTxRef"
                            v-model="vModel.cdNmTx"
                            name="cdNmTx"
                            :style="'width: 100%;'"
                            maxlength="100"
                    >
                    </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>상세 코드 속성값
              </label>
              <ul>
                <kendo-maskedtextbox
                        ref="cdValueTxRef"
                        v-model="vModel.cdValueTx"
                        name="cdValueTx"
                        :style="'width: 100%;'"
                        maxlength="100"
                >
                </kendo-maskedtextbox>
              </ul>
            </dd>
            <dd>
              <label>상세 코드 속성값2
              </label>
              <ul>
                <li>
                    <kendo-maskedtextbox
                            ref="cdValue2TxRef"
                            v-model="vModel.cdValue2Tx"
                            name="cdValue2Tx"
                            :style="'width: 100%;'"
                            maxlength="100"
                    >
                    </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>조회순서
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                          ref="seqNoRef"
                          v-model="vModel.seqNo"
                          name="seqNo"
                          maxlength="4"
                          :style="'width: 100%;'"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
            <dd>
              <label>상세 코드 설명</label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                          ref="cdDescTxRef"
                          v-model="vModel.cdDescTx"
                          name="cdDescTx"
                          :style="'width: 100%;'"
                          maxlength="200"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          <li>
            <dd>
              <label>사용여부
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-switch
                          ref="useYnRef"
                          name="useYn"
                          :checked="vModel.useYn === undefined || vModel.useYn === 'Y'"
                  >
                  </kendo-switch>
                </li>
              </ul>
            </dd>
          </li>
        </ul>
      </div>
      <div class="button_area">
        <ul>
          <li>
            <button class="large_btn" @click="fnGoBack">취소</button>
          </li>
          <li>
            <button id="buttonSubmit" class="large_btn orange" @click="fnSubmit">저장</button>
          </li>
        </ul>
      </div>
    </form>

  </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersKendoGridUtil from '@/common/kendo.grid.util'
import EtnersParseUtil from '@/common/etners.parse.util'
import EtnersDateTimeUtil from '@/common/etners.datetime.util'
import { fetchCodeDtlDetailResultData } from '@/api/api.common'
import { fetchCodeMstDetailResultData } from '@/api/api.common'
import $ from 'jquery'


export default{
  name: 'CodeManagementDtlSave',
  created () {
    this.$store.dispatch('initCmmCodeMstGrupIdRequest')
  },
  mounted: function() {
    const vm = this

    if(this.saveMode === 'UPDATE') {
      this.fnGetDetail()
    }

    let elementCmpy = document.getElementById('cmpyId')
    let elementCdGrup = document.getElementById('cdGrupId')

    elementCmpy.onkeyup = function (){
      vm.setMatchedNumber(elementCmpy.value,"cmpyCdRef")
    }
    elementCdGrup.onkeyup = function (){
      vm.setMatchedNumber(elementCdGrup.value,"cdIdRef")
    }
  },
  methods: {
    fnGetDetail: async function() {
      this.detail = await fetchCodeDtlDetailResultData(this.$route.params.cmpyCd,this.$route.params.cdGrupId, this.$route.params.cdId)
      this.vModel = this.detail.data.resultData
    },
    setMatchedNumber: function(value,type){
      const matchedPhone = /[0-9]$/.test(value)
      let refs = this.$refs


      if(!matchedPhone){
        if(type==='cmpyCdRef'){
          refs.cmpyCdRef.kendoWidget().value('')
        }else{
          refs.cdIdRef.kendoWidget().value('')
        }
        return false
      }else{
        if(type==='cmpyCdRef'){
          refs.cmpyCdRef.kendoWidget().value(value)
        }else{
          refs.cdIdRef.kendoWidget().value(value)
        }
        return true
      }

    },
    //그룹 코드 명 가져오기
    commonOnChangeEvent: async function(){
      this.temp= await fetchCodeMstDetailResultData(this.vModel.cmpyCd,this.$refs.codeManagementGbnRef.kendoWidget().value())
      this.vModel.cdGrupNmTx = this.temp.data.resultData[0].cdGrupNmTx
    },
    getDefaultRules: function()  {
      const resultMsg = { key: true, msg: '' }
      const cmpyCd = this.$refs.cmpyCdRef.kendoWidget().value()
      const cdGrupId = this.$refs.codeManagementGbnRef.kendoWidget().value()
      const cdGrupNmTx = this.$refs.cdGrupNmTxRef.kendoWidget().value()
      const cdId = this.$refs.cdIdRef.kendoWidget().value()
      const cdNmTx = this.$refs.cdNmTxRef.kendoWidget().value()
      const seqNo = this.$refs.seqNoRef.kendoWidget().value()

      if ( EtnersCommonUtil.isEmpty(cmpyCd) || EtnersCommonUtil.isEmpty(cdGrupId)|| EtnersCommonUtil.isEmpty(cdGrupNmTx) ||
           EtnersCommonUtil.isEmpty(cdId) || EtnersCommonUtil.isEmpty(cdNmTx) || EtnersCommonUtil.isEmpty(seqNo)) {
        resultMsg.key = false
        resultMsg.msg = '필수값을 입력해주세요.'
      }
      return resultMsg
    },
    // 저장 submit
    fnSubmit: function(e) {
      const vm = this
      const vModel = vm.vModel
      const defaultResultMsg =  this.getDefaultRules()
      let sessionLoginUserData = sessionStorage.getItem('adminLoginUserData')
      let loginUserObj = JSON.parse(sessionLoginUserData)

      if(defaultResultMsg.key) {

        let confirmKeyword = (vm.saveMode === 'INSERT' ? '등록' : '수정')
        kendo.confirm("코드정보를 "+confirmKeyword+"하시겠습니까?").done(function() {

          // 파라미터 객체 생성
          let params = {
            loginUserToken          : sessionStorage.loginUserToken,
            lastMdfyId              : loginUserObj.unqUserNo,
            frstRgstId              : loginUserObj.unqUserNo,
            cmpyCd                  : vm.vModel.cmpyCd,
            cdGrupId                : vm.$refs.codeManagementGbnRef.kendoWidget().value(),
            cdGrupNmTx              : vm.vModel.cdGrupNmTx,
            cdDescTx                : vm.vModel.cdDescTx,
            seqNo                   : vm.vModel.seqNo,
            cdId                    : vm.$refs.cdIdRef.kendoWidget().value(),
            cdNmTx                  : vm.vModel.cdNmTx,
            cdValueTx               : vm.vModel.cdValueTx,
            cdValue2Tx              : vm.vModel.cdValue2Tx,
            useYn                   : vm.$refs.useYnRef.kendoWidget().value() === true ? 'Y' : 'N'
          }

          // progressbar 시작
          vm.$Progress.start()

          // 저장
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/code/putCmmCodeDtlData.json`, params).then(response => {

            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              // progressbar 실패
              vm.$Progress.fail()
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              // progressbar 실패
              vm.$Progress.fail()
              return false
            }

            kendo.alert(confirmKeyword+"되었습니다.")

            // progressbar 종료
            vm.$Progress.finish()

            vm.$router.push({
              name: 'CodeManagementDtlDetail',
              params :{
                cmpyCd: response.data.resultData.cmpyCd,
                cdGrupId: response.data.resultData.cdGrupId,
                cdId: response.data.resultData.cdId
              }
            })
          })
        })

      } else {
        kendo.alert(defaultResultMsg.msg)
        return false
      }

    },
    fnGoBack: function(e) {
      this.$router.push({
        name:'CodeManagement'
      })
    }
  }
    ,
    data: function () {
      return {
          loginUserData: JSON.parse(sessionStorage.getItem('adminLoginUserData')),
          saveMode: (EtnersCommonUtil.isEmpty(this.$route.params.cdGrupId) ? 'INSERT' : 'UPDATE'),
          detail: {},
          temp: {},
          vModel: {
            useYn: 'true',
            cdGrupId: '',
            cdGrupNmTx: '',
            cdId: '',
            cdNmTx: '',
            cdValueTx: '',
            cdValue2Tx: '',
            seqNo: '',
            cdDescTx: '',
            cmpyCd: '00000'
          },
        }
      },
    }

  </script>

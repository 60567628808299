<template>
  <div id="container_home" class="eng">
    <div class="company_visual">
      <wait-service-window ref="waitServiceWindowRef" :style="popupCss" @onClickWindowClose="onClickWindowClose">
      </wait-service-window>
      <!-- 스크롤 효과 -->
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <!-- END 스크롤 효과 -->
      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          BUSINESS
        </h2>
        <h2 class="company_tit manytext animated d-2s">
          We contribute to strengthening the competitiveness <br>of the company by providing various tasks in the field
          of<br> management support as platform services.
        </h2>
        <div class="biz_img_company"><img src="@/assets/img/ebiz_top_img.png" alt=""></div>
      </div>
    </div>
    <!--비즈니스-->
    <div class="sub_wrap">
      <div class="company_inner animated">
        <!--경영지원-->
        <div class="biz_text">
          <div class="biz_img">
            <div class="list_btext">
              <div class="bs_tit text_ov">Business Support Platform
              </div>
              <img src="@/assets/img/buss_b03.png" alt="">
            </div>
          </div>
          <div class="right">
            <p>
            <div class="tit">Shared Service</div>
            <div class="sub">It is a strategic service that integrates management support resources such as</br> Human
              resource and General affairs necessary for the management of the company.
            </div>
            </p>
            <p>
            <div class="tit">ESOP</div>
            <div class="sub">
              It is a client-customized shared office with a digital platform that provides from space rental to
              business convenience services.
            </div>
            </p>
            <p>
            <div class="tit">Payroll</div>
            <div class="sub">
              It provides an optimal salary solution through comprehensive diagnosis and inspection by experts according
              to client size and industry.
            </div>
            </p>
            <p>
            <div class="tit">GAMDONG TIME</div>
            <div class="sub">
              It is a corporate gift-specialized platform that allows employees to choose the gifts they want from a
              variety of product lists.
            </div>
            </p>
            <div class="biz_btn">
              <a href="https://sharedservice.etners.com" target="_blank">SHARED SERVICE<img
                  src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//esop.etners.com" target="_blank">ESOP<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//etnerspayroll.com/index_en.html" target="_blank">PAYROLL<img src="@/assets/img/biz_arrow.png"
                  alt=""></a>
              <a href="//gamdongtime.etnersplatform.com" target="_blank">GAMDONG TIME<img
                  src="@/assets/img/biz_arrow.png" alt=""></a>

            </div>
          </div>
        </div>
        <!--글로벌-->
        <div class="biz_text">
          <div class="biz_img">
            <div class="list_btext">
              <div class="bs_tit text_ov">GLOBAL
              </div>
              <img src="@/assets/img/buss_b06.png" alt="">
            </div>
          </div>
          <div class="right">
            <p>
            <div class="tit">Mall</div>
            <div class="sub">
              It is a welfare service for overseas expatriates that helps them settle down overseas and improve their
              living standards.
            </div>
            </p>
            <p>
            <div class="tit">Housing</div>
            <div class="sub">
              It is company house total service that is responsible for moving in and leaving through history
              management.
            </div>
            </p>
            <p>
            <div class="tit">Moving</div>
            <div class="sub">
              It is an online moving estimate comparison platform that allows you to check prices by company in more
              than 260 regions around the world.
            </div>
            </p>
            <p>
            <div class="tit">Relocation</div>
            <div class="sub">
              From the start of employment to the end of employment, Relocation provides a premium service<br>for
              foreign employees based on the life-cycle of employees.
            </div>
            </p>
            <div class="biz_btn">
              <a href="//mall.etners.com" target="_blank">MALL<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//housing.etners.com/index_en.html" target="_blank">HOUSING<img src="@/assets/img/biz_arrow.png"
                  alt=""></a>
              <a href="//moving.etners.com/index_en.html" target="_blank">MOVING<img src="@/assets/img/biz_arrow.png"
                  alt=""></a>
              <a href="//relocation.etners.com/html_en/relo_landing.html" target="_blank">RELOCATION<img
                  src="@/assets/img/biz_arrow.png" alt=""></a>
            </div>
          </div>
        </div>
        <!--유통-->
        <div class="biz_text">
          <div class="biz_img">
            <div class="list_btext">
              <div class="bs_tit text_ov">Logistics
              </div>
              <img src="@/assets/img/buss_b11.png" alt="">
            </div>
          </div>
          <div class="right">
            <p>
            <div class="tit">Bidding</div>
            <div class="sub">
              Bidding sells corporate assets at reasonable prices through online real-time bidding for idle and unused
              assets.
            </div>
            </p>
            <p>
            <div class="tit">EFS</div>
            <div class="sub">
              It is a distribution and logistics brand specialized in the education industry and operates logistics
              services such as<br> proposing, supplying, refurbishing, and experience operation of IT devices.
            </div>
            </p>
            <p>
            <div class="tit">Shop</div>
            <div class="sub">
              We provide the products and services that enterprises need at ONE-STOP at a reasonable price.
            </div>
            </p>
            <div class="biz_btn">
              <a href="//bidding.etners.com" target="_blank">BIDDING<img src="@/assets/img/biz_arrow.png" alt=""> </a>
              <a href="//etnersefs.com/landing" target="_blank">EFS<img src="@/assets/img/biz_arrow.png" alt=""></a>
              <a href="//shop.etners.com" target="_blank">SHOP<img src="@/assets/img/biz_arrow.png" alt=""></a>

            </div>
          </div>
        </div>
        <!--사업이미지-->
        <h2 class="company_tit manytext align-c animated d-2s">
          Experience a smart Etners business with the business request management system,<br />
          Employee Smart Request Manager (ESRM). <a href="//esrm.etners.com" target="_blank"><img
              src="@/assets/img/arrow-right-circle.png"></a>
        </h2>
        <div class="biz_img_con">
          <img src="@/assets/img/ebiz_circle_img.png" alt="">
        </div>
        <div class="biz_img_mcon">
          <img src="@/assets/img/ebiz_circle_mimg.png" alt="">
        </div>
      </div>
      <!-- <kendo-window ref="popupWindowRef" :modal="true" :width="'100%'" :height="'100%'" style="display:none;"> -->

      <!-- </kendo-window> -->
    </div>
    <!--//비즈니스-->
  </div>

</template>

<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import WaitServiceWindow from '@/components/WaitServiceWindow'
  const ethDomain = ApiConfig.ethDomain

  export default {
    name: 'BusinessEn',
    components:{
      WaitServiceWindow
    },
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
      fnPopupOpen: function(){
        const vm = this
        // let submitPopWindow = vm.$refs.popupWindowRef.kendoWidget()
        // submitPopWindow.center().open()
        vm.popupCss = "display:block;"
      },
      onClickWindowClose() {
        this.popupCss = "display:none;"
      },

    },
    data: function () {
      const vm = this
      return {
        popupCss: 'display:none;'

      }
    }
  }
</script>

<template>
  <main id="main" class="eng">
    <!-- //header-->
		<div id="container_home">
      <div class="company_inner">
        <submit-question-window ref="submitQuestionWindowRef" :style="popupCss"
                                  :submitData="submitData"
                                  :popType="popType"
                                  @onClickWindowClose="onClickWindowClose">
        </submit-question-window>
        <!--//////////POP-UP START//////////-->
      	<div class="pop_dim" :style="popupBaseCss">
      		<div class="pop_area">
      			<a class="float-r"  href="/main/dashboard"><img src="@/assets/img/close_btn.png"></a>
      			<div class="pop_tit">Service Inquiry</div>
      			<div class="pop_tit_sub">
      				Please find our Business Introduction Letter
      			</div>
      			<div style="padding: 0 20px;">
      				<div>
                <div class="fl_input">
    							<label>Company Name <span class="dh_orange">*</span></label>
                  <kendo-maskedtextbox
                                      ref="companyNmTxRef"
                                      maxlength="14"
                                      name="companyNmTx"
                                      :style="'width: 100%;'"
                              ></kendo-maskedtextbox>
    						</div>
    						<div class="fl_input">
    							<label>Contact No<span class="dh_orange">*</span></label>
                  <kendo-maskedtextbox
                                      ref="phoneNoTxRef"
                                      maxlength="30"
                                      name="phoneNoTx"
                                      id="phoneNoTx"
                                      :placeholder="'01012341234'"
                                      :style="'width: 100%;'"
                              ></kendo-maskedtextbox>
    						</div>
    						<div class="fl_input">
    							<label>E-mail <span class="dh_orange">*</span></label>
                  <kendo-maskedtextbox
                                      ref="emailTxRef"
                                      maxlength="33"
                                      name="emailTx"
                                      id="emailTx"
                                      :placeholder="'ex@etners.com'"
                                      :style="'width: 100%;'"
                              ></kendo-maskedtextbox>
    						</div>
    						<div class="fl_input">
    							<label>Client Name <span class="dh_orange">*</span></label>
                  <kendo-maskedtextbox
                                      ref="managerNmTxRef"
                                      maxlength="14"
                                      name="managerNmTx"
                                      :style="'width: 100%;'"
                              ></kendo-maskedtextbox>
    						</div>
    						<div>
                  <div class="">
                    <input type="checkbox" id="agree_pledge" style="display:none;" v-model="vModel.agree_pledge" class="k-checkbox">
                    <label for="agree_pledge" class="k-checkbox-label">&nbsp;<span class="dh_orange mr5"><b>*</b></span>I hereby agree and confirm that all my Personal Information can be used and collected. 
                        <a class="dh_orange"
        										:href="'/../privacy_en.html?solCd=00'"
        										target="_blank">[View]</a>
                    </label>
                  </div>
                  <div class="">
                    <input type="checkbox" id="agree_marketing" style="display:none;" v-model="vModel.agree_marketing" class="k-checkbox">
                    <label for="agree_marketing" class="k-checkbox-label">ㆍ[Select] I agree to use marketing. 
                        <a class="dh_orange"
        										:href="'/../marketing_en.html?solCd=00'"
        										target="_blank">[View]</a>
                    </label>
                  </div>
    						</div>
      					<div class="pop_btn">
      						<button @click="fnSubmit">SAVE</button>
      					</div>
      				</div>
      			</div>

      		</div>

      	</div>
      	<!--//////////POP-UP END//////////-->
			</div>

		</div>
  </main>
</template>
<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import SubmitQuestionWindow from '@/components/SubmitQuestionWindow'
  import $ from 'jquery'


  export default {
    name: 'ProposalDownloadWindow',
    components: {
      SubmitQuestionWindow
    },

    created() {
    },
    mounted () {
      const vm = this

      vm.params = this.$route.params

      let elementPhone = document.getElementById('phoneNoTx')
      elementPhone.onkeyup = function (){
        vm.setMatchedPhone(elementPhone.value)
      }
      elementPhone.onkeydown = function (){
        vm.setMatchedPhone(elementPhone.value)
      }
    },
    computed: {

    },
    methods: {
      onClickWindowClose() {
        this.$router.push({
          name: 'UserDashboardEn'
        })
      },
      setMatchedPhone: function(value){
      const matchedPhone = /[0-9]$/.test(value)

      if(!matchedPhone){
        this.$refs.phoneNoTxRef.kendoWidget().value('')
        return false
      }else{
        this.$refs.phoneNoTxRef.kendoWidget().value(value)
        return true
      }

     },
      getDefaultRules: function(){
        const vm = this

        const resultMsg = { key: true, msg: '' }
        const companyNmTx = this.$refs.companyNmTxRef.kendoWidget().value()
        const managerNmTx = this.$refs.managerNmTxRef.kendoWidget().value()
        const phoneNoTx = this.$refs.phoneNoTxRef.kendoWidget().value()


        if ( EtnersCommonUtil.isEmpty(companyNmTx) || EtnersCommonUtil.isEmpty(companyNmTx) ) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter the company name.'
        }else if ( EtnersCommonUtil.isEmpty(managerNmTx) || EtnersCommonUtil.isEmpty(managerNmTx) ) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter your name.'
        }else if ( EtnersCommonUtil.isEmpty(phoneNoTx) || EtnersCommonUtil.isEmpty(phoneNoTx) ) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter your contact number.'
        }

        return resultMsg

      },
      getEmailRules: function () {
        const resultMsg = { key: true, msg: '' }
        const emailId = this.$refs.emailTxRef.kendoWidget().value()
        const matched = /^([\w-\.]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i.test(emailId)

        if ( EtnersCommonUtil.isEmpty(emailId)) {
          resultMsg.key = false
          resultMsg.msg = 'Please enter your email.'
        } else if (!matched) {
          resultMsg.key = false
          resultMsg.msg = 'Please match the email format.'
        }
        return resultMsg
      },
      fnSubmit: function(){
        const vm = this

        const vModel = vm.vModel
        const defaultResultMsg = this.getDefaultRules()
        const emailRules = this.getEmailRules()

        if(defaultResultMsg.key){
          if(emailRules.key){
            if (!vModel.agree_pledge) {
              kendo.alert('Please agree to the contents of personal information use. ')
              return false
            }

            kendo.confirm("Would you like to register? ").done(function() {
              let params = {
                solCd                   : '00', // 홈페이지문의
                companyNmTx             : vm.$refs.companyNmTxRef.kendoWidget().value(),
                managerNmTx             : vm.$refs.managerNmTxRef.kendoWidget().value(),
                emailTx                 : vm.$refs.emailTxRef.kendoWidget().value(),
                phoneNoTx               : vm.$refs.phoneNoTxRef.kendoWidget().value(),
                personalInfoAgreeYn     : vm.vModel.agree_pledge ? 'Y' : 'N',
                marketingAgreeYn        : vm.vModel.agree_marketing ? 'Y' : 'N',
              }

              vm.submitData = params
              vm.popupBaseCss ='display:none;'
              vm.popupCss='display:block;'
            })

          }else{
            kendo.alert(emailRules.msg)
            return false
          }
        }else{
          kendo.alert(defaultResultMsg.msg)
          return false
        }

      }
    },
    data: function () {
      const vm = this
      return {
        params : {},
        vModel: {
          agree_pledge: null,
          agree_marketing: null
        },
        submitData:{},
        popType : 'down',
        popupBaseCss: 'display:block;',
        popupCss: 'display:none;',

      }
    }
  }
</script>

<template>
  <div>
    <form ref="PatentFormRef" v-kendo-validator @submit.prevent="">
      <div class="contents_wrap">
        <ul class="filter_list">
          <li>
              <label>분류
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <li>
                  <div class="k-form-field">
                    <input ref="patentYRef"
                           v-model="patentRowData.patentYn"
                           type="radio"
                           name="patentYn"
                           id="patent"
                           class="k-radio"
                           :disabled="boolReadonly"
                           :value="'Y'">
                    <label for="patent" class="k-radio-label">특허</label>
                    <input ref="patentNRef"
                           v-model="patentRowData.patentYn"
                           type="radio"
                           name="patentYn"
                           id="certification"
                           :disabled="boolReadonly"
                           class="k-radio"
                           :value="'N'">
                    <label for="certification" class="k-radio-label">인증</label>
                  </div>
                </li>
              </ul>
          </li>
          <li>
              <label>특허 · 인증명
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <li>
                  <kendo-maskedtextbox
                      ref="patentNmTxRef"
                      :disabled="boolReadonly"
                      v-model ="patentRowData.patentNmTx"
                      id="patentNmTx"
                      :placeholder="'특허 · 인증명을 입력하세요.'"
                      :style="'width: 100%;'"
                      maxlength="100"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
          </li>
          <li>
              <label>특허 · 인증명 (영문)
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <li>
                  <kendo-maskedtextbox
                      ref="patentNmTxEnRef"
                      :disabled="boolReadonly"
                      v-model ="patentRowData.patentNmTxEn"
                      id="patentNmTxEn"
                      :placeholder="'특허 · 인증명(영문)을 입력하세요.'"
                      :style="'width: 100%;'"
                      maxlength="100"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
          </li>
          <li>
            <label>취득 · 인증 날짜
                <span class="forg">*</span>
              </label>
              <ul class="w30">
                <li>
                  <kendo-datepicker ref="patentCertDtPickerRef"
                                    id="patentCertDtPickerRef"
                                    :disabled="boolReadonly"
                                    v-model="patentRowData.fmtPatentCertDt"
                                    class="w95"
                                    placeholder="0000/00/00"
                                    :format="'yyyy/MM/dd'">
                  </kendo-datepicker>
                </li>
              </ul>
          </li>
          <li>
              <label>특허 · 인증번호
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <li>
                  <kendo-maskedtextbox
                      ref="patentCertNoTxRef"
                      :disabled="boolReadonly"
                      v-model ="patentRowData.patentCertNoTx"
                      id="patentCertNoTx"
                      :placeholder="'특허 · 인증번호를 입력하세요.'"
                      :style="'width: 100%;'"
                      maxlength="30"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
          </li>
          <li>
              <label>특허 · 인증번호 (영문)
                <span class="forg">*</span>
              </label>
              <ul class="w80">
                <li>
                  <kendo-maskedtextbox
                      ref="patentCertNoTxEnRef"
                      :disabled="boolReadonly"
                      v-model ="patentRowData.patentCertNoTxEn"
                      id="patentCertNoTxEn"
                      :placeholder="'특허 · 인증번호(영문)를 입력하세요.'"
                      :style="'width: 100%;'"
                      maxlength="30"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
          </li>
          <li v-show = "editMode == 'INSERT' || editMode == 'UPDATE'">
            <label>특허 · 인증 썸네일
              <span class="forg">*</span>
            </label>
            <ul class="w80 icon_file">
              <li>
                <div class="upload">
                  <file-upload-post v-bind:file="uploads.thumbnail"
                                      @child="bindingFiles"
                                      :imageName="'THUMBNAIL'">
                  </file-upload-post>
                </div>
              </li>
            </ul>
          </li>
          <li v-show = "editMode == 'DETAIL'">
            <label>특허 · 인증 썸네일
              <span class="forg">*</span>
            </label>
            <ul class="w80 icon_file">
              <li v-for="file in patentThumbnailFileData">
                <div class="btn_file"><span class="k-icon k-i-download"></span>
                  <a v-bind:href="makeFileUrl(file)">{{file.orgFileNameTx}}</a>
                </div>
              </li>
            </ul>
          </li>
          <li v-show = "editMode == 'INSERT' || editMode == 'UPDATE'">
            <label>특허 · 인증서 이미지
              <span class="forg">*</span>
            </label>
            <ul class="w80 icon_file">
              <li>
                <div class="upload">
                  <file-upload-post v-bind:file="uploads.certification"
                                      @child="bindingFiles"
                                      :imageName="'CERTIFICATION'">
                  </file-upload-post>
                </div>
              </li>
            </ul>
          </li>
          <li v-show = "editMode == 'DETAIL'">
            <label>특허 · 인증서 이미지
              <span class="forg">*</span>
            </label>
            <ul class="w80 icon_file">
              <li v-for="file in patentCertificationFileData">
                <div class="btn_file"><span class="k-icon k-i-download"></span>
                  <a v-bind:href="makeFileUrl(file)">{{file.orgFileNameTx}}</a>
                </div>
              </li>
            </ul>
          </li>
          <li v-if = "editMode == 'DETAIL'">
              <label>등록일
                <span class="forg">*</span>
              </label>
              <ul class="w30">
                <li>
                  <kendo-maskedtextbox
                      ref="patentFrstRgstDtRef"
                      v-model ="patentRowData.fmtLastMdfyDt"
                      :disabled="boolReadonly"
                      :style="'width: 100%;'"
                      maxlength="50"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
          </li>
          <li v-if = "editMode == 'DETAIL'">
                <label>등록자
                  <span class="forg">*</span>
                </label>
                <ul class="w30">
                  <li>
                    <kendo-maskedtextbox
                        ref="patentFrstRgstIdRef"
                        v-model ="patentRowData.lastMdfyId"
                        :disabled="boolReadonly"
                        :style="'width: 100%;'"
                        maxlength="50"
                    >
                    </kendo-maskedtextbox>
                  </li>
                </ul>
            </li>
            <li>
            <li>
          </li>
        </ul>
      </div>
    </form>
  <div class="button_area">

  <template v-if = "editMode == 'INSERT' || editMode == 'UPDATE'">
    <ul>
      <li class="w40">
        <button class="large_btn" @click="onClickWindowClose">취소</button>
      </li>
      <li class="w40">
        <button class="large_btn orange" @click="onClickPatentSave">저장</button>
      </li>
    </ul>
  </template>

  <template v-else-if = "editMode == 'DETAIL'">
    <ul>
      <li class="w40">
        <button class="large_btn" @click="onClickPatentDelete">삭제</button>
      </li>
      <li class="w40">
        <button class="large_btn orange" @click="onClickPatentModify">수정</button>
      </li>
    </ul>
  </template>
  </div>
</div>
</template>

<script>
import $ from 'jquery'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersParseUtil from '@/common/etners.parse.util'
import Constant from '@/common/constant'
import { Editor } from '@progress/kendo-editor-vue-wrapper';
import FileUploadPost from '@/components/FileUploadPost'
import EtnersDateTimeUtil from '@/common/etners.datetime.util'

export default {
  name: 'patentManageWindow',
  props: [ 'patentRowData', 'patentCertificationFileData', 'patentThumbnailFileData', 'editMode'],
  components: {'editor': Editor, FileUploadPost},
  data() {
    const vm = this
    return {
      mms:0,
      templateGbnVal: '',
      patentInfo:{},
      uploads: {
        certification: [],
        thumbnail: []
      },
      htmlText: ``,
      existThumbnailFileId : '',
      existCertificationFileId : '',
      boolReadonly : false,
      index :0,
      editor: {
        settings: {
          editorTools: [
            "viewHtml",
            "fontSize",
            "fontName",
            "foreColor",
            "backColor",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "createLink",
            "tableWizard",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn"
          ]
        }
      },


    }
  },
  mounted() {
    const vm = this
    vm.initFileDataHandler()
  },
  watch: {
    patentCertificationFileData: {
      handler: 'initFileDataHandler'
    },
    patentThumbnailFileData: {
      handler: 'initFileDataHandler'
    },
    editMode: {
      handler: 'initDataHandler'
    }
  },
  methods: {
    initDataHandler() {
      const vm = this
      vm.boolReadonly = false
      vm.index = 0
      if (this.editMode == 'DETAIL'){
        vm.boolReadonly = true
        vm.index = 2
      }else if (this.editMode == 'INSERT'){
        this.patentRowData.patentId = ''
        this.patentRowData.patentNmTx = ''
        this.patentRowData.patentNmTxEn = ''
        this.patentRowData.patentCertNoTx = ''
        this.patentRowData.patentCertNoTxEn = ''
        this.patentRowData.fmtPatentCertDt = ''
        this.patentRowData.patentYn = ''
        vm.uploads.certification = []
        vm.uploads.thumbnail = []
      }
    },
    onClickPatentSave() {
      const vm = this
      const userName = JSON.parse(sessionStorage.getItem('adminLoginUserData')).userNmKoTx
      const {patentId, patentNmTx,patentNmTxEn, patentCertNoTx, patentCertNoTxEn, patentYn } = vm.patentRowData
      let patentCertDt = this.$refs.patentCertDtPickerRef.kendoWidget().value()

      if (EtnersCommonUtil.isEmpty(patentCertDt)){
        patentCertDt = document.getElementById('patentCertDtPickerRef').value.replace(/\//g,"")
      }

      if (EtnersCommonUtil.isEmpty(patentYn)){
        kendo.alert('특허 · 인증 분류를 선택해주세요.')
        return false
      }
      if (EtnersCommonUtil.isEmpty(patentNmTx)){
        kendo.alert('특허 · 인증명을 입력해주세요.')
        return false
      }
      if (EtnersCommonUtil.isEmpty(patentNmTxEn)){
        kendo.alert('특허 · 인증명(영문)을 입력해주세요.')
        return false
      }
      if (EtnersCommonUtil.isEmpty(patentCertNoTx)){
        kendo.alert('특허 · 인증번호를 입력해주세요.')
        return false
      }
      if (EtnersCommonUtil.isEmpty(patentCertNoTxEn)){
        kendo.alert('특허 · 인증번호(영문)를 입력해주세요.')
        return false
      }
      if (vm.uploads.certification.length == 0) {
        kendo.alert("특허 및 인증서 파일을 선택해주세요.")
        return
      }
      if (vm.uploads.certification.length > 1) {
        kendo.alert("특허 및 인증서 파일은 하나만 업로드 가능합니다.")
        return
      }

      let param = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        patentId, patentCertNoTx, patentNmTx, patentYn, patentCertNoTxEn, patentNmTxEn,
        patentCertDt,
        frstRgstId: userName,
        lastMdfyId: userName,
        uploadedFiles : {}
      }

      let confirmKeyword = (vm.editMode === 'INSERT' ? '등록' : '수정')

      kendo.confirm(confirmKeyword + '하시겠습니까?').done(function() {
        let filesParam = {
            loginUserToken: sessionStorage.adminLoginUserToken,
            wmGbn: '01',
            existThumbnailFileId: vm.existThumbnailFileId,
            existThumbnailFileSeqs: [],
            existCertificationFileId: vm.existCertificationFileId,
            existCertificationFileSeqs: [],
            fileNames: []
        }

        const formData = new FormData()

        vm.uploads.certification.forEach(function(v) {
          formData.append("certification", v.rawFile)

          if (v.seq !== undefined) {
            filesParam.existCertificationFileSeqs.push(v.seq)
            filesParam.existCertificationFileId = v.fileId
          }
        })

        vm.uploads.thumbnail.forEach(function(v) {
          formData.append("thumbnail", v.rawFile)

          if (v.seq !== undefined) {
            filesParam.existThumbnailFileSeqs.push(v.seq)
            filesParam.existThumbnailFileId = v.fileId
          }
        })

        formData.forEach((value,key) => filesParam.fileNames.push(key))

        formData.append("uploadAuthParam", new Blob([JSON.stringify(filesParam)], {
          type: "application/json"
        }))

        vm.$Progress.start()

        ApiUtil.postIncludeUploadFile(`${ApiConfig.ethDomain}/v1/eth/post/savePatentFileUpload.json`, formData).then(response => {
          if (response.data.resultStatus.messageCode != "2000") {
            kendo.alert(`${response.data.resultStatus.messageText}`)
            vm.$Progress.fail()
            return
          }
          param.uploadedFiles = response.data.resultData.uploadedFileMap

          vm.editPatent(param)
        })
      })
    },
    initFileDataHandler: function() {
      const vm = this

      vm.uploads = { certification: [],
                    thumbnail: [] }

      const patentCertificationFiles = vm.patentCertificationFileData
      const patentThumbnailFiles = vm.patentThumbnailFileData


      if(EtnersCommonUtil.isNotEmpty(patentCertificationFiles)){
        vm.existCertificationFileId = patentCertificationFiles[0].fileId
        patentCertificationFiles.forEach(function(v) {
          const patentCertificationFileInfo = {
            'name': v.orgFileNameTx,
            'size': v.fileSizeTx,
            'extension': v.fileExtTx,
            'seq': v.fileSeqNo,
            'fileId': v.fileId,
            'uid': v.fileId + v.fileSeqNo,
            'url': `${ApiConfig.ethDomain}/files/${v.fileId}`
          }
          vm.uploads.certification.push(patentCertificationFileInfo)
        })
      }

      if(EtnersCommonUtil.isNotEmpty(patentThumbnailFiles)){
        vm.existThumbnailFileId = patentThumbnailFiles[0].fileId
        patentThumbnailFiles.forEach(function(v) {
          const patentThumbnailFileInfo = {
            'name': v.orgFileNameTx,
            'size': v.fileSizeTx,
            'extension': v.fileExtTx,
            'seq': v.fileSeqNo,
            'fileId': v.fileId,
            'uid': v.fileId + v.fileSeqNo,
            'url': `${ApiConfig.ethDomain}/files/${v.fileId}`
          }
          vm.uploads.thumbnail.push(patentThumbnailFileInfo)
        })
      }
    },
    bindingFiles(files) {
      const vm = this
      files.forEach(function(fileInfo) {
        switch (fileInfo.imageName) {
          case 'THUMBNAIL':
            vm.uploads.thumbnail.push(fileInfo)
            break
          case 'CERTIFICATION':
            vm.uploads.certification.push(fileInfo)
            break
        }
      })
    },
    onClickPatentModify() {
      this.$emit("editModeUpdate" , 'UPDATE')
    },
    onClickWindowClose(){
      this.$emit('onClickWindowClose')
    },
    onClickPatentDelete(){
      const vm = this

      let patentId = vm.patentRowData.patentId

        kendo.confirm('삭제 하시겠습니까?').done(function() {
          let param = {
            loginUserToken: sessionStorage.adminLoginUserToken,
            patentId: patentId
          }

          vm.$Progress.start()
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/deletePatent.json`, param).then(function(response) {
            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
              vm.$Progress.fail()
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              vm.$Progress.fail()
              return false
            }
            vm.$Progress.finish()

            kendo.alert('삭제 되었습니다.')

            vm.onClickWindowClose()

          })
        })
    },
    editPatent(param) {
      const vm =this

      if (vm.editMode === 'INSERT') {
        vm.$Progress.start()

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/addPatent.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            vm.$Progress.fail()
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            vm.$Progress.fail()
            return false
          }

          vm.$Progress.finish()
          vm.onClickWindowClose()
          kendo.alert('등록 되었습니다.')
          vm.initDataHandler()
        })
      }else if (vm.editMode === 'UPDATE') {
        vm.$Progress.start()

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/modifyPatent.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            vm.$Progress.fail()
            return false
          }

          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            vm.$Progress.fail()
            return false
          }

          vm.$Progress.finish()
          vm.onClickWindowClose()
          kendo.alert('수정 되었습니다.')
        })
      }
    },
    makeFileUrl: function(file) {
      return `${ApiConfig.ethDomain}/download/${file.fileId}/${file.fileSeqNo}`
    }

  }
}
</script>

<style scoped>
  #overlay{
    top:100;
    position:absolute;
    background-color:black;
    opacity:0.07;
    width:800px;
    height:400px;
  }
  #patentContent{
    top:100;
    position:absolute;

  }
</style>

<template>
  <div id="container">
    <div class="title_wrap"><h1>담당자관리 상세</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>담당자 정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>카테고리 </label>
            <ul>
              <li>{{detailData.solTx=='기타'?'EtnersHome':detailData.solTx}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>성명(한글) </label>
            <ul>
              <li>{{detailData.userNmKoTx}}</li>
            </ul>
          </dd>
          <dd>
            <label>성명(영문)</label>
            <ul class="w80">
              <li>{{detailData.userNmEnTx}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>메일 주소</label>
            <ul class="w80">
              <li>{{detailData.userId}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>근무지 주소</label>
            <ul class="w80">
              <li>{{detailData.addressInfoTx}} {{detailData.addressDetailTx}}<br />(우편번호 : {{detailData.postNoTx}})</li>
            </ul>
          </dd>
          <dd>
            <label>휴대전화번호</label>
            <ul class="w80">
              <li>{{detailData.phoneNoTx}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>가입 상태</label>
            <ul>
              <li>{{detailData.cvtAccountStatusCd}}</li>
            </ul>
          </dd>
          <dd>
            <label>마지막 접속일시</label>
            <ul class="w60">
              <li>{{detailData.fmtLastLoginDt}}</li>
            </ul>
          </dd>
        </li>
        <li>
            <dd>
              <label>알림 수신여부</label>
              <ul>
                <li>{{ detailData.receiveNotiYn }}</li>
              </ul>
            </dd>
          </li>
      </ul>
    </div>
    <div class="button_area w25">
      <ul>
        <li class="w30"><button class="large_btn orange" @click="redirectUserManageSave">수정</button></li>
        <li class="w30"><button class="large_btn" @click="redirectUserManageList">목록</button></li>
      </ul>
    </div>
  </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'

  export default {
    name: 'UserManagementDetail',
    created () {
      let vm = this

      let unqUserNo = vm.$route.params.unqUserNo

      vm.unqUserNo = unqUserNo

      let adminLoginUserToken = sessionStorage.getItem("adminLoginUserToken")

      let detailParam = {
        "loginUserToken" : adminLoginUserToken,
        "unqUserNo" : unqUserNo
      }

      ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/user/selectUserManagementDetail.json`, detailParam).then(function (response) {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          return false
        }

        const resultStatus = response.data.resultStatus

        if (resultStatus.messageCode !== '2000') {
          kendo.alert(resultStatus.messageText)
          return false
        }

        vm.detailData = response.data.resultData

      })
    },
    mounted () {
      const vm = this

    },
    methods: {
      redirectUserManageList: function() {
        this.$router.push(
            {
              name: 'UserManagement',
            },
        )
      },
      redirectUserManageSave: function() {
        this.$router.push(
            {
              name: 'UserManagementSave',
              params : {
                unqUserNo : this.unqUserNo
              }
            },
        )
      },
    },
    data: function () {
      const vm = this

      return {
        unqUserNo : "",
        detailData : {
          accountStatusCd : "",
          addressDetailTx : "",
          addressInfoTx : "",
          birthdateTx : "",
          cvtAccountStatusCd : "",
          fmtFrstRgstDt : "",
          fmtLastLoginDt : "",
          fmtTermsAgreeDt : "",
          phoneNoTx : "",
          postNoTx : "",
          unqUserNo : "",
          userId : "",
          userNmEnTx : "",
          userNmKoTx : "",
        },
      }
    },
  }
</script>

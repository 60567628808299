import { fetchFirstUserInfo, fetchedSendAuthNumber,
  fetchedConfirmAuthNumber, fetchedUpdatePassword } from '../api/api.user'

const userStoreModule = {
  state: {
    userInfo: [],
    userErrors: null
  },
  getters: {
  },
  mutations: {
    SET_USER (state, userInfo) {
      state.userInfo = userInfo
      sessionStorage.setItem('adminLoginUserData', JSON.stringify(userInfo))
      sessionStorage.setItem('adminLoginUserAuthCd',userInfo.authCd)
    },
    SET_USER_ERROR (state, error) {
      state.userErrors = error
      kendo.alert(error)
    }
  },
  actions: {
    FETCH_USER (context) {
      fetchFirstUserInfo()
        .then(response => {
          if (response.data.resultStatus.messageCode === '2000') {
            context.commit('SET_USER', response.data.resultData)
          } else {
            context.commit('SET_USER_ERROR', response.data.resultStatus.messageText)
          }
        })
        .catch(error => {
          console.log('==================== FETCH_USER error ====================')
          console.log(error)
          context.commit('SET_USER_ERROR', error)
        });
    },
    FETCH_SEND_AUTH(context, credentials) {
      return new Promise(resolve => {
        fetchedSendAuthNumber(credentials)
        .then(response => {
          if(response.data.resultStatus.messageCode === '2000'){
            resolve(response.data)
          }else if(response.data.resultStatus.messageCode === '6000'){
             context.commit('SET_USER_ERROR', "일치하는 정보가 존재하지 않습니다.")
          }else{
            context.commit('SET_USER_ERROR', response.data.resultStatus.messageText)
          }
        })
        .catch(error => {
          console.log('==================== FETCH_USER error ====================')
          console.log(error)
          context.commit('SET_USER_ERROR', error)
        });
      });

    },
    FETCH_CONFIRM_AUTH_NUMBER(context, credentials) {
      return new Promise(resolve => {
        fetchedConfirmAuthNumber(credentials)
        .then(response => {
          if(response.data.resultStatus.messageCode === '2000'){
            resolve(response.data);
          }else if(response.data.resultStatus.messageCode === '6000'){
            context.commit('SET_USER_ERROR', "인증번호가 일치하지 않습니다.")
          }else{
            context.commit('SET_USER_ERROR', response.data.resultStatus.messageText)
          }
        })
        .catch(error => {
          console.log('==================== FETCH_USER error ====================')
          console.log(error)
          context.commit('SET_USER_ERROR', error)
        });
      });

    },
    FETCH_UPDATE_PASSWORD(context, credentials) {
      return new Promise(resolve => {
        fetchedUpdatePassword(credentials)
        .then(response => {
          if(response.data.resultStatus.messageCode === '2000'){
            resolve(response.data);
          }else{
            context.commit('SET_USER_ERROR', response.data.resultStatus.messageText)
          }
        })
        .catch(error => {
          console.log('==================== FETCH_USER error ====================')
          console.log(error)
          context.commit('SET_USER_ERROR', error)
        });
      });

    },
  }
}

export default userStoreModule

<template >
  
  <div id="esop_prov">
    <!-- 모바일이면 닫기버튼이 상단에 보임 -->
     <div v-if="((isMobileDevice && (termsDetailInfo.termsSolCd != '49' && termsDetailInfo.termsSolCd != '11' && termsDetailInfo.termsSolCd != '13' && termsDetailInfo.termsSolCd != '03' && termsDetailInfo.termsSolCd != '29' && termsDetailInfo.termsSolCd != '10')) && (termsDetailInfo.termsCd == '02' ||  termsDetailInfo.termsCd == '01') || (termsDetailInfo.termsSolCd == '50' && termsDetailInfo.termsCd != '07'))" class="txt_r"><button class="mid_btn" style="width: 100px;" @click="onClickClose">닫기</button></div>

     <div :style="customStyle" v-html="termsDetailInfo.termsContents" ></div>
    <kendo-dropdownlist v-if="termsDetailInfo.termsCd == '02'"
                        ref="termsListGridRef"
                        :option-label="'이전 개인정보처리방침'" 
                        :data-source= "termsListDataSource"
                        :data-text-field="'termsStartDt'"
                        :data-value-field="'termsId'"
                        :index="0" 
                        :style="'width: 200px;'"
                        @change="getBeforTermsDetail"
                        >
      
    </kendo-dropdownlist>
    <kendo-dropdownlist v-if="termsDetailInfo.termsCd == '01'"
                          ref="termsListGridRef"
                          :option-label="'이전 이용약관'" 
                          :data-source= "termsListDataSource"
                          :data-text-field="'termsStartDt'"
                          :data-value-field="'termsId'"
                          :index="0" 
                          :style="'width: 150px;'"
                          @change="getBeforTermsDetail"
                          >
      </kendo-dropdownlist>
      <kendo-dropdownlist v-if="termsDetailInfo.termsCd == '03'"
                            ref="termsListGridRef"
                            :option-label="'이전 고정형 영상정보처리기기 운영관리 방침'" 
                            :data-source= "termsListDataSource"
                            :data-text-field="'termsStartDt'"
                            :data-value-field="'termsId'"
                            :index="0" 
                            :style="'width: 300px;'"
                            @change="getBeforTermsDetail"
                            >
        </kendo-dropdownlist>
      <kendo-dropdownlist v-if="termsDetailInfo.termsCd == '04'"
                              ref="termsListGridRef"
                              :option-label="'이전 법적고지'" 
                              :data-source= "termsListDataSource"
                              :data-text-field="'termsStartDt'"
                              :data-value-field="'termsId'"
                              :index="0" 
                              :style="'width: 150px;'"
                              @change="getBeforTermsDetail"
                              >
          </kendo-dropdownlist>
          <kendo-dropdownlist v-if="termsDetailInfo.termsCd == '10'"
                              ref="termsListGridRef"
                              :option-label="'이전 수집항목'" 
                              :data-source= "termsListDataSource"
                              :data-text-field="'termsStartDt'"
                              :data-value-field="'termsId'"
                              :index="0" 
                              :style="'width: 150px;'"
                              @change="getBeforTermsDetail"
                              >
          </kendo-dropdownlist>
      
      <!-- <div style="text-align: right; margin-top: 20px;"><button class="mid_btn" style="width: 100px;" @click="onClickClose">닫기</button></div> -->
      <div class="txt_r"><button v-if="(termsDetailInfo.termsCd == '10' || termsDetailInfo.termsCd == '02' || termsDetailInfo.termsCd == '01' || (termsDetailInfo.termsSolCd == '50' && termsDetailInfo.termsCd == '07')) && (isMobileDevice && (termsDetailInfo.termsSolCd != '49' && termsDetailInfo.termsSolCd != '11' && termsDetailInfo.termsSolCd != '13' && termsDetailInfo.termsSolCd != '03' && termsDetailInfo.termsSolCd != '29' && termsDetailInfo.termsSolCd != '10'))" class="mid_btn" style="width: 100px;" @click="onClickClose">닫기</button></div>
     <!-- <kendo-grid 
          v-if="termsDetailInfo.termsCd == '02'"
          ref="termsListGridRef" 
          style="cursor: pointer; height: auto;"
          :data-source="termsListDataSource" 
          :columns="termsGridRefColumns"
          :navigatable="true" 
          :filterable="false" 
          :sortable="true" 
          :scrollable="false" 
          :pageable-always-visible="true"
          :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]" 
          :reorderable="true" 
          :column-menu="false" 
          :resizable="true"
          :selectable="'multiple row'" 
          @change="getBeforTermsDetail"
          :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }">
      </kendo-grid> -->
  </div>
</template>

<script>
import ApiConfig from '@/api/api.config'
import ApiUtil from "../../../api/api.util";
import EtnersCommonUtil from '@/common/etners.common.util'
import { isMobile } from '../../../common/device.js'

export default {
  name: "TermsDetail",
  
  mounted() {
    this.getStartTermsDetail()
  },
  updated() {
    // this.setHeight()
    
  },
  created() {
    this.isMobileDevice = isMobile();
  },
  methods: {
    onClickClose() {
     window.open(history.go(-1), '_self', '').close();

    },
    // setHeight() {
    //   const vm = this
    //   const contents = document.getElementById('html_contents')
    //   const tabConList = contents.getElementsByClassName('tabCon')

    //   if (tabConList.length == 0) {
    //     contents.setAttribute('style', '')
    //     vm.customStyle.height = null
      
    //   } else {
    //     for (let tab of tabConList) {
    //       const height = tab.offsetHeight
    //       if (height > 0) {
    //         vm.customStyle.height = height + 20 + 'px'
    //       }
    //     }
    //   }
    // },
    getTermsDetail(termsId) {
      const vm = this
      let loginUserToken = sessionStorage.adminLoginUserToken
      const { termsSolCd, termsCd } = vm.$route.params      

      termsId = EtnersCommonUtil.isEmpty(termsId) ? vm.$route.params.termsId : termsId
      
      const url = `${ApiConfig.ethDomain}/v1/eth/terms/termsDetail.json`
      const params = {
        loginUserToken: loginUserToken,
        termsId,
        termsCd: termsCd,
        termsSolCd: termsSolCd,
      }
      ApiUtil.post(url, params).then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          this.$Progress.fail()
          return false
        }
        const resultStatus = response.data.resultStatus
        if (resultStatus.messageCode !== '2000') {
          kendo.alert(resultStatus.messageText)
          return false
         }
        const resultData = response.data.resultData
        vm.termsDetailInfo = resultData

       })
      vm.getBeforTermsList(params)
    },

    getBeforTermsDetail(e) {
      let sender = e.sender
      const item = sender.dataItem(sender.select())
      this.getTermsDetail(item.termsId)
    },
    getStartTermsDetail() {
      const vm = this
      let loginUserToken = sessionStorage.adminLoginUserToken
      const { termsSolCd, termsCd, termsId } = vm.$route.params

      const url = `${ApiConfig.ethDomain}/v1/eth/terms/termsDetail.json`
      const params = {
        loginUserToken: loginUserToken,
        termsId : termsId,
        termsCd: termsCd,
        termsSolCd: termsSolCd,
      }
      ApiUtil.post(url, params).then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          this.$Progress.fail()
          return false
        }
        const resultStatus = response.data.resultStatus
        if (resultStatus.messageCode !== '2000') {
          kendo.alert(resultStatus.messageText)
          return false
        }
        const resultData = response.data.resultData
        vm.termsDetailInfo = resultData
      })
      vm.getBeforTermsList(params)

    },
    getBeforTermsList:function(item) {
      const vm = this
      let termsId = EtnersCommonUtil.isEmpty(item) ? vm.$route.params.termsId : item.termsId
      let termsCd = EtnersCommonUtil.isEmpty(item) ? vm.$route.params.termsCd : item.termsCd
      let termsSolCd = EtnersCommonUtil.isEmpty(item) ? vm.$route.params.termsSolCd : item.termsSolCd

      const params = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        termsId,
        termsCd,
        termsSolCd
      }

      const url = `${ApiConfig.ethDomain}/v1/eth/terms/termsList.json`

      ApiUtil.post(url, params).then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          this.$Progress.fail()
          return false
        }
        const resultStatus = response.data.resultStatus
        if (resultStatus.messageCode !== '2000') {
          kendo.alert(resultStatus.messageText)
          return false
        }
        const resultData = response.data.resultData
        vm.termsListDataSource = resultData
      })

      // vm.termsListDataSource = new kendo.data.DataSource({
      //   name: 'termsListDataSource',
      //   page: 1,
      //   pageSize: 10,
      //   serverPaging: true,
      //   serverFiltering: true,
      //   serverSorting: true,
      //   transport: {
      //     read: {
      //       url: `${ApiConfig.ethDomain}/v1/eth/terms/termsList.json`,
      //       type: 'POST',
      //       dataType: 'json',
      //       contentType: 'application/json',
      //       cache: false,
      //     },
      //     parameterMap: function (options, operation) {
      //       if (operation === 'read') {
      //         let params = {
      //           skip: options.skip,
      //           take: options.take,
      //           page: options.page,
      //           pageSize: options.pageSize,
      //           sort: options.sort,
      //           termsId: termsId,
      //           termsCd: termsCd,
      //           termsSolCd: termsSolCd,
      //           requestType: 'PAGING'
      //         }
      //         sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

      //         return kendo.stringify(params)
      //       }
      //       if (operation !== 'read' && options.models) {
      //         return { models: kendo.stringify(options.models) }
      //       }
      //     }
      //   },
      //   schema: {
      //     type: 'json',
      //     parse: function (response) {
      //       let changeResultData = response.resultData
      //       let rowNumber = response.total + 1 - (vm.$refs.termsListGridRef.dataSource.page() - 1) * vm.$refs.termsListGridRef.dataSource.pageSize()
      //       if (changeResultData != null) {
      //         for (let i = 0; i < changeResultData.length; i++) {
      //           let row = changeResultData[i]
      //           let termsId = changeResultData[i].termsId
      //           row.termsId = termsId
      //           row.rowNumber = --rowNumber;
      //         }
      //         response.resultData = changeResultData
      //       }
      //       return response
      //     },
      //     total: function (response) {
      //       return response.total
      //     },
      //     data: function (response) {
      //       if (response.resultStatus.messageCode === '2000') {
      //         return response.resultData
      //       } else {
      //         kendo.alert('message: ' + response.resultStatus.messageText)
      //         return ''
      //       }

      //     }
      //   },
      //   error: function (e) {
      //     console.log('error event handler', e.errors[0])
      //   },
      // })
    },
  },

  data() {
    const kendoGridHeaderAttributes = {
      style: 'font-weight: bold; text-align : center;'
    }
    return {
      isMobileDevice: false,
      customStyle: {
        height: 'auto'
      },
      termsListDataSource:{},
      termsDetailInfo: {},
      termsGridRefColumns: [
        {
          field: 'termsStartDt',
          title: '개인정보 처리 방침',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
          template(dataItem) {

            if (dataItem.termsEndDt != null) {
              return dataItem.termsStartDt + '  ~  ' + dataItem.termsEndDt + ' 적용(클릭)'
            } else {
              return dataItem.termsStartDt + '  ~  ' + '현재'+ ' 적용(클릭)'
            }
          }
        }
      ],
      contents :''
    }
  }
}
</script>
<style scoped>
/* #wrap {
  min-width: 0px;
} */
body {
  font-family: Nanumgothic, Tahoma;
  font-size: 0.9em;
  line-height: 1.4em;
  color: #555;
  background: #F5F7FB;
}

#esop_prov {
  width: 940px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 25px;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  background: #fff;
}

#esop_prov .ep_tit {
  font-size: 1.8em;
  color: #222;
  border-bottom: 2px solid #ccc;
  padding: 0px 0 18px 0;
}

#esop_prov .ep_tit2 {
  font-size: 1em;
  font-weight: normal;
  color: #222;
  padding: 20px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  letter-spacing: -1px;
  margin: 20px 0px 30px; 
  background-color: rgb(250, 250, 250);
  line-height: 1.8em
}

#esop_prov h4 {
  padding-bottom: 0;
  font-size: 0.95em;
  margin-bottom: 0em;
  margin-top: 2em;
}

#esop_prov table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.9em;
}

#esop_prov table,
th,
td {
  border: 1px solid #ccc;
  padding: 5px;
}

@media (max-width: 1024px) {
  body {
    background: #fff;
  }

  #esop_prov {
    width: auto;
    padding: 10px;
    border: 0px;
    margin-top: 0px;
    font-size: 0.9em;
  }

  #esop_prov .ep_tit {
    font-size: 1.3em;
  }

  #esop_prov .ep_tit2 {
    padding: 10px;
  }

  #esop_prov table,
  th,
  td {
    font-size: 0.95em;
    padding: 0px;
  }
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, p, dl, dt, dd, ol, ul, li, fieldset, form, label, legend {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    box-sizing: content-box;
}
</style>

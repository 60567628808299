import ApiUtil from '../api/api.util'
import ApiConfig from '../api/api.config'
const ethDomain = ApiConfig.ethDomain

const urlStoreModule = {
  state: {
    url: [],
    errors: null
  },
  getters: {
  },
  mutations: {
    SET_URL_DATA (state, urlData) {
      state.url = urlData
      sessionStorage.setItem('ethDomain', urlData['00001'])
    },
    SET_URL_ERROR (state, error) {
      state.urlErrors = error
      kendo.alert(error)
    }
  },
  actions: {
    FETCH_URL (context) {
      return new Promise(resolve => {
        ApiUtil.get(`${ethDomain}/v1/eth/code/url.json`).then(response => {
          context.commit('SET_URL_DATA', response.data)
          resolve(response.data)
        }).catch(error => {
          console.log('==================== FETCH_URL error ====================')
          console.log(error.response)
          context.commit('SET_URL_ERROR', error.response)
        })
      })
    }
  }
}

export default urlStoreModule

<template>
  <div id="container">
    <div class="title_wrap">
      <h1>다운로드</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">            <li>

              <dd>
                <label>카테고리</label>
                <ul>
                  <li>
                    <kendo-dropdownlist
                        ref="solCdRef"
                        :data-source="$store.state.codeRequestStore.search.combobox.solCd.data"
                        :data-text-field="'cdNmTx'"
                        :data-value-field="'cdId'"
                        :option-label="'전체'"
                        :index="0"
                        :value="lastRouteParams.filter.searchSolCd"
                        @change="commonOnChangeEvent">
                    </kendo-dropdownlist>
                  </li>
                </ul>
              </dd>
            <dd>
              <label>다운로드일</label>
              <ul class="w60">
                <kendo-daterangepicker
                    ref="downloadDtRef"
                    :class="'w60 fl mr5'"
                    :format="'yyyy/MM/dd'"
                    :range="downloadDtRange"
                    @change="commonOnChangeEvent"
                >
                </kendo-daterangepicker>

              </ul>
            </dd>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div class="contents_top_sec">
          <button class="mid_btn fr mgl5" @click="onClickRefreshDashBoard">
            <span class="k-icon k-i-refresh"></span> 새로고침
          </button>
          <button class="mid_btn fr " @click="onClickExcelDownload">
            <i class="download_icon"></i>
            <span>엑셀 다운로드</span>
          </button>
        </div>
        <kendo-grid
            ref="downloadListGridRef"
            style="cursor: pointer;"
            :data-source="computedDownloadListDataSource"
            :pageable-always-visible="true"
            :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
            :reorderable="true"
            :column-menu="false"
            :resizable="true"
            :selectable="true"
            :sortable="true"
            :columns="downloadGridRefColumns"
            :dataBound="dataBoundForDownload"
            :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        >
        </kendo-grid>
      </div>
    </section>
  </div>
</template>

<script>

  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'
  import { saveExcel } from '@progress/kendo-vue-excel-export'

  import $ from 'jquery'
  import ApiUtil from '../../../../api/api.util'

  export default {
    name: 'DownloadHistory',
    beforeMount() {
      if (JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd != '99') this.$router.push({ name: 'HomeQnaManagement' })
    },
    created () {
      this.$store.dispatch('initSearchDataForCodeRequest')

      let defaultLastRouteParams = {
        page: 1,
        filter: {
          searchSolCd: '',
        },
      }

      let jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')
      this.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'recuTemplateDetail' ? (jsonStrLastRouteParams === undefined
          ? defaultLastRouteParams
          : JSON.parse(jsonStrLastRouteParams)) : defaultLastRouteParams

      if (EtnersCommonUtil.isNotEmpty(this.lastRouteParams)) {
        this.lastRouteParams.page = this.lastRouteParams.filter.page
      }

    },
    mounted () {
      const vm = this


      this.$refs.downloadDtRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      });
      this.$refs.downloadDtRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      });

    },
    computed: {
      computedDownloadListDataSource: function () {
        const vm = this

        let lastRequestParameterPage = vm.lastRouteParams.page !== undefined ? vm.lastRouteParams.page : 1

        const downloadListDataSource = new kendo.data.DataSource({
          name: 'downloadListDataSource',
          page: lastRequestParameterPage,
          pageSize: 10,
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
          transport: {
            read: {
              url: `${ApiConfig.ethDomain}/v1/eth/summary/getDownloadList.json`,
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              cache: false,
            },
            parameterMap: function (options, operation) {
              if (operation === 'read') {
                const filter = vm.currentPageFilterOption()
                let loginUserToken = sessionStorage.adminLoginUserToken

                vm.replaceSortFieldStr(options)

                let params = {
                  loginUserToken: loginUserToken,
                  searchSolCd: filter.searchSolCd,
                  searchDownDtStartYYYYMMDD : filter.searchDownloadDtStartYYYYMMDD,
                  searchDownDtEndYYYYMMDD : filter.searchDownloadDtEndYYYYMMDD,
                  skip: options.skip,
                  take: options.take,
                  page: options.page,
                  pageSize: options.pageSize,
                  sort: options.sort,
                  requestType: 'PAGING', //PAGING or EXPORT
                }

                sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

                return kendo.stringify(params)
              }

              if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) }
              }
            },
          },
          schema: {
            type: 'json',
            parse: function (response) {

              let changeResultData = response.resultData
              let rowNumber = response.total + 1 - (vm.$refs.downloadListGridRef.dataSource.page() - 1)
                  * vm.$refs.downloadListGridRef.dataSource.pageSize()
              if(changeResultData!=null) {

                for (let i = 0; i < changeResultData.length; i++) {
                  let row = changeResultData[i]
                  let downloadId = changeResultData[i].downloadId
                  row.downloadId = downloadId
                  row.rowNumber = --rowNumber;
                }
                response.resultData = changeResultData
              }
              return response
            },
            total: function (response) {
              return response.total
            },
            data: function (response) {
              if (response.resultStatus.messageCode === '2000') {
                return response.resultData
              } else {
                kendo.alert('message: ' + response.resultStatus.messageText)
                return ''
              }
            },
          },

          error: function (e) {
            console.log('error event handler', e.errors[0])
          },
        })

        return downloadListDataSource
      },
    },
    methods: {
      commonOnChangeEvent: function () {
        const vm = this
        let gridRef = vm.$refs.downloadListGridRef

        // 마지막 검색 데이터 page 초기화
        this.lastRouteParams.page = 1

        if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
          if (gridRef.kendoWidget().dataSource !== undefined) {
            gridRef.kendoWidget().dataSource.page(1)
          }
        }
      },
      currentPageFilterOption: function () {
        const vm = this
        const refs = vm.$refs

        let currentSearchDataObj = {}

        const filterData = vm.lastRouteParams.filter


        if (refs.solCdRef !== undefined) {
          const searchSolCd = refs.solCdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchSolCd)) {
            currentSearchDataObj.searchSolCd = searchSolCd
          }
        }


        if (refs.downloadDtRef !== undefined) {
          const downloadDtRangeValue = refs.downloadDtRef.kendoWidget().range()

          if (EtnersCommonUtil.isNotEmpty(downloadDtRangeValue)) {
            const startYYYYMMDD = (downloadDtRangeValue.start !== undefined && downloadDtRangeValue.start !== null)
                ? EtnersDateTimeUtil.dateToYYYYMMDD(downloadDtRangeValue.start)
                : undefined
            const endYYYYMMDD = (downloadDtRangeValue.end !== undefined && downloadDtRangeValue.end !== null)
                ? EtnersDateTimeUtil.dateToYYYYMMDD(downloadDtRangeValue.end)
                : undefined

            currentSearchDataObj.searchDownloadDtStartYYYYMMDD = startYYYYMMDD
            currentSearchDataObj.searchDownloadDtEndYYYYMMDD = endYYYYMMDD
          }
        }
        vm.filterData = currentSearchDataObj

        return currentSearchDataObj
      },
      dataBoundForDownload: function (evt) {
        let vm = this
        let isDragging = false;

        $(".k-grid td")
            .mousedown(function() {
              $(window).mousemove(function() {
                isDragging = true;
                $(window).unbind("mousemove");
              });
            })
            .mouseup(function() {
              let wasDragging = isDragging;
              isDragging = false;
              $(window).unbind("mousemove");
              if (!wasDragging) {
                let row = evt.sender.selectable.userEvents.currentTarget
                let selectedRow = evt.sender.select();
              }
            });
      },
      // 초기화 버튼 클릭 이벤트
      cleanSearchDataEvent (e) {
        const refs = this.$refs

        //템플릿구분
        if (refs.solCdRef !== undefined) {
          refs.solCdRef.kendoWidget().select(0)
        }
        if (refs.downloadDtRef !== undefined) {
          refs.downloadDtRef.kendoWidget().range({start: undefined, end: undefined})
        }

        sessionStorage.removeItem('LAST_ROUTE_PARAMS')

        this.commonOnChangeEvent(e)
      },
      replaceSortFieldStr (options) {
        if (options.sort != undefined && options.sort.length != 0){
          let sortField = options.sort[0].field
          let replaceField

          switch (sortField) {
            case "rowNumber":
              replaceField = "seqNo"
              break;
            default:
              replaceField = sortField
          }
          options.sort[0].field = replaceField
        }
      },
      onClickRefreshDashBoard: function(e) {
        this.$refs.downloadListGridRef.kendoWidget().dataSource.read()
      },
      isEmptyReturnDash : function(obj) {
        return EtnersCommonUtil.isEmpty(obj) ? '-' : obj
      },
      onClickExcelDownload(){

        const vm = this
        const lastRequestParameters = JSON.parse(sessionStorage.getItem('LAST_ROUTE_PARAMS'))
        const excelParams = JSON.parse(JSON.stringify(lastRequestParameters.filter))
        excelParams.requestType = 'EXPORT'

        const excelListUrl = `${ApiConfig.ethDomain}/v1/eth/summary/getDownloadList.json`

        ApiUtil.post(excelListUrl, excelParams).then(response => {

          const changeResultData = response.data.resultData

          if (changeResultData == null) {
            kendo.alert("엑셀 다운로드에 실패하였습니다. 관리자에게 문의해주세요.")
          }

          changeResultData.forEach(row => {
            row.solTx = vm.isEmptyReturnDash(row.solTx)
            row.fmtDownloadDt = vm.isEmptyReturnDash(row.fmtDownloadDt)
            row.companyNmTx = vm.isEmptyReturnDash(row.companyNmTx)
            row.managerNmTx = vm.isEmptyReturnDash(row.managerNmTx)
            row.phoneNoTx = vm.isEmptyReturnDash(row.phoneNoTx)
            row.emailTx = vm.isEmptyReturnDash(row.emailTx)
          })

          if (EtnersCommonUtil.isNotEmpty(changeResultData)) {
            const currentDate = EtnersDateTimeUtil.lookupDateYYYYMMDD()
            const currentYYYYMMDD = EtnersDateTimeUtil.dateToYYYYMMDD(currentDate)

            saveExcel({
              data: changeResultData,
              fileName: '소개서다운로드리스트_' + currentYYYYMMDD + '.xlsx',
              columns: [
                {
                  field: 'solTx',
                  title: '카테고리',
                  width: '100px'
                },
                {
                  field: 'fmtDownloadDt',
                  title: '다운로드 일자',
                  width: '300px',
                },
                {
                  field: 'companyNmTx',
                  title: '회사명',
                  width: '50px'
                },
                {
                  field: 'managerNmTx',
                  title: '성 명',
                  width: '50px'
                },
                {
                  field: 'phoneNoTx',
                  title: '연락처',
                  width: '100px',
                },
                {
                  field: 'emailTx',
                  title: '이메일',
                  width: '100px'
                }
              ]
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        })

      }
    },
    data: function () {
      const kendoGridHeaderAttributes = {
        style: 'font-weight: bold;',
      }

      const vm = this

      return {
        rowNumber: 0,
        downloadRowData: {},
        editMode: '',
        val: '',
        downloadDtRange: {start: undefined, end: undefined},
        messages: {
          year: '년',
          month: '월',
          day: '일',
        },
        filterData: {},
        lastRouteParams: {
          page: 1,
          filter: {
            searchSolCd: '',
          },
        },
        downloadGridRefColumns: [
          {
            field: 'rowNumber',
            title: 'No.',
            width: '3%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'solTx',
            title: '카테고리',
            width: '6%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'fmtDownloadDt',
            title: '다운로드 일자',
            width: '8%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'companyNmTx',
            title: '회사명',
            width: '14%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'managerNmTx',
            title: '성 명',
            width: '14%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'phoneNoTx',
            title: '연락처',
            width: '14%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'emailTx',
            title: '이메일',
            width: '14%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },

        ],
      }
    },
  }
</script>

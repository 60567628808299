/*****************************************************************
 * 공통
 *****************************************************************/
import Vue from 'vue'
import VueRouter from 'vue-router'
import JwtService from '@/common/jwt.service'

import { store } from '../store/index'

import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'

import NotFoundComponent from '../components/NotFound.vue'


/*****************************************************************
 * 사용자 웹 모듈
 *****************************************************************/
import UserDashboard                  from '../views/userHome/main/home/UserDashboard'
import CompanyOverview                from '../views/userHome/company/CompanyOverview'
import CeoMessage                     from '../views/userHome/company/CeoMessage'
import CompanyHistory                 from '../views/userHome/company/CompanyHistory'
import Business                       from '../views/userHome/business/Business'
import Report                         from '../views/userHome/promotion/Report'
import ReportDetail                   from '../views/userHome/promotion/ReportDetail'
import Csr                            from '../views/userHome/promotion/Csr'
import Certification                  from '../views/userHome/promotion/Certification'
import CertificationDetail            from '../views/userHome/promotion/CertificationDetail'
import Location                       from '../views/userHome/contact/Location'
import SubmitQuestion                 from '../views/userHome/qna/SubmitQuestion'
import ProposalDownloadWindow         from '../views/userHome/qna/ProposalDownloadWindow'
import UnSubscribe                    from '../views/userHome/qna/UnSubscribe'


/*****************************************************************
 * 사용자 웹 모듈 - 영문
 *****************************************************************/
import UserDashboardEn                  from '../views/userHomeEn/main/home/UserDashboardEn'
import CompanyOverviewEn                from '../views/userHomeEn/company/CompanyOverviewEn'
import CeoMessageEn                     from '../views/userHomeEn/company/CeoMessageEn'
import CompanyHistoryEn                 from '../views/userHomeEn/company/CompanyHistoryEn'
import BusinessEn                       from '../views/userHomeEn/business/BusinessEn'
import CsrEn                            from '../views/userHomeEn/promotion/CsrEn'
import LocationEn                       from '../views/userHomeEn/contact/LocationEn'
import SubmitQuestionEn                 from '../views/userHomeEn/qna/SubmitQuestionEn'
import ProposalDownloadWindowEn         from '../views/userHomeEn/qna/ProposalDownloadWindowEn'
import CertificationEn                  from '../views/userHomeEn/promotion/CertificationEn'
import CertificationDetailEn            from '../views/userHomeEn/promotion/CertificationDetailEn'
import ReportEn                         from '../views/userHomeEn/promotion/ReportEn'
import ReportDetailEn                   from '../views/userHomeEn/promotion/ReportDetailEn'

/*****************************************************************
 * 관리자 모듈
 *****************************************************************/
import AdminLogin                       from '../views/adminHome/main/login/AdminLogin'
import InitPassword                     from '../views/adminHome/main/login/InitPassword'
import FindPasswordStep1                from '../views/adminHome/main/login/FindPasswordStep1'
import UtopiaAdminLogin                 from '../views/adminHome/main/login/UtopiaAdminLogin'
import AdminDashboard                   from '../views/adminHome/main/dashboard/AdminDashboard'

import CodeManagement                   from '../views/adminHome/configurer/codeManagement/CodeManagement'
import CodeManagementMstSave            from '../views/adminHome/configurer/codeManagement/CodeManagementMstSave'
import CodeManagementDtlSave            from '../views/adminHome/configurer/codeManagement/CodeManagementDtlSave'
import CodeManagementMstDetail          from '../views/adminHome/configurer/codeManagement/CodeManagementMstDetail'
import CodeManagementDtlDetail          from '../views/adminHome/configurer/codeManagement/CodeManagementDtlDetail'

import VisitantHistory                  from '../views/adminHome/summary/visitant/VisitantHistory'
import DownloadHistory                  from '../views/adminHome/summary/download/DownloadHistory'
import HomeQnaManagement                from '../views/adminHome/summary/qna/HomeQnaManagement'
import HomeQnaManagementDetail          from '../views/adminHome/summary/qna/HomeQnaManagementDetail'

import PatentManagement                 from '../views/adminHome/post/certification/PatentManagement'
import ReportManagement                 from '../views/adminHome/post/report/ReportManagement'


import UserManagement                   from '../views/adminHome/user/management/UserManagement'
import UserManagementDetail             from '../views/adminHome/user/management/UserManagementDetail'
import UserManagementSave               from '../views/adminHome/user/management/UserManagementSave'

import PayrollNewsLetterManagement             from '../views/adminHome/payroll/NewsLetterManagement'

import SharedserviceNewsLetterManagement             from '../views/adminHome/sharedserivce/NewsLetterManagement'

import TermsManagement                  from '../views/adminHome/terms/TermsManagement'
import TermsDetail                      from '../views/adminHome/terms/TermsDetail'


Vue.use(VueRouter)

const checkMenu = () => (from, to, next) => {

  if (sessionStorage.getItem('webMenuData') == null) {
    ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/findAllMenuForWmGbn.json`, { wmGbn : '01' }).then(function (response) {
      if (response.data === undefined || response.data.resultStatus === undefined) {
        kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
        return false
      }

      sessionStorage.setItem('webMenuData', JSON.stringify(response.data.resultData))
      next()
    })
  }else {
    next()
  }
}

const checkEnMenu = () => (from, to, next) => {

  if (sessionStorage.getItem('webMenuData') == null) {
    ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/findAllMenuForWmGbn.json`, { wmGbn : '01' }).then(function (response) {
      if (response.data === undefined || response.data.resultStatus === undefined) {
        kendo.alert('Failed to receive data successfully.')
        return false
      }

      sessionStorage.setItem('webMenuData', JSON.stringify(response.data.resultData))
      next('en/main/dashboard/')

    })
  }else {
    next()
  }
}

// function isMobileDevice() {
//   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
// }

const requireAuth = () => (from, to, next) => {
  const redirectUrl = from.query.redirectUrl
  const loginUserToken = from.query.loginUserToken
  const sessionStorageLoginUserToken = sessionStorage.getItem('adminLoginUserToken')

  //로그인 토큰이 세션 스토리지에 존재하지 않을 때만 세팅하도록 처리
  if (ApiConfig.mode === 'development' && loginUserToken == undefined && sessionStorageLoginUserToken == null) {
   from.query.loginUserToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzYW4iOiJQM1dRMFNlZTJtOEhteVZpaEVDV1l3PT0iLCJzdWIiOiJFQk1QIExvZ2luIFRva2VuIHYxLjAiLCJhdWQiOiJnaHN0eWx1c0BnbWFpbC5jb20iLCJuYmYiOjE1OTg4NjM4ODYsImNjZSI6ImFCa2l5L29zbHBBNno2SVh4Qkc5U0E9PSIsInNjZCI6IkwvMFVoalV0aDJzQTBpOXVreDJxUEQ1ZllOTE1mVStJVzZ1aVp6NlV3NUdlanE4RkVWdWRKZHBnTE1ORER1WVIiLCJ3c2MiOiJ3SnlqTUtncFVRUlBVSS9PelpqVS93PT0iLCJpc3MiOiJlYm1wLnYxLmFwaSIsInVhdCI6IkNtMmxJMitldm51TW5iOHVtMnl4RXc9PSIsImRwdCI6IlZNd0EzTXZWbG5yeFYvekdPQ1Vvanc9PSIsImV4cCI6MTYwMTI4MzE0NiwiaWF0IjoxNTk4ODYzOTQ2fQ.Wtn3X-oDvucOsUVnX4pLfIN8FU51Ov705mkdwanif7eQw1vvVvnrJhBealYgsLd7yNuhPxTc2KmSXo6ChzapwA'
  }

  if(sessionStorage.getItem('loginUserData') == null && sessionStorage.getItem('adminLoginUserData') == null && redirectUrl === undefined) {
    next()
    return
  }

  if (sessionStorage.getItem('adminLoginUserData') !== null && redirectUrl === undefined) {
    const authCd = JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd
    // TODO : 권한별 초기화면 네비게이션 설정
    if (authCd !== undefined && authCd !== null && authCd !== '') {
      switch (authCd) {
        case '99' : //이트너스관리자
          next('/homeadmin/dashboard')
          break
        default :
          next('/accessDenied')
          break
      }
    }

    return
  }
  next()
}

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/main/dashboard',
    },
    {
      path: `/`,
      redirect: '/main/dashboard',
    },
    {
      path: `/common/notFound`,
      name: 'NotFound',
      component: NotFoundComponent
    },
    /***********************************************************************
     * 사용자 웹
     ***********************************************************************/
    {
      path: `/main/dashboard`,
      name: 'UserDashboard',
      component: UserDashboard,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/location`,
      name: 'Location',
      component: Location,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/company`,
      component: CompanyOverview,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/company/overview`,
      name: 'CompanyOverview',
      component: CompanyOverview,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/company/ceo`,
      name: 'CeoMessage',
      component: CeoMessage,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/company/history`,
      name: 'CompanyHistory',
      component: CompanyHistory,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/business`,
      name: 'Business',
      component: Business,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/promotion`,
      component: Report,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/promotion/report`,
      name: 'Report',
      component: Report,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/promotion/report/:reportId/:currentPage`,
      name: 'ReportDetail',
      component: ReportDetail,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/promotion/csr`,
      name: 'Csr',
      component: Csr,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/promotion/certification`,
      name: 'Certification',
      component: Certification,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/promotion/certification/detail/:patentId/:currentPage`,
      name: 'CertificationDetail',
      component: CertificationDetail,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/qna/question`,
      name: 'SubmitQuestion',
      component: SubmitQuestion,
      meta: {
        layout: 'UserMainLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/qna/question/download`,
      name: 'ProposalDownloadWindow',
      component: ProposalDownloadWindow,
      meta: {
        layout: 'UserPopLayout',
      },
      beforeEnter: checkMenu(),
      props: true
    },
    {
      path: `/qna/unSubscribe/:emailTx`,
      name: 'UnSubscribe',
      component: UnSubscribe,
      props: true
    },
    /***********************************************************************
         * 사용자 웹 - 영문
         ***********************************************************************/
    {
      path: `/en/main/dashboard`,
      name: 'UserDashboardEn',
      component: UserDashboardEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/location`,
      name: 'LocationEn',
      component: LocationEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/company`,
      component: CompanyOverviewEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/company/overview`,
      name: 'CompanyOverviewEn',
      component: CompanyOverviewEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/company/ceo`,
      name: 'CeoMessageEn',
      component: CeoMessageEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/company/history`,
      name: 'CompanyHistoryEn',
      component: CompanyHistoryEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/business`,
      name: 'BusinessEn',
      component: BusinessEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/promotion/csr`,
      name: 'CsrEn',
      component: CsrEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },

    {
      path: `/en/qna/question`,
      name: 'SubmitQuestionEn',
      component: SubmitQuestionEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      props: true
    },
    {
      path: `/en/qna/question/download`,
      name: 'ProposalDownloadWindowEn',
      component: ProposalDownloadWindowEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      props: true
    },
    {
      path: `/en/promotion/certification`,
      name: 'CertificationEn',
      component: CertificationEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/promotion/certification/detail/:patentId/:currentPage`,
      name: 'CertificationDetailEn',
      component: CertificationDetailEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/promotion`,
      component: ReportEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/promotion/report`,
      name: 'ReportEn',
      component: ReportEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    {
      path: `/en/promotion/report/:reportId/:currentPage`,
      name: 'ReportDetailEn',
      component: ReportDetailEn,
      meta: {
        layout: 'UserMainLayoutEn',
      },
      beforeEnter: checkEnMenu(),
      props: true
    },
    /***********************************************************************
     * 관리자
     ***********************************************************************/

    //로그인
    {
      path: `/homeadmin`,
      name: 'System',
      beforeEnter: requireAuth(),
      redirect: {
        name: 'AdminLogin'
      },
    },
    //utopia로그인
    {
      path: `/homeadmin/utopiaLogin`,
      name: 'UtopiaAdminLogin',
      component: UtopiaAdminLogin,
    },
    {
      path: `/homeadmin/login`,
      name: 'AdminLogin',
      component: AdminLogin,
      meta: {
        layout: 'CommonLoginLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/initPassword`,
      name: 'InitPassword',
      component: InitPassword,
      meta: {
        layout: 'CommonLoginLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/findPassword`,
      name: 'FindPasswordStep1',
      component: FindPasswordStep1,
      meta: {
        layout: 'CommonLoginLayout',
      },
    },
    //대시보드
    {
      path: `/homeadmin/dashboard`,
      name: 'AdminDashboard',
      component: AdminDashboard,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    //코드 관리
    {
      path: `/homeadmin/configurer/codeManagement`,
      name: 'CodeManagement',
      component: CodeManagement,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/configurer/codeManagement/Mst/save`,
      name: 'CodeManagementMstSave',
      component: CodeManagementMstSave,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/configurer/codeManagement/Mst/detail`,
      name: 'CodeManagementMstDetail',
      component: CodeManagementMstDetail,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/configurer/codeManagement/Dtl/save`,
      name: 'CodeManagementDtlSave',
      component: CodeManagementDtlSave,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/configurer/codeManagement/Dtl/detail`,
      name: 'CodeManagementDtlDetail',
      component: CodeManagementDtlDetail,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/summary`,
      component: VisitantHistory,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/summary/visitant`,
      name: 'VisitantHistory',
      component: VisitantHistory,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/summary/download`,
      name: 'DownloadHistory',
      component: DownloadHistory,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/summary/qna`,
      name: 'HomeQnaManagement',
      component: HomeQnaManagement,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/summary/qna/:qnaId`,
      name: 'HomeQnaManagementDetail',
      component: HomeQnaManagementDetail,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    //통계 관리
    {
      path: `/homeadmin/statistics`,
      name: 'statistics',
      redirect: {
        name: 'YearStatistics',
      },
    },
    {
      path: `/homeadmin/post/certification`,
      name: 'PatentManagement',
      component: PatentManagement,
      meta: {
        layout: 'AdminMainLayout',
      },
      // props: true,
    },
    {
      path: `/homeadmin/post/report`,
      name: 'ReportManagement',
      component: ReportManagement,
      meta: {
        layout: 'AdminMainLayout',
      },
    },
    {
      path: `/homeadmin/user/management`,
      name: 'UserManagement',
      component: UserManagement,
      meta: {
        layout: 'AdminMainLayout',
      },
    },
    {
      path: `/homeadmin/user/management/detail/:unqUserNo`,
      name: 'UserManagementDetail',
      component: UserManagementDetail,
      meta: {
        layout: 'AdminMainLayout',
      },
    },
    {
      path: `/homeadmin/user/management/save/:unqUserNo`,
      name: 'UserManagementSave',
      component: UserManagementSave,
      meta: {
        layout: 'AdminMainLayout',
      },
    },
    {
      path: `/homeadmin/newsletter`,
      name: 'PayrollNewsLetterManagement',
      component: PayrollNewsLetterManagement,
      meta: {
        layout: 'AdminMainLayout',
      },
    },
    {
      path: `/homeadmin/newsletter/payroll`,
      name: 'PayrollNewsLetterManagement',
      component: PayrollNewsLetterManagement,
      meta: {
        layout: 'AdminMainLayout',
     },
    },
     {
      path: `/homeadmin/newsletter/sharedservice`,
      name: 'SharedserviceNewsLetterManagement',
      component: SharedserviceNewsLetterManagement,
      meta: {
        layout: 'AdminMainLayout',
     },
    },
    {
      path: `/homeadmin/terms/management`,
      name: 'TermsManagement',
      component: TermsManagement,
      meta: {
        layout: 'AdminMainLayout',
      }
    },
    {
      path: `/homeadmin/terms/management/list`,
      name:'TermsManagement',
      component: TermsManagement,
      meta: {
        layout: 'AdminMainLayout',
      }
    },
  
    {
      path: `/terms/management/detail/:termsSolCd/:termsCd`,
      name:'TermsDetail',
      component: TermsDetail,
      meta: {
        layout: 'NoInfoHeaderLayout',
      }
      
      
    },

  ]
})


// 네비게이션 가드 (전역가드)
router.beforeEach(function (to, from, next) {
  let saveVisitHis = false

  window.addEventListener("beforeunload", function (e) {
    if (!navigator.sendBeacon) return
    var url = `${ApiConfig.ethDomain}/v1/eth/summary/receiveBeacon.json`
    navigator.sendBeacon(url + "?data=" + getCookie("visitId"))

    if (!to.meta.layout.includes("UserMainLayout")) {
      delCookie('visitId')
    }
  })

  if(!saveVisitHis){
    saveVisitHis = setVisitHis()
  }

  let getParameterByName = function (name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }

  const loginUserToken = sessionStorage.loginUserToken
  const adminLoginUserToken = sessionStorage.adminLoginUserToken
  const queryToken = from.query.loginUserToken

  let checkMode = "USER_MODE"

  if(loginUserToken) {
    checkMode = "USER_MODE"
  } else if(adminLoginUserToken) {
    checkMode = "ADMIN_MODE"
  }

  if("USER_MODE" === checkMode) {

    let paramLoginUserToken = getParameterByName('loginUserToken')

    if (paramLoginUserToken != null && paramLoginUserToken != '') {
      from.query.loginUserToken = paramLoginUserToken
    }

    //console.log(`loginUserToken : ${loginUserToken}`)

    // 토큰 정보 없는 경우
    // if (loginUserToken === undefined || loginUserToken === null) {
    //   if (to.path === '/') {
    //     return next('/common/checkDevice')
    //   }
    //   if (queryToken !== undefined && queryToken !== null) {
    //     return next()
    //   }
    //
    //   return next()
    // }
    //console.log(`to.path : ${to.path}`)

    if (to.path === '/accessDenied') {
      alert('접속할 수 있는 권한이 없습니다. 관리자에게 문의해주세요.')

      return false
    }

    if (sessionStorage.getItem('loginUserData') !== null) {
      // 접속자 메뉴리스트
      const originalMenuData = JSON.parse(sessionStorage.getItem('loginUserData')).menuMaster

      // 접속하고자 하는 메뉴가 접속자의 메뉴리스트에 있는지 확인 (접속 권한 체크)
      const boolMenuAuth = originalMenuData.some(menu =>{
        /*******************************************************************
         * 모든 프론트 프로젝트에 대한 디폴트 룰 정의
         * 2020. 08. 14. oxide.
         *
         * 1. 기본적으로는 menuMaster에서 가져온 메뉴 링크를 포함하거나
         *    메뉴 링크 + save/new/detail을 허용한다.
         * 2. popup은 메뉴 링크 상관없이 뜨도록 허용한다.
         *******************************************************************/
        const defaultRuleFl = to.path === '/accessDenied'
                           || to.path === '/'
                           || menu.urlLink === to.path
                           || menu.urlLink + '/' === to.path
                           || to.path.includes(menu.urlLink + '/save')
                           || to.path.includes(menu.urlLink + '/new')
                           || to.path.includes(menu.urlLink + '/detail')
                           || to.path.includes('/popup') //popup은 urlLink를 체크하지 않고 바로 들어올 수 있게 처리

        const customRuleFl1 = to.path.includes('/dashboard') //커스텀 룰
        const customRuleFl2 = to.path.includes('/myPage') //커스텀 룰
        const customRuleFl3 = to.path.includes('/resume') //커스텀 룰

        return true
        //return defaultRuleFl || customRuleFl1 || customRuleFl2 || customRuleFl3
      })

      /* 접속 권한이 있는 메뉴 진입인 경우 */
      if (boolMenuAuth === true) {
        
        return next()
        
      }
      else {
          // return next()
        /**
         * 접속불가 화면으로 이동
         * case 1. 이전 링크가 없는 경우
         * case 2. ESOP 메인에서 직접 링크로 들어왔을 경우 (requireAuth 타고 온 경우라 from과 to의 path 정보가 같음)
         */
        if (from.name === undefined || from.name === null || (from.path === to.path)) {
          return next('/accessDenied')
        }
      }
    } else {
      // if (!to.path.includes("homeadmin/login") && to.path.includes("homeadmin/")) {
      //   console.log(to.path)
      //   return next("/homeadmin/login")
      // } else {
      //   return next()
      // }
      return next()
      
    }



  } else if("ADMIN_MODE" === checkMode) {

    let paramAdminLoginUserToken = getParameterByName('adminLoginUserToken')

    if (paramAdminLoginUserToken != null && paramAdminLoginUserToken != '') {
      from.query.adminLoginUserToken = paramAdminLoginUserToken
    }

    // 토큰 정보 없는 경우
    if (adminLoginUserToken === undefined || adminLoginUserToken === null) {
      if (to.path === '/') {
        return next()
      }
      if (queryToken !== undefined && queryToken !== null) {
        return next()
      }

      return next('/')
    }

    if (to.path === '/accessDenied') {
      alert('접속할 수 있는 권한이 없습니다. 관리자에게 문의해주세요.')

      return false
    }

    if (sessionStorage.getItem('adminLoginUserData') !== null) {
      // 접속자 메뉴리스트
      const originalMenuData = JSON.parse(sessionStorage.getItem('adminLoginUserData')).menuMaster

      // 접속하고자 하는 메뉴가 접속자의 메뉴리스트에 있는지 확인 (접속 권한 체크)
      const boolMenuAuth = originalMenuData.some(menu =>{
        /*******************************************************************
         * 모든 프론트 프로젝트에 대한 디폴트 룰 정의
         * 2020. 08. 14. oxide.
         *
         * 1. 기본적으로는 menuMaster에서 가져온 메뉴 링크를 포함하거나
         *    메뉴 링크 + save/new/detail을 허용한다.
         * 2. popup은 메뉴 링크 상관없이 뜨도록 허용한다.
         *******************************************************************/
        const defaultRuleFl = to.path === '/accessDenied'
                           || to.path === '/'
                           || menu.urlLink === to.path
                           || menu.urlLink + '/' === to.path
                           || to.path.includes(menu.urlLink + '/save')
                           // || to.path.includes(menu.urlLink + '/new')
                           || to.path.includes(menu.urlLink + '/detail')
                           || to.path.includes('/popup') //popup은 urlLink를 체크하지 않고 바로 들어올 수 있게 처리

        const customRuleFl = to.path.includes(menu.urlLink + '/dashboard') //커스텀 룰
        const customRuleFl2 = to.path.includes('/dashboard') //커스텀 룰
        const customRuleFl3 = to.path.includes('/siksha') //커스텀 룰

        return defaultRuleFl || customRuleFl || customRuleFl2 || customRuleFl3 || true
      })

      /* 접속 권한이 있는 메뉴 진입인 경우 */
      if (boolMenuAuth === true) {
        return next()

      }
      /* 접속 권한이 없는 메뉴 진입인 경우 */
      else {
          // return next()
        /**
         * 접속불가 화면으로 이동
         * case 1. 이전 링크가 없는 경우
         * case 2. ESOP 메인에서 직접 링크로 들어왔을 경우 (requireAuth 타고 온 경우라 from과 to의 path 정보가 같음)
         */
        if (from.name === undefined || from.name === null || (from.path === to.path)) {
          return next('/accessDenied')
        }
      }
    } else {
      return next()
    }



  }

})


function setVisitHis() {

  let visitId = getCookie("visitId")??""

  let param = { visitId, solCd : "00"}

  ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/summary/setVisitHis.json`, param).then(function (response) {
    if (response.data === undefined || response.data.resultStatus === undefined) {
      kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
      return false
    }
    if (getCookie("visitId") == "") {
      setCookie("visitId", response.data.resultData.visitId, 1)
    }

  })

  return true

}

function setCookie(key, value, expiredays) {
  let todayDate = new Date();
  todayDate.setDate(todayDate.getDate() + expiredays)
  document.cookie = key + "=" + escape(value) + "; path=/; expires=" + todayDate.toGMTString() + ";"
}

function getCookie(key){
  key = new RegExp(key + '=([^;]*)')
  return key.test(document.cookie) ? unescape(RegExp.$1) : ''
}

function delCookie(key){
  let todayDate = new Date()
  document.cookie = key + "=; path=/; expires=" + todayDate.toGMTString() + ";"
}

router.afterEach((to, from) => {
  sessionStorage.setItem('LAST_ROUTE_NAME', from.name)
})

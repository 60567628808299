import ApiUtil from '../api/api.util'
import ApiConfig from '../api/api.config'
import EtnersDateTimeUtil from '../common/etners.datetime.util'

const DEFAULT_CMPY_CD = '00000'

let VUEX_ALL_CMPY_CODE_MAP = { }

if(sessionStorage.getItem('__VUEX_ALL_CMPY_CODE_MAP__') != null) {
  VUEX_ALL_CMPY_CODE_MAP = JSON.parse(sessionStorage.getItem('__VUEX_ALL_CMPY_CODE_MAP__'))

}
// else {
//   console.log(`JSON : ${JSON.stringify(ApiConfig)}`)
//   let allCodeUrl = `${ApiConfig.ethDomain}/v1/eth/code/allCodesByCmpyCd.json`
//   let allCodeParam =  {
//     "cmpyCd" : [DEFAULT_CMPY_CD]
//   }
//
//   console.log(`allCodeUrl : ${allCodeUrl}`)
//
//   ApiUtil.post(allCodeUrl, allCodeParam)
//   .then(response => {
//     VUEX_ALL_CMPY_CODE_MAP = response.data.allCodeMap
//   })
// }


const getCodeIsMatched = function (option, codeMap) {
  if (codeMap[option.cdGrup] == undefined) {
    return null
  }

  let codeByCdGrupArray = codeMap[option.cdGrup]

  for (let i = 0; i < codeByCdGrupArray.length; i++) {
    let code = codeByCdGrupArray[i]

    if (code.cdNm == option.cdNm) {
      return code
    }
  }

  return null
}

const lookupCompanyCodeByCodeName = function (option) {
  if (COMMON_CMPY_CODE_MAP[option.cmpyCd] == undefined) {
    return null
  } else {
    let codeMap = COMMON_CMPY_CODE_MAP[option.cmpyCd]

    return getCodeIsMatched(option, codeMap)
  }
}

const isNumber = (arg) => {
  // 문자열로 변환
  arg += ''

  // 좌우 공백 제거
  arg = arg.replace(/^\arg*|\arg*$/g, '')

  if (arg == '' || isNaN(arg)) {
    return false
  }

  return true
}

const getMatchType = (arg) => {
  if (arg === undefined || arg === null) {
    throw new Error('정의되지 않은 인자를 넣을 수 없습니다.')
  }

  let constructor = arg.constructor

  if (constructor == Number && isNumber(arg)) {
    return 'NUMBER'
  } else if (constructor == Object) {
    return 'OBJECT'
  } else if (constructor == String) {
    return 'STRING'
  } else if (constructor == Array) {
    return 'ARRAY'
  } else if (typeof arg === 'function') {
    return 'FUNCTION'
  } else if (typeof arg === 'object') {
    return 'OBJECT'
  } else {
    throw new Error('검증되지 않은 타입이 존재합니다.')
  }
}

const isEmpty = (arg) => {
  if (arg === undefined || arg === null || arg === '' || arg === 'null') {
    return true
  }

  let type = getMatchType(arg)

  let flag = false

  switch (type) {
    case 'NUMBER' :
      flag = false
      break
    case 'ARRAY' :
      flag = arg.length == 0
      break
    case 'STRING' :
      try {
        flag = arg.trim().length == 0
      } catch (e) {
        String.prototype.trim = function () {
          return this.replace(/^\s+|\s+$/g, '')
        }

        flag = arg.trim().length == 0
      }
      break
    case 'OBJECT' :
      try {
        flag = Object.keys(arg).length == 0
      } catch (e) {
        let count = 0

        for (let key in arg) {
          count++
        }

        flag = count == 0
      }
      break
  }

  return flag
}

const isNotEmpty = (arg) => {
  return !isEmpty(arg)
}

/**
 * 숫자 3자리마다 콤마(,)를 찍어서 리턴함.
 *
 */
const numberWithCommas = function (number) {
  const n = parseInt(number)

  if (isNaN(n)) {
    return number
  }

  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * 문자열에 콤마가 있는 경우 제거함.
 *
 */
const removeCommasInText = function (n) {
  if (typeof n === 'undefined' || n == null) {
    return 0
  }

  const txtNumber = '' + n
  return parseInt(txtNumber.replace(/(,)/g, ''))
}

/**
 * 텍스트에서 숫자만 추출해서 리턴함. 없으면 빈 문자열을 리턴
 *
 */
const extractOnlyNumbers = function (text) {
  if (text + '' == 'NaN') {
    return ''
  }

  switch (getMatchType(text)) {
    case 'STRING' :
      return text.replace(/[^0-9]/g, '')
    case 'NUMBER' :
      return text
    default :
      return ''
  }
}

const getAllCompanyCodeMapUsingVuex = function(cmpyCd) {
  if(cmpyCd == undefined) {
    return VUEX_ALL_CMPY_CODE_MAP[DEFAULT_CMPY_CD]
  } else {
    return VUEX_ALL_CMPY_CODE_MAP[cmpyCd]
  }

}

const sortArray = function (obj, sortColumn) {
  obj.sort(function (f, l) {

    // assuming distance is always a valid integer
    return parseInt(f[sortColumn], 10) - parseInt(l[sortColumn], 10)

  })
  return obj
}

/**
 * 목록 화면에서 검색 조건이 있을경우 검색조건(filters)를 return함
 */
const getLastRouteParams = function (lastRouteParams) {

  let filterArray = lastRouteParams.filter
  let returnLastRouteParams = {}

  if (isNotEmpty(filterArray)) {
    // 등록일 value값 세팅
    if (Object.keys(lastRouteParams).length > 0 && lastRouteParams.filter.length > 0) {
      filterArray.forEach(filter => {
        // let arr = filter.filters
        // if (arr[0].field === 'inputDt'||arr[0].field === 'frstRgstDt'||arr[0].field === 'mailSendDate'||arr[0].field === 'applyDt') {
        //   returnLastRouteParams[arr[0].field+'Start'] = EtnersDateTimeUtil.lookupDateYYYYMMDD(arr[0].value)
        //   returnLastRouteParams[arr[0].field+'End'] = EtnersDateTimeUtil.lookupDateYYYYMMDD(arr[1].value)
        // } else {
        //   let fieldId = arr[0].field
        //
        //   //조인컬럼의 경우 필드명 구조가 "entity.column" 구조이며, 해당 구조를 element value 속성에서 filed명으로 인식되지 않으므로 가공 처리.
        //   if(fieldId.indexOf(".") > -1) {
        //     fieldId = fieldId.split(".")[0]+"Dot"+fieldId.split(".")[1]
        //   }
        //
        //   if(fieldId==='ContractHDotfrstRgstDt'){
        //     returnLastRouteParams[fieldId+'Start'] = EtnersDateTimeUtil.lookupDateYYYYMMDD(arr[0].value)
        //     returnLastRouteParams[fieldId+'End'] = EtnersDateTimeUtil.lookupDateYYYYMMDD(arr[1].value)
        //   }
        //
        //   if(fieldId==='ContractMstDotcontractFromDt'){
        //
        //     returnLastRouteParams['contractDtStart'] = EtnersDateTimeUtil.lookupDateYYYYMMDD(arr[0].value)
        //
        //   }
        //   if(fieldId==='ContractMstDotcontractToDt'){
        //     returnLastRouteParams['contractDtEnd'] = EtnersDateTimeUtil.lookupDateYYYYMMDD(arr[0].value)
        //
        //   }
        //
        //   returnLastRouteParams[fieldId] = arr[0].value
        // }
      })
    }
  }

  return returnLastRouteParams
}

/**
 * 왼쪽 문자열 채우기
 * @param padStr
 * @param width
 * @param str
 * @returns {*}
 */
const lpadString = function(padStr, width, str) {
  let result = ''

  if(isEmpty(padStr) || isEmpty(width) || isEmpty(str)) {
    return str
  }

  str = str + ''
  result = str.length >= width ? str : new Array(width - str.length+1).join(padStr) + str

  return result
}


export default {
  isNumber,
  getMatchType,
  isEmpty,
  isNotEmpty,
  extractOnlyNumbers,
  getAllCompanyCodeMapUsingVuex,
  sortArray,
  getLastRouteParams,
  numberWithCommas,
  lpadString,
}

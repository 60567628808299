<template>
    <div class="system-noti_box">  <img src="@/assets/img/sys_reject.png" alt="">
    <div class="system-title">마케팅활용동의 수신거부 완료 <br />
    </div>
    <div class="system_tex1"> 귀하의 수신 거부 요청이 완료 되었습니다. </div>
    <div class="system_tex2"> 수신 거부 후 다시 수신하시려면, 이트너스 홈페이지에서 수신 동의를 설정하여 다시 구독할 수 있습니다. <br />
        앞으로 더욱 노력하는 이트너스가 되겠습니다. </div>
    </div>
</template>


<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'

export default {
  name: 'UnSubscribe',
  data() {
    const vm = this
    return {

    }
  },
  mounted() {
    this.setUnSubscribe()
  },
  methods: {
    setUnSubscribe() {
      let params = {
        emailTx: this.$route.params.emailTx
      }
      ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/unSubscribe.json`, params).then(function () {})
    }
  }
}
</script>

<style>
.system-noti_box {
    text-align: center;
    background: #fff;
    max-width: 550px;
    width: 90%;
    margin: 0 auto;
    -webkit-box-shadow: 0 10px 20px rgb(51 51 51 / 7%);
    box-shadow: 0 10px 20px rgb(51 51 51 / 7%);
    padding: 40px 60px 50px 60px;
    border: 1px solid #eee;
    border-radius: 18px;
    -webkit-box-shadow: 0 0 15px 0 rgb(32 44 77 / 10%);
    box-shadow: 0 0 15px 0 rgb(32 44 77 / 10%);
    margin-top: 5%;
}
.system-title {
    font-size: 35px;
    color: #555;
    font-family: 'Noto Sans KR', 'Malgun Gothic', sans-serif;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -1px;
    text-align: center;
    margin-top:20px;
}
.system_tex1 {
    font-family: 'Noto Sans KR', 'Malgun Gothic', sans-serif;
    font-size: 25px;
    font-weight: normal;
    color: #222;
    padding: 30px 0;
    letter-spacing: -1px;
    line-height: 1.2;
    text-align: center;
}
.system_tex2 {
    font-family: 'Noto Sans KR', 'Malgun Gothic', sans-serif;
    color: #555;
    font-size: 15px;
    margin-top: 10px;
    line-height: 20px;
    padding: 25px;
    background-color: #f5f5f5;
    border-radius: 5px;
    word-break: keep-all;
}
</style>
<template>
  <div id="container">
    <div class="title_wrap">
      <h1>방문자</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">
            <li>

              <dd>
                <label>카테고리</label>
                  <ul>
                      <li>
                        <kendo-dropdownlist
                            ref="solCdRef"
                            :data-source="$store.state.codeRequestStore.search.combobox.solCd.data"
                            :data-text-field="'cdNmTx'"
                            :data-value-field="'cdId'"
                            :option-label="'전체'"
                            :index="0"
                            :value="lastRouteParams.filter.searchSolCd"
                            @change="commonOnChangeEvent">
                        </kendo-dropdownlist>
                      </li>
                  </ul>
              </dd>
              <dd>
                  <label>접속유형</label>
                  <ul class="w60">
                    <li>
                        <kendo-dropdownlist
                            ref="deviceCdRef"
                            :data-source="$store.state.codeRequestStore.search.combobox.deviceCd.data"
                            :data-text-field="'cdNmTx'"
                            :data-value-field="'cdId'"
                            :option-label="'전체'"
                            :index="0"
                            :value="lastRouteParams.filter.searchDeviceCd"
                            @change="commonOnChangeEvent">
                        </kendo-dropdownlist>
                      </li>
                  </ul>
              </dd>
            </li>
            <li>
            <dd>
              <label>유입경로</label>
                <ul>
                    <li>
                        <kendo-maskedtextbox
                                ref="visitRouteTxRef"
                                id="visitRouteTx"
                                :style="'width: 100%;'"
                                :value="lastRouteParams.filter.visitRouteTx"
                                @change="commonOnChangeEvent">
                        </kendo-maskedtextbox>
                    </li>
                </ul>
            </dd>
            <dd>
                <label>방문일</label>
                <ul class="w60">
                    <kendo-daterangepicker
                            ref="visitDtRef"
                            :class="'w60 fl mr5'"
                            :format="'yyyy/MM/dd'"
                            :range="visitDtRange"
                            @change="commonOnChangeEvent"
                    >
                    </kendo-daterangepicker>

                </ul>
            </dd>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div class="contents_top_sec">
        <button class="mid_btn f_r" @click="onClickRefreshDashBoard">
          <span class="k-icon k-i-refresh"></span> 새로고침
        </button>
        </div>
        <kendo-grid
            ref="visitantListGridRef"
            class="mgt10"
            style="cursor: pointer;"
            :data-source="computedVisitantListDataSource"
            :pageable-always-visible="true"
            :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
            :reorderable="true"
            :column-menu="false"
            :resizable="true"
            :selectable="true"
            :sortable="true"
            :columns="visitantGridRefColumns"
            :dataBound="dataBoundForVisitant"
            :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        >
        </kendo-grid>
      </div>
    </section>
  </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'

  import $ from 'jquery'

  export default {
    name: 'VisitantHistory',
    beforeMount() {
      if (JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd != '99') this.$router.push({ name: 'HomeQnaManagement' })
    },
    created () {
      this.$store.dispatch('initSearchDataForCodeRequest')

      let defaultLastRouteParams = {
        page: 1,
        filter: {
          searchRgstNm: ''
        },
      }

      let jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')
      this.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'visitantDetail' ? (jsonStrLastRouteParams === undefined
          ? defaultLastRouteParams
          : JSON.parse(jsonStrLastRouteParams)) : defaultLastRouteParams

      if (EtnersCommonUtil.isNotEmpty(this.lastRouteParams)) {
        this.lastRouteParams.page = this.lastRouteParams.filter.page
      }

    },
    mounted () {
      const vm = this

      //엔터키 이벤트 자동 세팅
      const enterkeyEventTargetIdArray = [
         'visitRouteTx'
      ]

      for (let elementId of enterkeyEventTargetIdArray) {
        let element = document.getElementById(elementId)
        if (element !== undefined) {
          element.onkeyup = function (e) {
            const ENTER_KEY_CODE = 13
            let currentKeyCode = window.event.keyCode || e.which
            if (currentKeyCode === ENTER_KEY_CODE) {
              vm.commonOnChangeEvent(e)
            }
          }
        }
      }
      this.$refs.visitDtRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      });
      this.$refs.visitDtRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      });

    },
    computed: {
      computedVisitantListDataSource: function () {
        const vm = this

        let lastRequestParameterPage = vm.lastRouteParams.page !== undefined ? vm.lastRouteParams.page : 1

        const visitantListDataSource = new kendo.data.DataSource({
          name: 'visitantListDataSource',
          page: lastRequestParameterPage,
          pageSize: 10,
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
          transport: {
            read: {
              url: `${ApiConfig.ethDomain}/v1/eth/summary/visitantList.json`,
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              cache: false,
            },
            parameterMap: function (options, operation) {
              if (operation === 'read') {
                const filter = vm.currentPageFilterOption()
                let loginUserToken = sessionStorage.adminLoginUserToken

                vm.replaceSortFieldStr(options)

                let params = {
                  loginUserToken: loginUserToken,
                  searchSolCd: filter.searchSolCd,
                  searchDeviceCd: filter.searchDeviceCd,
                  searchVisitRouteTx: filter.visitRouteTx,
                  searchVisitDtStartYYYYMMDD : filter.searchVisitDtStartYYYYMMDD,
                  searchVisitDtEndYYYYMMDD : filter.searchVisitDtEndYYYYMMDD,
                  skip: options.skip,
                  take: options.take,
                  page: options.page,
                  pageSize: options.pageSize,
                  sort: options.sort,
                  requestType: 'PAGING', //PAGING or EXPORT
                }

                sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

                return kendo.stringify(params)
              }

              if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) }
              }
            },
          },
          schema: {
            type: 'json',
            parse: function (response) {

                let changeResultData = response.resultData
                let rowNumber = response.total + 1 - (vm.$refs.visitantListGridRef.dataSource.page() - 1)
                    * vm.$refs.visitantListGridRef.dataSource.pageSize()
                if(changeResultData!=null) {

                  for (let i = 0; i < changeResultData.length; i++) {
                    let row = changeResultData[i]
                    let visitId = changeResultData[i].visitId
                    row.visitId = visitId
                    row.rowNumber = --rowNumber
                    row.visitRouteTx = changeResultData[i].visitRouteTx??"기타" 
                }
                response.resultData = changeResultData
              }
              return response
            },
            total: function (response) {
              return response.total
            },
            data: function (response) {
              if (response.resultStatus.messageCode === '2000') {
                return response.resultData
              } else {
                kendo.alert('message: ' + response.resultStatus.messageText)
                return ''
              }
            },
          },

          error: function (e) {
            console.log('error event handler', e.errors[0])
          },
        })

        return visitantListDataSource
      },
    },
    methods: {
      commonOnChangeEvent: function () {
        const vm = this
        let gridRef = vm.$refs.visitantListGridRef

        // 마지막 검색 데이터 page 초기화
        this.lastRouteParams.page = 1

        if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
          if (gridRef.kendoWidget().dataSource !== undefined) {
            gridRef.kendoWidget().dataSource.page(1)
          }
        }
      },
      currentPageFilterOption: function () {
        const vm = this
        const refs = vm.$refs

        let currentSearchDataObj = {}

        const filterData = vm.lastRouteParams.filter


        if (refs.solCdRef !== undefined) {
          const searchSolCd = refs.solCdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchSolCd)) {
            currentSearchDataObj.searchSolCd = searchSolCd
          }
        }

        if (refs.deviceCdRef !== undefined) {
          const searchDeviceCd = refs.deviceCdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchDeviceCd)) {
            currentSearchDataObj.searchDeviceCd = searchDeviceCd
          }
        }

        if (refs.visitRouteTxRef !== undefined) {
          const searchVisitRouteTx = refs.visitRouteTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchVisitRouteTx)) {
            currentSearchDataObj.visitRouteTx = searchVisitRouteTx
          }
        }


      if (refs.visitDtRef !== undefined) {
        const visitDtRangeValue = refs.visitDtRef.kendoWidget().range()

        if (EtnersCommonUtil.isNotEmpty(visitDtRangeValue)) {
          const startYYYYMMDD = (visitDtRangeValue.start !== undefined && visitDtRangeValue.start !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(visitDtRangeValue.start)
                              : undefined
          const endYYYYMMDD = (visitDtRangeValue.end !== undefined && visitDtRangeValue.end !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(visitDtRangeValue.end)
                              : undefined

          currentSearchDataObj.searchVisitDtStartYYYYMMDD = startYYYYMMDD
          currentSearchDataObj.searchVisitDtEndYYYYMMDD = endYYYYMMDD
        }
      }
        vm.filterData = currentSearchDataObj

        return currentSearchDataObj
      },
      dataBoundForVisitant: function (evt) {
         let vm = this
         let isDragging = false;

         $(".k-grid td")
         .mousedown(function() {
            $(window).mousemove(function() {
                isDragging = true;
                $(window).unbind("mousemove");
            });
         })
         .mouseup(function() {
            let wasDragging = isDragging;
            isDragging = false;
            $(window).unbind("mousemove");
            if (!wasDragging) {
              let row = evt.sender.selectable.userEvents.currentTarget
              let selectedRow = evt.sender.select()
            }
         });
     },
      // 초기화 버튼 클릭 이벤트
      cleanSearchDataEvent (e) {
        const refs = this.$refs

        //템플릿구분
        if (refs.solCdRef !== undefined) {
          refs.solCdRef.kendoWidget().select(0)
        }

        if (refs.deviceCdRef !== undefined) {
          refs.deviceCdRef.kendoWidget().select(0)
        }

        if (refs.visitDtRef !== undefined) {
          refs.visitDtRef.kendoWidget().range({start: undefined, end: undefined})
        }

        if (refs.visitRouteTxRef !== undefined) {
          refs.visitRouteTxRef.kendoWidget().value('')
        }

        sessionStorage.removeItem('LAST_ROUTE_PARAMS')

        this.commonOnChangeEvent(e)
      },
      replaceSortFieldStr (options) {
        if (options.sort != undefined && options.sort.length != 0){
          let sortField = options.sort[0].field
          let replaceField

          switch (sortField) {
            case "rowNumber":
              replaceField = "seqNo"
              break;
            case "visitRouteTxStr":
              replaceField = "visitRouteTx"
              break;
            default:
              replaceField = sortField
          }
          options.sort[0].field = replaceField
        }
      },
      onClickRefreshDashBoard: function(e) {
        this.$refs.visitantListGridRef.kendoWidget().dataSource.read()
      },
    },
    data: function () {
      const kendoGridHeaderAttributes = {
        style: 'font-weight: bold;',
      }

      const vm = this

      return {
        rowNumber: 0,
        visitantRowData: {},
        val: '',
        visitDtRange: {start: undefined, end: undefined},
        messages: {
          year: '년',
          month: '월',
          day: '일',
        },
        filterData: {},
        lastRouteParams: {
          page: 1,
          filter: {
            searchRgstNm: '',
            searchTemplateGbn: '',
            searchTemplateNm: ''
          },
        },
        visitantGridRefColumns: [
          {
            field: 'rowNumber',
            title: 'No.',
            width: '3%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'solTx',
            title: '',
            width: '7%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'visitIpTx',
            title: '방문자 IP',
            width: '7%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },

          {
            field: 'fmtVisitDt',
            title: '방문 날짜',
            width: '9%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'stayTime',
            title: '체류 시간',
            width: '9%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            template: function(dataItem){
              return EtnersCommonUtil.isEmpty(dataItem.stayTime) ? '<span class="forg">접속중</span>' : dataItem.stayTime
            }
          },
          {
            field: 'visitRouteTx',
            title: '유입 경로',
            width: '13%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'browserTx',
            title: '접속 정보 상세',
            width: '*',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tleft',
            },
          },
          {
            field: 'deviceTx',
            title: '접속 유형',
            width: '5%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },

        ],
      }
    },
  }
</script>

<template>
  <div id="container">
    <div class="title_wrap">
      <h1>코드</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">
            <li>
              <dd>
                <label>그룹 코드</label>
                <ul>
                  <li>
                    <kendo-dropdownlist
                        ref="codeManagementGbnRef"
                        :data-source="$store.state.codeRequestStoreMstModule.search.combobox.codeGrupCd.data"
                        :data-text-field="'cdGrupId'"
                        :data-value-field="'cdGrupId'"
                        :option-label="'전체'"
                        :index="0"
                        :style="'width: 100%;'"
                        :value="searchMst.lastRouteParams.filter.searchCodeMstList"
                        @change="commonOnChangeEvent"
                    >
                    </kendo-dropdownlist>
                  </li>
                </ul>
              </dd>
              <dd>
                <label>사용유무</label>
                <ul>
                  <li>
                    <kendo-buttongroup
                      ref="useYnRef"
                      :index="searchMst.lastRouteParams.filter.useYn === '' ? 0 : searchMst.lastRouteParams.filter.useYn === 'Y' ? 1 : 2"
                      @select="commonOnChangeEvent"
                      :style="{width: '100%', border: '0'}"
                      >
                      <kendo-buttongroup-button v-bind:style="'width: 34%;'"
                                                v-for="item in userCdButtons"
                                                v-bind:key="item.cdId">
                        {{item.cdNmTx}}
                      </kendo-buttongroup-button>
                  </kendo-buttongroup>
                </li>
              </ul>
              </dd>
            </li>
            <li>
              <dd>
                <label>그룹 코드명</label>
                  <ul>
                      <li>
                          <kendo-maskedtextbox
                                  ref="codeManagementTxRef"
                                  id="codeManagementTx"
                                  :style="'width: 100%;'"
                                  :value="searchMst.lastRouteParams.filter.cdGrupNmTx"
                          >
                          </kendo-maskedtextbox>
                      </li>
                  </ul>
              </dd>
            </li>
            <li>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div class="contents_top_sec">
          <button class="mid_btn orange" @click="onClickCreateNewCodeMst">
            <span class="k-icon k-i-plus"></span> 코드 등록
          </button>
        </div>
        <kendo-grid
            ref="codeManagementListGridRef"
            style="cursor: pointer;"
            :data-source="computedCodeManagementListDataSource"
            :pageable-always-visible="true"
            :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
            :reorderable="true"
            :column-menu="false"
            :resizable="true"
            :sortable="true"
            :selectable="true"
            :columns="codeManagementGridRefColumns"
            :dataBound="dataBoundForCodeManagement"
            :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        >
        </kendo-grid>
         <div class="contents_top_sec" :style="{display: btnDetailDisplay}">
          <button class="mid_btn orange fr" @click="onClickCreateNewCodeDtl">
            <span class="k-icon k-i-plus"></span> 코드 등록
          </button>
        </div>
        <kendo-grid
            ref="codeManagementDetailGridRef"
            :style="{display: gridModeSettings}"
            :data-source="computedCodeManagementDetailDataSource"
            :pageable-always-visible="true"
            :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
            :reorderable="true"
            :column-menu="false"
            :resizable="true"
            :sortable="true"
            :selectable="true"
            :columns="codeManagementDetailGridRefColumns"
            :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        >
        </kendo-grid>
      </div>
    </section>
  </div>

</template>
<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersKendoGridUtil from '@/common/kendo.grid.util'
import EtnersParseUtil from '@/common/etners.parse.util'
import EtnersDateTimeUtil from '@/common/etners.datetime.util'
//import { saveExcel } from '@progress/kendo-vue-excel-export'
import $ from 'jquery'

export default {
  name: 'CodeManagement',
  created () {
    this.$store.dispatch('initCmmCodeMstGrupIdRequest')
    //
    // let defaultLastRouteParams = {
    //   page: 1,
    //   filter: {
    //     cmpyCd: '',
    //     cdGrupId: '',
    //     cdGrupNmTx: '',
    //     useYn: ''
    //   },
    // }
    //
    // let jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')
    // this.searchMst.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'CodeManageDetail' ? (jsonStrLastRouteParams === undefined
    //     ? defaultLastRouteParams
    //     : JSON.parse(jsonStrLastRouteParams)) : defaultLastRouteParams
    //
    // if (EtnersCommonUtil.isNotEmpty(this.searchMst.lastRouteParams)) {
    //   this.searchMst.lastRouteParams.page = this.searchMst.lastRouteParams.filter.page
    // }

  },
  mounted () {
    const vm = this

    //엔터키 이벤트 자동 세팅
    const enterkeyEventTargetIdArray = [
      'codeManagementTx'
    ]

    for (let elementId of enterkeyEventTargetIdArray) {
      let element = document.getElementById(elementId)
      if (element !== undefined) {
        element.onkeyup = function (e) {
          const ENTER_KEY_CODE = 13
          let currentKeyCode = window.event.keyCode || e.which
          if (currentKeyCode === ENTER_KEY_CODE) {
            vm.commonOnChangeEvent(e)
          }
        }
      }
    }


  },
  computed: {
    computedCodeManagementListDataSource: function () {
      const vm = this

      let lastRequestParameterPage = vm.searchMst.lastRouteParams.page !== undefined ? vm.searchMst.lastRouteParams.page : 1

      const codeManagementListDataSource = new kendo.data.DataSource({
        name: 'codeManagementListDataSource',
        page: lastRequestParameterPage,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.ethDomain}/v1/eth/code/selectSearchCmmCodeMst.json`,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              const filter = vm.currentPageFilterOption()
              let loginUserToken = sessionStorage.adminLoginUserToken

              let params = {
                loginUserToken: loginUserToken,
                cmpyCd: filter.cmpyCd,
                cdGrupId: filter.cdGrupId,
                cdGrupNmTx: filter.cdGrupNmTx,
                useYn: filter.useYn,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                sort: options.sort,
                requestType: 'PAGING', //PAGING or EXPORT
              }

              sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

              return kendo.stringify(params)
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {

              let changeResultData = response.resultData
              let dataSource =  vm.$refs.codeManagementListGridRef.dataSource

              let rowNumber = response.total + 1 - (dataSource.page() - 1) * dataSource.pageSize()
              if(changeResultData!=null) {

                for (let i = 0; i < changeResultData.length; i++) {
                  let row = changeResultData[i]
                  row.rowNumber = --rowNumber;
              }
              response.resultData = vm.fnGetConvertedResultData(changeResultData,rowNumber)
            }
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            } else {
              kendo.alert('message: ' + response.resultStatus.messageText)
              return ''
            }
          },
        },

        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })

      return codeManagementListDataSource
    },
    computedCodeManagementDetailDataSource: function () {
      const vm = this

      let lastRequestParameterPage = vm.searchDtl.lastRouteParams.page !== undefined ? vm.searchDtl.lastRouteParams.page : 1

      const codeManagementDetailDataSource = new kendo.data.DataSource({
        name: 'codeManagementDetailDataSource',
        page: lastRequestParameterPage,
        pageSize: 5,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.ethDomain}/v1/eth/code/selectMstDetailCmmCodeDtl.json`,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {


              if (operation === 'read') {

                const filter = vm.currentPageFilterOption2()
                let loginUserToken = sessionStorage.adminLoginUserToken

                let params = {
                  loginUserToken: loginUserToken,
                  cmpyCd: filter.cmpyCd,
                  cdGrupId: filter.cdGrupId,
                  skip: options.skip,
                  take: options.take,
                  page: options.page,
                  pageSize: options.pageSize,
                  sort: options.sort,
                  requestType: 'PAGING', //PAGING or EXPORT
                }

                sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

                return kendo.stringify(params)
              }

              if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) }
              }


          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
              let changeResultData = response.resultData
              let dataSource = vm.$refs.codeManagementDetailGridRef.dataSource
              let rowNumber = response.total + 1 - (dataSource.page() - 1) * dataSource.pageSize()
              if(changeResultData!=null) {

                for (let i = 0; i < changeResultData.length; i++) {
                  let row = changeResultData[i]
                  row.rowNumber = --rowNumber;
              }

              response.resultData = vm.fnGetConvertedResultData(changeResultData,rowNumber)
            }
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            } else {
              kendo.alert('message: ' + response.resultStatus.messageText)
              return ''
            }
          },

          error: function (e) {
            console.log('error event handler', e.errors[0])
          },
        },
      })

      return codeManagementDetailDataSource
    },

  },
  methods: {
    commonOnChangeEvent: function () {
      const vm = this
      let gridRef = vm.$refs.codeManagementListGridRef

      // 마지막 검색 데이터 page 초기화
      this.searchMst.lastRouteParams.page = 1

      if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
        if (gridRef.kendoWidget().dataSource !== undefined) {
          gridRef.kendoWidget().dataSource.page(1)
        }
      }
    },
    currentPageFilterOption: function () {
      const vm = this
      const refs = vm.$refs

      let currentSearchDataObj = {}

      const filterData = vm.searchMst.lastRouteParams.filter


      //회사코드
      currentSearchDataObj.cmpyCd = "00000"
      //그룹코드
      if (refs.codeManagementGbnRef !== undefined) {
        const cdGrupId = refs.codeManagementGbnRef.kendoWidget().value()

        if (cdGrupId !== undefined && cdGrupId !== '') {
          currentSearchDataObj.cdGrupId = cdGrupId
        }
      }

      //그룹코드명
      if (refs.codeManagementTxRef !== undefined) {
        const cdGrupNmTx = refs.codeManagementTxRef.kendoWidget().value()

        if (cdGrupNmTx !== undefined && cdGrupNmTx !== '') {
          currentSearchDataObj.cdGrupNmTx = cdGrupNmTx
        }
      }


      // useYn 사용여부
        if (refs.useYnRef != undefined) {
          const useYnIndex = refs.useYnRef.kendoWidget().current().index()


          switch (useYnIndex) {
            case 0:
              break
            case 1:
              currentSearchDataObj.useYn = 'Y'
              break
            case 2:
              currentSearchDataObj.useYn = 'N'
          }
        }

      vm.searchMst.searchStatus.filterData = currentSearchDataObj

      return currentSearchDataObj
    },
    currentPageFilterOption2: function () {
      const vm = this
      const refs = vm.$refs

      let currentSearchDataObj = {}

      const filterData = vm.searchDtl.lastRouteParams.filter

      //회사코드
      currentSearchDataObj.cmpyCd = "00000"
      //그룹코드
      currentSearchDataObj.cdGrupId = this.searchDtl.lastRouteParams.filter.cdGrupId

      vm.searchDtl.searchStatus.filterData = currentSearchDataObj

      return currentSearchDataObj
    },
    onClickCreateNewCodeMst: function (e) {
      this.$router.push(
          {
            name: 'CodeManagementMstSave',
          },
      )
    },
    onClickCreateNewCodeDtl: function (e) {
      this.$router.push(
          {
            name: 'CodeManagementDtlSave',
          },
      )
    },
    dataBoundForCodeManagement: function (evt) {
       let vm = this
       let isDragging = false;

       $(".k-grid td")
       .mousedown(function() {
          $(window).mousemove(function() {
              isDragging = true;
              $(window).unbind("mousemove");
          });
       })
       .mouseup(function() {
          let wasDragging = isDragging;
          isDragging = false;
          $(window).unbind("mousemove");
          if (!wasDragging) {
            let row = evt.sender.selectable.userEvents.currentTarget
            let rowDataItem = evt.sender.dataItem(row)
            vm.onChangeUserManageListGrid(rowDataItem)
          }
       });
   },
   // resultData의 코드를 코드명으로 치환하여 리턴
      fnGetConvertedResultData: function(resultData, startRowNo) {
        let cvtResultData = resultData

        if(startRowNo === undefined) {
          startRowNo = 0
        }

        cvtResultData.forEach(item => {
          item.rowNo = ++startRowNo
          item.useYn = item.useYn === 'Y' ? '사용' : '미사용'
        })

        return cvtResultData
      },
    //row 클릭 이벤트
    onChangeUserManageListGrid: function(rowData) {

      let vm = this
      const refs = vm.$refs
      const detailSearchDataObj = {
        cmpyCd : rowData.cmpyCd,
        cdGrupId : rowData.cdGrupId
      }

      vm.gridModeSettings = 'block'
      vm.btnDetailDisplay = 'block'

      let gridRef = vm.$refs.codeManagementDetailGridRef

      // 마지막 검색 데이터 page 초기화
      this.searchDtl.lastRouteParams.filter = detailSearchDataObj

      if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
        if (gridRef.kendoWidget().dataSource !== undefined) {
          gridRef.kendoWidget().dataSource.read()
        }
      }

    },
    // 초기화 버튼 클릭 이벤트
    cleanSearchDataEvent (e) {
      const refs = this.$refs

      //그룹코드 초기화
      if (refs.codeManagementGbnRef !== undefined) {
        refs.codeManagementGbnRef.kendoWidget().select(0)
      }

      // 그룹코드명 초기화
      if (refs.codeManagementTxRef !== undefined) {
        refs.codeManagementTxRef.kendoWidget().value('')
      }

      // 사용여부 초기화
        if (refs.useYnRef !== undefined) {
          refs.useYnRef.kendoWidget().select(0)
        }

      sessionStorage.removeItem('LAST_ROUTE_PARAMS')

      this.commonOnChangeEvent(e)
    },

  }, //end method
  data: function () {
    const kendoGridHeaderAttributes = {
      style: 'font-weight: bold;',
    }

    const vm = this

    return {
      gridModeSettings : 'none',
      rowNumber: 0,
      btnDetailDisplay: 'none',
      rgstDtRange: {start: undefined, end: undefined},
      userCdButtons: [
        {
          cdId: '',
          cdNmTx: '전체',
          style: 'width:60%',
          selected: true
        },
        {
          cdId: 'Y',
          cdNmTx: '사용',
          style: 'width:60%',
          selected: false
        },
        {
          cdId: 'N',
          cdNmTx: '미사용',
          style: 'width:60%',
          selected: false
        }
      ],
      searchMst: {
        searchStatus: {
          filterData: {},
        },
        lastRouteParams: {
          page: 1,
          filter: {
            cmpyCd: '',
            cdGrupId: '',
            cdGrupNmTx: '',
            useYn: ''
          },
        },
      },
      searchDtl: {
        searchStatus: {
          filterData: {},
        },
        lastRouteParams: {
          page: 1,
          filter: {
            cmpyCd: '',
            cdGrupId: ''
          },
        },
      },
      codeManagementGridRefColumns: [
        {
          field: 'rowNumber',
          title: 'No.',
          width: '10%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },
        {
          field: 'cmpyCd',
          title: '회사코드',
          width: '15%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },
        {
          field: 'cdGrupId',
          title: '그룹코드',
          width: '15%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },
        {
          field: 'cdGrupNmTx',
          title: '그룹코드명',
          width: '50%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },

        {
          field: 'seqNo',
          title: '조회순서',
          width: '10%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },

        {
          field: 'useYn',
          title: '사용유무',
          width: '10%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },
        {
          field: 'cdGrupId',
          title: '수정',
          width: '10%',
          //command: 'edit',
          command: {
            text: "수정",
            click: function(e){
              let tr = $(e.target).closest("tr");
              let data = this.dataItem(tr);

                vm.$router.push({
                  name:'CodeManagementMstDetail',
                  params : {
                    cmpyCd : data.cmpyCd
                    ,cdGrupId : data.cdGrupId
                  }
                })
            }
          },

          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
          class: 'tcenter',
          },
        },
        {
          field: 'cdGrupId',
          title: '삭제',
          width: '10%',
          command: {
            text: "삭제",
            click: function(e){
              let tr = $(e.target).closest("tr")
              let data = this.dataItem(tr)
              let params = {
                cmpyCd : data.cmpyCd
                ,cdGrupId : data.cdGrupId
              }

              kendo.confirm('하위코드까지 전부 삭제처리가 됩니다. 해당 코드를 삭제하시겠습니까?').done(function() {


                ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/code/deleteCmmCodeAllData.json`,params).then(function (response) {

                  if (response.data === undefined || response.data.resultStatus === undefined) {
                    kendo.alert('정상적으로 처리되지 않았습니다.')
                    return false
                  }

                  const resultStatus = response.data.resultStatus
                  if (resultStatus.messageCode !== '2000') {
                    kendo.alert(resultStatus.messageText)
                    return false
                  }
                  kendo.alert("정상적으로 처리되었습니다.")
                  vm.$router.go()
                })
              })

            }
          },
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
          class: 'tcenter',
          },
        },
      ],
      codeManagementDetailGridRefColumns: [
        {
          field: 'rowNumber',
          title: 'No.',
          width: '10%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
          sortable:false
        },
        {
          field: 'cdGrupId',
          title: '그룹코드',
          width: '15%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },
        {
          field: 'cdId',
          title: '상세코드',
          width: '15%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },
        {
          field: 'cdNmTx',
          title: '상세코드명',
          width: '30%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },
        {
          field: 'cdDescTx',
          title: '상세코드설명',
          width: '50%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },
        {
          field: 'seqNo',
          title: '조회순서',
          width: '15%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },

        {
          field: 'useYn',
          title: '사용유무',
          width: '15%',
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
            class: 'tcenter',
          },
        },

        {
          field: 'cdGrupId',
          title: '수정',
          width: '10%',
          command: {
            text: "수정",
            click: function(e){
              let tr = $(e.target).closest("tr");
              let data = this.dataItem(tr);

                vm.$router.push({
                  name:'CodeManagementDtlDetail',
                  params : {
                    cmpyCd : data.cmpyCd
                    ,cdGrupId : data.cdGrupId
                    ,cdId: data.cdId
                  }
                })
            }
          },
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
          class: 'tcenter',
          },
        },
        {
          field: 'cdGrupId',
          title: '삭제',
          width: '10%',
          command: {
            text: "삭제",
            click: function(e){
              let tr = $(e.target).closest("tr")
              let data = this.dataItem(tr)
              let params = {
                cmpyCd : data.cmpyCd
                ,cdGrupId : data.cdGrupId
                ,cdId: data.cdId
              }

              ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/code/deleteCmmCodeDtlData.json`,params).then(function (response) {

                if (response.data === undefined || response.data.resultStatus === undefined) {
                  kendo.alert('정상적으로 처리되지 않았습니다.')
                  return false
                }

                const resultStatus = response.data.resultStatus
                if (resultStatus.messageCode !== '2000') {
                  kendo.alert(resultStatus.messageText)
                  return false
                }
                kendo.alert("정상적으로 처리되었습니다.")
                vm.$router.go()
              })

            }
          },
          headerAttributes: kendoGridHeaderAttributes,
          attributes: {
          class: 'tcenter',
          },
        },



      ],
    }
  },
}

</script>

<style scoped>

</style>

import ApiUtil from './api.util'
import ApiConfig from './api.config'

const ethDomain = ApiConfig.ethDomain

function fetchFirstUserInfo () {
  const param = {
    loginUserToken: sessionStorage.loginUserToken,
    wmGbn: '01'
  }

  return ApiUtil.post(`${ethDomain}/v1/eth/user/firstAccessInfo.json`, param)
}

function fetchedSendAuthNumber(userId){
  return ApiUtil.post(`${ethDomain}/v1/eth/user/sendAuthNumber.json`, userId)
}

function fetchedConfirmAuthNumber(params){
  return ApiUtil.post(`${ethDomain}/v1/eth/user/confirmAuthNumber.json`,params)
}

function fetchedUpdatePassword(params){
  return ApiUtil.post(`${ethDomain}/v1/eth/user/updateUserPassword.json`,params)
}

export {
  fetchFirstUserInfo, fetchedSendAuthNumber, fetchedConfirmAuthNumber, fetchedUpdatePassword
}

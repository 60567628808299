<template>
  <main id="main eng" class="eng">
    <div class="main-visual main-section">
      <div class="main-visual__slider swiper-container swiper-container-fade swiper-container-horizontal">
        <swiper ref="mainSwiper" :options="swiperOption" class="swiper-wrapper" style="transition-duration: 0ms">
          <swiper-slide class="main-visual__item swiper-slide swiper-slide-prev" data-swiper-autoplay="2000">
            <div class="main-visual__bg swiper-lazy swiper-lazy-loaded"
              style="background-image: url(//dev-etnershome.etners.com/img/mainimg.png)"> </div>
            <div class="main-visual__content">
              <div class="wrap-middle">
                <h2 class="main-visual__title animated d-1s">
                  Work less and increase<br> the efficiency!<br>
                  It is possible with <br> the Management Support Platform.</h2>
                <div class="main-visual__action animated d-2s">
                  <a href="/company/overview"
                    class="ke-btn__basic ke-btn--case-stroke ke-btn--type-01 ke-btn--size-normal">
                    <span>MORE VIEW</span>
                  </a>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="main-visual__item swiper-slide swiper-slide-active" data-swiper-autoplay="9000">
            <div class="main-visual__bg">
              <div class="ke-background-video ke-background-video--angular">
                <div class="ke-background-video__vod">
                  <video ref="video1" width="1920" height="954" playsinline="" loop="" muted="" preload="">
                    <source src="@/assets/img/vmovie01.mp4" type="video/mp4">
                  </video>
                </div>
                <div class="ke-background-video__poster swiper-lazy swiper-lazy-loaded"
                  style="background-image: url(//dev-etnershome.etners.com/img/mainimg.png) opacity: 0 visibility: hidden display: none">
                </div>
              </div>
            </div>
            <div class="main-visual__content">
              <div class="wrap-middle">
                <h2 class="main-visual__title animated d-1s">
                  Work less and increase<br> the efficiency!<br>
                  It is possible with <br> the Management Support Platform.</h2>
                <div class="main-visual__action"> <a href="/business"
                    class="ke-btn__basic ke-btn--case-stroke ke-btn--type-01 ke-btn--size-normal">
                    <span>MORE VIEW</span></a> </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="main-visual__item swiper-slide swiper-slide-active" data-swiper-autoplay="11000">
            <div class="main-visual__bg">
              <div class="ke-background-video ke-background-video--angular">
                <div class="ke-background-video__vod">
                  <video ref="video2" width="1920" height="954" playsinline="" loop="" muted="" preload="">
                    <source src="@/assets/img/vmovie02.mp4" type="video/mp4">
                  </video>
                </div>
                <div class="ke-background-video__poster swiper-lazy swiper-lazy-loaded"
                  style="background-image: url(//dev-etnershome.etners.com/img/mainimg.png) opacity: 0 visibility: hidden display: none">
                </div>
              </div>
            </div>
            <div class="main-visual__content">
              <div class="wrap-middle">
                <h2 class="main-visual__title animated d-1s">
                  Work less and increase<br> the efficiency!<br>
                  It is possible with <br> the Management Support Platform.</h2>
                <div class="main-visual__action"> <a href="/company/overview"
                    class="ke-btn__basic ke-btn--case-stroke ke-btn--type-01 ke-btn--size-normal">
                    <span>MORE VIEW</span></a> </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-control" style="opacity: 1">
          <button class="swiper-navigation swiper-button-prev" tabindex="0" role="button" aria-label="Previous slide">
            <div class="ke-guide--icon">
              <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 12L3 6.93671L8 2" stroke="black" stroke-width="3" stroke-miterlimit="10"> </path>
              </svg>
            </div>
            <span class="sr-only">이전</span>
          </button>
          <div lang="en" class="swiper-pagination swiper-pagination-fraction">
            <span class="swiper-pagination-current">1</span>
            <span class="swiper-pagination-dot"></span>
            <span class="swiper-pagination-total">2</span>
          </div>
          <div class="swiper-state swiper-state--play" @click="onClickSwiperState">
            <button class="swiper-state__btn swiper-state__btn--play">
              <div class="ke-guide--icon">
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="M14,4h4v16h-4V4z M6,20h4V4H6V20z"></path>
                </svg>
              </div>
              <span class="sr-only">일시정지</span>
            </button>
            <button class="swiper-state__btn swiper-state__btn--pause">
              <div class="ke-guide--icon">
                <svg width="8" height="8" viewBox="0 0 8 8">
                  <path d="M7,4L1,0v8L7,4z"></path>
                </svg>
              </div>
              <span class="sr-only">재생</span>
            </button>
          </div>

          <button class="swiper-navigation swiper-button-next" tabindex="0" role="button" aria-label="Next slide">
            <div class="ke-guide--icon">
              <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12L7 6.93671L2 2" stroke="black" stroke-width="3" stroke-miterlimit="10"> </path>
              </svg>
            </div>
            <span class="sr-only">다음</span>
          </button>
        </div>
        <div class="swiper-progress" style="opacity: 1">
          <div class="swiper-progress__bar" style="width: 34.5833%"></div>
        </div>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
      </div>
    </div>
    <section class="section04">
      <div class="company_inner animated">
        <h2 class="main_tit animated d-1s align-c">We support clients that enable them to focus on their core <br>
          competencies through a broad <span class="dh_orange"><b>range of services optimized</b></span> for clients.
          <a href="/business"><img src="@/assets/img/arrow-right-circle.png"></a>
        </h2>
        <!-- 타이틀 -->
        <div class="clearfix">
          <div class="inside_title">Management Support</div>
        </div>
        <!-- //타이틀 -->
        <ul class="inside_list animated d-2s">
          <li class="animated on">
            <a href="https://sharedservice.etners.com" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Shared Service
                </span><img src="@/assets/img/n1.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  BPO integrated service in HR·GA field
                </div>
              </div>
            </a>
          </li>
          <li class="animated on">
            <a href="//esop.etners.com" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">ESOP
                </span>
                <img src="@/assets/img/n2.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  B2B dedicated Shared Office with converged platform</div>
              </div>
            </a>
          </li>
          <li class="animated on">
            <a href="//etnerspayroll.com/index_en.html" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Payroll
                </span>
                <img src="@/assets/img/n3.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  Payroll diagnosis and operation, wage compensation consulting</div>
              </div>
            </a>
          </li>
          <li class="animated on">
            <a href="//gamdongtime.etnersplatform.com/" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Gamdongtime
                </span>
                <img src="@/assets/img/n4.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  Customized gift-specialized platform for executives and employees</div>
              </div>
            </a>
          </li>
        </ul>
        <!-- 타이틀 -->
        <div class="clearfix">
          <div class="inside_title">Global</div>
        </div>
        <!-- //타이틀 -->
        <ul class="inside_list animated d-2s">
          <li class="animated on">
            <a href="//mall.etners.com" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Mall
                </span><img src="@/assets/img/n5.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  Direct delivery welfare solution for expatriates
                </div>
              </div>
            </a>
          </li>
          <li class="animated on">
            <a href="//housing.etners.com/index_en.html" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Housing
                </span><img src="@/assets/img/n6.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  Total service from moving in to moving out
                </div>
              </div>
            </a>
          </li>
          <li class="animated on">
            <a href="//moving.etners.com/index_en.html" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Moving
                </span><img src="@/assets/img/n7.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  ONE-STOP international moving platform by competitive bidding
                </div>
              </div>
            </a>
          </li>
          <li class="animated on">
            <a href="//relocation.etners.com/html_en/relo_landing.html" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Relocation
                </span><img src="@/assets/img/n8.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  Domestic Settlement Support Service for foreign employees
                </div>
              </div>
            </a>
          </li>
        </ul>
        <!-- 타이틀 -->
        <div class="clearfix">
          <div class="inside_title">Distribution</div>
        </div>
        <!-- //타이틀 -->
        <ul class="inside_list animated d-2s">
          <li class="animated on">
            <a href="//bidding.etners.com/" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Bidding
                </span><img src="@/assets/img/n9.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  Online Competitive Bidding Sale of idle assets
                </div>
              </div>
            </a>
          </li>
          <li class="animated on">
            <a href="//etnersefs.com/landing" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">EFS
                </span><img src="@/assets/img/n10.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  Logistics operation solution
                </div>
              </div>
            </a>
          </li>
          <li class="animated on">
            <a href="//shop.etners.com" target="_blank">
              <div class="list__thumb">
                <span class="bs_tit2 text_ov">Shop
                </span><img src="@/assets/img/n11.png" alt="">
              </div>
              <div class="list_text">
                <div class="sub text_ov2">
                  Affordable B2B special offer
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </section>
    <section class="section02">
      <div class="inner">
        <h2 class="main_tit animated d-1s align-c">To make our clients <span class="dh_orange"><b>more valuable</b></span><br>
          ETNERS thinks constantly.</h2>
        <ul class="inside_list">

          <li class="animated">
            <div class="list_text align-c animated d-1s">
              <div class="mtit text_ov">Number of Accumulated Clients</div>
              <div class="msub text_ov3">

                <span class="number">2000</span><span class="munit">Clients</span><span class="increase animated d-5s"><img
                    src="@/assets/img/num_arrow.png"></span>
              </div>
            </div>
          </li>
          <li class="animated">
            <div class="list_text align-c animated d-5s">
              <div class="mtit text_ov">Patent·Certification</div>
              <div class="msub text_ov3">

                <span class="number">12</span><span class="munit">EA</span><span class="increase animated d-5s"><img
                    src="@/assets/img/num_arrow.png"></span>
              </div>
            </div>
          </li>
          <li class="animated">
            <div class="list_text align-c animated d-1s">
              <div class="mtit text_ov">Copyright </div>
              <div class="msub text_ov3">
                <span class="number">79</span><span class="munit">EA</span><span class="increase animated d-5s"><img
                    src="@/assets/img/num_arrow.png"></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="section03">
      <div class="main_tit align-c animated"><b>Technology accredited by Patents and Reliable high-quality service</b>
      <div class="hide_568"></div>
      are crucial factors for Business Partnership</div>
      <div class="galist">
        <slick ref="slick" :options="slickOptions">
          <div v-for="item in patentList">
            <p class="m_gallery_sec">
              <span class="m_gallery_area"></span><img :src="item.imageSrc">
            </p>
            <span class="m_gallery_txt animated on d-1s">{{ item.patentCertNoTxEn }}<b>{{ item.patentNmTxEn }}</b></span>
          </div>
        </slick>
      </div>
    </section>
  </main>
</template>
<script>
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
const ethDomain = ApiConfig.ethDomain
import $ from 'jquery'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import EtnersCommonUtil from '@/common/etners.common.util'
import Slick from 'vue-slick'

export default {
  name: 'UserDashboardEn',
  components: {
    swiper, swiperSlide, Slick
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy()
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions)
      }
    })
  },
  mounted() {
    const vm = this

    vm.component_slider()
    vm.main_visual_slider()
    vm.mainVisualProgress = undefined
    vm.countNumberEffect()

    let param = {
      requestType: 'EXPORT'
    }

    ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/patentList.json`, param).then(function (response) {
      if (response.data === undefined || response.data.resultStatus === undefined) {
        kendo.alert('Failed to retrieve data.')
        return false
      }
      vm.patentList = response.data.resultData

      vm.patentList.forEach(item => {
        item.imageSrc = `${ApiConfig.ethDomain}/files/${item.patentThumbnailFileId}`
      })

    })

  },
  methods: {
    main_visual_slider() {
      const vm = this
      let mainVisual = $('.main-visual')
      let swiperState = $('.swiper-state')

      if (mainVisual.find('.main-visual__item').length <= 1) {
        mainVisual.find('.swiper-lazy').each(function () {
          $(this).css('background-image', 'url(' + $(this).attr('data-background') + ')')
        })
        vm.main_visual_transition(true)
        return
      }

      let mainVisualSlider = this.$refs.mainSwiper.swiper

      mainVisualSlider.on('init', function () {
        vm.main_visual_transition(true)

        gsap.to(mainVisual.find('.swiper-control, .swiper-progress'), {
          opacity: 1,
          duration: .3
        })
      })
      mainVisualSlider.init()

      mainVisualSlider.on('realIndexChange', function () {
        vm.main_visual_transition(false)
      })

    },
    onClickSwiperState() {
      let swiperState = $('.swiper-state')
      let mainVisualSlider = this.$refs.mainSwiper.swiper

      if (swiperState.hasClass('swiper-state--play')) {
        swiperState.removeClass('swiper-state--play').addClass('swiper-state--pause')
        this.$refs.video1.pause()
        this.$refs.video2.pause()
      } else {
        if (swiperState.hasClass('swiper-state--charging')) {
          mainVisualSlider.slideNext()
        }
        swiperState.removeClass('swiper-state--pause').addClass('swiper-state--play')
        this.$refs.video1.play()
        this.$refs.video2.play()
      }
    },
    main_visual_transition(isInit) {
      const vm = this
      let swiper = this.$refs.mainSwiper.swiper
      let prevSwiperSlider = undefined
      let currSwiperSlider = undefined
      let isSingle = false

      if (typeof swiper == 'undefined') {
        currSwiperSlider = $('.main-visual .main-visual__item')
        isSingle = true
      } else {
        prevSwiperSlider = $(swiper.slides[swiper.previousIndex])
        currSwiperSlider = $(swiper.slides[swiper.activeIndex])
      }

      if (vm.mainVisualProgress != undefined) {
        vm.mainVisualProgress.kill()
      }

      if (!isInit) {
        gsap.killTweensOf(prevSwiperSlider.find('.main-visual__title, .main-visual__action'))
        gsap.to(prevSwiperSlider.find('.main-visual__title, .main-visual__action'), {
          opacity: 0,
          duration: .3,
          ease: 'power1.out'
        })

        if (!!prevSwiperSlider.find('.ke-background-video__vod').length) {
          prevSwiperSlider.find('.ke-background-video__vod').find('video')[0].pause()
        }
      }

      gsap.killTweensOf(currSwiperSlider.find('.main-visual__title, .main-visual__action'))
      gsap.fromTo(currSwiperSlider.find('.main-visual__title, .main-visual__action'), {
        y: 30,
        opacity: 0,
        rotation: 0.1
      }, {
        y: 0,
        opacity: 1,
        rotation: 0,
        ease: 'power2.out',
        duration: 1.1,
        delay: .15,
        stagger: .15
      })

      if (!!currSwiperSlider.find('.ke-background-video__vod').length) {
        let currVideo = currSwiperSlider.find('.ke-background-video__vod').find('video')[0]
        let currSwiperSliderPoster = currSwiperSlider.find('.ke-background-video__poster')
        let initialPlay = true

        currVideo.load()
        currVideo.oncanplaythrough = function () {

          if (!initialPlay) return
          initialPlay = false

          currVideo.currentTime = 0
          currVideo.play()

          if (currSwiperSliderPoster.is(':visible')) {
            gsap.to(currSwiperSliderPoster, {
              delay: .2,
              duration: .3,
              autoAlpha: 0,
              onComplete: function () {
                currSwiperSliderPoster.hide()
              }
            })
          }

          if (!isSingle) {
            vm.main_visual_progress(currVideo.duration * 1000)
          }
        }
      } else {
        if (!isSingle) {
          vm.main_visual_progress(5000)
        }
      }
    },
    main_visual_progress(speed) {
      const vm = this

      let swiperState = $('.main-visual__slider .swiper-state'),
        swiperProgressBar = $('.main-visual__slider .swiper-progress__bar')

      if (vm.mainVisualProgress != undefined) {
        vm.mainVisualProgress.kill()
      }

      vm.mainVisualProgress = gsap.fromTo(swiperProgressBar, {
        width: '0%'
      }, {
        width: '100%',
        duration: parseInt(speed / 1000),
        ease: 'none',
        onStart: function () {
          swiperState.removeClass('swiper-state--charging')
        },
        onComplete: function () {
          swiperState.addClass('swiper-state--charging')

          if (swiperState.hasClass('swiper-state--play') && EtnersCommonUtil.isNotEmpty(vm.$refs.mainSwiper.swiper)) {
            vm.$refs.mainSwiper.swiper.slideNext()
          }
        }
      })
    },
    component_slider() {
      let $slider = $('.component-slider .swiper-container')

      if ($slider.length < 1) {
        return
      }

      if ($('body').hasClass('page-template-developer')) {

        let $this = $('.developer-news .swiper-container')

        if (typeof $this[0].swiper == 'undefined') {
          new Swiper($this, {
            slidesPerView: 'auto',
            loop: true,
            preloadImages: false,
            lazy: {
              loadPrevNext: true,
              loadPrevNextAmount: 4, // Wide
              loadOnTransitionStart: true
            },
            speed: 500,
            preventInteractionOnTransition: true,
            followFinger: true,
            navigation: {
              nextEl: $('.developer-news__slider .swiper-button-next'),
              prevEl: $('.developer-news__slider .swiper-button-prev'),
            }
          })
        }

      } else {
        $slider.each(function () {

          let $this = $(this)
          let gauge = ($this.closest('.component-slider').hasClass('component-slider--small')) ? 3 : 2

          if ($this.closest('.component-slider').hasClass('component-slider--small') && JT.is_screen(540)) {
            gauge = 2
          }

          if ($this.find('.swiper-slide').length >= gauge) {
            if ($this.closest('.component-slider').index() == 0) {

              let $parent = $this.closest('.component-slider')
              $parent.find('.swiper-button-next').removeAt
              if (JT.is_screen(1023)) {
                $parent.removeAttr('style')
              } else {
                let containerSpace = -(($(window).width() - $parent.closest('.component-section').width()) / 2)
                let navigationPos = -(containerSpace) + parseInt($parent.find('.swiper-button-next').css('right')) - parseInt($parent.find('.component-slider__item:first-child').css('padding-right'))
                $parent.css('margin-right', containerSpace)
                $parent.find('.swiper-button-next').css('right', navigationPos)
              }
            }
            if (typeof $this[0].swiper == 'undefined') {
              $this.addClass('swiper-use-buttons')

              new Swiper($this, {
                slidesPerView: 'auto',
                loop: false,
                speed: 500,
                preventInteractionOnTransition: true,
                followFinger: true,
                navigation: {
                  nextEl: $this.find('.swiper-button-next'),
                  prevEl: $this.find('.swiper-button-prev'),
                }
              })
            }
          } else {
            if (typeof $this[0].swiper != 'undefined') {
              $this.removeClass('swiper-use-buttons')
              $this[0].swiper.destroy()
            }
          }
        })
      }
    },
    visible(partial) {
      let $w = jQuery(window)
      let viewTop = $w.scrollTop()
      let viewBottom = viewTop + $w.height()
      let _top = partial.offset().top
      let _bottom = _top + partial.height()
      let compareTop = partial === true ? _bottom : _top
      let compareBottom = partial === true ? _top : _bottom

      return ((compareBottom <= viewBottom) && (compareTop >= viewTop) && partial.is(':visible'))
    },
    countNumberEffect() {
      const vm = this
      $(window).scroll(function () {
        let numberArea = $('.number')

        if (vm.visible(numberArea)) {
          if (numberArea.hasClass('counter-loaded')) {
            return
          }
          numberArea.addClass('counter-loaded')

          numberArea.each(function () {
            let $this = $(this)
            jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
              duration: 5000,
              easing: 'swing',
              step: function () {
                $this.text(Math.ceil(this.Counter))
              }
            })
          })
        }
      })
    },
  },
  data: function () {
    const vm = this
    return {
      patentList: [],
      mainVisualProgress: {},
      slickOptions: {
        slidesToShow: 6,
        autoplay: true,
        releaseDelay: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 510,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 370,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 359,
            settings: {
              slidesToShow: 2
            }
          },
        ]
      },
      swiperOption: {
        initialSlide: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
        centeredSlides: true,
        spaceBetween: 0,
        followFinger: false,
        freeMode: true,
        navigation: true,
        loop: true,
        preventInteractionOnTransition: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: true,
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          renderFraction: function (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' + '<span class="swiper-pagination-dot"></span>' + '<span class="' + totalClass + '"></span>'
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    }
  }
}
</script>

<template>
  <div id="container_home">
    <div class="company_visual">
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          CSR
        </h2>
        <h2 class="company_tit animated d-2s">
          We will stand with a sense of responsibility for society and the environment.
        </h2>
      </div>
      <div class="company_img animated">
        <img src="@/assets/img/csr_top.png" class="animated d-1s" alt="">
      </div>

    </div>

    <div class="company_inner">
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">
          <div class="text animated d-1s"><div class="big_tit"># Sponsorship with Gyeonggi-do Province local artists</div>
          <div class="cs_text">To boost local art activity, we sponsor Gyeonggi-do Province artists, regularly through GGFC(GyeongGi Culture Foundation)</div></div>
          <div class="img animated d-1s">
            <img src="@/assets/img/csr_01.png" alt="">
          </div>
        </div>
        </li>
      </ul>
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">
          <div class="text animated d-1s">
            <div class="big_tit"># Corporate Volunteer Program Wetners</div>
            <div class="cs_text">Wetners is a corporate social club stands for ‘We’ 
              and our Company Name ‘ETNERS’, “We stand with local society who is in need of aid and we go together”</div>
            </div>
          <div class="img animated d-1s"><img src="@/assets/img/csr_02.png" alt=""></div>
        </div>
        </li>
      </ul>
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">
          <div class="text animated d-1s">
          <div class="big_tit"># Spring Festival</div>
          <div class="cs_text">This is a SR(Social Responsibility) campaign for local society. 
            As a type of Matching Grant(10,000KRW Per 1 employee), we collect donations and deliver to Seongnam Welfare Center for the Disabled.</div>
          </div>
          <div class="img animated d-1s"><img src="@/assets/img/csr_03.png" alt=""></div>
        </div>
        </li>
      </ul>
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">

          <div class="text animated d-1s">
          <div class="big_tit"># Volunteer Partnership with Seongnam Welfare Center for the Disabled</div>
          <div class="cs_text">We have agreed a partnership with Seongnam Welfare Center for the Disabled, 
            increase welfare for local disabled and build welfare culture to live together. Our Participations are as follows; 
            ‘Clean House’ – Hoarders, ‘Sseudam Sseudam’ – Cleaning Activity ‘Paralympic held by Seongnam city’, ‘Chuseok Festival’ 
            ‘Happy Bazaar&1 day beer festival’
</div>
          </div>
          <div class="img animated d-1s"><img src="@/assets/img/csr_04.png" alt=""></div>
        </div>
        <div class="clearfix"></div>
        </li>
      </ul>
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">

          <div class="text animated d-1s">
          <div class="big_tit"># Sponsorship of aspiring track and field</div>
          <div class="cs_text">We provide performance-enhancing scholarships to contribute to the development of Korean track and field and to help athletes train in a better environment and show their skills to their fullest.
</div>
          </div>
          <div class="img animated d-1s"><img src="@/assets/img/csr_05.png" alt=""></div>
        </div>
        <div class="clearfix"></div>
        </li>
      </ul>
      </div>
    </div>
</template>

<script>
  import ApiConfig from '@/api/api.config'
  export default {
    name: 'CsrEn',
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
    },
    data: function () {
      const vm = this
      return {

      }
    }
  }
</script>

import ApiUtil from './api.util'
import ApiConfig from './api.config'

const ethDomain = ApiConfig.ethDomain

function fetchCodeMstDetailResultData (cmpyCd, cdGrupId) {

  const params = {
    loginUserToken: sessionStorage.loginnUserToken,
    cmpyCd: cmpyCd,
    cdGrupId: cdGrupId
  }

  return ApiUtil.post(`${ethDomain}/v1/eth/code/getOneCmmCodeMst.json`, params)
}

function fetchCodeDtlDetailResultData (cmpyCd, cdGrupId, cdId) {

  const params = {
    loginUserToken: sessionStorage.loginUserToken,
    cmpyCd: cmpyCd,
    cdGrupId: cdGrupId,
    cdId: cdId,
  }

  return ApiUtil.post(`${ethDomain}/v1/eth/code/getOneCmmCodeDtl.json`, params)
}

function fetchRsaPublicKey() {
    return new Promise(resolve => {
        let params = {
            loginUserToken: sessionStorage.loginUserToken
        }
        ApiUtil.post(`${ethDomain}/v1/eth/common/rsa/getPublicKey.json`, params).then(response => {
            if (response.data === undefined || response.data.resultStatus === undefined) {
                kendo.alert('데이터가 정상적으로 조회되지 않았습니다.')
                resolve(null)
            }
            if (response.data.resultStatus.messageCode !== '2000') {
                kendo.alert(response.data.resultStatus.messageText)
                resolve(null)
            }

            let resultData = response.data.resultData
            resolve(resultData)

        }).catch(error => {
            console.log(url + ' error : ' + error)
            resolve(null)
        })
    })
}

export { fetchCodeMstDetailResultData, fetchCodeDtlDetailResultData, fetchRsaPublicKey }

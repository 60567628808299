import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'

const ethDomain = ApiConfig.ethDomain

const codeRequestStoreModule = {
 state: {
   dataSource: {
     lastRequestParameters: {}
   },
   search: {
     combobox: {
       codeManageGrupCd: { // 그룹코드
         type: 'combobox',
         data: [],
         index: 0
       },
     }
   },
   currentRow: {
     currentRowData: [] // kendo grid에서 행위가 일어나는 row의 data
   }
 },
 getters: {},
 mutations: {
   setCodeManageGrupCdIndex (state, index) {
     state.search.combobox.codeManageGrupCd.index = index
   },
   setCodeManageGrupCdData (state, data) {
     state.search.combobox.codeManageGrupCd.data = data
   },

 },
 actions: {

   /**********************************************************************
    * [주의사항]
    * actions 안에 정의하는 함수는 모듈별로 네임스페이스를 구분하지 않으므로,
    * 다른 모듈에서 사용하는 이름을 중복으로 사용하지 않아야 합니다.
    *
    * vue 모듈 안의 함수에서는 다음과 같이 호출이 가능합니다.
    *
    * this.$store.dispatch('initSearchDataForCodeRequest')
    *********************************************************************/

   /**
    * 페이지 로딩시 최초로 검색창 세팅.
    * 데이터를 ajax로 가져와서 세팅해야합니다.
    */
   initSearchDataForCodeManagementRequest (context) {
     /** 여러개의 공통 코드를 동시에 요청할 경우에 대한 파라미터값 */
     const url = `${ethDomain}/v1/eth/code/commonCodes.json`
     const data = {
            "cmpyCd": "00000",
            "cdGrupId": "",
            "options": {
                "displayAll": false
            }

      }

      ApiUtil.post(url, data).then(response => {
       // 그룹 코드
       let codeManageGrupCdDataArray = response.data
      // codeManageGrupCdDataArray = EtnersCommonUtil.sortArray(codeManageGrupCdDataArray, 'seqNo')
       context.commit('setCodeManageGrupCdData', codeManageGrupCdDataArray)
       for (let i = 0; i < codeManageGrupCdDataArray.length; i++) {
         const code = codeManageGrupCdDataArray[i]
         if (code.selected === true) {
           context.commit('setCodeManageGrupCdIndex', i)
         }
       }

     });
   },


 }

}

export default codeRequestStoreModule

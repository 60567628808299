<template>
  <div>

    <!--//////////POP-UP START//////////-->
  	<div class="pop_dim">
  		<div class="pop_area" style="height: 500px;">
  			<div class="float-r" @click="goPrevPage" style="cursor: pointer;"><img src="@/assets/img/close_btn.png"></div>
  			<div class="pop_tit">서비스 준비중</div>
  			<div class="pop_tit_sub">

  			</div>
  			<div style="padding: 30px 15px 0 0;text-align:center">

  				<img src="@/assets/img/img_ready.png" style="width:200px;margin-bottom:30px;">
  			</div>
  			<div class="pop_tit" style="color: #333;">준비중입니다!<br>
  				<span class="mt20" style="line-height:1.5em;">보다 나은 서비스 제공을 위하여 <br>페이지 준비중에 있습니다.<br>
  					빠른 시일내에 준비하여 찾아뵙겠습니다. </span> </div>
  		</div>

  	</div>
  	<!--//////////POP-UP END//////////-->

  </div>
</template>
<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import $ from 'jquery'


  export default {
    name: 'WaitServiceWindow',
    components: {

    },

    created() {
    },
    mounted () {

    },
    computed: {

    },
    methods: {
      goPrevPage: function(){
        this.$emit('onClickWindowClose')
      },
    },
    data: function () {
      const vm = this
      return {
      }
    }
  }
</script>

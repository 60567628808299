import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'

const ethDomain = ApiConfig.ethDomain

const codeRequestStoreModule = {
    state: {
        dataSource: {
            lastRequestParameters: {},
        },
        search: {
            button: {
                useYn: { // 사용여부
                    type: 'radio',
                    data: [],
                    index: 0
                }
            },
            input: {
                recuProcCd: {
                    type: 'checkbox',
                    data: [],
                    validator: '',
                },

            },
            datepicker: {
                // 날짜 입력값이 하나일 경우는 startDate, endDate 대신 targetDate로 명명
                pickupRequestDate: {
                    type: 'datepicker',
                    startDate: '',
                    endDate: ''
                }
            },
            timepicker: {
                pickupRequestDate: {
                    type: 'datepicker',
                    startTime: '',
                    startHour: '', // DB 컬럼 PU_REQ_TM 때문에 추가한 값
                    startMinute: '', // DB 컬럼 PU_REQ_MT 때문에 추가한 값
                    endTime: '',
                    endHour: '', // DB 컬럼 PU_REQ_TM 때문에 추가한 값
                    endMinute: '' // DB 컬럼 PU_REQ_MT 때문에 추가한 값
                }
            },
            combobox: {
                solCd: {
                    type: 'combobox',
                    data: [],
                    index: 0
                },
                birthYear: {
                    type: 'combobox',
                    data: [],
                    index: 0
                },
                deviceCd: {
                    type: 'combobox',
                    data: [],
                    index: 0
                },
                accountStatusCd: {
                    type: 'combobox',
                    data: [],
                    index: 0
                },
                categoryCd: {
                    type: 'combobox',
                    data: [],
                    index: 0
                },
                sharedServiceCategoryCd: {
                    type: 'combobox',
                    data: [],
                    index: 0
                },
                termsCd: {
                    type: 'combobox',
                    data: [],
                    index: 0
                },
                termsSolCd: {
                    type: 'combobox',
                    data: [],
                    index: 0
                }
            }
        },
        currentRow: {
            currentRowData: [] // kendo grid에서 행위가 일어나는 row의 data
        }
    },
    getters: {},
    mutations: {
        setUseYnIndex(state, index) {
            state.search.button.useYn.index = index
        },
        setUseYnData(state, data) {
            state.search.button.useYn.data = data
        },
        setSolCdIndex(state, index) {
            state.search.combobox.solCd.index = index
        },
        setSolCdData(state, data) {
            state.search.combobox.solCd.data = data
        },
        setBirthYearTxData(state, data) {
            state.search.combobox.birthYear.data = data
        },
        setBirthYearTxIndex(state, data) {
            state.search.combobox.birthYear.index = data
        },
        setDeviceCdData(state, data) {
            state.search.combobox.deviceCd.data = data
        },
        setDeviceCdIndex(state, data) {
            state.search.combobox.deviceCd.index = data
        },
        setAccountStatusCdIndex(state, index) {
            state.search.combobox.accountStatusCd.index = index
        },
        setAccountStatusCdData(state, data) {
            state.search.combobox.accountStatusCd.data = data
        },
        setCategoryCdIndex(state, index) {
            state.search.combobox.categoryCd.index = index
        },
        setCategoryCdData(state, data) {
            state.search.combobox.categoryCd.data = data
        },
        setSharedServiceCategoryCdIndex(state, index) {
            state.search.combobox.sharedServiceCategoryCd.index = index
        },
        setSharedServiceCategoryCdData(state, data) {
            state.search.combobox.sharedServiceCategoryCd.data = data
        },
        setTermsCdIndex(state, index) {
            state.search.combobox.termsCd.index = index
        },
        setTermsCdData(state, data) {
            state.search.combobox.termsCd.data = data
        },
        setTermsSolCdIndex(state, index) {
             state.search.combobox.termsSolCd.index = index
        },
        setTermsSolCdData(state, data) {
            state.search.combobox.termsSolCd.data = data
        },
    },
    actions: {

        /**********************************************************************
         * [주의사항]
         * actions 안에 정의하는 함수는 모듈별로 네임스페이스를 구분하지 않으므로,
         * 다른 모듈에서 사용하는 이름을 중복으로 사용하지 않아야 합니다.
         *
         * vue 모듈 안의 함수에서는 다음과 같이 호출이 가능합니다.
         *
         * this.$store.dispatch('initSearchDataForCodeRequest')
         *********************************************************************/

        /**
         * 페이지 로딩시 최초로 검색창 세팅.
         * 데이터를 ajax로 가져와서 세팅해야합니다.
         */
        initSearchDataForCodeRequest(context) {
            /** 여러개의 공통 코드를 동시에 요청할 경우에 대한 파라미터값 */
            const url = `${ethDomain}/v1/eth/code/multiCommonCodes.json`
            const data = {
                "codes": [
                    {
                        "cmpyCd": "00000",
                        "cdGrupId": "001",
                        "options": {
                            "displayAll": false
                        }
                    },
                    {
                        "cmpyCd": "00000",
                        "cdGrupId": "002",
                        "options": {
                            "displayAll": false
                        }
                    },
                    {
                        "cmpyCd": "00000",
                        "cdGrupId": "005",
                        "options": {
                            "displayAll": false
                        }
                    },
                    {
                        "cmpyCd": "00000",
                        "cdGrupId": "006",
                        "options": {
                            "displayAll": false
                        }
                    },
                    {
                        "cmpyCd": "00000",
                        "cdGrupId": "008",
                        "options": {
                            "displayAll": false
                        }
                    },
                    {
                        "cmpyCd": "00000",
                        "cdGrupId": "009",
                        "options": {
                            "displayAll": false
                        }
                    },
                    {
                        "cmpyCd": "00000",
                        "cdGrupId": "010",
                        "options": {
                            "displayAll": false
                        }
                    },
                ]
            }

            const keyName = "__COMMON_CODE_MAP__"

            ApiUtil.post(url, data).then(response => {

                const codeMap = response.data.codeMap

                // 사용여부
                const useYnButtons = [
                    {
                        cdNmTx: '전체',
                        cdId: '',
                        cdNameSpace: '',
                        selected: true
                    },
                    {
                        cdNmTx: '사용',
                        cdId: 'Y',
                        cdNameSpace: 'Y',
                        selected: false
                    },
                    {
                        cdNmTx: '미사용',
                        cdId: 'N',
                        cdNameSpace: 'N',
                        selected: false
                    }
                ]

                let useYnButtonIndex = 0

                for (let i = 1; i < useYnButtons.length; i++) {
                    const buttonArray = useYnButtons[i]
                    if (buttonArray.selected !== undefined && buttonArray.selected) {
                        useYnButtonIndex = i
                    }
                }

                context.commit('setUseYnData', useYnButtons)
                context.commit('setUseYnIndex', useYnButtonIndex)

                let solCdDataArray = codeMap['001']
                solCdDataArray = EtnersCommonUtil.sortArray(solCdDataArray, 'seqNo')
                context.commit('setSolCdData', solCdDataArray)
                for (let i = 0; i < solCdDataArray.length; i++) {
                    const code = solCdDataArray[i]
                    if (code.selected === true) {
                        context.commit('setSolCdIndex', i)
                    }
                }


                let deviceCdDataArray = codeMap['002']
                deviceCdDataArray = EtnersCommonUtil.sortArray(deviceCdDataArray, 'seqNo')
                context.commit('setDeviceCdData', deviceCdDataArray)
                for (let i = 0; i < deviceCdDataArray.length; i++) {
                    const code = deviceCdDataArray[i]
                    if (code.selected === true) {
                        context.commit('setDeviceCdIndex', i)
                    }
                }

                let accountStatusCdDataArray = codeMap['005']
                accountStatusCdDataArray = EtnersCommonUtil.sortArray(accountStatusCdDataArray, 'seqNo')
                context.commit('setAccountStatusCdData', accountStatusCdDataArray)
                for (let i = 0; i < accountStatusCdDataArray.length; i++) {
                    const code = accountStatusCdDataArray[i]
                    if (code.selected === true) {
                        context.commit('setAccountStatusCdIndex', i)
                    }
                }

                let categoryCdDataArray = codeMap['006']
                categoryCdDataArray = EtnersCommonUtil.sortArray(categoryCdDataArray, 'seqNo')
                context.commit('setCategoryCdData', categoryCdDataArray)
                for (let i = 0; i < categoryCdDataArray.length; i++) {
                    const code = categoryCdDataArray[i]
                    if (code.selected === true) {
                        context.commit('setCategoryCdIndex', i)
                    }
                }

                let sharedServiceCategoryCdDataArray = codeMap['010']
                sharedServiceCategoryCdDataArray = EtnersCommonUtil.sortArray(sharedServiceCategoryCdDataArray, 'seqNo')
                context.commit('setSharedServiceCategoryCdData', sharedServiceCategoryCdDataArray)
                for (let i = 0; i < sharedServiceCategoryCdDataArray.length; i++) {
                    const code = sharedServiceCategoryCdDataArray[i]
                    if (code.selected === true) {
                        context.commit('setSharedServiceCategoryCdIndex', i)
                    }
                }

                let termsCdDataArray = codeMap['008']
                termsCdDataArray = EtnersCommonUtil.sortArray(termsCdDataArray, 'seqNo')
                context.commit('setTermsCdData', termsCdDataArray)
                for (let i = 0; i < termsCdDataArray.length; i++) {
                    const code = termsCdDataArray[i]
                    if (code.selected === true) {
                        context.commit('setTermsCdIndex', i)
                    }
                }

                let termsSolCdDataArray = codeMap['009']
                termsSolCdDataArray = EtnersCommonUtil.sortArray(termsSolCdDataArray, 'seqNo')
                context.commit('setTermsSolCdData', termsSolCdDataArray)
                for (let i = 0; i < termsSolCdDataArray.length; i++) {
                    const code = termsSolCdDataArray[i]
                    if (code.selected === true) {
                        context.commit('setTermsSolCdIndex', i)
                    }
                }

                //출생연도
                let startBirthYear = 1960
                let endBirthYear = new Date().getFullYear()

                let birthYearTxDataArray = new Array()

                for (let i = startBirthYear; i <= endBirthYear; i++) {

                    let selected = i == 1990 ? true : false

                    birthYearTxDataArray.push(
                        {
                            cdNmTx: `${i}`,
                            cdId: `${i}`,
                            cdNameSpace: `${i}`,
                            selected: selected
                        }
                    )
                }

                context.commit('setBirthYearTxData', birthYearTxDataArray)
                //console.log(`birthYearTxDataArray : ${birthYearTxDataArray}`)

            })
        }
    }
}

export default codeRequestStoreModule

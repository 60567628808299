<template>
  <section id="container" class="home_admin">
    <div class="small_title_wrap">
      <h1>홈페이지 관리자 대시보드 </h1>
    </div>
    <div class="box_flex mgt20 top">
      <div class="bg_box_round">
        <h2>
          {{monthAgo}} ~ {{date}}<br><span class="txt_s">(최근 30일)</span>
        </h2>
        <ul class="list_box">
          <li>
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
              </svg>
            </div>
            <div class="s_title">
              방문자
            </div>
              <div class="s_text">
                <router-link class="link t_blue"  to="/homeadmin/summary/visitant">{{isValueEmpty(mainTotalData.visitCnt)}}</router-link><span class="s_txt">건</span>
            </div>
          </li>
          <li>
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
              </svg>
            </div>
            <div class="s_title">
              다운로드
            </div>
           <div class="s_text">
             <router-link class="link t_green"  to="/homeadmin/summary/download">{{isValueEmpty(mainTotalData.downloadCnt)}}</router-link><span class="s_txt">건</span>
            </div>
          </li>
          <li>
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </div>
            <div class="s_title">
              문의하기
            </div>
             <div class="s_text">
               <router-link class="link org"  to="/homeadmin/summary/qna">{{isValueEmpty(mainTotalData.qnaCnt)}}</router-link><span class="s_txt">건</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="right bg_box_round pd20">
        <div class="w25">
          <ul class="box_flex box_btn">
            <li>
              <button :class="btn_orange" @click="setMainPieChartData('day')">주간</button>
            </li>
            <li>
              <button :class="btn_white" @click="setMainPieChartData('month')">월간</button>
            </li>
          </ul>
        </div>
        <div class="box_flex">
         <div class="w65">
          <div class="bg_box">
            <h2>총 방문자/다운로드</h2>
            <kendo-chart
                :class="'total_chart'"
                :series="weekDoubleChart.series"
                :legend="weekDoubleChart.legend"
                :style="{height: '250px'}"
                :category-axis="weekDoubleChart.categoryAxis"
                :value-axis="weekDoubleChart.valueAxes"
                :tooltip="weekDoubleChart.tooltip"
                :legend-position="'right'"
                 :chart-area-margin-top="20"
                :chart-area-margin-bottom="20"
                :chart-area-margin-right="20"
                :chart-area-margin-left="20"
                :theme="'sass'">
            </kendo-chart>
          </div>
          <div class="box_flex mgt30">
          <div class="bg_box">
            <div class="srm_m_title">
              방문자 현황
            </div>
            <div class="chart_css">
              <chart
                  :title-text="''"
                  :legend-visible="false"
                  :series-defaults-type="'bar'"
                  :series="mainchartSolVisitData"
                  :chart-area-width="450"
                  :chart-area-height="360"
                  :category-axis="mainchartSolVisitDataCategories"
                  :value-axis="valueAxis"
                  :tooltip="tooltip"
                  :value-axis-line-visible="false"
                  :value-axis-minor-grid-lines-visible="false"
                  :value-axis-major-grid-lines-visible="false"
                  :chart-area-margin-top="20"
                  :chart-area-margin-bottom="20"
                  :chart-area-margin-right="20"
                  :chart-area-margin-left="20"
                  :theme="'sass'">
              </chart>
            </div>
          </div>
          <div class="bg_box">
            <div class="srm_m_title">
              다운로드 현황
            </div>
            <div class="chart_css">
              <chart :title-text="''"
                     :legend-visible="false"
                     :series-defaults-type="'bar'"
                     :series="mainchartSolDownloadData"
                     :chart-area-width="450"
                     :chart-area-height="360"
                     :value-axis-max="50"
                     :category-axis="mainchartSolDownloadDataCategories"
                     :value-axis="valueAxis"
                     :tooltip="tooltip"
                     :value-axis-line-visible="false"
                     :value-axis-minor-grid-lines-visible="false"
                     :value-axis-major-grid-lines-visible="false"
                     :chart-area-margin-top="20"
                      :chart-area-margin-bottom="20"
                      :chart-area-margin-right="20"
                      :chart-area-margin-left="20"
                     :theme="'sass'">
              </chart>
            </div>
          </div>

        </div>
        </div>
        <div class="w35">
          <div class="bg_box">
              <h2>문의하기</h2>
          <div style="position: relative;">
            <div class="inquiry" >총<span class="count">{{qnaTotalCount}}</span>건</div>
            <kendo-chart
                    :chart-area-background="''"
                    :legend-position="'right'"
                    :series="weekPieChart"
                    :CONTAINER_SIZE = "'300;'"
                    :style="'height:350px'"
                    :series-defaults-labels-visible="true"
                    :series-defaults-labels-position="'outsideEnd'"
                    :series-defaults-labels-background="'transparent'"
                    :series-defaults-labels-template="labelTemplate"
                    :tooltip-visible="true"
                    :tooltip-template="tooltipTemplate"
                    :chart-area-margin-top="-10"
                    :chart-area-margin-bottom="-10"
                    :chart-area-margin-right="10"
                    :chart-area-margin-left="-10"
                                        :theme="'sass'">
            </kendo-chart>
          </div>
          <div class="chart_css">
<!--            <chart ref="chart"-->
<!--                   :legend-visible="false"-->
<!--                   :chart-area-background="''"-->
<!--                   :chart-area-height="200"-->
<!--                   :chart-area-margin-top="20"-->
<!--                   :chart-area-margin-bottom="20"-->
<!--                   :chart-area-margin-right="20"-->
<!--                   :chart-area-margin-left="20"-->
<!--                   :series-defaults-type="'bar'"-->
<!--                   :series-defaults-stack="true"-->
<!--                   :series="mainchartQnaData"-->
<!--                   :category-axis-categories="mainchartQnaDataCategories"-->
<!--                   :value-axis-max="qnaAxisMax"-->
<!--                   :value-axis-min="0"-->
<!--                   :value-axis-line-visible="false"-->
<!--                   :value-axis-minor-grid-lines-visible="false"-->
<!--                   :value-axis-major-grid-lines-visible="false"-->
<!--                   :tooltip="tooltip"-->
<!--                   :theme="'sass'"-->
<!--                   :style="'margin-top:10px'">-->
<!--            </chart>-->

              <chart :title-text="''"
                     :legend-visible="false"
                     :series-defaults-type="'bar'"
                     :series="mainchartSolQnaData"
                     :chart-area-width="450"
                     :chart-area-height="340"
                     :category-axis="mainchartSolQnaDataCategories"
                     :value-axis="valueAxis"
                     :tooltip="tooltip"
                     :value-axis-line-visible="false"
                     :value-axis-minor-grid-lines-visible="false"
                     :value-axis-major-grid-lines-visible="false"
                     :chart-area-margin-top="20"
                     :chart-area-margin-bottom="20"
                     :chart-area-margin-right="20"
                     :chart-area-margin-left="20"
                     :theme="'sass'">
              </chart>
            </div>
          </div>
        </div>
        </div>


      </div>
    </div>

  </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersKendoGridUtil from '@/common/kendo.grid.util'
import EtnersParseUtil from '@/common/etners.parse.util'
import EtnersDateTimeUtil from '@/common/etners.datetime.util'



//import { saveExcel } from '@progress/kendo-vue-excel-export'
import { Chart } from '@progress/kendo-charts-vue-wrapper'

const ethDomain = ApiConfig.ethDomain
import $ from 'jquery'

export default {
  name: 'AdminDashboard',
  components: {
    'chart': Chart
  },
  beforeMount() {
    if (JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd != '99') {
      this.$router.push({ name: 'HomeQnaManagement' })
    } 
  },
  created() {
    const vm = this

    const nowDate = new Date();

    const year = nowDate.getFullYear(); // 년
    const month = nowDate.getMonth();   // 월
    const day = nowDate.getDate();      // 일

    const nowDate_tra = new Date(year, month - 1, day).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-')

    vm.monthAgo = nowDate_tra


    vm.date = new Date(year, month, day).toLocaleDateString().replace(/\./g, '').replace(/\s/g, '-')

    let param = {
      loginUserToken: sessionStorage.adminLoginUserToken
    }

    ApiUtil.post(`${ethDomain}/v1/eth/main/getAllCountList.json`,param).then(function (response){

      if (response.data.resultStatus.messageCode === '2000') {
        let resultData = response.data.resultData
        vm.mainTotalData = resultData

        vm.setMainPieChartData('day')

      } else {
        kendo.alert(response.data.resultStatus.messageText)
      }

    })

  },
  methods: {
    isValueEmpty: function(val) {
      return EtnersCommonUtil.isEmpty(val) ? 0 : val
    },
    setMainPieChartData: function(type) {
      const vm = this

      let param = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        type : type
      }

      if(type==='day'){
        vm.btn_orange = "large_btn orange"
        vm.btn_white = "large_btn"
      }else{
        vm.btn_orange = "large_btn"
        vm.btn_white = "large_btn orange"
      }

      ApiUtil.post(`${ethDomain}/v1/eth/main/getAllChartList.json`,param).then(function (response){


        if (response.status === 200) {
          let resultData = response.data


          //vm.setChartCount(resultData)
          let mainchartVisitData = resultData.visit
          let mainchartHomeVisitData = resultData.visit_home
          let mainchartDownloadData = resultData.download
          let mainchartQnaData = resultData.qna
          let resultSolVisit = resultData.sol_visit
          let resultSolDownload = resultData.sol_download
          let resultSolQna= resultData.sol_qna

          vm.setChartCount(mainchartVisitData,mainchartDownloadData)
          vm.setPieCount(resultSolQna)
          vm.setSolutionCount('visit',resultSolVisit)
          vm.setSolutionCount('download',resultSolDownload)
          vm.setSolutionCount('qna',resultSolQna)

        } else {
          kendo.alert(response.statusText)
        }
      })


    },
    setChartCount: function(visit,download){
      /************************
       * SolCd
       ************************
       * 00 : etnershome
       * 01 : shared service
       * 02 : payroll
       * 03 : bidding
       * 04 : housing
       * 05 : curation(감동타임)
       * 06 : mall
       * 07 : moving
       * 08 : relocation
       * 09 : shop
       * 10 : efs
       * 11 : esop
       * 12 : welcome(채용)
       * */
      let vm = this
      let categoriesVisitArr= []
      let seriesVisitArr = []
      let seriesDownloadArr = []

      //주간, 월간 max 셋팅을 위한 초기화
      vm.visitAxisMax = 0

      visit.forEach(item => {
        categoriesVisitArr.push(item.visitDt)
        seriesVisitArr.push(item.dayCnt)
        if(item.dayCnt > vm.visitAxisMax){
          vm.visitAxisMax = item.dayCnt
        }
      })

      download.forEach(item => {
        seriesDownloadArr.push(item.dayCnt)
        if(item.dayCnt > vm.visitAxisMax){
          vm.visitAxisMax = item.dayCnt
        }
      })

      vm.weekDoubleChart = {
        series: [
          {
            type: 'column',
            name: '방문자현황',
            data: seriesVisitArr,
            axis: 'cnt',
            color: '#fe5f40',
            labels: {
              background: "transparent",
              visible: true,
              format: '{0:N0}',
              template: "#= value #"
            }
          },
           { type: 'line', color: '#004e66', name: '다운로드현황', data: seriesDownloadArr, axis: 'cnt' ,}
        ],
        legend: {
          position: "bottom"
        },

        valueAxes: [{
          name: 'cnt',
          min: 0,
          max: vm.visitAxisMax+150,
        }
        ],
        categoryAxis: {
          categories: categoriesVisitArr,
          justified: true,
          majorGridLines: {
            visible: false
          }
        },
        tooltip: {
          visible: true,
          format: '{0}',
          background: "#fe5f40",
          template: "#= series.name  #[ #= value # ]"
        }
      }


    },
    setPieCount: function(qna){

      /************************
       * SolCd
       ************************
       * 00 : etnershome
       * 01 : shared service
       * 02 : payroll
       * 03 : bidding
       * 04 : housing
       * 05 : curation(감동타임)
       * 06 : mall
       * 07 : moving
       * 08 : relocation
       * 09 : shop
       * 10 : efs
       * 11 : esop
       * 12 : welcome(채용)
       * */
      let vm = this
      let qnaSum =  0

      let pieSeries = [{
        type: 'donut',
        data: []
      }]

      for (let key in qna) {
        let dataObj = {}
        if(qna[key].solCnt > 0){

          dataObj.category = qna[key].solNm
          dataObj.value = qna[key].solCnt
          qnaSum += qna[key].solCnt
          switch (qna[key].solId){
            case "00" : dataObj.color = "#fe5f40"; break;
            case "01" : dataObj.color = "#bae314"; break;
            case "02" : dataObj.color = "#0ebaa9"; break;
            case "03" : dataObj.color = "#2e5cff"; break;
            case "04" : dataObj.color = "#2196f3"; break;
            case "05" : dataObj.color = "#f74852"; break;
            case "06" : dataObj.color = "#3f92b5"; break;
            case "07" : dataObj.color = "#6ad2df"; break;
            case "08" : dataObj.color = "#4caf50"; break;
            case "09" : dataObj.color = "#ffd14f"; break;
            case "10" : dataObj.color = "#626fe3"; break;
            case "11" : dataObj.color = "#00643c"; break;
            case "12" : dataObj.color = "#f1dda0"; break;
            case "13" : dataObj.color = "#9e9e9e"; break;

          }

          pieSeries[0].data.push(dataObj)
        }
      }
      vm.qnaTotalCount = qnaSum
      vm.weekPieChart = pieSeries

    },
    setSolutionCount: function(type,resultData){
      let vm = this

      let categories = [{
        categories: [],
        majorGridLines: {
          visible: false
        }
      }]

      let categoriesArr = []
      let seriesArr = []

      let series  = [{
        type: 'bar',
        color: "#9f9c9c",
        data: []
      }]

      let solAxisMax = 0

      for (let key in resultData) {
        if(resultData[key].solCnt >= solAxisMax){
          solAxisMax = resultData[key].solCnt
        }
        categories[0].categories.push(resultData[key].solNm)
        categoriesArr.push=resultData[key].solNm
        seriesArr.push = resultData[key].solCnt

        series[0].data.push(resultData[key].solCnt)
      }

      if(type==='visit'){
        vm.valueAxis.max = solAxisMax+5
        vm.mainchartSolVisitDataCategories = categories
        vm.mainchartSolVisitData = series

      }else if(type==='download'){
        vm.valueAxis.max = solAxisMax+5
        vm.mainchartSolDownloadDataCategories = categories
        vm.mainchartSolDownloadData = series
      }else if(type==='qna'){
        vm.valueAxis.max = solAxisMax+5
        vm.mainchartSolQnaDataCategories = categories
        vm.mainchartSolQnaData = series
      }


    },
  },
  data: function() {

    return {
      date: '',
      monthAgo: '',
      mainTotalData: {},
      val: '',
      weekDoubleChart:[],
      weekPieChart:[],
      mainchartSolVisitDataCategories:{},
      mainchartSolVisitData: [],
      mainchartSolDownloadDataCategories:[],
      mainchartSolDownloadData: [],
      mainchartSolQnaDataCategories:[],
      mainchartSolQnaData: [],
      axisMax : 0,
      qnaTotalCount : 0,
      visitAxisMax: 0,
      downloadAxisMax: 0,
      qnaAxisMax: 0,
      tooltip: {
        visible: true,
        template: "#= category # #= value #" + "건"
      },
      labelTemplate: "#= category # - #= kendo.format('{0:P}', percentage)#",
      tooltipTemplate: "#= category # - #= kendo.format('{0:P}', percentage) #",
      sampleCharts:{},
      weeklyData: {},
      btn_white : "large_btn",
      btn_orange : "large_btn orange",
      valueAxis: [{
        line: {
          visible: false
        },
        minorGridLines: {
          visible: false
        },
        labels: {
          rotation: "auto"
        }
      }]
    }
  },
}
</script>

<style>
.k-tooltip {
}
.chart_css > div.k-chart > svg > g > g > g > g > g:first-child > g > path {
  fill: #fe5f40;
  stroke: #fe5f40;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(2) > g > path {
  fill: #bae314;
  stroke: #bae314;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(3) > g > path {
  fill: #0ebaa9;
  stroke: #0ebaa9;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(4) > g > path {
  fill: #2e5cff;
  stroke: #2e5cff;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(5) > g > path {
  fill: #2196f3;
  stroke: #2196f3;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(6) > g > path {
  fill: #f74852;
  stroke: #f74852;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(7) > g > path {
  fill: #3f92b5;
  stroke: #3f92b5;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(8) > g > path {
  fill: #6ad2df;
  stroke: #6ad2df;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(9) > g > path {
  fill: #4caf50;
  stroke: #4caf50;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(10) > g > path {
  fill: #ffd14f;
  stroke: #ffd14f;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(11) > g > path {
  fill: #626fe3;
  stroke: #626fe3;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(12) > g > path {
  fill: #00643c;
  stroke: #00643c;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(13) > g > path {
  fill: #f1dda0;
  stroke: #f1dda0;
}
.chart_css > div.k-chart > svg > g > g > g > g > g:nth-child(14) > g > path {
  fill: #9e9e9e;
  stroke: #9e9e9e;
}
.k-tooltip.k-chart-tooltip {
  background:#555 !important ;
}
.k-tooltip.k-chart-tooltip .k-tooltip-content{
  color:#fff !important
}

</style>


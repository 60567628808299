<template>
  <div>
      <div class="pop-container">
          <div class="pop-conts">
          <div class="system-noti_box">
              <img class="mgt30" src="../assets/img/system_noti.png">
              <h2 class="title">이트너스 홈페이지 시스템 안정화 작업 예정<br /></h2>
              <div class="cont mt20">
              시스템 안정화를 위한 점검을 진행할 예정입니다.<br>
              하기 일정 참고하시어, 접속 부탁드립니다.<br>
              서비스 이용에 불편을 드리는 점 미리 양해 부탁드립니다.
              </div>        
              <div class="info_box mt10 mb15">
              <span>점검시간</span><br />
              2024.3.9 (토)
              </div>
          </div>
          </div>
      </div>

      <div class="button_area w50 mb10 mt30">
        <button type="submit" class="large_btn" @click="onClickWindowCloseAndReload">닫기</button>
      </div>
  </div>

</template>

<script>
export default {
name: 'SystemNotiPopup',
props: [],
components: { },
created () {
  const vm = this
},
data() {
  const vm = this
  return {
    boolReadonly : false,
    index :0,
  }
},
mounted(){
},
methods: {
  onClickWindowCloseAndReload(){
    this.$emit('onClickWindowCloseAndReload','')
  }
}
}

</script>

<style scoped>
</style>

<template>
  <div id="container">
    <div class="title_wrap">
      <h1>담당자 관리</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">
            <li>
              <dd>
                <label>카테고리</label>
                <ul>
                  <li>
                    <kendo-dropdownlist ref="solCdRef"
                      :data-source="$store.state.codeRequestStore.search.combobox.solCd.data"
                      :data-text-field="'cdNmTx'" :data-value-field="'cdId'" :option-label="'전체'" :index="0"
                      :style="'width: 100%;'" :value="'002_' + lastRouteParams.filter.searchSolCd"
                      @change="commonOnChangeEvent">
                    </kendo-dropdownlist>
                  </li>
                </ul>
              </dd>
              <dd>
                <label>가입상태</label>
                <ul>
                  <li>
                    <kendo-dropdownlist ref="accountStatusCdRef"
                      :data-source="$store.state.codeRequestStore.search.combobox.accountStatusCd.data"
                      :data-text-field="'cdNmTx'" :data-value-field="'cdNameSpace'" :option-label="'전체'" :index="0"
                      :style="'width: 100%;'" :value="'002_'+lastRouteParams.filter.searchAccountStatusCd"
                      @change="commonOnChangeEvent">
                    </kendo-dropdownlist>
                  </li>
                </ul>
              </dd>
            </li>
            <li>
              <dd>
                <label>메일 주소(ID)</label>
                <ul>
                  <li>
                    <kendo-maskedtextbox ref="userIdRef" id="userIdInput" :style="'width: 100%;'"
                      :value="lastRouteParams.filter.searchUserId" placeholder="이메일 형식으로 입력해주세요">
                    </kendo-maskedtextbox>
                  </li>
                </ul>
              </dd>
              <dd>
                <label>성명</label>
                <ul>
                  <li>
                    <kendo-maskedtextbox ref="userNmKoTxRef" id="userNmKoTxInput" :style="'width: 100%;'"
                      :value="lastRouteParams.filter.searchUserNmKoTx" placeholder="이름을 입력해주세요">
                    </kendo-maskedtextbox>
                  </li>
                </ul>
              </dd>
            </li>
            <li>
              <dd>
                <label>연락처</label>
                <ul>
                  <li>
                    <kendo-maskedtextbox ref="phoneNoTxRef" id="phoneNoTxInput" :style="'width: 100%;'"
                      :value="lastRouteParams.filter.searchPhoneNoTx" placeholder="연락처를 입력해주세요. ( 숫자만 입력 )">
                    </kendo-maskedtextbox>
                  </li>
                </ul>
              </dd>
              <dd>
                <label>등록일</label>
                <ul class="w60">
                  <kendo-daterangepicker ref="frstRgstDtDatepickerRef" :class="'w60 fl mr5'" :format="'yyyy/MM/dd'"
                    :range="frstRgstDtRange" @change="commonOnChangeEvent">
                  </kendo-daterangepicker>
                </ul>
              </dd>
            </li>
            <li>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div class="contents_top_sec">
          <button class="mid_btn fr" @click="onClickExcelDownloadForUserManageList">
            <i class="download_icon"></i>
            <span>엑셀 다운로드</span>
          </button>
          <button class="mid_btn orange fl" @click="onClickCreateNewManager">
            <span class="k-icon k-i-plus"></span> 담당자 등록
          </button>
        </div>
        <kendo-grid ref="userManagementListGridRef" style="cursor: pointer;"
          :data-source="computedUserManagementListDataSource" :pageable-always-visible="true"
          :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]" :reorderable="true" :column-menu="false"
          :resizable="true" :selectable="true" :sortable="true" :columns="userManagementListGridColumns"
          :dataBound="dataBoundForSmsTemplate" @change="onChangeUserManagementListGrid"
          :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }">
        </kendo-grid>
      </div>

      <kendo-window :title="'담당자 등록'" ref="managerRef" :modal="true" :width="'900px'" style="box-sizing:border-box;"
        :visible="false">
        <manager-manage-window ref="managerWindowRef" @onClickWindowClose="onClickWindowClose">
        </manager-manage-window>
      </kendo-window>

    </section>
  </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersParseUtil from '@/common/etners.parse.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'
  import { saveExcel } from '@progress/kendo-vue-excel-export'
  import $ from 'jquery'
  import ManagerManageWindow from '@/components/ManagerManageWindow'

  export default {
    name: 'UserManagement',
    components:{
      ManagerManageWindow
    },
    beforeMount() {
      if (JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd != '99') this.$router.push({ name: 'HomeQnaManagement' })
    },
    created () {
      this.$store.dispatch('initSearchDataForCodeRequest')

      let defaultLastRouteParams = {
        page: 1,
        filter: {
          searchRgstNm: '',
          searchTemplateGbn: '',
          searchTemplateNm: '',
          searchFrstRgstDtStartYYYYMMDD: '',
          searchFrstRgstDtEndYYYYMMDD: '',
          searchBirthdayStartYYYYMMDD: '',
          searchBirthdayEndYYYYMMDD: '',
          searchUserNmKoTx:'',
          searchAccountStatusCd:'',
          searchUserId: '',
          searchPhoneNoTx: '',
          searchSolCd : ''
        },
      }

      let jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')

      this.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'UserManagementDetail' ? JSON.parse(jsonStrLastRouteParams) : defaultLastRouteParams

      if (EtnersCommonUtil.isNotEmpty(this.lastRouteParams)) {
        let searchFrstRgstDtStartYYYYMMDD = this.lastRouteParams.filter.searchFrstRgstDtStartYYYYMMDD
        let searchFrstRgstDtEndYYYYMMDD = this.lastRouteParams.filter.searchFrstRgstDtEndYYYYMMDD
        let searchBirthdayStartYYYYMMDD = this.lastRouteParams.filter.searchBirthdayStartYYYYMMDD
        let searchBirthdayEndYYYYMMDD = this.lastRouteParams.filter.searchBirthdayEndYYYYMMDD

        this.lastRouteParams.page = this.lastRouteParams.filter.page

        if(EtnersCommonUtil.isNotEmpty(searchFrstRgstDtStartYYYYMMDD)){
          let startDate =new Date(EtnersParseUtil.convertYYYYMMDDFormat(searchFrstRgstDtStartYYYYMMDD))
          this.frstRgstDtRange.start = startDate
        }
        if(EtnersCommonUtil.isNotEmpty(searchFrstRgstDtEndYYYYMMDD)){
          let endDate =new Date(EtnersParseUtil.convertYYYYMMDDFormat(searchFrstRgstDtEndYYYYMMDD))
          this.frstRgstDtRange.end = endDate
        }

        if(EtnersCommonUtil.isNotEmpty(searchBirthdayStartYYYYMMDD)){
          let startDate =new Date(EtnersParseUtil.convertYYYYMMDDFormat(searchBirthdayStartYYYYMMDD))
          this.birthdateDtRange.start = startDate
        }
        if(EtnersCommonUtil.isNotEmpty(searchBirthdayEndYYYYMMDD)){
          let endDate =new Date(EtnersParseUtil.convertYYYYMMDDFormat(searchBirthdayEndYYYYMMDD))
          this.birthdateDtRange.end = endDate
        }
      }


    },
    mounted () {
      const vm = this

      //엔터키 이벤트 자동 세팅
      const enterkeyEventTargetIdArray = [
        'userIdInput', 'userNmKoTxInput', 'phoneNoTxInput'
      ]

      for (let elementId of enterkeyEventTargetIdArray) {
        let element = document.getElementById(elementId)
        if (element !== undefined) {
          element.onkeyup = function (e) {
            const ENTER_KEY_CODE = 13
            let currentKeyCode = window.event.keyCode || e.which
            if (currentKeyCode === ENTER_KEY_CODE) {
              vm.commonOnChangeEvent(e)
            }
          }
        }
      }

      //datepicker 한글 적용
      this.$refs.frstRgstDtDatepickerRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      });
      this.$refs.frstRgstDtDatepickerRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      });


      //datepicker 한글 적용
      this.$refs.birthdateDtDatepickerRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      });
      this.$refs.birthdateDtDatepickerRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      });

    },
    computed: {
      computedUserManagementListDataSource: function () {
        const vm = this

        let lastRequestParameterPage = vm.lastRouteParams.page !== undefined ? vm.lastRouteParams.page : 1

        const userManagementListDataSource = new kendo.data.DataSource({
          name: 'userManagementListDataSource',
          page: lastRequestParameterPage,
          pageSize: 10,
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
          transport: {
            read: {
              url: `${ApiConfig.ethDomain}/v1/eth/user/selectUserManagementList.json`,
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              cache: false,
            },
            parameterMap: function (options, operation) {
              if (operation === 'read') {
                const filter = vm.currentPageFilterOption()
                let loginUserToken = sessionStorage.adminLoginUserToken

                let params = {
                  loginUserToken: loginUserToken,
                  searchUserId: filter.searchUserId,
                  searchUserNmKoTx: filter.searchUserNmKoTx,
                  searchPhoneNoTx: filter.searchPhoneNoTx,
                  searchAccountStatusCd : filter.searchAccountStatusCd,
                  searchFrstRgstDtStartYYYYMMDD : filter.searchFrstRgstDtStartYYYYMMDD,
                  searchFrstRgstDtEndYYYYMMDD : filter.searchFrstRgstDtEndYYYYMMDD,
                  searchBirthdayStartYYYYMMDD : filter.searchBirthdayStartYYYYMMDD,
                  searchBirthdayEndYYYYMMDD: filter.searchBirthdayEndYYYYMMDD,
                  searchSolCd : filter.searchSolCd,
                  skip: options.skip,
                  take: options.take,
                  page: options.page,
                  pageSize: options.pageSize,
                  sort: options.sort,
                  requestType: 'PAGING', //PAGING or EXPORT
                }

                sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

                return kendo.stringify(params)
              }

              if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) }
              }
            },
          },
          schema: {
            type: 'json',
            parse: function (response) {

                let changeResultData = response.resultData
                let rowNumber = response.total + 1 - (vm.$refs.userManagementListGridRef.dataSource.page() - 1)
                    * vm.$refs.userManagementListGridRef.dataSource.pageSize()
                if(changeResultData!=null) {

                  for (let i = 0; i < changeResultData.length; i++) {
                    let row = changeResultData[i]
                    row.rowNumber = --rowNumber;

                }
                response.resultData = changeResultData
              }
              return response
            },
            total: function (response) {
              return response.total
            },
            data: function (response) {
              if (response.resultStatus.messageCode === '2000') {
                return response.resultData
              } else {
                kendo.alert('message: ' + response.resultStatus.messageText)
                return ''
              }
            },
          },

          error: function (e) {
            console.log('error event handler', e.errors[0])
          },
        })

        return userManagementListDataSource
      },
    },
    methods: {
      commonOnChangeEvent: function () {
        const vm = this
        let gridRef = vm.$refs.userManagementListGridRef

        // 마지막 검색 데이터 page 초기화
        this.lastRouteParams.page = 1

        if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
          if (gridRef.kendoWidget().dataSource !== undefined) {
            gridRef.kendoWidget().dataSource.page(1)
          }
        }
      },
      currentPageFilterOption: function () {
        const vm = this
        const refs = vm.$refs

        let currentSearchDataObj = {}

        const filterData = vm.lastRouteParams.filter
        //카테고리
        if (refs.solCdRef !== undefined) {
          const searchSolCd = refs.solCdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchSolCd)) {
            currentSearchDataObj.searchSolCd = searchSolCd
          }
       }
        //가입 상태
        if (refs.accountStatusCdRef !== undefined) {
          const searchAccountStatusCd = refs.accountStatusCdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchAccountStatusCd)) {
            currentSearchDataObj.searchAccountStatusCd = searchAccountStatusCd.split("_")[1]
          }
        }

        //메일 주소
        if (refs.userIdRef !== undefined) {
          const searchUserId = refs.userIdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchUserId)) {
            currentSearchDataObj.searchUserId = searchUserId
          }
        }

        //성명
        if (refs.userNmKoTxRef !== undefined) {
          const searchUserNmKoTx = refs.userNmKoTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchUserNmKoTx)) {
            currentSearchDataObj.searchUserNmKoTx = searchUserNmKoTx
          }
        }

        // 등록일자
        if (refs.frstRgstDtDatepickerRef !== undefined) {
          const frstRgstDtRangeValue = refs.frstRgstDtDatepickerRef.kendoWidget().range()

          if (EtnersCommonUtil.isNotEmpty(frstRgstDtRangeValue)) {
            const startYYYYMMDD = (frstRgstDtRangeValue.start !== undefined && frstRgstDtRangeValue.start !== null)
                                ? EtnersDateTimeUtil.dateToYYYYMMDD(frstRgstDtRangeValue.start)
                                : undefined

            const endYYYYMMDD = (frstRgstDtRangeValue.end !== undefined && frstRgstDtRangeValue.end !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(frstRgstDtRangeValue.end)
                              : undefined

            currentSearchDataObj.searchFrstRgstDtStartYYYYMMDD = startYYYYMMDD
            currentSearchDataObj.searchFrstRgstDtEndYYYYMMDD = endYYYYMMDD
          }
        }

        // 생년월일
        if (refs.birthdateDtDatepickerRef !== undefined) {
          const birthdateDtRangeValue = refs.birthdateDtDatepickerRef.kendoWidget().range()

          if (EtnersCommonUtil.isNotEmpty(birthdateDtRangeValue)) {
            const startYYYYMMDD = (birthdateDtRangeValue.start !== undefined && birthdateDtRangeValue.start !== null)
                                ? EtnersDateTimeUtil.dateToYYYYMMDD(birthdateDtRangeValue.start)
                                : undefined

            const endYYYYMMDD = (birthdateDtRangeValue.end !== undefined && birthdateDtRangeValue.end !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(birthdateDtRangeValue.end)
                              : undefined

            currentSearchDataObj.searchBirthdayStartYYYYMMDD = startYYYYMMDD
            currentSearchDataObj.searchBirthdayEndYYYYMMDD = endYYYYMMDD
          }
        }

        //연락처
        if (refs.phoneNoTxRef !== undefined) {
          const searchPhoneNoTx = refs.phoneNoTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchPhoneNoTx)) {
            currentSearchDataObj.searchPhoneNoTx = searchPhoneNoTx
          }
        }

        vm.filterData = currentSearchDataObj

        return currentSearchDataObj
      },
      dataBoundForSmsTemplate: function (evt) {
        let vm = this
        let isDragging = false
        let lastCellValue = ""

        $(".k-grid td")
        .mousedown(function() {
          $(window).mousemove(function(e) {
            isDragging = true
            console.log(`e.target : ${e.target}`)
            lastCellValue = e.target.getInnerHTML()
            $(window).unbind("mousemove")
          });
        })
        .mouseup(function() {
          let wasDragging = isDragging;
          isDragging = false
          $(window).unbind("mousemove")
          if (!wasDragging) {
            let row = evt.sender.selectable.userEvents.currentTarget
            let rowDataItem = evt.sender.dataItem(row)
          }
        })
     },
      //row 클릭 이벤트
      onChangeUserManagementListGrid: function (e) {
        const vm = this
        const refs = this.$refs

        let selectedRow = e.sender.select()
        const rowDataItem = e.sender.dataItem(selectedRow)

        //row 클릭시 상세 화면으로 이동
        this.$router.push(
            {
              name: 'UserManagementDetail',
              params : {
                unqUserNo: rowDataItem.unqUserNo,
              },
            },
        )





      },
      // 초기화 버튼 클릭 이벤트
      cleanSearchDataEvent (e) {
        const refs = this.$refs

        //가입상태
        if (refs.accountStatusCdRef !== undefined) {
          refs.accountStatusCdRef.kendoWidget().select(0)
        }

        //메일 주소(ID)
        if (refs.userIdRef !== undefined) {
          refs.userIdRef.kendoWidget().value('')
        }

        //성명
        if (refs.userNmKoTxRef !== undefined) {
          refs.userNmKoTxRef.kendoWidget().value('')
        }

        //등록일
        if (refs.frstRgstDtDatepickerRef !== undefined) {
          refs.frstRgstDtDatepickerRef.kendoWidget().range({start: undefined, end: undefined})
        }

        //생년월일
        if (refs.birthdateDtDatepickerRef !== undefined) {
          refs.birthdateDtDatepickerRef.kendoWidget().range({start: undefined, end: undefined})
        }

        //연락처
        if (refs.phoneNoTxRef !== undefined) {
          refs.phoneNoTxRef.kendoWidget().value('')
        }

        sessionStorage.removeItem('LAST_ROUTE_PARAMS')

        this.commonOnChangeEvent(e)
      },
      onClickExcelDownloadForUserManageList: function () {
        const vm = this

        let lastRequestParameters = JSON.parse(sessionStorage.getItem('LAST_ROUTE_PARAMS')).filter
        const excelParams = JSON.parse(JSON.stringify(lastRequestParameters))
        excelParams.requestType = 'EXPORT'

        let listUrl = `${ApiConfig.ethDomain}/v1/eth/user/selectUserManagementList.json`

        ApiUtil.post(listUrl, excelParams).then(response => {
          //let solutionCodeMap = vm.$store.getters.getAllSolutionCodeMap[vm.workspaceCd]['0000']

          let changeResultData = response.data.resultData

          if (changeResultData != null) {
            let rowNumber = response.data.total + 1 - (vm.$refs.userManagementListGridRef.dataSource.page() - 1) * vm.$refs.userManagementListGridRef.dataSource.pageSize()

            for (let i = 0; i < changeResultData.length; i++) {
              let row = changeResultData[i]
              row.rowNumber = --rowNumber
            }
          }

          response.data.resultData = changeResultData

          if (EtnersCommonUtil.isNotEmpty(changeResultData)) {
            const currentDate = EtnersDateTimeUtil.lookupDateYYYYMMDD()
            const currentYYYYMMDD = EtnersDateTimeUtil.dateToYYYYMMDD(currentDate)

            saveExcel({
              data: changeResultData,
              fileName: '회원관리목록_' + currentYYYYMMDD + '.xlsx',
              columns: [
                { field: 'rowNumber', title: 'No.', width: '100px' },
                { field: 'userNmKoTx', title: '성명', width: '100px' },
                { field: 'birthdateTx', title: '생년월일', width: '100px' },
                { field: 'phoneNoTx', title: '연락처', width: '100px' },
                { field: 'userId', title: '메일 주소(ID)', width: '100px' },
                { field: 'fmtFrstRgstDt', title: '등록일', width: '100px' },
                { field: 'fmtLastLoginDt', title: '마지막 접속일시', width: '100px' },
                { field: 'cvtAccountStatusCd', title: '가입 상태', width: '100px' },
              ],
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        })
      },
      onClickCreateNewManager: function() {
        this.$refs.managerRef.kendoWidget().center().open()
      },
      onClickWindowClose() {
        this.$refs.managerRef.kendoWidget().close()
        this.$refs.userManagementListGridRef.kendoWidget().dataSource.read()
      },
    },
    data: function () {
      const kendoGridHeaderAttributes = {
        style: 'font-weight: bold;',
      }

      const vm = this

      return {
        rowNumber: 0,
        frstRgstDtRange: {start: undefined, end: undefined},
        birthdateDtRange: {start: undefined, end: undefined},
        messages: {
          year: '년',
          month: '월',
          day: '일',
        },
        filterData: {},
        lastRouteParams: {
          page: 1,
          filter: {
            searchRgstNm: '',
            searchTemplateGbn: '',
            searchTemplateNm: '',
            searchFrstRgstDtStartYYYYMMDD: '',
            searchFrstRgstDtEndYYYYMMDD: '',
            searchBirthdayStartYYYYMMDD: '',
            searchBirthdayEndYYYYMMDD: '',
            searchUserNmKoTx:'',
            searchAccountStatusCd:'',
            searchUserId: '',
            searchPhoneNoTx: '',
            searchSolCd : ''
          },
        },
        userManagementListGridColumns: [
          {
            field: 'rowNumber',
            title: 'No.',
            width: '5%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'solTx',
            title: '구분',
            width: '10%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            template: function(dataItem){
              return dataItem.solTx === '기타' ? 'EtnersHome' : dataItem.solTx
            },
          },
          {
            field: 'userNmKoTx',
            title: '성명',
            width: '8%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'phoneNoTx',
            title: '연락처',
            width: '12%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'userId',
            title: '메일 주소(ID)',
            width: '20%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },

          {
            field: 'fmtFrstRgstDt',
            title: '등록일',
            width: '10%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'fmtLastLoginDt',
            title: '마지막 접속일시',
            width: '13%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'cvtAccountStatusCd',
            title: '가입 상태',
            width: '10%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },

        ],
      }
    },
  }
</script>

<template>
  <div id="container_home" class="eng">
    <div class="company_visual">
      <!-- 스크롤 효과 -->
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <!-- END 스크롤 효과 -->
      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          MESSAGE
        </h2>
        <h2 class="company_tit animated d-2s">
          We create a new management support platform with innovative thinking and bold challenges.
        </h2>
      </div>
      <div class="company_img animated">
        <img src="@/assets/img/company_02.png" class="animated d-1s" alt="">
      </div>
    </div>
    <div class="company_inner">
      <ul class="ceo_list">
        <li class="right animated">

          <div class="txt">
            <div class="tit animated d-1s">We sincerely welcome you to visit the Etners website.</div>
            <div class="sub animated d-2s">
              ETNERS is a combination of eternal, which means ‘forever, immortal’, and partners, which means ‘companion’, meaning to be an eternal companion. As the mission statement suggests, since its inception in 1998, ETNERS has provided management support solutions to a number of companies with a commitment to become a reliable partner for clients based on credibility and trust.<br>

              Starting with shared service that integrates human resources and general affairs, we expand our business such as corporate welfare, expatriate and foreigner support, education distribution, and shared office, and contribute to enhancing corporate competitiveness so that clients can focus on their core tasks.<br><br>

              In the midst of the rapidly changing business environment over the past year, ETNERS is constantly challenging to seize opportunities for growth. By innovating the way we work in the field of management support, securing technology through patent applications, and developing customized solutions, we have made a remarkable leap forward as a nation's top management support platform company.<br>

              In the future, we would like to build a global business model that the world is paying attention to beyond Korea's top management support platform company that leads digital transformation with collective intelligence and strong teamwork of executives and employees. We will create new values that will impress more clients.<br><br>

								<p class="align-r">ETNERS CEO<br><img src="@/assets/img/ceosign.png" class="animated d-3s"
										alt=""></p>
                    <div class="clearfix"></div>
							</div>

          </div>
          <div class="img"><img src="@/assets/img/ceo_img.png" class="animated d-1s" alt=""></div>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>
<script>
  import ApiConfig from '@/api/api.config'
  export default {
    name: 'CeoMessageEn',
    created() {
    },
    mounted () {
    },
    methods: {
    },
    data: function () {
      const vm = this
      return {

      }
    }
  }
</script>

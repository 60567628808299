<template>
  <section id="container">
    <div class="title_wrap">
      <div class="title_wrap">
        <h1>그룹하위 코드 상세 정보</h1>
      </div>
    </div>
    <form ref="formRef" v-kendo-validator @submit.prevent="">
      <div class="contents_wrap">
        <div class="small_title_wrap">
          <h2>그룹하위 코드 정보</h2>
        </div>
        <ul class="filter_list">
          <li>
            <dd>
              <label>회사코드
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  {{vModel.cmpyCd}}
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>그룹 코드
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  {{ vModel.cdGrupId }}
                </li>
              </ul>
            </dd>
            <dd>
              <label>그룹 코드 명
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  {{ vModel.cdGrupNmTx }}
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>상세 코드
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  {{ vModel.cdId }}
                </li>
              </ul>
            </dd>
            <dd>
              <label>상세 코드 명
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  {{ vModel.cdNmTx }}
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>상세 코드 속성값
              </label>
              <ul>
                <li>
                  {{ vModel.cdValueTx }}
                </li>
              </ul>
            </dd>
            <dd>
              <label>상세 코드 속성값2
              </label>
              <ul>
                <li>
                  {{ vModel.cdValue2Tx }}
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>조회순서
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  {{ vModel.seqNo }}
                </li>
              </ul>
            </dd>
            <dd>
              <label>상세 코드 설명</label>
              <ul>
                <li>
                  {{ vModel.cdDescTx }}
                </li>
              </ul>
            </dd>
          <li>
            <dd>
              <label>사용여부
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-switch
                          ref="useYnRef"
                          name="useYn"
                          :checked="vModel.useYn === undefined || vModel.useYn === 'Y'"
                  >
                  </kendo-switch>
                </li>
              </ul>
            </dd>
          </li>
        </ul>
      </div>
      <div class="button_area">
        <ul>
          <li>
            <button class="large_btn" @click="fnGoBack">취소</button>
          </li>
          <li>
            <button id="buttonSubmit2" class="large_btn orange" @click="fnModifySubmit">수정</button>
          </li>
        </ul>
      </div>
    </form>

  </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersKendoGridUtil from '@/common/kendo.grid.util'
import EtnersParseUtil from '@/common/etners.parse.util'
import EtnersDateTimeUtil from '@/common/etners.datetime.util'
import { fetchCodeDtlDetailResultData } from '@/api/api.common'
import $ from 'jquery'


export default{
  name: 'CodeManagementDtlDetail',
  mounted: function() {
        const vm = this
        this.fnGetDetail()
  },
  methods: {
    fnGetDetail: async function() {
      this.detail = await fetchCodeDtlDetailResultData(this.$route.params.cmpyCd,this.$route.params.cdGrupId,this.$route.params.cdId)
      this.vModel = this.detail.data.resultData
    },
    fnGoBack: function(e) {
      this.$router.push({
        name:'CodeManagement'
      })
    },
    fnModifySubmit : function(){
        this.$router.push({
          name: 'CodeManagementDtlSave',
          params : {
            cmpyCd: this.vModel.cmpyCd,
            cdGrupId: this.vModel.cdGrupId,
            cdId: this.vModel.cdId
          }
        })
    },
  },
  data: function () {
    return {
      loginUserData: JSON.parse(sessionStorage.getItem('loginUserData')),
      vModel: {
        useYn: 'true',
        cdGrupId: '',
        cdGrupNmTx: '',
        cdId: '',
        cdNmTx: '',
        cdValueTx: '',
        cdValue2Tx: '',
        seqNo: '',
        cdDescTx: '',
        cmpyCd: ''
      },
    }
  },
}
  </script>

<template>
  <div id="container">
    <div class="title_wrap"><h1>담당자관리 수정</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>담당자 정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>성명(한글) </label>
            <ul>
              <li>
                <kendo-maskedtextbox
                  ref="userNmKoTxRef"
                  maxlength="20"
                  id="userNmKoTxInput"
                  :style="'width: 100%;'"
                  v-model="detailData.userNmKoTx"
                >
                </kendo-maskedtextbox>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>성명(영문)</label>
            <ul>
              <li>
                <kendo-maskedtextbox
                  ref="userNmEnTxRef"
                  id="userNmEnTxInput"
                  maxlength="30"
                  :style="'width: 100%;'"
                  v-model="detailData.userNmEnTx"
                >
                </kendo-maskedtextbox>
              </li>
            </ul>
          </dd>
          <dd>
            <label>메일 주소</label>
            <ul>
              <li class="mr5">
                <kendo-maskedtextbox
                  ref="userIdInputRef"
                  id="userIdInput"
                  maxlength="25"
                  class="f_l w70"
                  :style="'width: 100%;'"
                  v-model="detailData.userId"
                >
                </kendo-maskedtextbox>
                <button class="mid_btn orange mgl5" id="duplicateSubmit" @click="fnCheckDuplicateEmail()">중복확인</button>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>근무지 주소</label>
            <ul>
              <li>
                <kendo-maskedtextbox
                  ref="addressInfoTxRef"
                  id="addressInfoTx"
                  name="addressInfoTx"
                  maxlength="40"
                  :style="'width: 100%;'"
                  v-model="detailData.addressInfoTx"
                >
                </kendo-maskedtextbox>
                <kendo-maskedtextbox
                  ref="addressDetailTxRef"
                  id="addressDetailTx"
                  maxlength="40"
                  name="addressDetailTx"
                  :style="'width: 100%;'"
                  v-model="detailData.addressDetailTx"
                >
                </kendo-maskedtextbox>
              </li>
            </ul>
          </dd>
          <dd>
            <label>휴대전화번호</label>
            <ul class="w80">
              <li>
                <kendo-maskedtextbox
                  ref="phoneNoTxRef"
                  id="phoneNoTxInput"
                  maxlength="11"
                  :style="'width: 100%;'"
                  v-model="detailData.phoneNoTx"
                >
                </kendo-maskedtextbox>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>우편번호</label>
            <ul class="w70">
            <li class="mr5">
              <kendo-maskedtextbox
                  ref="postNoTxRef"
                  id="postNoTx"
                  maxlength="10"
                  name="postNoTx"
                  :disabled="true"
                  :style="'width: 100%;'"
                  :class="'w70 f_l'"
                  v-model="detailData.postNoTx"
                >
                </kendo-maskedtextbox>
                <button class="mid_btn orange fl mgl10" @click="openPostWindow()">우편번호 검색</button>

              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>가입 상태</label>
            <ul>
              <li>
                {{detailData.cvtAccountStatusCd}} <button v-if="detailData.accountStatusCd !== '99'" class="mid_btn mgl20" @click="optoutUserData()">탈퇴</button>
              </li>
            </ul>
          </dd>
          <dd>
            <label>마지막 접속일시</label>
            <ul class="w60">
              <li>{{detailData.fmtLastLoginDt}}</li>
            </ul>
          </dd>
        </li>
        <li>
            <dd>
              <label>알림 수신여부</label>
              <ul>
                <li>
                  <kendo-dropdownlist
                    ref="receiveNotiYnRef"
                    :data-source="[{ 'cdNmTx': '사용', 'cdId': 'Y' }, { 'cdNmTx': '미사용', 'cdId': 'N' }]"
                    :data-text-field="'cdNmTx'"
                    :data-value-field="'cdId'"
                    v-model="detailData.receiveNotiYn"
                    :index="0"
                  >
                  </kendo-dropdownlist>
                </li>
              </ul>
            </dd>
          </li>
      </ul>
    </div>
    <div class="button_area w25">
      <ul>
        <li class="w30"><button class="large_btn dgray" @click="deleteUserData">사용자 삭제</button></li>
        <li class="w30"><button class="large_btn" @click="cancelConfirmEvent">취소</button></li>
        <li class="w30"><button class="large_btn orange" @click="updateUserData">저장</button></li>
      </ul>
    </div>
    <!-- 주소검색 window -->
    <daum-postcode-window-for-user-management
      ref="daumPostCodeWindowComponent"
      v-on:send-addr-data="getAddrData"
      post-no-element-id="postNoTx"
      main-address-element-id="addressInfoTx"
      detail-address-element-id="addressDetailTx"
    >
    </daum-postcode-window-for-user-management>
  </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersKendoGridUtil from '@/common/kendo.grid.util'
  import EtnersParseUtil from '@/common/etners.parse.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'

  /* Kendo Excel Export Module */
  import { saveExcel } from '@progress/kendo-vue-excel-export'
  import { process, filterBy } from '@progress/kendo-data-query'

  import DaumPostcodeWindowForUserManagement from '@/components/DaumPostcodeWindowForUserManagement'

  /**
   * JQuery 모듈 로딩.
   *
   * JQuery 모듈은 Selector 용도로 사용하거나,
   * 부득이하게 Kendo의 css 를 제어해야하는 경우 사용한다.
   *
   * Vue에서 JQuery를 사용하지 못할 이유는 없지만
   * Vue로 상태 제어 및 이벤트 처리하도록 구현해둔 DOM 영역을 JQuery로 직접 건드리게 되면
   * Vue가 사용하는 가상 DOM에는 JQuery로 변경한 부분이 반영되지 않아 문제가 발생할 수 있다는 점을 인지하고 주의해서 사용해야 한다.
   */
  import $ from 'jquery'

  export default {
    name: 'UserManagementSave',
    components: {
      DaumPostcodeWindowForUserManagement
    },
    created () {
      let vm = this

      let unqUserNo = vm.$route.params.unqUserNo
      vm.unqUserNo = unqUserNo

      let adminLoginUserToken = sessionStorage.getItem("adminLoginUserToken")

      let detailParam = {
        "loginUserToken" : adminLoginUserToken,
        "unqUserNo" : unqUserNo
      }

      ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/user/selectUserManagementDetail.json`, detailParam).then(function (response) {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          return false
        }

        const resultStatus = response.data.resultStatus

        if (resultStatus.messageCode !== '2000') {
          kendo.alert(resultStatus.messageText)
          return false
        }

        vm.detailData = response.data.resultData
      })
    },
    mounted () {
      const vm = this

      //datepicker 한글 적용
      // this.$refs.birthdateTxDatePickerRef.kendoWidget().setOptions({
      //   messages: this.messages
      // })

      let elementPhone = document.getElementById('phoneNoTxInput')

      elementPhone.onkeyup = function(){
        vm.setMatchedPhone(elementPhone.value)
      }

    },
    methods: {
      setMatchedPhone: function(value){
        const matchedPhone = /[0-9]$/.test(value)
        let text = this.$refs.phoneNoTxRef.kendoWidget().value()

        if(!matchedPhone){
          this.$refs.phoneNoTxRef.kendoWidget().value('')
          return false
        }else{
          this.$refs.phoneNoTxRef.kendoWidget().value(value)
          return true
        }

      },
      makeDateForBirthdateTx: function(birthdateTx) {
        let birthdateYYYYMMDD = birthdateTx.split('/').join('')

        return EtnersDateTimeUtil.lookupDateYYYYMMDD(birthdateYYYYMMDD)
      },
      getEmailRules: function () {
        const resultMsg = { key: true, msg: '' }
        const emailId = this.$refs.userIdInputRef.kendoWidget().value()
        const matched = /.+@.+/.test(emailId)

        if ( EtnersCommonUtil.isEmpty(emailId)) {
          resultMsg.key = false
          resultMsg.msg = '이메일(ID)을 입력해주세요.'
        } else if (!matched) {
          resultMsg.key = false
          resultMsg.msg = '이메일 형식을 맞춰주세요.'
        }
        return resultMsg
      },
      //이메일 중복 확인
      fnCheckDuplicateEmail: function(e){

        let passWordDuplicateElme = document.querySelector('#duplicateSubmit')

        const emailId = this.$refs.userIdInputRef.kendoWidget().value()
        const userId = { 'userId': emailId }
        const resultMsg = this.getEmailRules()

        if(resultMsg.key){
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/user/duplicateEmail.json`,userId).then(response => {

            let resultData = response.data.resultData
            let resultStatus = response.data.resultStatus

            if(resultStatus.messageCode !== '2000'){
              if(resultStatus.messageCode === '3001' ){
                kendo.alert("네이버로 등록한 계정이 존재합니다.")
                return false
              }else if(resultStatus.messageCode === '3002' ){
                kendo.alert("카카오로 등록한 계정이 존재합니다.")
                return false
              }else{
                kendo.alert(response.data.resultStatus.messageText)
                return false
              }

            }else{
              kendo.alert('사용가능한 이메일입니다.')
              emailDuplicateElme.setAttribute('disabled','disabled')
            }
          })
        }else{
          kendo.alert(resultMsg.msg)
        }


      },
      optoutUserData: function() {
        const vm = this

        const router = vm.$router
        const confirmMessage = '정말 이 사용자를 탈퇴 처리하시겠습니까?'

        let adminLoginUserToken = sessionStorage.getItem("adminLoginUserToken")

        let params = {
          loginUserToken : adminLoginUserToken,
          unqUserNo: vm.unqUserNo,
          accountStatusCd: '99',
          snsUserKeyTx : vm.detailData.snsUserKeyTx,
          snsUserRefreshKeyTx: vm.detailData.snsUserRefreshKeyTx
        }

        kendo.confirm(confirmMessage).done(function () {
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/user/updateUserStatus.json`,params).then(function (response) {

            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('정상적으로 처리되지 않았습니다.')
              return false
            }

            const resultStatus = response.data.resultStatus
            if (resultStatus.messageCode !== '2000') {
              kendo.alert(resultStatus.messageText)
              return false
            }

            kendo.alert('회원 정보가 탈퇴 처리되었습니다.')
            router.push({
              name:'UserManagement'
            })
          })


        })
      },
      deleteUserData: function() {
        const vm = this

        const router = vm.$router

        let adminLoginUserToken = sessionStorage.getItem("adminLoginUserToken")

        const confirmMessage = '정말 이 담당자를 삭제 처리하시겠습니까?'

        let params = {
          loginUserToken : adminLoginUserToken,
          unqUserNo: vm.unqUserNo
        }

        kendo.confirm(confirmMessage).done(function () {
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/user/deleteUserData.json`, params).then(function(response){

            if (response.data === undefined) {
              kendo.alert('정상적으로 처리되지 않았습니다.')
              return false
            }

            const resultStatus = response.data.resultStatus

            kendo.alert('회원 정보가 삭제 처리되었습니다.')
            router.push({
              name:'UserManagement'
            })
          }) 
        })
      
      },
      updateUserData: function() {
        const vm = this

        const router = vm.$router
        const detailData = vm.detailData

        let adminLoginUserToken = sessionStorage.getItem("adminLoginUserToken")
        const updateUserParam = {
          loginUserToken : adminLoginUserToken,
          unqUserNo : detailData.unqUserNo,
          addressInfo : vm.$refs.addressInfoTxRef.kendoWidget().value(),
          addressDetail : vm.$refs.addressDetailTxRef.kendoWidget().value(),
          // birthdateTx : vm.$refs.birthdateTxDatePickerRef.kendoWidget()._oldText,
          phoneNoTx : vm.$refs.phoneNoTxRef.kendoWidget().value(),
          postNoTx : vm.$refs.postNoTxRef.kendoWidget().value(),
          userId : detailData.userId,
          userNmEnTx : detailData.userNmEnTx,
          userNmKoTx: detailData.userNmKoTx,
          receiveNotiYn: detailData.receiveNotiYn
        }

        kendo.confirm('사용자 정보를 변경하시겠습니까?').done(function () {
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/user/updateUserDetailData.json`, updateUserParam).then(function (response) {
            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
              return false
            }

            const resultStatus = response.data.resultStatus

            if (resultStatus.messageCode !== '2000') {
              kendo.alert(resultStatus.messageText)
              return false
            }

            //kendo.alert("회원 정보를 변경 저장하였습니다.")
            router.push(
                {
                  name: 'UserManagement',
                },
            )
          })
        })

      },
      openPostWindow: function (e) {
        let daumPostCodeWindow = this.$refs.daumPostCodeWindowComponent.$refs.daumPostCodeRef.kendoWidget()
        daumPostCodeWindow.center().open()
      },
      cancelConfirmEvent: function() {
        const vm = this
        const router = vm.$router

        kendo.confirm('사용자 정보 수정을 중단하고 목록으로 돌아가시겠습니까?').done(function () {

          router.push(
              {
                name: 'UserManagement',
              },
          )

        })
      },
      redirectUserManageList: function() {
        this.$router.push(
            {
              name: 'UserManagement',
            },
        )
      },
      getAddrData (addrData) {
        const vm = this
        vm.detailData.postNoTx = addrData.zipCode
        vm.detailData.addressDetailTx = addrData.addr

        console.log("getAddrData !!!")
        //상세 주소 focusing
        vm.$nextTick(() => vm.$refs.addressDetailTxRef.focus())
      },
    },
    data: function () {
      const vm = this

      return {
        messages: {
          year: '년',
          month: '월',
          day: '일',
        },
        unqUserNo : "",
        zipCode : '',
        detailData : {
          accountStatusCd : "",
          addressDetailTx : "",
          addressInfoTx : "",
          // birthdateTx : "",
          cvtAccountStatusCd : "",
          fmtFrstRgstDt : "",
          fmtLastLoginDt : "",
          fmtTermsAgreeDt : "",
          phoneNoTx : "",
          postNoTx : "",
          unqUserNo : "",
          userId : "",
          userNmEnTx : "",
          userNmKoTx : "",
        },
      }
    },
  }
</script>

<template>
  <kendo-window ref="daumPostCodeRef" id="daumPostCodeWindow" :modal="true" :width="'500px'" :height="'400px'" style="display:none">
    <daum-postcode :on-complete="handleAddress" :on-close="closePostCodeCallBack" :auto-close="false">
    </daum-postcode>
  </kendo-window>
</template>

<script>
  import DaumPostcode from 'vuejs-daum-postcode'

  export default {
    name: 'DaumPostCodeWindow',
    props: [ 'postNoElementId', 'mainAddressElementId', 'detailAddressElementId' ],
    data(){
      return{
        addrData: {},
      }
    },
    components: {
      DaumPostcode
    },
    methods: {
      handleAddress: function (data) {
        let fullAddress = data.address
        let extraAddress = ''
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName)
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '')
        }

        if(this.$parent.addrData !== undefined) {
          /* data에 setting하는 방식 */

          this.addrData.zipCode = data.zonecode,
          this.addrData.addr = fullAddress

          this.$emit('send-addr-data', this.addrData)

        } else {
          /* element value에 setting하는 방식 */

          const postNoElementId = this.$props.postNoElementId
          const mainAddressElementId = this.$props.mainAddressElementId
          const detailAddressElementId = this.$props.detailAddressElementId

          // 우편번호
          if(postNoElementId !== undefined && postNoElementId !== '') {
            document.getElementById(postNoElementId).value = data.zonecode
          } else {
            document.getElementsByName('zipCode')[0].value = data.zonecode
          }
          // 주소
          if(mainAddressElementId !== undefined && mainAddressElementId !== '') {
            document.getElementById(mainAddressElementId).value = fullAddress
          } else {
            document.getElementsByName('addr')[0].value = fullAddress
          }
          // 상세주소
          if(detailAddressElementId !== undefined && detailAddressElementId !== '') {
            document.getElementById(detailAddressElementId).value = ''
          } else {
            document.getElementsByName('addrDtl')[0].value = ''
          }

          document.getElementById(detailAddressElementId).focus()

          const addrEl = document.getElementsByName('addr')

          if(addrEl[0] != undefined) {
            addrEl[0].value = '('+data.zonecode+') '+fullAddress
          }
        }

        this.$refs.daumPostCodeRef.kendoWidget().close()
      },
      closePostCodeCallBack: function (state) {
        try {
          document.getElementsByClassName('btn_clear')[0].click()
          document.getElementsByClassName('btn_search')[0].click()
        } catch(e) {
          console.log(`e : ${e}`)
        }

      },
    }
  }
</script>

<style scoped>
</style>

<template>
  <div>
    <form ref="ManagerFormRef" v-kendo-validator @submit.prevent="">
      <div class="contents_wrap">
        <ul class="filter_list">
          <li>
            <dd>
              <label class="w30">카테고리
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-dropdownlist
                        ref="solCdRef"
                        :data-source="$store.state.codeRequestStore.search.combobox.solCd.data"
                        :data-text-field="'cdNmTx'"
                        :data-value-field="'cdId'"
                        :option-label="'전체'"
                        :index="0">
                    </kendo-dropdownlist>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label class="w30">성명(한글)
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                      ref="userNmKoTxRef"
                      :placeholder="'성명(한글)을 입력하세요.'"
                      :class="'w100'"
                      maxlength="100"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
          </dd>
          <dd>
              <label class="w30">성명(영문)
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                      ref="userNmEnTxRef"
                      :placeholder="'성명(영문)을 입력하세요.'"
                      :class="'w100'"
                      maxlength="100"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd class="w100">
            <label style="width:14.5%">메일 주소
              <span class="forg">*</span>
            </label>
            <ul>
              <li>
                <kendo-maskedtextbox
                    ref="userIdInputRef"
                    :placeholder="'메일 주소를 입력하세요.'"
                    :class="'w60 mg0'"
                    maxlength="30">
                </kendo-maskedtextbox>
                <button class="mid_btn orange mgl5" id="duplicateSubmit" @click="fnCheckDuplicateEmail()">중복확인</button>

              </li>
            </ul>
           </dd>
          </li>
          <li>
            <dd>
              <label class="w30">비밀번호
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                          ref="passWordNoRef"
                          name="passWordNoChk"
                          maxlength="50"
                          id="passWordNoChk"
                          type="password"
                          :placeholder="'비밀번호를 입력해주세요.'"
                          :class="'w100'">
                  </kendo-maskedtextbox>
                <span class="k-invalid-msg" data-for="newPassword"></span>
                </li>
              </ul>
            </dd>       
            <dd>
              <label class="w30">비밀번호 확인
                <span class="forg">*</span>
              </label>
              <ul class="w70">
                <li>
                <kendo-maskedtextbox
                            ref="passwordTxRef"
                            name="passwordTx"
                            maxlength="50"
                            id="passwordTx"
                            type="password"
                            :placeholder="'비밀번호를 한번 더 입력해주세요.'"
                            :class="'w100'">
                </kendo-maskedtextbox>
                <div class="msg_green" :style="{display: passCoincide}" >비밀번호가 일치 합니다.</div>
                <div class="msg_red" :style="{display: passDisCoincide}" >비밀번호가 일치하지 않습니다.</div>
              </li>
              </ul>
            </dd>
          </li>
        <li>
          <dd class="w100">
            <label style="width:14.5%">근무지 주소
            </label>
            <ul class="w85">
            <li class="mr5">
              <kendo-maskedtextbox
                  ref="postNoTxRef"
                  id="postNoTx"
                  maxlength="10"
                  name="postNoTx"
                  :disabled="true"
                  :style="'width:50%;'"
                  :class="'w70 f_l'"
                >
                </kendo-maskedtextbox>
                <button class="mid_btn orange fl mgl10" @click="openPostWindow()">우편번호 검색</button>
                <kendo-maskedtextbox
                  ref="addressInfoTxRef"
                  id="addressInfoTx"
                  class="w65 mgr5"
                  name="addressInfoTx"
                  maxlength="40"
                  >
                </kendo-maskedtextbox>
                <kendo-maskedtextbox
                  ref="addressDetailTxRef"
                  id="addressDetailTx"
                  class="w30"
                  maxlength="40"
                  name="addressDetailTx"
                  >
                </kendo-maskedtextbox>
              </li>
            </ul>
          </dd>
        </li>
          <li>
            <dd>
            <label class="w30">휴대전화번호
              <span class="forg">*</span>
            </label>
            <ul>
              <li>
                <kendo-maskedtextbox
                    ref="phoneNoTxRef"
                    :placeholder="'휴대전화번호를 입력하세요.'"
                    :class="'w100'"
                    id="phoneNoTxInput"
                    maxlength="30">
                </kendo-maskedtextbox>
              </li>
            </ul>
            </dd>
          </li>
        </ul>
      </div>
    </form>
  <div class="button_area">
  <template>
    <ul>
      <li class="w40">
        <button class="large_btn" @click="onClickWindowClose">취소</button>
      </li>
      <li class="w40">
        <button class="large_btn orange" @click="onClickManagerSave">저장</button>
      </li>
    </ul>
  </template>
  </div>
  <daum-postcode-window-for-user-management
      ref="daumPostCodeWindowComponent"
      post-no-element-id="postNoTx"
      main-address-element-id="addressInfoTx"
      detail-address-element-id="addressDetailTx">
  </daum-postcode-window-for-user-management>
</div>
</template>

<script>
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import EtnersCommonUtil from '@/common/etners.common.util'
import DaumPostcodeWindowForUserManagement from '@/components/DaumPostcodeWindowForUserManagement'

export default {
  name: 'managerManageWindow',
  components: {
    DaumPostcodeWindowForUserManagement
  },
  data() {
    const vm = this
    return {
      emaliYn : 'N',
      mms:0,
      templateGbnVal: '',
      managerInfo:{},
      htmlText: ``,
      boolReadonly : false,
      index :0,
      passValidate: 'inline-block',
      emailValidate: 'none',
      passCoincide: 'none',
      passDisCoincide: 'none',
    }
  },
  mounted() {
    const vm = this
    this.$store.dispatch('initSearchDataForCodeRequest')

    let elementPhone = document.getElementById('phoneNoTxInput')

    elementPhone.onkeyup = function(){
      vm.setMatchedPhone(elementPhone.value)
    }

  },
  methods: {
    setMatchedPhone: function(value){
      const matchedPhone = /[0-9]$/.test(value)

      if(!matchedPhone){
        this.$refs.phoneNoTxRef.kendoWidget().value('')
        return false
      }else{
        this.$refs.phoneNoTxRef.kendoWidget().value(value)
        return true
      }

    },
    fnCheckDuplicateEmail: function(e){
      const vm = this
      let passWordDuplicateElme = document.querySelector('#duplicateSubmit')

      const emailId = this.$refs.userIdInputRef.kendoWidget().value()
      const userId = { 'userId': emailId }
      const resultMsg = this.getEmailRules()


      if(resultMsg.key){
        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/user/duplicateEmail.json`,userId).then(response => {

          let resultData = response.data.resultData
          let resultStatus = response.data.resultStatus

          if(resultStatus.messageCode !== '2000'){
            if(resultStatus.messageCode === '3001' ){
              kendo.alert("네이버로 등록한 계정이 존재합니다.")
              return false
            }else if(resultStatus.messageCode === '3002' ){
              kendo.alert("카카오로 등록한 계정이 존재합니다.")
              return false
            }else{
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }

          } else {
            vm.emaliYn = 'Y'
            kendo.alert('사용가능한 이메일입니다.')
          }
        })
      }else{
        kendo.alert(resultMsg.msg)
      }


    },
    getEmailRules: function () {
      const resultMsg = { key: true, msg: '' }
      const emailId = this.$refs.userIdInputRef.kendoWidget().value()
      const matched = /.+@.+/.test(emailId)

      if ( EtnersCommonUtil.isEmpty(emailId)) {
        resultMsg.key = false
        resultMsg.msg = '이메일(ID)을 입력해주세요.'
      } else if (!matched) {
        resultMsg.key = false
        resultMsg.msg = '이메일 형식을 맞춰주세요.'
      }
      return resultMsg
    },
    onClickManagerSave() {
      const vm = this
      let solCd = vm.$refs.solCdRef.kendoWidget().value()
      let authCd = solCd == '99' || solCd == '00' ? '99' : solCd

      let param = {
        loginUserToken : sessionStorage.getItem("adminLoginUserToken"),
        authCd, 
        accountStatusCd         : '01', 
        userId                  : vm.$refs.userIdInputRef.kendoWidget().value(),
        passwordTx              : vm.$refs.passwordTxRef.kendoWidget().value(),
        userNmKoTx              : vm.$refs.userNmKoTxRef.kendoWidget().value(),
        userNmEnTx              : vm.$refs.userNmEnTxRef.kendoWidget().value(),
        // birthdateTx             : vm.$refs.birthDateRef.kendoWidget()._oldText.replace(/[/]/g,''),
        postNoTx                : vm.$refs.postNoTxRef.kendoWidget().value(),
        addressInfoTx           : vm.$refs.addressInfoTxRef.kendoWidget().value(),
        addressDetailTx         : vm.$refs.addressDetailTxRef.kendoWidget().value(),
        phoneNoTx               : vm.$refs.phoneNoTxRef.kendoWidget().value(),
        termsAgreeYn            : 'Y',
        personalInfoAgreeYn     : 'Y',
        useYn                   : 'Y',
        seqNo                   : '1',
      }
      
      if (vm.emaliYn == 'N') {
        kendo.alert("이메일 중복을 확인해주세요")
        return;
      }
      if (vm.$refs.solCdRef.kendoWidget().value() === undefined || vm.$refs.solCdRef.kendoWidget().value() === null || vm.$refs.solCdRef.kendoWidget().value()==='' ) {
        kendo.alert("카테고리를 선택해주세요")
        return;
      }
      if (vm.$refs.userNmKoTxRef.kendoWidget().value() === undefined || vm.$refs.userNmKoTxRef.kendoWidget().value() === null || vm.$refs.userNmKoTxRef.kendoWidget().value() === '') {
        kendo.alert("성명(한글)을 입력해주세요")
        return;
      }
      if (vm.$refs.userNmEnTxRef.kendoWidget().value() === undefined || vm.$refs.userNmEnTxRef.kendoWidget().value() === null || vm.$refs.userNmEnTxRef.kendoWidget().value() === '') {
        kendo.alert("성명(영문)을 입력해주세요")
        return;
      }
      if (vm.$refs.userIdInputRef.kendoWidget().value() === undefined || vm.$refs.userIdInputRef.kendoWidget().value() === null || vm.$refs.userIdInputRef.kendoWidget().value() === '') {
        kendo.alert("메일 주소를 입력해주세요")
        return;
      }
      if (vm.$refs.passWordNoRef.kendoWidget().value() === undefined || vm.$refs.passWordNoRef.kendoWidget().value() === null || vm.$refs.passWordNoRef.kendoWidget().value() === '') {
        kendo.alert("비밀번호를 입력해주세요")
        return;
      }
      if (vm.$refs.passwordTxRef.kendoWidget().value() === undefined || vm.$refs.passwordTxRef.kendoWidget().value() === null || vm.$refs.passwordTxRef.kendoWidget().value() === '') {
        kendo.alert("비밀번호 확인을 입력해주세요")
        return;
      }
      if (vm.$refs.phoneNoTxRef.kendoWidget().value() === undefined || vm.$refs.phoneNoTxRef.kendoWidget().value() === null || vm.$refs.phoneNoTxRef.kendoWidget().value() === '') {
        kendo.alert("휴대전화 번호를 입력해주세요")
        return;
      }
      if (vm.$refs.passWordNoRef.kendoWidget().value() !== vm.$refs.passwordTxRef.kendoWidget().value()) {
         kendo.alert("비밀번호를 같게 입력해주세요")
        return;
      }
      
      kendo.confirm('등록 하시겠습니까?').done(function() {
        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/user/inputData.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }

          vm.onClickWindowClose()
          kendo.alert('등록 되었습니다.')
        })
      })
    },
    onClickWindowClose(){
      this.$emit('onClickWindowClose')
    },
    openPostWindow: function (e) {
      this.$refs.daumPostCodeWindowComponent.$refs.daumPostCodeRef.kendoWidget().center().open()
    },
    newPwKeyUp: function(value){
      const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{8,50}$/.test(value)
      if (!matched) {
        //kendo.alert('비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.')
        this.passValidate='inline-block'
        this.passCoincide='none'
        this.passDisCoincide='inline-block'
      }else{
        this.passValidate='none'
      }
    },
  }
}
</script>

<style scoped>
  #overlay{
    top:100;
    position:absolute;
    background-color:black;
    opacity:0.07;
    width:800px;
    height:400px;
  }
  #managerContent{
    top:100;
    position:absolute;

  }
</style>

<template>
  <div>
    <form ref="NewsletterFormRef" v-kendo-validator @submit.prevent="">
      <div class="contents_wrap">
        <!-- <div class="small_title_wrap">
          <h2>뉴스레터 {{ editMode === 'INSERT' ? '등록' : '수정' }}</h2>
        </div> -->
        <ul class="filter_list">
          <li>
            <label>솔루션 코드
              <span class="forg">*</span>
            </label>
            <ul class="w30">
              <li>
                <kendo-dropdownlist ref="categoryCdRef" :disabled="boolReadonly"
                  :data-source="$store.state.codeRequestStore.search.combobox.solCd.data" :data-text-field="'cdNmTx'"
                  v-model="newsletterRowData.solCd" :data-value-field="'cdId'" :option-label="'전체'" :index="0"
                  :style="'width: 100%;'">
                </kendo-dropdownlist>
              </li>
            </ul>
          </li>
          <li>
            <label>카테고리
              <span class="forg">*</span>
            </label>
            <ul class="w30">
              <li>
                <kendo-dropdownlist ref="categoryCdRef" :disabled="boolReadonly"
                  :data-source="$store.state.codeRequestStore.search.combobox.categoryCd.data"
                  :data-text-field="'cdNmTx'" v-model="newsletterRowData.categoryCd" :data-value-field="'cdId'"
                  :option-label="'전체'" :index="0" :style="'width: 100%;'">
                </kendo-dropdownlist>
              </li>
            </ul>
          </li>
          <li>
            <label>제목
              <span class="forg">*</span>
            </label>
            <ul class="w80">
              <li>
                <kendo-maskedtextbox ref="newsletterTitleTxRef" :disabled="boolReadonly"
                  v-model="newsletterRowData.newsletterTitleTx" id="newsletterTitleTx" :placeholder="'제목을 입력하세요.'"
                  :style="'width: 100%;'" maxlength="150">
                </kendo-maskedtextbox>
              </li>
            </ul>
          </li>
          <li>
            <label>설명</label>
            <ul class="w80">
              <li>
                <kendo-maskedtextbox ref="descriptionRef" :disabled="boolReadonly"
                  v-model="newsletterRowData.newsletterDescription" id="newsletterDescription"
                  :placeholder="'설명을 입력하세요.'" :style="'width: 100%;'" maxlength="150">
                </kendo-maskedtextbox>
              </li>
            </ul>
          </li>
          <li>
            <label>키워드</label>
            <ul class="w80">
              <li>
                <kendo-maskedtextbox ref="keywordRef" :disabled="boolReadonly"
                  v-model="newsletterRowData.newsletterKeyword" id="newsletterKeyword" :placeholder="'키워드를 입력하세요.'"
                  :style="'width: 100%;'" maxlength="150">
                </kendo-maskedtextbox>
              </li>
            </ul>
          </li>
          <li v-show="editMode == 'INSERT' || editMode == 'UPDATE'">
            <label>썸네일 이미지
              <span class="forg">*</span>
            </label>
            <ul class="w80 icon_file">
              <li>
                <div class="upload">
                  <file-upload-post v-bind:file="uploads.thumbnail" @child="bindingFiles" :imageName="'THUMBNAIL'"
                    id="existThumbnailFileId">
                  </file-upload-post>
                </div>
              </li>
            </ul>
          </li>
          <li v-show="editMode == 'DETAIL'">
            <label>썸네일 이미지
              <span class="forg">*</span>
            </label>
            <ul class="w80 icon_file">
              <li v-for="file in newsletterThumbnailFileData">
                <div class="btn_file"><span class="k-icon k-i-download"></span>
                  <a v-bind:href="makeFileUrl(file)">{{ file.orgFileNameTx }}</a>
                </div>
              </li>
            </ul>
          </li>
          <li v-if="editMode == 'DETAIL'">
            <label>등록일
              <span class="forg">*</span>
            </label>
            <ul class="w80">
              <li>
                <kendo-maskedtextbox ref="newsletterFrstRgstDtRef" v-model="newsletterRowData.fmtFrstRgstDt"
                  :disabled="boolReadonly" id="newsletterFrstRgstDt" :style="'width: 100%;'" maxlength="50">
                </kendo-maskedtextbox>
              </li>
            </ul>
          </li>
          <li v-show="editMode == 'INSERT' || editMode == 'UPDATE'">
            <label>첨부 파일
            </label>
            <ul class="w80 icon_file">
              <li>
                <div class="upload">
                  <file-upload-post v-bind:file="uploads.attachment" @child="bindingFiles" :imageName="'ATTACHMENT'"
                    id="existAttachmentFileId">
                  </file-upload-post>
                </div>
              </li>
            </ul>
          </li>
          <li v-show="editMode == 'DETAIL'">
            <label>첨부 파일
            </label>
            <ul class="w80 icon_file">
              <li v-for="file in newsletterAttachmentFileData">
                <div class="btn_file"><span class="k-icon k-i-download"></span>
                  <a v-bind:href="makeFileUrl(file)">{{ file.orgFileNameTx }}</a>
                </div>
              </li>
            </ul>
          </li>
          <li v-if="editMode == 'DETAIL'">
            <label>등록자
              <span class="forg">*</span>
            </label>
            <ul class="w80">
              <li>
                <kendo-maskedtextbox ref="newsletterFrstRgstIdRef" v-model="newsletterRowData.lastMdfyId"
                  :disabled="boolReadonly" id="newsletterFrstRgstId" :style="'width: 100%;'" maxlength="50">
                </kendo-maskedtextbox>
              </li>
            </ul>
          </li>
          <li class="lh_none">
            <label>내용
              <span class="forg">*</span>
            </label>
            <ul class="w80" v-show="editMode == 'INSERT' || editMode == 'UPDATE'">
              <div id="overlay" v-bind:style="{'z-index' : index}">
              </div>
              <editor ref="newsletterContentRef" id="newsletterContent" :tools="editor.settings.editorTools"
                :disabled="boolReadonly" :resizable-content="true" :resizable-toolbar="true"
                v-model="newsletterRowData.newsletterContentsTx" style="height:1000px;" rows="10" cols="30"
                validationMessage="내용을 입력해주세요.">
              </editor>
            </ul>
            <ul class="w80" v-show="editMode == 'DETAIL'">
              <li v-html="newsletterRowData.newsletterContentsTx" ref="newsletterContentsTxRef"
                style="line-height:inherit !important"></li>
            </ul>
          </li>
        </ul>
      </div>
    </form>
    <div class="button_area">

      <template v-if="editMode == 'INSERT' || editMode == 'UPDATE'">
        <ul>
          <li class="w40">
            <button class="large_btn" @click="onClickWindowClose">취소</button>
          </li>
          <li class="w40">
            <button class="large_btn orange" @click="onClickNewsletterSave">저장</button>
          </li>
        </ul>
      </template>

      <template v-else-if="editMode == 'DETAIL'">
        <ul>
          <li class="w40">
            <button class="large_btn" @click="onClickNewsletterDelete">삭제</button>
          </li>
          <li class="w40">
            <button class="large_btn orange" @click="onClickNewsletterModify">수정</button>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import EtnersCommonUtil from '@/common/etners.common.util'
import { Editor } from '@progress/kendo-editor-vue-wrapper';
import FileUploadPost from '@/components/FileUploadPost'

export default {
  name: 'payrollNewsletterManageWindow',
  props: ['newsletterRowData', 'editMode', 'newsletterThumbnailFileData', 'newsletterAttachmentFileData'],
  components: { 'editor': Editor, FileUploadPost },
  created() {
    this.$store.dispatch('initSearchDataForCodeRequest')
  },
  data() {
    const vm = this
    return {
      mms:0,
      templateGbnVal: '',
      newsletterInfo:{},
      htmlText: ``,
      boolReadonly : false,
      index :0,
      uploads: {
        thumbnail: [],
        attachment: []
      },
      existThumbnailFileId: '',
      existAttachmentFileId: '',
      editor: {
        settings: {
          editorTools: [
            "viewHtml",
            "fontSize",
            "fontName",
            "foreColor",
            "backColor",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "createLink",
            "tableWizard",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn"
          ]
        }
      },
    }
  },
  watch: {
    editMode: {
      handler: 'initDataHandler'
    },
    newsletterAttachmentFileData: {
      handler: 'initFileDataHandler'
    },
    newsletterThumbnailFileData: {
      handler: 'initFileDataHandler'
    }
  },
  methods: {
    initDataHandler() {
      const vm = this
      vm.boolReadonly = false
      vm.index = 0
      if (this.editMode == 'DETAIL'){
        vm.boolReadonly = true
        vm.index = 2
      }else if (this.editMode == 'INSERT'){
        this.newsletterRowData.newsletterId = ''
        this.newsletterRowData.newsletterTitleTx = ''
        this.newsletterRowData.newsletterContentsTx = ''
        this.newsletterRowData.newsletterDescription = ''
        this.newsletterRowData.newsletterKeyword = ''
        this.$refs.categoryCdRef.kendoWidget().value('')
        this.$refs.newsletterContentRef.kendoWidget().value('')
        vm.uploads.thumbnail = []
        vm.uploads.attachment = []
      }
    },
    initFileDataHandler: function(){
      const vm = this
      const newsletterAttachmentFiles = vm.newsletterAttachmentFileData
      const newsletterThumbnailFiles = vm.newsletterThumbnailFileData

      vm.uploads = {
        attachment: [],
        thumbnail: []
      }
      if (EtnersCommonUtil.isNotEmpty(newsletterAttachmentFiles)) {
        vm.existAttachmentFileId = newsletterAttachmentFiles[0].fileId
        newsletterAttachmentFiles.forEach(function (v) {
          const newsletterAttachmentFileInfo = {
            'name': v.orgFileNameTx,
            'size': v.fileSizeTx,
            'extension': v.fileExtTx,
            'seq': v.fileSeqNo,
            'fileId': v.fileId,
            'uid': v.fileId + v.fileSeqNo,
            'url': `${ApiConfig.ethDomain}/files/${v.fileId}`
          }
          vm.uploads.attachment.push(newsletterAttachmentFileInfo)
        })
      }

      if (EtnersCommonUtil.isNotEmpty(newsletterThumbnailFiles)) {
        vm.existThumbnailFileId = newsletterThumbnailFiles[0].fileId
        newsletterThumbnailFiles.forEach(function (v) {
          const newsletterThumbnailFileInfo = {
            'name': v.orgFileNameTx,
            'size': v.fileSizeTx,
            'extension': v.fileExtTx,
            'seq': v.fileSeqNo,
            'fileId': v.fileId,
            'uid': v.fileId + v.fileSeqNo,
            'url': `${ApiConfig.ethDomain}/files/${v.fileId}`
          }
          vm.uploads.thumbnail.push(newsletterThumbnailFileInfo)
        })
      }
     
    },
    removeFiles () {
      const vm = this
      let idx = 0
      vm.uploads.forEach(upload => {
        upload.seq = idx
        idx++
      })
    },
    bindingFiles(files) {
      const vm = this
      files.forEach(function (fileInfo) {
        switch (fileInfo.imageName) {
          case 'THUMBNAIL':
            vm.uploads.thumbnail.push(fileInfo)
            break
          case 'ATTACHMENT':
            vm.uploads.attachment.push(fileInfo)
            break
        }
      })
    },
    onClickNewsletterSave() {
      const vm = this
      const userName = JSON.parse(sessionStorage.getItem('adminLoginUserData')).userNmKoTx

      let confirmKeyword = (vm.editMode === 'INSERT' ? '등록' : '수정')      
      let newsletterTitleTx = this.$refs.newsletterTitleTxRef.kendoWidget().value()
      let newsletterContentsTx = vm.newsletterRowData.newsletterContentsTx
      let newsletterId = vm.newsletterRowData.newsletterId
      let categoryCd = vm.newsletterRowData.categoryCd
      let solCd = vm.newsletterRowData.solCd
      let newsletterDescription = vm.newsletterRowData.newsletterDescription
      let newsletterKeyword = vm.newsletterRowData.newsletterKeyword

      let param = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        newsletterId,
        newsletterTitleTx,
        newsletterContentsTx,
        newsletterKeyword,
        newsletterDescription,
        categoryCd,
        solCd,
        frstRgstId: userName,
        lastMdfyId: userName,
        uploadedFiles: {}
      }
      if (EtnersCommonUtil.isEmpty(solCd)) {
        kendo.alert('솔루션코드를 입력해주세요.')
        return false
      }
      if (EtnersCommonUtil.isEmpty(newsletterTitleTx)){
        kendo.alert('제목을 입력해주세요.')
        return false
      }
      if (vm.uploads.length == 0) {
        kendo.alert("썸네일 파일을 선택해주세요.")
        return
      }
      if (vm.uploads.length > 1) {
        kendo.alert("첨부파일은 하나만 업로드 가능합니다.")
        return
      }
      if (vm.uploads.attachment.length > 1) {
        kendo.alert("첨부파일은 하나만 업로드 가능합니다.")
        return
      }
      

      if (this.validateNewsletter()) {
        kendo.confirm(confirmKeyword + '하시겠습니까?').done(function() {
          let filesParam = {
            loginUserToken: sessionStorage.adminLoginUserToken,
            wmGbn: '50', //뉴스레터
            existThumbnailFileId: vm.existThumbnailFileId,
            existThumbnailFileSeqs: [],
            existAttachmentFileId: vm.existAttachmentFileId,
            existAttachmentFileSeqs: [],
            fileNames: []
          }

          const formData = new FormData()
          vm.uploads.thumbnail.forEach(function (v) {
            formData.append("thumbnail", v.rawFile)
            if (v.seq !== undefined) {
              filesParam.existThumbnailFileSeqs.push(v.seq)
              filesParam.existThumbnailFileId = v.fileId
            }
          })

          vm.uploads.attachment.forEach(function (v) {
            formData.append("attachment", v.rawFile)
            if (v.seq !== undefined) {
              filesParam.existAttachmentFileSeqs.push(v.seq)
              filesParam.existAttachmentFileId = v.fileId
            }
          })
           
          formData.forEach((value, key) => filesParam.fileNames.push(key))

          formData.append("uploadAuthParam", new Blob([JSON.stringify(filesParam)], {
            type: "application/json"
          }))
          vm.$Progress.start()
           ApiUtil.postIncludeUploadFile(`${ApiConfig.ethDomain}/v1/eth/newsletter/saveNewsletterFileUpload.json`, formData).then(response => {
            if (response.data.resultStatus.messageCode != "2000") {
              kendo.alert(`${response.data.resultStatus.messageText}`)
              vm.$Progress.fail()
              return
            }
            param.uploadedFiles = response.data.resultData.uploadedFileMap
            vm.editNewsletter(param)
          })

        })
      }
    },
    editNewsletter(param) {
      const vm =this

      if (vm.editMode === 'INSERT') {
        vm.$Progress.start()

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/newsletter/addNewsletter.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            vm.$Progress.fail()
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            vm.$Progress.fail()
            return false
          }

          vm.$Progress.finish()
          vm.onClickWindowClose()
          kendo.alert('등록 되었습니다.')
          vm.initDataHandler()
        })
      } else if (vm.editMode === 'UPDATE') {
        vm.$Progress.start()

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/newsletter/modifyNewsletter.json`, param).then(function(response) {
          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            vm.$Progress.fail()
            return false
          }

          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            vm.$Progress.fail()
            return false
          }

          vm.$Progress.finish()
          vm.onClickWindowClose()
          kendo.alert('수정 되었습니다.')
        })
      }
    },
    onClickNewsletterModify() {
      this.$emit("editModeUpdate" , 'UPDATE')
    },
    validateNewsletter() {
      const vm = this

      let newsletterTitleTx = this.$refs.newsletterTitleTxRef.kendoWidget().value()
      let newsletterContentsTx = vm.newsletterRowData.newsletterContentsTx

      if (EtnersCommonUtil.isEmpty(newsletterTitleTx)){
        kendo.alert('제목을 입력해주세요.')
        return false
      }

      return true
    },
    onClickWindowClose(){
      this.$emit('onClickWindowClose')
    },
    onClickNewsletterDelete(){
      const vm = this

      let newsletterId = vm.newsletterRowData.newsletterId

        kendo.confirm('삭제 하시겠습니까?').done(function() {
          let param = {
            loginUserToken: sessionStorage.adminLoginUserToken,
            newsletterId
          }

          vm.$Progress.start()
          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/newsletter/deleteNewsletter.json`, param).then(function(response) {
            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
              vm.$Progress.fail()
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              vm.$Progress.fail()
              return false
            }
            vm.$Progress.finish()

            kendo.alert('삭제 되었습니다.')

            vm.onClickWindowClose()

          })
        })
    },
    makeFileUrl: function(file) {
      return `${ApiConfig.ethDomain}/download/${file.fileId}/${file.fileSeqNo}`
    }
  }
}
</script>

<style scoped>
  /* #overlay{
    top:100;
    position:absolute;
    background-color:black;
    opacity:0.07;
    width:800px;
    height:400px;
  } */
  #newsletterContent{
    top:100px;
    position:absolute;

  }
</style>

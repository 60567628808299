<template>
  <div id="container">
    <div class="title_wrap">
      <h1>보도자료</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">
            <li>
              <dd>
                <label>보도자료 제목</label>
                <ul class="w60">
                  <kendo-maskedtextbox ref="reportTitleTxRef" id="reportTitleTx" :style="'width: 100%;'"
                    :value="lastRouteParams.filter.searchReportTitleTx">
                  </kendo-maskedtextbox>
                </ul>
              </dd>
              <dd>
                <label>작성일</label>
                <ul class="w60">
                  <kendo-daterangepicker ref="lastMdfyDtRef" :class="'w60 fl mr5'" :format="'yyyy/MM/dd'"
                    :range="rgstDtRange" @change="commonOnChangeEvent">
                  </kendo-daterangepicker>

                </ul>
              </dd>
            </li>
            <li>
              <dd>
                <label>작성자</label>
                <ul class="w60">
                  <kendo-maskedtextbox ref="lastMdfyIdRef" id="lastMdfyId" :style="'width: 100%;'"
                    :value="lastRouteParams.filter.searchUserNmKoTx">
                  </kendo-maskedtextbox>
                </ul>
              </dd>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div class="contents_top_sec">
          <button class="mid_btn orange fl" @click="onClickCreateNewReport">
            <span class="k-icon k-i-plus"></span> 보도자료 등록
          </button>
        </div>
        <kendo-grid ref="reportListGridRef" style="cursor: pointer;" :data-source="computedReportListDataSource"
          :pageable-always-visible="true" :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]" :reorderable="true"
          :column-menu="false" :resizable="true" :selectable="true" :sortable="true" :columns="reportGridRefColumns"
          :dataBound="dataBoundForReport" @change="onChangeReportListGrid"
          :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }">
        </kendo-grid>

        <kendo-window :title="editMode == 'INSERT' ? '보도자료 등록' : '보도자료 수정'" ref="reportRef" :modal="true"
          :visible="false" :width="'1000px'" :height="'85%'" style="box-sizing:border-box;">
          <report-manage-window ref="reportWindowRef" :reportRowData="reportRowData" :reportFileData="reportFileData"
            :editMode='editMode' @editModeUpdate="editModeUpdate" @onClickWindowClose="onClickWindowClose">
          </report-manage-window>
        </kendo-window>
      </div>
    </section>
  </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'
  //import { saveExcel } from '@progress/kendo-vue-excel-export'
  import ReportManageWindow from '@/components/ReportManageWindow'

  import $ from 'jquery'

  export default {
    name: 'ReportManagement',
    components:{
      ReportManageWindow
    },
    beforeMount() {
      if (JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd != '99') this.$router.push({ name: 'HomeQnaManagement' })
    },
    created () {
      this.$store.dispatch('initSearchDataForCodeRequest')

      let defaultLastRouteParams = {
        page: 1,
        filter: {
          searchRgstNm: ''
        },
      }

      let jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')
      this.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'reportDetail' ? (jsonStrLastRouteParams === undefined
          ? defaultLastRouteParams
          : JSON.parse(jsonStrLastRouteParams)) : defaultLastRouteParams

      if (EtnersCommonUtil.isNotEmpty(this.lastRouteParams)) {
        this.lastRouteParams.page = this.lastRouteParams.filter.page
      }

    },
    mounted () {
      const vm = this

      //엔터키 이벤트 자동 세팅
      const enterkeyEventTargetIdArray = [
         'reportTitleTx','lastMdfyId'
      ]

      for (let elementId of enterkeyEventTargetIdArray) {
        let element = document.getElementById(elementId)
        if (element !== undefined) {
          element.onkeyup = function (e) {
            const ENTER_KEY_CODE = 13
            let currentKeyCode = window.event.keyCode || e.which
            if (currentKeyCode === ENTER_KEY_CODE) {
              vm.commonOnChangeEvent(e)
            }
          }
        }
      }
      this.$refs.lastMdfyDtRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      });
      this.$refs.lastMdfyDtRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      });

    },
    computed: {
      computedReportListDataSource: function () {
        const vm = this

        let lastRequestParameterPage = vm.lastRouteParams.page !== undefined ? vm.lastRouteParams.page : 1

        const reportListDataSource = new kendo.data.DataSource({
          name: 'reportListDataSource',
          page: lastRequestParameterPage,
          pageSize: 10,
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
          transport: {
            read: {
              url: `${ApiConfig.ethDomain}/v1/eth/post/reportAdminList.json`,
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              cache: false,
            },
            parameterMap: function (options, operation) {
              if (operation === 'read') {
                const filter = vm.currentPageFilterOption()
                let loginUserToken = sessionStorage.adminLoginUserToken

                vm.replaceSortFieldStr(options)

                let params = {
                  loginUserToken: loginUserToken,
                  searchLastMdfyId: filter.lastMdfyId,
                  searchReportTitleTx: filter.reportTitleTx,
                  searchLastMdfyDtStartYYYYMMDD : filter.searchLastMdfyDtStartYYYYMMDD,
                  searchLastMdfyDtEndYYYYMMDD : filter.searchLastMdfyDtEndYYYYMMDD,
                  skip: options.skip,
                  take: options.take,
                  page: options.page,
                  pageSize: options.pageSize,
                  sort: options.sort,
                  requestType: 'PAGING', //PAGING or EXPORT
                }

                sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

                return kendo.stringify(params)
              }

              if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) }
              }
            },
          },
          schema: {
            type: 'json',
            parse: function (response) {

                let changeResultData = response.resultData
                let rowNumber = response.total + 1 - (vm.$refs.reportListGridRef.dataSource.page() - 1)
                    * vm.$refs.reportListGridRef.dataSource.pageSize()
                if(changeResultData!=null) {

                  for (let i = 0; i < changeResultData.length; i++) {
                    let row = changeResultData[i]
                    let reportId = changeResultData[i].reportId
                    row.reportId = reportId
                    row.rowNumber = --rowNumber;
                }
                response.resultData = changeResultData
              }
              return response
            },
            total: function (response) {
              return response.total
            },
            data: function (response) {
              if (response.resultStatus.messageCode === '2000') {
                return response.resultData
              } else if (response.resultStatus.messageCode === '4204') {
                return next("/homeadmin/login")
              } else {
                kendo.alert('message: ' + response.resultStatus.messageText)
                return ''
              }
            },
          },

          error: function (e) {
            console.log('error event handler', e.errors[0])
          },
        })

        return reportListDataSource
      },
    },
    methods: {
      commonOnChangeEvent: function () {
        const vm = this
        let gridRef = vm.$refs.reportListGridRef

        // 마지막 검색 데이터 page 초기화
        this.lastRouteParams.page = 1

        if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
          if (gridRef.kendoWidget().dataSource !== undefined) {
            gridRef.kendoWidget().dataSource.page(1)
          }
        }
      },
      currentPageFilterOption: function () {
        const vm = this
        const refs = vm.$refs

        let currentSearchDataObj = {}

        const filterData = vm.lastRouteParams.filter

        //제목
        if (refs.reportTitleTxRef !== undefined) {
          const searchReportTitleTx = refs.reportTitleTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchReportTitleTx)) {
            currentSearchDataObj.reportTitleTx = searchReportTitleTx
          }
        }

        //등록자
        if (refs.lastMdfyIdRef !== undefined) {
          const searchLastMdfyId = refs.lastMdfyIdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchLastMdfyId)) {
            currentSearchDataObj.lastMdfyId = searchLastMdfyId
          }
        }

        // 요청일자
      if (refs.lastMdfyDtRef !== undefined) {
        const rgstDtRangeValue = refs.lastMdfyDtRef.kendoWidget().range()

        if (EtnersCommonUtil.isNotEmpty(rgstDtRangeValue)) {
          const startYYYYMMDD = (rgstDtRangeValue.start !== undefined && rgstDtRangeValue.start !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(rgstDtRangeValue.start)
                              : undefined
          const endYYYYMMDD = (rgstDtRangeValue.end !== undefined && rgstDtRangeValue.end !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(rgstDtRangeValue.end)
                              : undefined

          currentSearchDataObj.searchLastMdfyDtStartYYYYMMDD = startYYYYMMDD
          currentSearchDataObj.searchLastMdfyDtEndYYYYMMDD = endYYYYMMDD
        }
      }
        vm.filterData = currentSearchDataObj

        return currentSearchDataObj
      },
      onClickCreateNewReport: function (e) {
        const vm = this
        vm.editMode = 'INSERT'
        vm.reportRowData.reportId = ''
        vm.reportRowData.tmpltTypeCd = ''
        vm.reportRowData.reportTitleTx = ''
        vm.reportRowData.reportContentsTx = ''
        vm.reportRowData.reportContentsTitleTx = ''
        vm.uploads = ''
        vm.reportFileData = ''
        let reportManageWindow = this.$refs.reportRef.kendoWidget()
        reportManageWindow.center().open()

      },
      dataBoundForReport: function (evt) {
         let vm = this
         let isDragging = false;

         $(".k-grid td")
         .mousedown(function() {
            $(window).mousemove(function() {
                isDragging = true;
                $(window).unbind("mousemove");
            });
         })
         .mouseup(function() {
            let wasDragging = isDragging;
            isDragging = false;
            $(window).unbind("mousemove");
            if (!wasDragging) {
              let row = evt.sender.selectable.userEvents.currentTarget
              let selectedRow = evt.sender.select();
            }
         });
     },
      //row 클릭 이벤트
      onChangeReportListGrid: function (e) {
        const vm = this

        let selectedRow = e.sender.select();
        const rowData = e.sender.dataItem(selectedRow);
        vm.editMode = 'DETAIL'

        let param = {
          loginUserToken: sessionStorage.adminLoginUserToken,
          reportId: rowData.reportId,
        }

        ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/post/reportDetail.json`, param).then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.reportRowData = response.data.resultData
              vm.reportFileData = response.data.resultData.reportFiles
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
        )

        let reportManageWindow = this.$refs.reportRef.kendoWidget()
        reportManageWindow.center().open()
      },
      // 초기화 버튼 클릭 이벤트
      cleanSearchDataEvent (e) {
        const refs = this.$refs

        //템플릿구분
        if (refs.reportGbnSrhRef !== undefined) {
          refs.reportGbnSrhRef.kendoWidget().select(0)
        }

        if (refs.lastMdfyDtRef !== undefined) {
          refs.lastMdfyDtRef.kendoWidget().range({start: undefined, end: undefined})
        }

        if (refs.reportTitleTxRef !== undefined) {
          refs.reportTitleTxRef.kendoWidget().value('')
        }

        if (refs.lastMdfyIdRef !== undefined) {
          refs.lastMdfyIdRef.kendoWidget().value('')
        }

        sessionStorage.removeItem('LAST_ROUTE_PARAMS')

        this.commonOnChangeEvent(e)
      },
      replaceSortFieldStr (options) {
        if (options.sort != undefined && options.sort.length != 0){
          let sortField = options.sort[0].field
          let replaceField

          switch (sortField) {
            case "rowNumber":
              replaceField = "seqNo"
              break;
            case "lastMdfyDtStr":
              replaceField = "lastMdfyDt"
              break;
            default:
              replaceField = sortField
          }
          options.sort[0].field = replaceField
        }
      },
      onClickWindowClose() {
        this.$refs.reportRef.kendoWidget().close()
        this.$refs.reportListGridRef.kendoWidget().dataSource.read()
      },
      editModeUpdate(editMode) {
        const vm =this
        vm.editMode = editMode
      }
    },
    data: function () {
      const kendoGridHeaderAttributes = {
        style: 'font-weight: bold;',
      }

      const vm = this

      return {
        rowNumber: 0,
        reportRowData: {},
        reportFileData: {},
        editMode: '',
        val: '',
        rgstDtRange: {start: undefined, end: undefined},
        messages: {
          year: '년',
          month: '월',
          day: '일',
        },
        filterData: {},
        lastRouteParams: {
          page: 1,
          filter: {
            searchRgstNm: '',
            searchTemplateGbn: '',
            searchTemplateNm: ''
          },
        },
        reportGridRefColumns: [
          {
            field: 'rowNumber',
            title: 'No.',
            width: '8%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'reportTitleTx',
            title: '보도자료 제목',
            width: '50%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tleft',
            },
          },
          {
            field: 'fmtLastMdfyDt',
            title: '작성일',
            width: '15%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'lastMdfyId',
            title: '작성자',
            width: '15%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
        ],
      }
    },
  }
</script>

import EtnersCommonUtil from './etners.common.util'

/**
 * 순수하게 자바스크립트로 된
 * 날짜/시간 관련 유틸 함수 모듈
 */

const lookupDateByTodayMidnight = () => {
  let dt = new Date()

  let yyyy = dt.getFullYear()
  let mm = dt.getMonth()
  let dd = dt.getDate()

  return new Date(yyyy, mm, dd, 0, 0, 0)
}

const lookupDateTime = () => {
  let dt = new Date()

  let yyyy = dt.getFullYear()
  let mm = dt.getMonth()
  let dd = dt.getDate()
  let hh = dt.getHours()
  let minutes = dt.getMinutes()
  return new Date(yyyy, mm, dd, hh, minutes)
}

const TODAY_DATETIME = lookupDateByTodayMidnight()

const lookupDateYYYYMMDD = (yyyymmdd) => {
  if (yyyymmdd == undefined) {
    return lookupDateByTodayMidnight()
  }

  if (EtnersCommonUtil.getMatchType(yyyymmdd) != 'STRING') {
    throw new Exception('잘못된 값입니다')
  }

  let yyyy = Number(yyyymmdd.substring(0, 4))
  let mm = Number(yyyymmdd.substring(4, 6)) - 1
  let dd = Number(yyyymmdd.substring(6, 8))

  return new Date(yyyy, mm, dd, 0, 0, 0)
}

const lookupDateTimeHHMM = (hhmi) => {
  if (EtnersCommonUtil.getMatchType(hhmi) != 'STRING') {
    throw new Exception('잘못된 값입니다')
  }

  let yyyy = TODAY_DATETIME.getFullYear()
  let mm = TODAY_DATETIME.getMonth()
  let dd = TODAY_DATETIME.getDate()

  let hh = hhmi.substring(0, 2)
  let mi = hhmi.substring(2, 4)

  return new Date(yyyy, mm, dd, hh, mi, 0)
}

const lookupDateTimeYYYYMMDDHHMM = (yyyymmdd, hhmi) => {
  if (EtnersCommonUtil.getMatchType(yyyymmdd) != 'STRING') {
    throw new Exception('잘못된 값입니다')
  }

  if (EtnersCommonUtil.getMatchType(hhmi) != 'STRING') {
    throw new Exception('잘못된 값입니다')
  }

  let yyyy = Number(yyyymmdd.substring(0, 4))
  let mm = Number(yyyymmdd.substring(4, 6)) - 1
  let dd = Number(yyyymmdd.substring(6, 8))

  let hh = hhmi.substring(0, 2)
  let mi = hhmi.substring(2, 4)

  return new Date(yyyy, mm, dd, hh, mi, 0)
}

const isEmpty = (arg) => {
  if (arg === undefined || arg === null || arg === 'null') {
    return true
  }

  let type = getMatchType(arg)

  let flag = false

  switch (type) {
    case 'NUMBER' :
      flag = false
      break
    case 'ARRAY' :
      flag = arg.length == 0
      break
    case 'STRING' :
      try {
        flag = arg.trim().length == 0
      } catch (e) {
        String.prototype.trim = function () {
          return this.replace(/^\s+|\s+$/g, '')
        }

        flag = arg.trim().length == 0
      }
      break
    case 'OBJECT' :
      try {
        flag = Object.keys(arg).length == 0
      } catch (e) {
        let count = 0

        for (let key in arg) {
          count++
        }

        flag = count == 0
      }
      break
  }

  return flag
}

const isNotEmpty = (arg) => {
  return !isEmpty(arg)
}

const dateToYYYYMMDD = function (date) {
  return date.getFullYear() + convertTwoDigit(date.getMonth() + 1) + convertTwoDigit(date.getDate())
}

const dateToYYYYMM = function (date) {
  return date.getFullYear() + convertTwoDigit(date.getMonth() + 1)
}

const dateToYYYYMMDDWithPoint = function(date, separator) {
  if(separator == undefined) {
    separator = "."
  }

  return `${date.getFullYear()}${separator}${convertTwoDigit(date.getMonth() + 1)}${separator}${convertTwoDigit(date.getDate())}`
}

const dateToHHMISS = function(date, separator) {
  if(separator == undefined) {
    separator = ""
  }

  return `${convertTwoDigit(date.getHours())}${separator}${convertTwoDigit(date.getMinutes())}${separator}${convertTwoDigit(date.getSeconds())}`
}

const dateToHHMI = function(date, separator) {
  if(separator == undefined) {
    separator = ""
  }

  return convertTwoDigit(date.getHours()) + separator +  convertTwoDigit(date.getMinutes())
}

const getDayOfWeek = function(date) {
  const week = ['일', '월', '화', '수', '목', '금', '토']

  return week[date.getDay()]
}

const nowYYYYMM = function() {
  const date = new Date()

  return date.getFullYear() +  convertTwoDigit(date.getMonth() + 1)
}

const nowYYYYMMDD = function() {
  const date = new Date()

  return date.getFullYear() + '/' +  convertTwoDigit(date.getMonth() + 1) + '/' + convertTwoDigit(date.getDate())
}

const nowYYYYMMDDHHMM = function() {
  const date = new Date()
  const YYYYMMDD = nowYYYYMMDD()
  let HHMM =   date.getHours() + ''+ date.getMinutes()

  return lookupDateTimeYYYYMMDDHHMM(YYYYMMDD,HHMM)
}

const dateToYYMM = function (date) {
  return convertTwoDigit(date.getMonth() + 1) + '/' + convertTwoDigit(date.getDate())
}


const convertTwoDigit = function(number) {
  if(number < 10) {
    return `0${number}`
  } else {
    return `${number}`
  }
}

export default {
  lookupDateYYYYMMDD,
  lookupDateTimeHHMM,
  lookupDateTimeYYYYMMDDHHMM,
  isEmpty,
  isNotEmpty,
  lookupDateByTodayMidnight,
  lookupDateTime,
  dateToYYYYMMDD,
  dateToYYYYMM,
  dateToYYYYMMDDWithPoint,
  dateToHHMISS,
  dateToHHMI,
  getDayOfWeek,
  nowYYYYMM,
  nowYYYYMMDD,
  nowYYYYMMDDHHMM,
  convertTwoDigit,
  dateToYYMM
}

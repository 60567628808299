<template>
  <div id="container_home">
    <div class="company_visual">
      <!-- 스크롤 효과 -->
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <!-- END 스크롤 효과 -->
      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          GREETINGS
        </h2>
        <h2 class="company_tit animated d-2s">
          혁신적인 사고와 과감한 도전으로 새로운 경영지원플랫폼을 만들어 갑니다.
        </h2>
      </div>
      <div class="company_img animated">
        <img src="@/assets/img/company_02.png" class="animated d-1s" alt="">
      </div>
    </div>
    <div class="company_inner">
      <ul class="ceo_list">
        <li class="right animated">

          <div class="txt">
            <div class="tit animated d-1s">이트너스 홈페이지를 방문해 주신 여러분을 진심으로 환영합니다.</div>
            <div class="sub animated d-2s">
              이트너스(ETNERS)는 '영원한, 불멸의'라는 뜻의 'eternal'과 '동반자'라는 의미를 가진 'partners'의 합성어입니다.
사명에서 알 수 있듯이, 이트너스는 1998년 창립 이후 신용과 신뢰를 바탕으로
클라이언트의 든든한 파트너가 되겠다는 의지 아래 수많은 기업에 경영지원 솔루션을 제공해왔습니다.
<br><br>
경영에 필요한 인사, 총무 업무를 통합하는 쉐어드서비스를 시작으로
기업 복지, 주재원 및 외국인 지원, 교육 유통, 공유 오피스 등 사업을 확장하며
클라이언트가 핵심 업무에 집중할 수 있도록 기업 경쟁력 향상에 이바지하고 있습니다.
<br><br>
이트너스는 지난 기간 급변하는 경영환경 속에서 성장의 기회를 잡기 위해 끊임 없이 도전하고 있습니다.
경영지원 분야의 일하는 방식을 혁신하고, 특허 출원을 통한 기술력 확보와 맞춤형 솔루션 개발 등을 통해
국내 최고의 경영지원플랫폼 전문기업으로서 괄목할 만한 도약을 이뤄냈습니다.
<br><br>
앞으로도 임직원들의 집단지성과 끈끈한 팀워크로
디지털 전환(Digital Transformation)을 선도하는 대한민국 최고의 경영지원플랫폼 전문기업을 넘어
세계가 주목하는 글로벌 비즈니스 모델을 구축하고자 합니다.
<br><br>더 많은 클라이언트에게 감동을 선사하는 새로운 가치를 만들어가겠습니다.
								<p class="align-r">이트너스 대표<br><img src="@/assets/img/ceosign.png" class="animated d-3s" alt=""><br></p>
                    <div class="clearfix"></div>
							</div>

          </div>
          <div class="img"><img src="@/assets/img/ceo_img.png" class="animated d-1s" alt=""></div>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>
<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  const ethDomain = ApiConfig.ethDomain

  export default {
    name: 'CeoMessage',
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
    },
    data: function () {
      const vm = this
      return {

      }
    }
  }
</script>

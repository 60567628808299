<template>
  <main id="main">
    <!-- //header-->
    <div id="container_home">
      <div class="company_inner">
        <submit-question-window ref="submitQuestionWindowRef" :style="popupCss" :submitData="submitData"
          :popType="popType" @onClickWindowClose="onClickWindowClose">
        </submit-question-window>
        <!--//////////POP-UP START//////////-->
        <div class="pop_dim" :style="popupBaseCss">
          <div class="pop_area" style="height: auto;">
            <a class="float-r" href="/main/dashboard"><img src="@/assets/img/close_btn.png"></a>
            <div class="pop_tit">소개서 다운로드</div>
            <div class="pop_tit_sub">
              아래 정보를 입력하신 후 소개서 다운로드가 가능합니다.
            </div>
            <div style="padding: 0 20px;">
              <div>
                <div class="fl_input">
                  <label>회사명 <span class="dh_orange">*</span></label>
                  <kendo-maskedtextbox ref="companyNmTxRef" maxlength="14" name="companyNmTx"
                    :style="'width: 100%;'"></kendo-maskedtextbox>
                </div>
                <div class="fl_input">
                  <label>연락처<span class="dh_orange">*</span></label>
                  <kendo-maskedtextbox ref="phoneNoTxRef" maxlength="30" name="phoneNoTx" id="phoneNoTx"
                    :placeholder="'01012341234'" :style="'width: 100%;'"></kendo-maskedtextbox>
                </div>
                <div class="fl_input">
                  <label>이메일 <span class="dh_orange">*</span></label>
                  <kendo-maskedtextbox ref="emailTxRef" maxlength="33" name="emailTx" id="emailTx"
                    :placeholder="'ex@etners.com'" :style="'width: 100%;'"></kendo-maskedtextbox>
                </div>
                <div class="fl_input">
                  <label>성명 <span class="dh_orange">*</span></label>
                  <kendo-maskedtextbox ref="managerNmTxRef" maxlength="14" name="managerNmTx"
                    :style="'width: 100%;'"></kendo-maskedtextbox>
                </div>
                <div v-show="serviceType !== '02'">
                  <div>
                    <input type="checkbox" v-model="vModel.agree_pledge" id="agree_pledge" class="k-checkbox"
                      style="display: none;">
                    <label for="agree_pledge" class="k-checkbox-label">ㆍ[필수] 개인정보 수집 및 이용에 동의합니다.
                      <span class="dh_orange" v-show="isInformationIframeVisible"
                        @click="hideIframe('information', $event)">[닫기]</span>
                      <span class="dh_orange" v-show="!isInformationIframeVisible"
                        @click="showIframe('information', $event)">[열기]</span>
                    </label>
                    <iframe v-show="isInformationIframeVisible" :src="information" width="800px" height="100px"
                      frameborder="0" allowfullscreen style=" width:100%;" >
                    </iframe>
                  </div>
                  <div>
                    <input type="checkbox" v-model="vModel.agree_marketing" id="agree_marketing" class="k-checkbox"
                      style="display: none;">
                    <label for="agree_marketing" class="k-checkbox-label">ㆍ[선택] 마케팅 활용에 동의합니다.
                      <span class="dh_orange" v-show="isMarketingIframeVisible"
                        @click="hideIframe('marketing', $event)">[닫기]</span>
                      <span class="dh_orange" v-show="!isMarketingIframeVisible"
                        @click="showIframe('marketing', $event)">[열기]</span>
                    </label>
                    <iframe v-show="isMarketingIframeVisible" :src="marketing" height="100px" width="840px"
                      frameborder="0" allowfullscreen style=" width:100%; " >
                    </iframe>
                  </div>
                </div>
                <div class="pop_btn">
                  <button @click="fnSubmit">저장</button>
                </div>
              </div>
            </div>

          </div>

        </div>
        <!--//////////POP-UP END//////////-->
      </div>

    </div>
  </main>
</template>
<script>
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import EtnersCommonUtil from '@/common/etners.common.util'
import SubmitQuestionWindow from '@/components/SubmitQuestionWindow'
import $ from 'jquery'


export default {
  name: 'ProposalDownloadWindow',
  components: {
    SubmitQuestionWindow
  },

  created() {
  },
  mounted() {
    const vm = this

    vm.params = this.$route.params

    let elementPhone = document.getElementById('phoneNoTx')
    elementPhone.onkeyup = function () {
      vm.setMatchedPhone(elementPhone.value)
    }
    elementPhone.onkeydown = function () {
      vm.setMatchedPhone(elementPhone.value)
    }
  },
  computed: {

  },
  methods: {
    hideIframe(type, event) {
      event.preventDefault();
      event.stopPropagation();
      if (type == 'information') {
        this.isInformationIframeVisible = false;
      } else if (type == 'marketing') {
        this.isMarketingIframeVisible = false;
      }
    },
    showIframe(type, event) {
      event.preventDefault();
      event.stopPropagation();
      if (type == 'information') {
        this.isInformationIframeVisible = true;
      } else if (type == 'marketing') {
        this.isMarketingIframeVisible = true;
      }
    },
    onClickWindowClose() {
      this.$router.push({
        name: 'UserDashboard'
      })
    },
    setMatchedPhone: function (value) {
      const matchedPhone = /[0-9]$/.test(value)

      if (!matchedPhone) {
        this.$refs.phoneNoTxRef.kendoWidget().value('')
        return false
      } else {
        this.$refs.phoneNoTxRef.kendoWidget().value(value)
        return true
      }

    },
    getDefaultRules: function () {
      const vm = this

      const resultMsg = { key: true, msg: '' }
      const companyNmTx = this.$refs.companyNmTxRef.kendoWidget().value()
      const managerNmTx = this.$refs.managerNmTxRef.kendoWidget().value()
      const phoneNoTx = this.$refs.phoneNoTxRef.kendoWidget().value()


      if (EtnersCommonUtil.isEmpty(companyNmTx) || EtnersCommonUtil.isEmpty(companyNmTx)) {
        resultMsg.key = false
        resultMsg.msg = '회사명을 입력해주세요.'
      } else if (EtnersCommonUtil.isEmpty(managerNmTx) || EtnersCommonUtil.isEmpty(managerNmTx)) {
        resultMsg.key = false
        resultMsg.msg = '성명을 입력해주세요.'
      } else if (EtnersCommonUtil.isEmpty(phoneNoTx) || EtnersCommonUtil.isEmpty(phoneNoTx)) {
        resultMsg.key = false
        resultMsg.msg = '연락처를 입력해주세요.'
      }

      return resultMsg

    },
    getEmailRules: function () {
      const resultMsg = { key: true, msg: '' }
      const emailId = this.$refs.emailTxRef.kendoWidget().value()
      const matched = /^([\w-\.]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i.test(emailId)

      if (EtnersCommonUtil.isEmpty(emailId)) {
        resultMsg.key = false
        resultMsg.msg = '이메일을 입력해주세요.'
      } else if (!matched) {
        resultMsg.key = false
        resultMsg.msg = '이메일 형식을 맞춰주세요.'
      }
      return resultMsg
    },
    fnSubmit: function () {
      const vm = this

      const vModel = vm.vModel
      const defaultResultMsg = this.getDefaultRules()
      const emailRules = this.getEmailRules()

      if (defaultResultMsg.key) {
        if (emailRules.key) {
          if (!vModel.agree_pledge) {
            kendo.alert('개인정보 활용 내용에 동의해주세요. ')
            return false
          }

          kendo.confirm("등록 하시겠습니까? ").done(function () {
            let params = {
              solCd: '00', // 홈페이지문의
              companyNmTx: vm.$refs.companyNmTxRef.kendoWidget().value(),
              managerNmTx: vm.$refs.managerNmTxRef.kendoWidget().value(),
              emailTx: vm.$refs.emailTxRef.kendoWidget().value(),
              phoneNoTx: vm.$refs.phoneNoTxRef.kendoWidget().value(),
              personalInfoAgreeYn: vm.vModel.agree_pledge ? 'Y' : 'N',
              marketingAgreeYn: vm.vModel.agree_marketing ? 'Y' : 'N',
            }

            vm.submitData = params
            vm.popupBaseCss = 'display:none;'
            vm.popupCss = 'display:block;'
          })

        } else {
          kendo.alert(emailRules.msg)
          return false
        }
      } else {
        kendo.alert(defaultResultMsg.msg)
        return false
      }

    }
  },
  data: function () {
    const vm = this
    return {
      isMarketingIframeVisible: false,
      isInformationIframeVisible: false,
      information: 'https://etners.com/terms/management/detail/00/05',
      marketing: 'https://etners.com/terms/management/detail/00/06',
      params: {},
      vModel: {
        agree_pledge: null,
        agree_marketing: null
      },
      submitData: {},
      popType: 'down',
      popupBaseCss: 'display:block;',
      popupCss: 'display:none;',

    }
  }
}
</script>

<template>
  <div>
    <h1 class="login_small_title_wrap">
      <img src="@/assets/img/logo.png" style="">
      <h2 class="mgt10">
        이트너스 홈페이지(관리자)
      </h2>
    </h1>
    <form method="post" v-kendo-validator @submit.prevent="onSubmit(userId, userPwd)" class="k-form">
      <fieldset>
        <label class="k-form-field">
          <input type="email" id="userId" name="userId" class="k-textbox" v-model="userId" placeholder="ID(메일주소)" autofocus>
        </label>
        <label class="k-form-field">
          <input type="password" id="userPwd" name="userPwd" class="k-textbox" v-model="userPwd" placeholder="PW">
        </label>
        <button type="submit" class="mid_btn orange login_btn"> 로그인</button>
      </fieldset>
      <!-- <div class="kfont"><a href="/homeadmin/findPassword">비밀번호 찾기</a> </div> -->

    </form>
  </div>
</template>


<script>

  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'

  const ethDomain = ApiConfig.ethDomain

  export default {
    name: 'AdminLogin',

    created : function() {

    },
    mounted : function() {
    },
    computed: {},
    methods: {
      onSubmit (userId, passwordTx) {
        let validator = this.kendoValidator
        //만약 여러번 로그인이 수행되었을 경우를 대비해서 기존 데이터를 삭제한다.
        sessionStorage.removeItem('adminUserInfo')
        sessionStorage.removeItem('adminLoginUserToken')
        sessionStorage.removeItem('adminLoginUserData')

        //사용자 로그인 데이터가 있는 경우 제거. 2021. 05. 31. oxide
        sessionStorage.removeItem('loginUserToken')
        sessionStorage.removeItem('loginUserData')
        sessionStorage.removeItem('userInfo')
        sessionStorage.removeItem('isFirstLogin')

        const vm = this
        let router = this.$router

        if (validator.validate()) {

          if (passwordTx == null) {
            kendo.alert('비밀번호를 입력해주세요.')
            return false
          }

          if (passwordTx.length < 6) {
            kendo.alert('비밀번호 항목의 값은 최소 6글자이어야 합니다.')
            return false
          }

          const loginParams = {
            userId: userId,
            passwordTx: passwordTx,
            wmGbn: '11', //사용자 웹 01, 사용자 모바일 02, 관리자 웹 11, 관리자 모바일 12
          }

          let elem = document.createElement('div')

          elem.setAttribute('class', 'k-overlay')
          elem.setAttribute('style', 'z-index: 10003; opacity: 0.5;')

          document.body.appendChild(elem)

          ApiUtil.post(`${ethDomain}/v1/eth/login.json`, loginParams).then(function (response) {
            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              document.body.removeChild(elem)
              return false
            }

            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              document.body.removeChild(elem)
              return false
            }

            /**
             * 로그인 성공 시점
             */
            let adminLoginUserData = response.data.resultData

            let adminLoginUserToken = adminLoginUserData.loginUserToken

            const firstAccessParams = {
              loginUserToken: adminLoginUserToken,
              wmGbn: '11', //사용자 웹 01, 사용자 모바일 02, 관리자 웹 11, 관리자 모바일 12
              responseMenuYn: 'Y',
            }

            ApiUtil.post(`${ethDomain}/v1/eth/user/firstAccessInfo.json`, firstAccessParams).then(function (response) {
              if (response.data === undefined || response.data.resultStatus === undefined) {
                kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
                document.body.removeChild(elem)
                return false
              }

              if (response.data.resultStatus.messageCode !== '2000') {
                kendo.alert(response.data.resultStatus.messageText)
                document.body.removeChild(elem)
                return false
              }

              /**
               * firstAccessInfo 데이터 가져온 시점
               */
              let firstAccessInfoResultData = response.data.resultData

              //alert(`firstAccessInfoResultData : ${firstAccessInfoResultData}`)

              sessionStorage.setItem('adminLoginUserToken', adminLoginUserToken)
              sessionStorage.setItem('adminLoginUserData', JSON.stringify(firstAccessInfoResultData))
              sessionStorage.setItem('adminUserInfo', JSON.stringify(adminLoginUserData))

              const { authCd, userId, unqUserNo, loginCountNo } = firstAccessInfoResultData 
              let isFirstLogin = loginCountNo == 1 ? true : false

              setTimeout(function () {
                if (authCd == '99') {
                  router.push(
                    {
                      name: 'AdminDashboard',
                    },
                  )
                }else if (isFirstLogin){
                  router.push(
                    {
                      name: 'InitPassword', params : { userId, unqUserNo },
                    },
                  )
                }else {
                  router.push(
                    {
                      name: 'HomeQnaManagement',
                    },
                  )  
                }
                document.body.removeChild(elem)
              }, 500)
            })
          })
        } else {
          let errors = validator.errors()
          kendo.alert(errors[0])
        }
      },
    },

    data : function() {
      return {
        contractNo: this.$route.params.contractNo,
        userId: '',
        userPwd: '',
      }
    },
  }
</script>
<style>

</style>

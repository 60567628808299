<template>
  <div id="container_home">
    <div class="company_visual">
      <div id="side-wrap">
        <div id="side-wrap-inner">
          <div id="side-title">
            Let me introduce our company
          </div>
          <div id="side-down">
          </div>
        </div>
      </div>
      <div class="company_inner animated">
        <h2 class="company_title animated d-1s">
          CSR
        </h2>
        <h2 class="company_tit animated d-2s">
          사회와 환경 등에 대한 책임감을 가지고
실천해 나아가겠습니다.
        </h2>
      </div>
      <div class="company_img animated">
        <img src="@/assets/img/csr_top.png" class="animated d-1s" alt="">
      </div>

    </div>

    <div class="company_inner">
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">
          <div class="text animated d-1s"><div class="big_tit">#경기도 지역 예술작가 후원</div>
          <div class="cs_text">지역문화
            활성화를 위해 경기문화재단을 통해
            경기도
            지역 예술작가를 정기적으로 후원하고 있습니다
            신진
            작가를 발굴하고, 지역 예술작가가 창작활동에 집중할 수 있도록 지원합니다.</div></div>
          <div class="img animated d-1s">
            <img src="@/assets/img/csr_01.png" alt="">
          </div>
        </div>
        </li>
      </ul>
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">
          <div class="text animated d-1s">
            <div class="big_tit">#사내봉사단 위트너스</div>
            <div class="cs_text">위트너스는 'We(우리)'와 사명 '이트너스(ETNERS)'가 합쳐진 사내 동아리로,
              "지역사회에 도움이 필요한 분들과 함께하며 그들과 '우리'가 되어 함께 나아간다"는 의미를 담고 있습니다.</div>
            </div>
          <div class="img animated d-1s"><img src="@/assets/img/csr_02.png" alt=""></div>
        </div>
        </li>
      </ul>
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">
          <div class="text animated d-1s">
          <div class="big_tit">#스프링 페스티벌</div>
          <div class="cs_text">지역사회를 위한 사회공헌 캠페인입니다.
            매칭그랜트 방식(참여하는 임직원 1인당 1만원씩 적립)으로 기부금을 모아 성남시장애인종합복지관에 전달합니다.</div>
          </div>
          <div class="img animated d-1s"><img src="@/assets/img/csr_03.png" alt=""></div>
        </div>
        </li>
      </ul>
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">

          <div class="text animated d-1s">
          <div class="big_tit">#성남시장애인종합복지관 봉사 협약 체결</div>
          <div class="cs_text">성남시장애인종합복지관과 봉사 협약을 체결하여
            지역사회 장애인의 복지 증진과 더불어 살아가는 복지문화를 조성합니다.
            저장강박증 장애인 가정을 위한 '클린하우스'와 지역사회 환경 정화 활동 '쓰담쓰담'부터
            '성남시 장애인어울림체육대회', '한가위 큰잔치', '행복나눔바자회&일일호프' 등에 참여했습니다.</div>
          </div>
          <div class="img animated d-1s"><img src="@/assets/img/csr_04.png" alt=""></div>
        </div>
        <div class="clearfix"></div>
        </li>
      </ul>
      <ul class="csr_list">
        <li class="animated on">
        <div class="flex_wrap animated">

          <div class="text animated d-1s">
          <div class="big_tit">#육상 꿈나무 후원</div>
          <div class="cs_text">한국 육상 발전에 기여하고 선수들이 더 좋은 환경에서 훈련하고 마음껏 기량을 펼칠 수 있도록 경기력 향상 장학금을 지원하고 있습니다.</div>
          </div>
          <div class="img animated d-1s"><img src="@/assets/img/csr_05.png" alt=""></div>
        </div>
        <div class="clearfix"></div>
        </li>
      </ul>
      </div>
    </div>
</template>

<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  const ethDomain = ApiConfig.ethDomain
  import $ from 'jquery'

  export default {
    name: 'Csr',
    created() {
      const vm = this

    },
    mounted () {
      const vm = this
    },
    methods: {
    },
    data: function () {
      const vm = this
      return {

      }
    }
  }
</script>

<template>
  <section id="container">
    <div class="title_wrap">
      <div class="title_wrap">
        <h1>코드 {{ saveMode === 'INSERT' ? '등록' : '수정' }}</h1>
      </div>
    </div>
    <form ref="formRef" v-kendo-validator @submit.prevent="">
      <div class="contents_wrap">
        <div class="small_title_wrap">
          <h2>그룹 코드 정보</h2>
        </div>
        <ul class="filter_list">
          <li>
            <dd>
              <label>회사코드
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                          ref="cmpyCdRef"
                          id="cmpyId"
                          v-model="vModel.cmpyCd"
                          name="cmpyCd"
                          :placeholder="'00000'"
                          :style="'width: 100%;'"
                          maxlength="20"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>그룹 코드
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                          ref="cdGrupIdRef"
                          id="cdGrupId"
                          v-model="vModel.cdGrupId"
                          name="cdGrupId"
                          :style="'width: 100%;'"
                          maxlength="20"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
            <dd>
              <label>그룹 코드 명
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                    <kendo-maskedtextbox
                            ref="cdGrupNmTxRef"
                            v-model="vModel.cdGrupNmTx"
                            name="cdGrupNmTx"
                            :style="'width: 100%;'"
                            maxlength="100"
                    >
                    </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          </li>
          <li>
            <dd>
              <label>조회순서
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                          ref="seqNoRef"
                          v-model="vModel.seqNo"
                          name="seqNo"
                          maxlength="4"
                          :style="'width: 100%;'"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
            <dd>
              <label>그룹 코드 설명</label>
              <ul>
                <li>
                  <kendo-maskedtextbox
                          ref="cdDescTxRef"
                          v-model="vModel.cdDescTx"
                          name="cdDescTx"
                          :style="'width: 100%;'"
                          maxlength="200"
                  >
                  </kendo-maskedtextbox>
                </li>
              </ul>
            </dd>
          <li>
            <dd>
              <label>사용여부
                <span class="forg">*</span>
              </label>
              <ul>
                <li>
                  <kendo-switch
                          ref="useYnRef"
                          name="useYn"
                          :checked="vModel.useYn === undefined || vModel.useYn === 'Y'"
                  >
                  </kendo-switch>
                </li>
              </ul>
            </dd>
          </li>
        </ul>
      </div>
      <div class="button_area">
        <ul>
          <li>
            <button class="large_btn" @click="fnGoBack">취소</button>
          </li>
          <li>
            <button id="buttonSubmit"  class="large_btn orange" @click="fnSubmit">저장</button>
          </li>
        </ul>
      </div>
    </form>
  </section>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@/common/etners.common.util'
import EtnersKendoGridUtil from '@/common/kendo.grid.util'
import EtnersParseUtil from '@/common/etners.parse.util'
import EtnersDateTimeUtil from '@/common/etners.datetime.util'
import { fetchCodeMstDetailResultData } from '@/api/api.common'
import $ from 'jquery'


export default{
  name: 'CodeManagementMstSave',
  mounted: function() {
        const vm = this
        // 상세 데이터 가져오기
        if(this.saveMode === 'UPDATE') {
          this.fnGetDetail()
        }

        let elementCmpy = document.getElementById('cmpyId')
        let elementCdGrup = document.getElementById('cdGrupId')

        elementCmpy.onkeyup = function (){
          vm.setMatchedNumber(elementCmpy.value,"cmpyCdRef")
        }
        elementCdGrup.onkeyup = function (){
          vm.setMatchedNumber(elementCdGrup.value,"cdGrupIdRef")
        }

      },
  methods: {
        fnGetDetail: async function() {
          this.detail = await fetchCodeMstDetailResultData(this.$route.params.cmpyCd,this.$route.params.cdGrupId)
          this.vModel = this.detail.data.resultData[0]
        },
        setMatchedNumber: function(value,type){
          const matchedPhone = /[0-9]$/.test(value)
          let refs = this.$refs


          if(!matchedPhone){
            if(type==='cmpyCdRef'){
              refs.cmpyCdRef.kendoWidget().value('')
            }else{
              refs.cdGrupIdRef.kendoWidget().value('')
            }
            return false
          }else{
            if(type==='cmpyCdRef'){
              refs.cmpyCdRef.kendoWidget().value(value)
            }else{
              refs.cdGrupIdRef.kendoWidget().value(value)
            }
            return true
          }

        },
        getDefaultRules: function()  {
          const resultMsg = { key: true, msg: '' }
          const cmpyCd = this.$refs.cmpyCdRef.kendoWidget().value()
          const cdGrupId = this.$refs.cdGrupIdRef.kendoWidget().value()
          const cdGrupNmTx = this.$refs.cdGrupNmTxRef.kendoWidget().value()
          const seqNo = this.$refs.seqNoRef.kendoWidget().value()

          if ( EtnersCommonUtil.isEmpty(cmpyCd)|| EtnersCommonUtil.isEmpty(cdGrupId)|| EtnersCommonUtil.isEmpty(cdGrupNmTx)|| EtnersCommonUtil.isEmpty(seqNo)) {
            resultMsg.key = false
            resultMsg.msg = '필수값을 입력해주세요.'
          }
          return resultMsg
        },
        fnCheckDuplicateCode : function(){

          let sessionLoginUserData = sessionStorage.getItem('loginUserData')
          let loginUserToken = sessionStorage.loginUserToken
          let loginUserObj = JSON.parse(sessionLoginUserData)


          const cmpyCd = this.$refs.cmpyCdRef.kendoWidget().value()
          const cdGrupId = this.$refs.cdGrupIdRef.kendoWidget().value()

          const params = {
            'cmpyCd': cmpyCd,
            'cdGrupId' : cdGrupId
          }
          const resultMsg = this.getDefaultRules()

          if(resultMsg.key){
            ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/code/dupilcateCodeChecked.json`,params).then(response => {
              //
              // if(response.data > 0){
              //   kendo.alert('중복되는 그룹코드가 존재합니다.');
              //   return false
              // }else{
              //   kendo.alert('사용가능한 이메일입니다.')
              // }

            })
          }else{
            kendo.alert(resultMsg.msg)
          }

        },
        // 저장 submit
        fnSubmit: function() {

          const vm = this
          const vModel = vm.vModel
          const defaultResultMsg =  this.getDefaultRules()
          let sessionLoginUserData = sessionStorage.getItem('adminLoginUserData')
          let loginUserObj = JSON.parse(sessionLoginUserData)

          if(defaultResultMsg.key) {

            let confirmKeyword = (vm.saveMode === 'INSERT' ? '등록' : '수정')
            kendo.confirm("코드정보를 "+confirmKeyword+"하시겠습니까?").done(function() {

              // 파라미터 객체 생성
              let params = {
                loginUserToken          : sessionStorage.loginUserToken,
                lastMdfyId              : loginUserObj.unqUserNo,
                frstRgstId              : loginUserObj.unqUserNo,
                cmpyCd                  : vm.$refs.cmpyCdRef.kendoWidget().value(),
                cdGrupId                : vm.$refs.cdGrupIdRef.kendoWidget().value(),
                cdGrupNmTx              : vm.vModel.cdGrupNmTx,
                cdDescTx                : vm.vModel.cdDescTx,
                seqNo                   : vm.vModel.seqNo,
                useYn                   : vm.$refs.useYnRef.kendoWidget().value() === true ? 'Y' : 'N'
              }

              // progressbar 시작
              vm.$Progress.start()

              // 저장
              ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/code/putCmmCodeMstData.json`, params).then(response => {

                if (response.data === undefined || response.data.resultStatus === undefined) {
                  kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
                  // progressbar 실패
                  vm.$Progress.fail()
                  return false
                }
                if (response.data.resultStatus.messageCode !== '2000') {
                  kendo.alert(response.data.resultStatus.messageText)
                  // progressbar 실패
                  vm.$Progress.fail()
                  return false
                }

                kendo.alert(confirmKeyword+"되었습니다.")
                // progressbar 종료
                vm.$Progress.finish()
                let responseCmpyCd = response.data.resultData.cmpyCd
                let responseGroupId = response.data.resultData.cdGrupId

                vm.$router.push({
                  name: 'CodeManagementMstDetail',
                  params :{
                    cmpyCd:responseCmpyCd,
                    cdGrupId:responseGroupId
                  }
                })

              })
            })

          } else {
            kendo.alert(defaultResultMsg.msg)
            return false
          }

        },
        fnGoBack: function(e) {
          this.$router.push({
            name:'CodeManagement'
          })
        }
      },
      components: {
        //MultiDropdownMcpCafeteria,
      //  DaumPostcodeWindow
      },
      data: function () {
        return {
          loginUserData: JSON.parse(sessionStorage.getItem('adminLoginUserData')),
          saveMode: (EtnersCommonUtil.isEmpty(this.$route.params.cdGrupId) ? 'INSERT' : 'UPDATE'),
          detail: {},
          vModel: {
            useYn: 'true',
            cdGrupId: '',
            cdGrupNmTx: '',
            seqNo: '',
            cdDescTx: '',
            cmpyCd: ''
          },
        }
      },
    }

  </script>

<template>
  <div id="container">
    <div class="title_wrap">
      <h1>문의하기</h1>
    </div>
    <div class="contents_wrap">
      <div class="content_list">
        <div class="button_wrap_area">
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
        <div id="filter_section" class="filter_wrap">
          <ul class="filter_list">
            <li v-show="authCd == '99'">
              <dd>
                <label>카테고리</label>
                  <ul>
                      <li>
                        <kendo-dropdownlist
                            ref="solCdRef"
                            :data-source="$store.state.codeRequestStore.search.combobox.solCd.data"
                            :data-text-field="'cdNmTx'"
                            :data-value-field="'cdId'"
                            :option-label="'전체'"
                            :index="0"
                            :value="lastRouteParams.filter.searchSolCd"
                            @change="commonOnChangeEvent">
                        </kendo-dropdownlist>
                      </li>
                  </ul>
              </dd>
              <dd>
                  <label>마케팅활용동의</label>
                  <ul class="w60">
                    <li>
                      <kendo-dropdownlist
                            ref="marketingAgreeYNRef"
                            :data-source="marketingAgreeYNCombo"
                            :data-text-field="'cdNmTx'"
                            :data-value-field="'value'"
                            :value="lastRouteParams.filter.marketingAgreeYN"
                            @select="onSelectMarketingAgreeYN"
                        >
                        </kendo-dropdownlist>
                      </li>
                  </ul>
              </dd>
            </li>
            <li>
            <dd>
              <label>성명</label>
                <ul>
                    <li>
                      <kendo-maskedtextbox
                                ref="managerNmTxRef"
                                id="managerNmTx"
                                :style="'width: 60%;'"
                                :value="lastRouteParams.filter.managerNmTx"
                                @change="commonOnChangeEvent">
                        </kendo-maskedtextbox>
                    </li>
                </ul>
            </dd>
            <dd>
                <label>이메일</label>
                <ul class="w60">
                  <li>
                    <kendo-maskedtextbox
                                ref="emailTxRef"
                                id="emailTx"
                                :style="'width: 60%;'"
                                :value="lastRouteParams.filter.emailTx"
                                @change="commonOnChangeEvent">
                        </kendo-maskedtextbox>
                    </li>
                </ul>
            </dd>
            </li>
            <li>
            <dd>
              <label>회사명</label>
                <ul>
                    <li>
                        <kendo-maskedtextbox
                                ref="companyNmTxRef"
                                id="companyNmTx"
                                :style="'width: 60%;'"
                                :value="lastRouteParams.filter.companyNmTx"
                                @change="commonOnChangeEvent">
                        </kendo-maskedtextbox>
                    </li>
                </ul>
            </dd>
            <dd>
                <label>문의일</label>
                <ul class="w60">
                    <kendo-daterangepicker
                            ref="qnaDtRef"
                            :class="'w60 fl mr5'"
                            :format="'yyyy/MM/dd'"
                            :range="qnaDtRange"
                            @change="commonOnChangeEvent">
                    </kendo-daterangepicker>
                </ul>
            </dd>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <section>
      <div class="content_list">
        <div class="contents_top_sec">
          <button class="mid_btn fr" @click="onClickExcelDownloadQnaList">
            <i class="download_icon"></i>
            <span>엑셀 다운로드</span>
          </button>
        </div>
        <kendo-grid
            ref="qnaListGridRef"
            style="cursor: pointer;"
            :data-source="computedQnaListDataSource"
            :pageable-always-visible="true"
            :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
            :reorderable="true"
            :column-menu="false"
            :resizable="true"
            :selectable="true"
            :sortable="true"
            :columns="qnaGridRefColumns"
            :dataBound="dataBoundForQna"
            @change="onChangeQnaListGrid"
            :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        >
        </kendo-grid>
      </div>
    </section>
  </div>
</template>

<script>
  import ApiConfig from '@/api/api.config'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import EtnersDateTimeUtil from '@/common/etners.datetime.util'
  import ApiUtil from '../../../../api/api.util'
  import $ from 'jquery'
  import { saveExcel } from '@progress/kendo-vue-excel-export'

  export default {
    name: 'HomeQnaManagement',

    created () {
      const vm = this
      this.$store.dispatch('initSearchDataForCodeRequest')
      vm.authCd = JSON.parse(sessionStorage.getItem('adminLoginUserData')).authCd

      let defaultLastRouteParams = {
        page: 1,
        filter: {
          searchRgstNm: ''
        },
      }

      let jsonStrLastRouteParams = sessionStorage.getItem('LAST_ROUTE_PARAMS')
      this.lastRouteParams = sessionStorage.getItem('LAST_ROUTE_NAME') === 'qnaDetail' ? (jsonStrLastRouteParams === undefined
          ? defaultLastRouteParams
          : JSON.parse(jsonStrLastRouteParams)) : defaultLastRouteParams

      if (EtnersCommonUtil.isNotEmpty(this.lastRouteParams)) {
        this.lastRouteParams.page = this.lastRouteParams.filter.page
      }

    },
    mounted () {
      const vm = this

      //엔터키 이벤트 자동 세팅
      const enterkeyEventTargetIdArray = [
         'companyNmTx', 'emailTx', 'managerNmTx' 
      ]

      for (let elementId of enterkeyEventTargetIdArray) {
        let element = document.getElementById(elementId)
        if (element !== undefined) {
          element.onkeyup = function (e) {
            const ENTER_KEY_CODE = 13
            let currentKeyCode = window.event.keyCode || e.which
            if (currentKeyCode === ENTER_KEY_CODE) {
              vm.commonOnChangeEvent(e)
            }
          }
        }
      }
      this.$refs.qnaDtRef.kendoWidget()._startDateInput.setOptions({
        messages: this.messages
      })
      this.$refs.qnaDtRef.kendoWidget()._endDateInput.setOptions({
        messages: this.messages
      })
    },
    computed: {
      computedQnaListDataSource: function () {
        const vm = this

        let lastRequestParameterPage = vm.lastRouteParams.page !== undefined ? vm.lastRouteParams.page : 1

        const qnaListDataSource = new kendo.data.DataSource({
          name: 'qnaListDataSource',
          page: lastRequestParameterPage,
          pageSize: 10,
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
          transport: {
            read: {
              url: `${ApiConfig.ethDomain}/v1/eth/summary/getQnaList.json`,
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              cache: false,
            },
            parameterMap: function (options, operation) {
              if (operation === 'read') {
                const filter = vm.currentPageFilterOption()
                let loginUserToken = sessionStorage.adminLoginUserToken

                vm.replaceSortFieldStr(options)

                let params = {
                  loginUserToken,
                  authCd: vm.authCd,
                  searchSolCd: filter.searchSolCd,
                  searchCompanyNmTx: filter.searchCompanyNmTx,
                  searchEmailTx: filter.searchEmailTx,
                  searchManagerNmTx: filter.searchManagerNmTx,
                  searchMarketingAgreeYn: filter.searchMarketingAgreeYn,
                  searchQnaDtStartYYYYMMDD : filter.searchQnaDtStartYYYYMMDD,
                  searchQnaDtEndYYYYMMDD : filter.searchQnaDtEndYYYYMMDD,
                  skip: options.skip,
                  take: options.take,
                  page: options.page,
                  pageSize: options.pageSize,
                  sort: options.sort,
                  requestType: 'PAGING', //PAGING or EXPORT
                }

                sessionStorage.setItem('LAST_ROUTE_PARAMS', kendo.stringify({ filter: params }))

                return kendo.stringify(params)
              }

              if (operation !== 'read' && options.models) {
                return { models: kendo.stringify(options.models) }
              }
            },
          },
          schema: {
            type: 'json',
            parse: function (response) {

                let changeResultData = response.resultData
                let rowNumber = response.total + 1 - (vm.$refs.qnaListGridRef.dataSource.page() - 1)
                    * vm.$refs.qnaListGridRef.dataSource.pageSize()
                if(changeResultData!=null) {

                  for (let i = 0; i < changeResultData.length; i++) {
                    let row = changeResultData[i]
                    let qnaId = changeResultData[i].qnaId
                    row.qnaId = qnaId
                    row.rowNumber = --rowNumber
                }
                response.resultData = changeResultData
              }
              return response
            },
            total: function (response) {
              return response.total
            },
            data: function (response) {
              if (response.resultStatus.messageCode === '2000') {
                return response.resultData
              } else {
                kendo.alert('message: ' + response.resultStatus.messageText)
                return ''
              }
            },
          },

          error: function (e) {
            console.log('error event handler', e.errors[0])
          },
        })

        return qnaListDataSource
      },
    },
    methods: {
      commonOnChangeEvent: function () {
        const vm = this
        let gridRef = vm.$refs.qnaListGridRef

        // 마지막 검색 데이터 page 초기화
        this.lastRouteParams.page = 1

        if (gridRef !== undefined && gridRef.kendoWidget() !== undefined) {
          if (gridRef.kendoWidget().dataSource !== undefined) {
            gridRef.kendoWidget().dataSource.page(1)
          }
        }
      },
      currentPageFilterOption: function () {
        const vm = this
        const refs = vm.$refs

        let currentSearchDataObj = {}

        const filterData = vm.lastRouteParams.filter


        if (refs.solCdRef !== undefined) {
          const searchSolCd = refs.solCdRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchSolCd)) {
            currentSearchDataObj.searchSolCd = searchSolCd
          }
        }

        if (refs.companyNmTxRef !== undefined) {
          const searchCompanyNmTx = refs.companyNmTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchCompanyNmTx)) {
            currentSearchDataObj.searchCompanyNmTx = searchCompanyNmTx
          }
        }

        if (refs.emailTxRef !== undefined) {
          const searchEmailTx = refs.emailTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchEmailTx)) {
            currentSearchDataObj.searchEmailTx = searchEmailTx
          }
        }


        if (refs.managerNmTxRef !== undefined) {
          const searchManagerNmTx = refs.managerNmTxRef.kendoWidget().value()
          if (EtnersCommonUtil.isNotEmpty(searchManagerNmTx)) {
            currentSearchDataObj.searchManagerNmTx = searchManagerNmTx
          }
        }

      if (refs.qnaDtRef !== undefined) {
        const qnaDtRangeValue = refs.qnaDtRef.kendoWidget().range()

        if (EtnersCommonUtil.isNotEmpty(qnaDtRangeValue)) {
          const startYYYYMMDD = (qnaDtRangeValue.start !== undefined && qnaDtRangeValue.start !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(qnaDtRangeValue.start)
                              : undefined
          const endYYYYMMDD = (qnaDtRangeValue.end !== undefined && qnaDtRangeValue.end !== null)
                              ? EtnersDateTimeUtil.dateToYYYYMMDD(qnaDtRangeValue.end)
                              : undefined

          currentSearchDataObj.searchQnaDtStartYYYYMMDD = startYYYYMMDD
          currentSearchDataObj.searchQnaDtEndYYYYMMDD = endYYYYMMDD
        }
      }
        vm.filterData = currentSearchDataObj
        currentSearchDataObj.searchMarketingAgreeYn = vm.selectedMarketingAgreeYN

        return currentSearchDataObj
      },
      dataBoundForQna: function (evt) {
         let isDragging = false;

         $(".k-grid td")
         .mousedown(function() {
            $(window).mousemove(function() {
                isDragging = true;
                $(window).unbind("mousemove");
            });
         })
         .mouseup(function() {
            let wasDragging = isDragging;
            isDragging = false;
            $(window).unbind("mousemove");
            if (!wasDragging) {
              let row = evt.sender.selectable.userEvents.currentTarget
              let selectedRow = evt.sender.select()
            }
         });
     },
      // 초기화 버튼 클릭 이벤트
      cleanSearchDataEvent (e) {
        const vm = this
        const refs = this.$refs

        //템플릿구분
        if (refs.solCdRef !== undefined) {
          refs.solCdRef.kendoWidget().select(0)
        }

        if (refs.qnaCategoryCdRef !== undefined) {
          refs.qnaCategoryCdRef.kendoWidget().select(0)
        }

        if (refs.qnaDtRef !== undefined) {
          refs.qnaDtRef.kendoWidget().range({start: undefined, end: undefined})
        }

        if (refs.companyNmTxRef !== undefined) {
          refs.companyNmTxRef.kendoWidget().value('')
        }

        if (refs.marketingAgreeYNRef !== undefined) {
          refs.marketingAgreeYNRef.kendoWidget().select(0)
          vm.selectedMarketingAgreeYN = ''
        }

        sessionStorage.removeItem('LAST_ROUTE_PARAMS')

        this.commonOnChangeEvent(e)
      },
      replaceSortFieldStr (options) {
        if (options.sort != undefined && options.sort.length != 0){
          let sortField = options.sort[0].field
          let replaceField

          switch (sortField) {
            case "rowNumber":
              replaceField = "seqNo"
              break;
            case "companyNmTxStr":
              replaceField = "companyNmTx"
              break;
            default:
              replaceField = sortField
          }
          options.sort[0].field = replaceField
        }
      },
      onSelectMarketingAgreeYN : function(e) {
        const vm = this
        vm.selectedMarketingAgreeYN = e.dataItem.value
        vm.commonOnChangeEvent(e)
      },
      isEmptyReturnDash : function(obj) {
        return EtnersCommonUtil.isEmpty(obj) ? '-' : obj
      },
      onChangeQnaListGrid: function (e) {
        let selectedRow = e.sender.select()
        const rowDataItem = e.sender.dataItem(selectedRow)

        this.$router.push(
            {
              name: 'HomeQnaManagementDetail',
              params : {
                qnaId: rowDataItem.qnaId,
              },
            },
        )
      },
      onClickExcelDownloadQnaList() {
        const vm = this
        const lastRequestParameters = JSON.parse(sessionStorage.getItem('LAST_ROUTE_PARAMS'))
        const excelParams = JSON.parse(JSON.stringify(lastRequestParameters.filter))
        excelParams.requestType = 'EXPORT'

        const excelListUrl = `${ApiConfig.ethDomain}/v1/eth/summary/getQnaList.json`

        ApiUtil.post(excelListUrl, excelParams).then(response => {

          const changeResultData = response.data.resultData

          if (changeResultData == null) {
            kendo.alert("엑셀 다운로드에 실패하였습니다. 관리자에게 문의해주세요.")
          }

          changeResultData.forEach(row => {
            row.qnaId = vm.isEmptyReturnDash(row.qnaId)
            row.solTx = vm.isEmptyReturnDash(row.solTx)
            row.companyNmTx = vm.isEmptyReturnDash(row.companyNmTx)
            row.managerNmTx = vm.isEmptyReturnDash(row.managerNmTx)
            row.emailTx = vm.isEmptyReturnDash(row.emailTx)
            row.phoneNoTx = vm.isEmptyReturnDash(row.phoneNoTx)
            row.qnaContentsTx = vm.isEmptyReturnDash(row.qnaContentsTx)
            row.fmtQnaDt = vm.isEmptyReturnDash(row.fmtQnaDt)
            row.fmtLastMdfyDt = vm.isEmptyReturnDash(row.fmtLastMdfyDt)
            row.marketingAgreeYn = vm.isEmptyReturnDash(row.marketingAgreeYn)
            row.fmtMarketingAgreeDt = vm.isEmptyReturnDash(row.fmtMarketingAgreeDt)
            row.fmtMarketingDisAgreeDt = vm.isEmptyReturnDash(row.fmtMarketingDisAgreeDt)
            row.personalInfoAgreeYn = vm.isEmptyReturnDash(row.personalInfoAgreeYn)
            row.fmtPersonalInfoAgreeDt = vm.isEmptyReturnDash(row.fmtPersonalInfoAgreeDt)
          })

          if (EtnersCommonUtil.isNotEmpty(changeResultData)) {
            const currentDate = EtnersDateTimeUtil.lookupDateYYYYMMDD()
            const currentYYYYMMDD = EtnersDateTimeUtil.dateToYYYYMMDD(currentDate)

            saveExcel({
              data: changeResultData,
              fileName: '문의내역_' + currentYYYYMMDD + '.xlsx',
              columns: [
                {
                  field: 'qnaId',
                  title: '문의Id',
                  width: '100px'
                },
                {
                  field: 'solTx',
                  title: '카테고리',
                  width: '300px',
                },
                {
                  field: 'companyNmTx',
                  title: '회사명',
                  width: '50px'
                },
                {
                  field: 'managerNmTx',
                  title: '성명',
                  width: '50px'
                },
                {
                  field: 'emailTx',
                  title: '이메일',
                  width: '100px',
                },
                {
                  field: 'phoneNoTx',
                  title: '연락처',
                  width: '100px'
                },
                {
                  field: 'qnaContentsTx',
                  title: '문의내용',
                  width: '100px'
                },
                {
                  field: 'fmtQnaDt',
                  title: '문의일자',
                  width: '100px'
                },
                {
                  field: 'fmtLastMdfyDt',
                  title: '최종 수정 일자',
                  width: '100px'
                },
                {
                  field: 'marketingAgreeYn',
                  title: '마케팅활용동의',
                  width: '50px'
                },
                {
                  field: 'fmtMarketingAgreeDt',
                  title: '마케팅활용동의 일자',
                  width: '100px'
                },
                {
                  field: 'fmtMarketingDisAgreeDt',
                  title: '마케팅활용동의 철회 일자',
                  width: '100px'
                },
                {
                  field: 'personalInfoAgreeYn',
                  title: '개인정보수집동의',
                  width: '100px'
                },
                {
                  field: 'fmtPersonalInfoAgreeDt',
                  title: '개인정보수집동의 일자',
                  width: '100px'
                },
          
              ],
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        })
      },
    },
    data: function () {
      const kendoGridHeaderAttributes = {
        style: 'font-weight: bold;',
      }

      const vm = this

      return {
        rowNumber: 0,
        qnaRowData: {},
        val: '',
        authCd: '',
        selectedMarketingAgreeYN: '',
        qnaDtRange: {start: undefined, end: undefined},
        marketingAgreeYNCombo: [ 
          {
            value: '',
            cdNmTx: '전체',
            style: 'width:60%',
            selected: true
          },
          {
            value: 'Y',
            cdNmTx: '동의',
            style: 'width:60%',
            selected: false
          },
          {
            value: 'N',
            cdNmTx: '비동의',
            style: 'width:60%',
            selected: false
          }
        ],

        messages: {
          year: '년',
          month: '월',
          day: '일',
        },
        filterData: {},
        lastRouteParams: {
          page: 1,
          filter: {
            searchRgstNm: '',
            searchTemplateGbn: '',
            searchTemplateNm: ''
          },
        },
        qnaGridRefColumns: [
          {
            field: 'rowNumber',
            title: 'No.',
            width: '3%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'solTx',
            title: '카테고리',
            width: '5%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            sortable:false
          },
          {
            field: 'companyNmTx',
            title: '회사명',
            width: '10%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'managerNmTx',
            title: '성명',
            width: '6%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'phoneNoTx',
            title: '연락처',
            width: '7%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'emailTx',
            title: '이메일',
            width: '10%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'fmtPersonalInfoAgreeDt',
            title: '개인정보수집동의 일자',
            width: '8%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
          },
          {
            field: 'marketingAgreeYn',
            title: '마케팅활용동의',
            width: '5%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            template: function(dataItem){
              return dataItem.marketingAgreeYn === 'Y'? '동의' : '비동의'
            }
          },
          {
            field: 'answerTypeCd',
            title: '답변여부',
            width: '5%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            template: function(dataItem){
              return EtnersCommonUtil.isNotEmpty(dataItem.answerTypeCd) ? '완료' : '<span class="forg">미답변</span>'
            }
          },
          {
            field: 'answerTypeCd',
            title: '메일/알림톡 발송여부',
            width: '5%',
            headerAttributes: kendoGridHeaderAttributes,
            attributes: {
              class: 'tcenter',
            },
            template: function(dataItem){
              return dataItem.answerTypeCd == '01' ? '완료' : '<span class="forg">미발송</span>'
            }
          },
        ],
      }
    },
  }
</script>

<template>
  <main id="main">
    <!-- //header-->
    <div class="company_inner" style="margin-top: 300px;">
      <!-- 문의 -->
      <div class="company_inner">
        <submit-question-window ref="submitQuestionWindowRef" :style="popupCss" :submitData="submitData"
          :popType="popType" @onClickWindowClose="onClickWindowClose">
        </submit-question-window>
        <h1 class="page_tit animated on d-1s">서비스 문의 </h1>
        <div class="gray3 sub_stit animated on d-1s"><b>서비스 이용과 관련한 문의사항을 등록해 주세요. </b></div>
        <!--태그선택시 등록 입력폼 활성화-->
        <div class="bundle_tag animated on d-3s">
          <a :class="[serviceType==='01'? 'on':'']" @click="setClassOn('service','01')"># Shared Service</a>
          <a :class="[serviceType==='02'? 'on':'']" @click="setClassOn('service','02')"># Payroll</a>
          <a :class="[serviceType==='05'? 'on':'']" @click="setClassOn('service','05')"># Curation</a>
          <a :class="[serviceType==='11'? 'on':'']" @click="setClassOn('service','11')"># ESOP</a>
          <a :class="[serviceType==='06'? 'on':'']" @click="setClassOn('service','06')"># Mall</a>
          <a :class="[serviceType==='07'? 'on':'']" @click="setClassOn('service','07')"># Moving</a>
          <a :class="[serviceType==='04'? 'on':'']" @click="setClassOn('service','04')"># Housing</a>
          <a :class="[serviceType==='08'? 'on':'']" @click="setClassOn('service','08')"># Relocation</a>
          <a :class="[serviceType==='03'? 'on':'']" @click="setClassOn('service','03')"># Bidding</a>
          <a :class="[serviceType==='09'? 'on':'']" @click="setClassOn('service','09')"># Shop</a>
          <a :class="[serviceType==='10'? 'on':'']" @click="setClassOn('service','10')"># EFS</a>
          <a :class="[serviceType==='13'? 'on':'']" @click="setClassOn('service','13')"># ESRM</a>
          <a :class="[serviceType==='00'? 'on':'']" @click="setClassOn('service','00')"># 기타</a>
        </div>
        <!--등록-->
        <div class="wrap_vision animated on d-2s">
          <div>
            <div class="fl_input">
              <label>회사명 <span class="dh_orange">*</span></label>
              <kendo-maskedtextbox ref="companyNmTxRef" maxlength="14" name="companyNmTx"
                :style="'width: 100%;'"></kendo-maskedtextbox>
            </div>
            <div class="fl_input">
              <label>연락처<span class="dh_orange">*</span></label>
              <kendo-maskedtextbox ref="phoneNoTxRef" maxlength="30" name="phoneNoTx" id="phoneNoTx"
                :placeholder="'01012341234'" :style="'width: 100%;'"></kendo-maskedtextbox>
            </div>
            <div class="fl_input">
              <label>이메일 <span class="dh_orange">*</span></label>
              <kendo-maskedtextbox ref="emailTxRef" maxlength="33" name="emailTx" id="emailTx"
                :placeholder="'ex@etners.com'" :style="'width: 100%;'"></kendo-maskedtextbox>
            </div>
            <div class="fl_input">
              <label>성명 <span class="dh_orange">*</span></label>
              <kendo-maskedtextbox ref="managerNmTxRef" maxlength="14" name="managerNmTx"
                :style="'width: 100%;'"></kendo-maskedtextbox>
            </div>
            <div class="fl_input" :style="payrollCss">
              <label>임직원수<span class="dh_orange">*</span></label>
              <kendo-maskedtextbox ref="compUserCntRef" maxlength="10" name="compUserCnt"
                :style="'width: 100%;'"></kendo-maskedtextbox>
            </div>
            <div class="diclear" :style="payrollCss">
              <label>회사분류<span class="dh_orange">*</span></label>
              <ul class="comp_group">
                <li v-for="(item, index) in compType" :key="index" :id="item.id" :class="{on:getActive(item.id,'comp')}"
                  @click="setSelectedList(item.id,'comp')">
                  {{item.value}}
                </li>
              </ul>
            </div>
            <div class="diclear" :style="payrollCss">
              <label>관심분야<span class="dh_orange">*</span></label>
              <ul class="comp_group">
                <li v-for="(item, index) in interestType" :key="index" :id="item.id"
                  :class="{on:getActive(item.id,'inte')}" @click="setSelectedList(item.id,'inte')">
                  {{item.value}}
                </li>
              </ul>
            </div>
            <div class="diclear" :style="payrollCss">
              <label>문의경로<span class="dh_orange">*</span></label>
              <ul class="comp_group">
                <li 
                  v-for="(item, index) in inquiryPathType"
                  :key="index" 
                  :id="item.id"
                  :class="{ on: getActive(item.id, 'path') }" 
                  @click="setSelectedList(item.id, 'path')">
                  {{ item.value }}
                </li>
              </ul>
            </div>
            <div><label>문의내용 <span class="dh_orange">*</span></label>
              <textarea v-model="vModel.qnaContents" id="qnaContentsRef" name="qnaContents"
                style="height:120px;"></textarea>
            </div>

            <div v-show="serviceType === '02'">
              <div>
                <input type="checkbox" v-model="vModel.agree_pledge" id="agree_pledge" class="k-checkbox"
                  style="display: none;">
                <label for="agree_pledge" class="k-checkbox-label">ㆍ[필수] 개인정보 수집 및 이용에 동의합니다.
                  <span class="dh_orange" v-show="isInformationIframeVisible"
                    @click="hideIframe('information', $event)">[닫기]</span>
                  <span class="dh_orange" v-show="!isInformationIframeVisible"
                    @click="showIframe('information', $event)">[열기]</span>
                </label>
                <iframe v-show="isInformationIframeVisible" :src="payrollInformation" width="800px" height="450px"
                  frameborder="0" allowfullscreen style=" width:100%;" scrolling="no">
                </iframe>
              </div>
              <div>
                <input type="checkbox" v-model="vModel.agree_marketing" id="agree_marketing" class="k-checkbox"
                  style="display: none;">
                <label for="agree_marketing" class="k-checkbox-label">ㆍ[선택] 마케팅 활용에 동의합니다.
                  <span class="dh_orange" v-show="isMarketingIframeVisible"
                    @click="hideIframe('marketing', $event)">[닫기]</span>
                  <span class="dh_orange" v-show="!isMarketingIframeVisible"
                    @click="showIframe('marketing', $event)">[열기]</span>
                </label>
                <iframe v-show="isMarketingIframeVisible" :src="payrollMarketing" height="350" width="840px"
                  frameborder="0" allowfullscreen style=" width:100%; " scrolling="no">
                </iframe>
              </div>
            </div>

            <div v-show="serviceType !== '02'">
              <div>
                <input type="checkbox" v-model="vModel.agree_pledge" id="agree_pledge" class="k-checkbox"
                  style="display: none;">
                <label for="agree_pledge" class="k-checkbox-label">ㆍ[필수] 개인정보 수집 및 이용에 동의합니다.
                  <span class="dh_orange" v-show="isInformationIframeVisible"
                    @click="hideIframe('information' , $event)">[닫기]</span>
                  <span class="dh_orange" v-show="!isInformationIframeVisible"
                    @click="showIframe('information', $event)">[열기]</span>
                </label>
                <iframe v-show="isInformationIframeVisible" :src="information" width="800px" height="450px"
                  frameborder="0" allowfullscreen style=" width:100%;" scrolling="no">
                </iframe>
              </div>
              <div>
                <input type="checkbox" v-model="vModel.agree_marketing" id="agree_marketing" class="k-checkbox"
                  style="display: none;">
                <label for="agree_marketing" class="k-checkbox-label">ㆍ[선택] 마케팅 활용에 동의합니다.
                  <span class="dh_orange" v-show="isMarketingIframeVisible"
                    @click="hideIframe('marketing', $event)">[닫기]</span>
                  <span class="dh_orange" v-show="!isMarketingIframeVisible"
                    @click="showIframe('marketing', $event)">[열기]</span>
                </label>
                <iframe v-show="isMarketingIframeVisible" :src="marketing" height="350" width="840px" frameborder="0"
                  allowfullscreen style=" width:100%; " scrolling="no">
                </iframe>
              </div>
            </div>

            <div class="confm">
              <button @click="fnSubmit" id="confm_btn">저장</button>
            </div>
          </div>
        </div>
        <!--//등록-->
      </div>
      <!-- //문의  -->
    </div>
  </main>
</template>
<script>
  import ApiConfig from '@/api/api.config'
  import ApiUtil from '@/api/api.util'
  import EtnersCommonUtil from '@/common/etners.common.util'
  import SubmitQuestionWindow from '@/components/SubmitQuestionWindow'
  const ethDomain = ApiConfig.ethDomain
  import $ from 'jquery'
  

  export default {
    name: 'SubmitQuestion',
    components: {
      SubmitQuestionWindow
    },

    created() {
    },
    mounted () {
      const vm = this
      let elementPhone = document.getElementById('phoneNoTx')
      elementPhone.onkeyup = function (){
        vm.setMatchedPhone(elementPhone.value)
      }
      elementPhone.onkeydown = function (){
        vm.setMatchedPhone(elementPhone.value)
      }

    },
    computed: {

    },
  methods: {
    hideIframe(type, event) {
      event.preventDefault();
      event.stopPropagation();
      if (type == 'information') {
        this.isInformationIframeVisible = false;
      } else if (type == 'marketing') {
        this.isMarketingIframeVisible = false;
      }
    },
    showIframe(type, event) {
      event.preventDefault();
      event.stopPropagation();
      if (type == 'information') {
        this.isInformationIframeVisible = true;
      } else if (type == 'marketing') {
        this.isMarketingIframeVisible = true;
      }
    },
      setSelectedList: function(item,type){
        let list = []
        if(type === 'inte'){
          list = this.interestSelected
        } else if (type === 'path') { 
          list = this.inquiryPathSelected
        }else {
          list = this.compSelected
        }

        if(!list.includes(item)){
          list.push(item)
        }else{
          list.splice(list.indexOf(item),1)
        }
      },
      getActive: function(item,type){
        let isActive = false
        let list = []
        if(type === 'inte'){
          list = this.interestSelected
        } else if (type === 'path') {
          list = this.inquiryPathSelected
        }
        else {
          list = this.compSelected
        }

        list.forEach(id => {
          if(item === id){
            isActive = true
          }
        })
        return isActive
      },
      setClassOn: function(type,service){
        const vm = this
        vm.serviceType = service
        if(service==='02'){
          vm.payrollCss = 'display: inline-block;'
        }else{
          vm.payrollCss = 'display: none;'
        }

      },
      setMatchedPhone: function(value){
      const matchedPhone = /[0-9]$/.test(value)

      if(!matchedPhone){
        this.$refs.phoneNoTxRef.kendoWidget().value('')
        return false
      }else{
        this.$refs.phoneNoTxRef.kendoWidget().value(value)
        return true
      }

     },
      getDefaultRules: function(){
        const vm = this
        let type = vm.serviceType

        const resultMsg = { key: true, msg: '' }
        const companyNmTx = this.$refs.companyNmTxRef.kendoWidget().value()
        const managerNmTx = this.$refs.managerNmTxRef.kendoWidget().value()
        const emailId = this.$refs.emailTxRef.kendoWidget().value()
        const phoneNoTx = this.$refs.phoneNoTxRef.kendoWidget().value()
        const qnaContents = vm.vModel.qnaContents


        if(type === '02'){
          const compUserCnt = this.$refs.compUserCntRef.kendoWidget().value()
          if ( EtnersCommonUtil.isEmpty(compUserCnt)) {
            resultMsg.key = false
            resultMsg.msg = '임직원수를 입력해주세요.'
          }
          else if(this.interestSelected.length < 1){
            resultMsg.key = false
            resultMsg.msg = '관심분야를 선택해주세요.'
          }
          else if(this.compSelected.length < 1){
            resultMsg.key = false
            resultMsg.msg = '회사분류를 선택해주세요.'
          }
          else if ( EtnersCommonUtil.isEmpty(qnaContents)) {
            resultMsg.key = false
            resultMsg.msg = '문의내용을 입력해주세요.'
          }
          else if (this.inquiryPathSelected.length < 1) {
            resultMsg.key = false
            resultMsg.msg = '문의경로를 선택해주세요.'
          }
        }else{
          if ( EtnersCommonUtil.isEmpty(qnaContents)) {
            resultMsg.key = false
            resultMsg.msg = '문의내용을 입력해주세요.'
          }
        }

        if ( EtnersCommonUtil.isEmpty(companyNmTx)) {
          resultMsg.key = false
          resultMsg.msg = '회사명을 입력해주세요.'
        }else if ( EtnersCommonUtil.isEmpty(phoneNoTx)) {
          resultMsg.key = false
          resultMsg.msg = '연락처를 입력해주세요.'
        }else if ( EtnersCommonUtil.isEmpty(emailId)) {
          resultMsg.key = false
          resultMsg.msg = '이메일을 입력해주세요.'
        }else if ( EtnersCommonUtil.isEmpty(managerNmTx)) {
          resultMsg.key = false
          resultMsg.msg = '성명을 입력해주세요.'
        }




        return resultMsg

      },
      getEmailRules: function () {
        const resultMsg = { key: true, msg: '' }
        const emailId = this.$refs.emailTxRef.kendoWidget().value()
        const matched = /^([\w-\.]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i.test(emailId)

        if ( EtnersCommonUtil.isEmpty(emailId)) {
          resultMsg.key = false
          resultMsg.msg = '이메일을 입력해주세요.'
        } else if (!matched) {
          resultMsg.key = false
          resultMsg.msg = '이메일 형식을 맞춰주세요.'
        }
        return resultMsg
      },
      fnSubmit: function(){
        const vm = this
        const vModel = vm.vModel
        const defaultResultMsg = this.getDefaultRules()
        const emailRules = this.getEmailRules()
        let btnSubmitDis = document.querySelector('#confm_btn')
        let solCd = vm.serviceType
        let params = {}

        if(defaultResultMsg.key){
          if(emailRules.key){
            if (!vModel.agree_pledge) {
              kendo.alert('개인정보 활용 내용에 동의해주세요. ')
              return false
            }

            kendo.confirm("문의 하시겠습니까? ").done(function() {

              btnSubmitDis.setAttribute('disabled','disabled')
              if(solCd==='02'){
                params = {
                  solCd                   : solCd,
                  faqCategoryCd           : '01', //서비스문의
                  companyNmTx             : vm.$refs.companyNmTxRef.kendoWidget().value(),
                  managerNmTx             : vm.$refs.managerNmTxRef.kendoWidget().value(),
                  emailTx                 : vm.$refs.emailTxRef.kendoWidget().value(),
                  qnaContentsTx           : vm.vModel.qnaContents,
                  phoneNoTx               : vm.$refs.phoneNoTxRef.kendoWidget().value(),
                  compUserCnt             : vm.$refs.compUserCntRef.kendoWidget().value(),
                  personalInfoAgreeYn     : vm.vModel.agree_pledge ? 'Y' : 'N',
                  marketingAgreeYn        : vm.vModel.agree_marketing ? 'Y' : 'N',
                  compType                : vm.compSelected.join('|'),
                  compInte: vm.interestSelected.join('|'),
                  inquiryPath: vm.inquiryPathSelected.join('|')
                }

              }else{
                params = {
                  solCd                   : solCd,
                  faqCategoryCd           : '01', //서비스문의
                  companyNmTx             : vm.$refs.companyNmTxRef.kendoWidget().value(),
                  managerNmTx             : vm.$refs.managerNmTxRef.kendoWidget().value(),
                  emailTx                 : vm.$refs.emailTxRef.kendoWidget().value(),
                  phoneNoTx               : vm.$refs.phoneNoTxRef.kendoWidget().value(),
                  personalInfoAgreeYn     : vm.vModel.agree_pledge ? 'Y' : 'N',
                  marketingAgreeYn        : vm.vModel.agree_marketing ? 'Y' : 'N',
                  qnaContentsTx           : vm.vModel.qnaContents
                }
              }
              // 저장
              ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/question/questionInsert.json`, params).then(response => {

                if (response.data === undefined || response.data.resultStatus === undefined) {
                  kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
                  return false
                }
                if (response.data.resultStatus.messageCode !== '2000') {
                  kendo.alert(response.data.resultStatus.messageText)
                  return false
                }

                vm.submitData = params

                vm.popupCss="display:block;"
              })
            })

          }else{
            kendo.alert(emailRules.msg)
            return false
          }
        }else{
          kendo.alert(defaultResultMsg.msg)
          return false
        }


      },
      onClickWindowClose() {
        this.popupCss="display:none;"
        this.$router.go()

      },
    },
    data: function () {
      const vm = this
      return {
        isMarketingIframeVisible: false,
        isInformationIframeVisible: false,
        payrollInformation: 'https://etners.com/terms/management/detail/36/05',
        payrollMarketing: 'https://etners.com/terms/management/detail/36/06',
        information: 'https://etners.com/terms/management/detail/32/05',
        marketing: 'https://etners.com/terms/management/detail/32/06',
        payrollCss:'display: none;',
        serviceType: '01',
        compType:[
            {
              id:"01",
              value:"제조업"
            },
          {
            id:"02",
            value:"도매 및 소매업"
          },
          {
            id:"03",
            value:"공공행정"
          },
          {
            id:"04",
            value:"교육 서비스업"
          },
          {
            id:"05",
            value:"금융 및 보험업"
          },
          {
            id:"06",
            value:"정보통신업"
          },
          {
            id:"07",
            value:"전문, 과학 및 기술 서비스업"
          },
          {
            id:"08",
            value:"보건업 및 사회복지 서비스업"
          },
          {
            id:"09",
            value:"건설업"
          },
          {
            id:"10",
            value:"ETC"
          }
        ],
        interestType:[
          {
            id:"01",
            value:"페이롤"
          },
          {
            id:"02",
            value:"기업복리후생"
          },
          {
            id:"03",
            value:"사내근로복지기금 설립 및 운영"
          },
          {
            id:"04",
            value:"총보상 컨설팅"
          },
          {
            id:"05",
            value:"온라인 연봉계약"
          },
          {
            id:"06",
            value:"근태관리 솔루션"
          },
          {
            id:"07",
            value:"기타"
          }
        ],
        inquiryPathType: [
          {
            id: "01",
            value: "네이버 검색"
          },
          {
            id: "02",
            value: "구글 검색"
          },
          {
            id: "03",
            value: "지인추천"
          },
          {
            id: "04",
            value: "기타"
          }
        ],
        submitData:{},
        popType : "qna",
        popupCss: 'display:none;',
        compSelected : [],
        interestSelected: [],
        inquiryPathSelected: [],
        vModel: {
          agree_pledge: null,
          qnaContents: null,
          agree_marketing: null
        },
      }
    }
  }
</script>

<template>
  <div id="container">
    <div class="title_wrap">
      <h1>문의내역 상세</h1>
    </div>
    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>{{ detailData.qnaId }}</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>카테고리</label>
            <ul v-if="editMode">
              <li>
                <kendo-dropdownlist ref="solTxRef"
                  :data-source="$store.state.codeRequestStore.search.combobox.solCd.data" :data-text-field="'cdNmTx'"
                  :data-value-field="'cdId'" :option-label="'전체'" :index="0" v-model="detailData.solCd">
                </kendo-dropdownlist>
              </li>
            </ul>
            <ul v-else>
              <li>{{detailData.solTx}}</li>
            </ul>
          </dd>
          <dd>
            <label>성명</label>
            <ul v-if="editMode">
              <li>
                <kendo-maskedtextbox ref="managerNmTxRef" id="managerNmTx" :style="'width: 60%;'"
                  v-model="detailData.managerNmTx">
                </kendo-maskedtextbox>
              </li>
            </ul>
            <ul v-else class="w80">
              <li>{{detailData.managerNmTx}}</li>
            </ul>
          </dd>
        </li>
        <li v-show="detailData.solCd == 12">
          <dd>
            <label>공고명</label>
            <ul v-if="editMode">
              <li>
                <kendo-maskedtextbox ref="recuNmTxRef" id="recuNmTx" :style="'width: 100%;'"
                  v-model="detailData.recuNmTx">
                </kendo-maskedtextbox>
              </li>
            </ul>
            <ul v-else class="w80">
              <li>{{ detailData.recuNmTx }}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>회사명</label>
            <ul v-if="editMode">
              <li>
                <kendo-maskedtextbox ref="companyNmTxRef" id="companyNmTx" :style="'width: 60%;'"
                  v-model="detailData.companyNmTx">
                </kendo-maskedtextbox>
              </li>
            </ul>
            <ul v-else class="w80">
              <li>{{detailData.companyNmTx}}</li>
            </ul>
          </dd>
          <dd>
            <label>메일 주소</label>
            <ul v-if="editMode">
              <li>
                <kendo-maskedtextbox ref="emailTxRef" id="emailTx" :style="'width: 60%;'" v-model="detailData.emailTx">
                </kendo-maskedtextbox>
              </li>
            </ul>
            <ul v-else class="w80">
              <li>{{detailData.emailTx}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>연락처</label>
            <ul v-if="editMode">
              <li>
                <kendo-maskedtextbox ref="phoneNoTxRef" id="phoneNoTx" :style="'width: 60%;'"
                  v-model="detailData.phoneNoTx">
                </kendo-maskedtextbox>
              </li>
            </ul>
            <ul v-else class="w80">
              <li>{{detailData.phoneNoTx}}</li>
            </ul>
          </dd>
          <dd>
            <label>문의 일자</label>
            <ul>
              <li>{{detailData.fmtQnaDt}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>마케팅 활용 동의</label>
            <ul v-if="editMode">
              <li>
                <common-radio-group v-model="detailData.marketingAgreeYn" :data-items='[{label: "동의", value: "Y",},
                    {label: "비동의", value: "N"}]'>
                </common-radio-group>
              </li>
            </ul>
            <ul v-else>
              <li>{{detailData.marketingAgreeYn}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>마케팅 활용 동의 일자</label>
            <ul class="w60">
              <li>{{detailData.fmtMarketingAgreeDt}}</li>
            </ul>
          </dd>
          <dd>
            <label>마케팅 활용 동의 철회 일자</label>
            <ul>
              <li>{{detailData.fmtMarketingDisAgreeDt}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>개인 정보 수집 동의</label>
            <ul v-if="editMode">
              <li>
                <common-radio-group v-model="detailData.personalInfoAgreeYn" :data-items='[{label: "동의", value: "Y",},
                    {label: "비동의", value: "N"}]'>
                </common-radio-group>
              </li>
            </ul>
            <ul v-else class="w60">
              <li>{{detailData.personalInfoAgreeYn}}</li>
            </ul>
          </dd>
          <dd>
            <label>개인정보수집동의 일자</label>
            <ul class="w60">
              <li>{{detailData.fmtPersonalInfoAgreeDt}}</li>
            </ul>
          </dd>
        </li>
        <li v-show="detailData.solCd == '02'">
          <dd>
            <label>임직원 수</label>
            <ul class="w60">
              <li>{{detailData.compUserCnt}}</li>
            </ul>
          </dd>
          <dd>
            <label>회사분류</label>
            <ul class="w60">
              <li>{{detailData.compTypeNm}}</li>
            </ul>
          </dd>
        </li>
        <li v-show="detailData.solCd == '02'">
          <dd>
            <label>관심분야</label>
            <ul class="w60">
              <li>{{detailData.compInteNm}}</li>
            </ul>
          </dd>
          <dd>
            <label>문의경로</label>
            <ul class="w60">
              <li>{{detailData.inquiryPathNm}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label style="width:9.5%">문의 내용</label>
            <ul v-if="editMode">
              <li>
                <label>답변 내용
                  <span class="forg">*</span>
                </label>
                <div id="overlay" v-bind:style="'0'"></div>
                <editor ref="qnaAnswerTxRef" id="qnaAnswerTx" :tools="editor.settings.editorTools"
                  :resizable-content="true" :resizable-toolbar="true" v-model="detailData.qnaContentsTx"
                  style="height:350px;" rows="10" cols="30" validationMessage="내용을 입력해주세요.">
                </editor>
              </li>
            </ul>
            <ul v-else class="w60 ">
              <li class="inquiry_cont" v-html="detailData.qnaContentsTx" ref="qnaContentsRef"></li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <div class="button_area w25">
      <ul>
        <li class="w25"><button class="large_btn dgray" @click="deleteQna">문의글 삭제</button></li>
        <li class="w25" v-if="editMode"><button class="large_btn green" @click="saveModifiedQna">저장</button></li>
        <li class="w25" v-else><button class="large_btn green" @click="modifyQna">문의글 수정</button></li>

        <li class="w25" v-if="detailData.answerTypeCd != undefined">
          <button class="large_btn orange" @click="setQnaAnswer">답변수정</button>
        </li>
        <li class="w25" v-else><button class="large_btn orange" @click="setQnaAnswer">답변등록</button></li>

        <li class="w25"><button class="large_btn" @click="redirectQnaList">목록</button></li>

      </ul>
    </div>
    <div v-show="detailData.answerDt != undefined" class="contents_wrap box_answer mgt10">
      <div class="small_title_wrap">
        <h2>답변</h2>
      </div>
      <ul class="filter_list">
        <li>
          <dd>
            <label>답변자</label>
            <ul class="w60">
              <li>{{detailData.answerId}}</li>
            </ul>
          </dd>
          <dd>
            <label>답변 일시</label>
            <ul>
              <li>{{detailData.fmtAnswerDt}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>발송 구분</label>
            <ul class="w60">
              <li>
                {{detailData.answerTypeTx}}
                <span v-show="detailData.answerTypeCd == '01'" style="margin-left:10px">
                  [ 제목 : {{ detailData.answerTitleTx }} ]
                </span>
                <button v-show="detailData.answerTypeCd == '02'" class="small_btn mgl10"
                  @click="onClickSendMailAndAlimTalk()">이메일 / 알림톡 발송</button>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>첨부파일</label>
            <ul class="w80 icon_file">
              <li v-for="file in detailData.answerFiles">
                <div class="btn_file"><span class="k-icon k-i-download"></span>
                  <a v-bind:href="makeFileUrl(file)">{{file.orgFileNameTx}}</a>
                </div>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label style="width:9.5%">답변 제목</label>
            <ul class="w80">
              <li class="inquiry_cont">{{detailData.answerTitleTx}}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label style="width:9.5%">답변 내용</label>
            <ul class="w80">
              <li class="inquiry_cont" v-html="detailData.answerTx" ref="answerTxRef"></li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>
    <kendo-window :title="'답변 등록'" ref="answerRef" :modal="true" :width="'1000px'" :height="'71%'"
      style="z-index: 10004; transform: scale(1); opacity: 1; " :visible="false">
      <answer-window :detailData="detailData" @onClickWindowClose="onClickWindowClose"></answer-window>
    </kendo-window>
  </div>
</template>

<script>
  import ApiUtil from '@/api/api.util'
  import ApiConfig from '@/api/api.config'
  import AnswerWindow from '@/components/AnswerWindow'
  import { Editor } from '@progress/kendo-editor-vue-wrapper';
  import CommonRadioGroup from '@/components/CommonRadioGroup'

  export default {
    name: 'HomeQnaManagementDetail',
    components:{
      AnswerWindow, 'editor': Editor, CommonRadioGroup
    },
    created () {
      let vm = this
      this.$store.dispatch('initSearchDataForCodeRequest')
      let adminLoginUserData = sessionStorage.getItem('adminLoginUserData');
      let parsedAuthData = JSON.parse(adminLoginUserData);
      let authCd = parsedAuthData.authCd;

      let param = {
        loginUserToken: sessionStorage.adminLoginUserToken,
        authCd: authCd,
        qnaId: vm.$route.params.qnaId,
        }

      ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/summary/getQnaDetail.json`, param).then(function (response) {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          return false
        }

        const resultStatus = response.data.resultStatus

        if (resultStatus.messageCode !== '2000') {
          kendo.alert(resultStatus.messageText)
          return false
        }

        vm.detailData = response.data.resultData
      })
    },
    methods: {
      redirectQnaList: function() {
        this.$router.push(
          {
            name: 'HomeQnaManagement',
          },
        )
      },
      deleteQna() {
        const vm = this

        let param = {
          loginUserToken: sessionStorage.adminLoginUserToken,
          qnaId: vm.detailData.qnaId
        }

        kendo.confirm("정말 삭제 하시겠습니까?").done(function () {
            ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/question/deleteQna.json`, param).then(function(response){
              if (response.data === undefined) {
                kendo.alert('정상적으로 처리되지 않았습니다.')
                return false
              }else {

                kendo.confirm('삭제 되었습니다.').done(function () {
                  vm.$router.go(-1)
                })
              }
            })
          })
      },
      modifyQna() {
        const vm = this
        kendo.confirm("문의 글을 수정 하시겠습니까?").done(function () {
          vm.editMode = true
        })
      },
      saveModifiedQna() {
        const vm = this

        let param = {
          recuNmTx                : vm.detailData.recuNmTx ,
          qnaId                   : vm.detailData.qnaId,
          emailTx                 : vm.detailData.emailTx,
          phoneNoTx               : vm.detailData.phoneNoTx,
          personalInfoAgreeYn     : vm.detailData.personalInfoAgreeYn,
          marketingAgreeYn        : vm.detailData.marketingAgreeYn,
          solCd                   : vm.detailData.solCd,
          companyNmTx             : vm.detailData.companyNmTx,
          managerNmTx             : vm.detailData.managerNmTx,
          lastMdfyId              : JSON.parse(sessionStorage.getItem('adminLoginUserData')).unqUserNo,
          managerNmTx             : vm.detailData.managerNmTx,
          qnaContentsTx           : vm.detailData.qnaContentsTx
        }

        kendo.confirm("저장 하시겠습니까?").done(function () {

          ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/question/questionUpdate.json`, param).then(response => {

            if (response.data === undefined || response.data.resultStatus === undefined) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }

            kendo.alert("수정 되었습니다.")
            vm.$router.go(0);
          })
        })
      },
      onClickSendMailAndAlimTalk() {
        const vm = this
        const { qnaId, answerTx, answerTitleTx } = vm.detailData

        let param = {
          loginUserToken: sessionStorage.adminLoginUserToken,
          qnaId,
          answerId: JSON.parse(sessionStorage.getItem('adminLoginUserData')).userId,
          answerTitleTx, answerTx,
          answerTypeCd: "01"
        }

        kendo.confirm("이메일 및 알림톡을 발송 하시겠습니까?").done(function () {
            ApiUtil.post(`${ApiConfig.ethDomain}/v1/eth/qna/question/saveQnaAnswer.json`, param).then(function(response){

              if (response.data === undefined) {
                kendo.alert('정상적으로 처리되지 않았습니다.')
                return false
              }else {
                vm.sendQnaAnswerAlimTalkAndSms()

                kendo.confirm('정상적으로 발송 되었습니다.').done(function () {
                  vm.$router.go(0)
                })
              }
            })
          })
      },
      sendQnaAnswerAlimTalkAndSms() {
        const vm = this
        const { phoneNoTx, solTx, answerTitleTx } = vm.detailData

        let messageParams = {
          solCd:"2014",
          toNumber: phoneNoTx,
          tmpltSn:"1",
          cmpyCd:"00001",
          messageParams:[ solTx, answerTitleTx ],
          fromNumber:"",
          sendOnlyAlimTalk:false,
          reservationSendYYYYMMDDHHMI:"",
          alimTalkEventNm:"사용자 문의 답변 알림"
        }

        ApiUtil.post(`https://fcm-api.etnersplatform.com/v1/ebmp/kakao/alimtalk/sendAlimTalkAndSms.json`, messageParams).then(function (response) {
          if (response.data.resultStatus.messageCode != "2000") {
            kendo.alert(`${response.data.resultStatus.messageText}`)
            return
          }
        })
      },
      setQnaAnswer: function() {
        this.$refs.answerRef.kendoWidget().center().open()
      },
      onClickWindowClose() {
        this.$refs.answerRef.kendoWidget().close()
      },
      makeFileUrl: function(file) {
        return `${ApiConfig.ethDomain}/download/${file.fileId}/${file.fileSeqNo}`
      }
    },
    data: function () {
      const vm = this

      return {
        detailData : {},
        editMode : false,
              editor: {
        settings: {
          editorTools: [
            "viewHtml",
            "fontSize",
            "fontName",
            "foreColor",
            "backColor",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "createLink",
            "tableWizard",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn"
          ]
        }
      }
      }
    },
  }
</script>

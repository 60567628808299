
const codeToText = function (solutionCodeMap, code) {
  if (code == undefined || code == '') {
    return code
  }

  if (solutionCodeMap[code] == undefined) {
    return code
  }
  return solutionCodeMap[code].cdNm
}

// Date 타입 객체를 String으로 format (YYYYMMDD)
const convertDateToStringYYYYMMDDFormat = function(date) {

  if(date == undefined || date == null || !(date instanceof Date)) {

    return date
  }

  let YYYY = date.getFullYear()
  let MM = date.getMonth()+1 < 10 ? "0"+(date.getMonth()+1) : (date.getMonth()+1).toString()
  let DD = date.getDate() < 10 ? "0"+date.getDate() : date.getDate().toString()

  return YYYY+MM+DD
}

// Date 타입 객체를 String으로 format (HH)
const convertDateToStringHHFormat = function(date) {

  if(date == undefined || date == null || !(date instanceof Date)) {

    return date
  }

  let HH = date.getHours() < 10 ? "0"+date.getHours() : date.getHours().toString()

  return HH

}


const convertYYYYMMFormat = function (yyyymm) {
  if (yyyymm == undefined || yyyymm == '') {
    return yyyymm
  }

  return yyyymm.substring(0, 4) + '/' + yyyymm.substring(4, 6)
}


const convertYYYYMMDDFormat = function (yyyymmdd) {
  if (yyyymmdd == undefined || yyyymmdd == '') {
    return yyyymmdd
  }

  return yyyymmdd.substring(0, 4) + '/' + yyyymmdd.substring(4, 6) + '/' + yyyymmdd.substring(6, 8)
}

const convertHHMIFormat = function (hhmi) {
  if (hhmi == undefined || hhmi == '') {
    return hhmi
  }

  return hhmi.substring(0, 2) + ':' + hhmi.substring(2, 4)
}

const convertRegistIdFormatWithMasking = function (id) {

  if(id === undefined || id === '' || id === null) {
    return id
  }

  return id.replace(/(\d{6})(\d{7})/, '$1-$2').replace(/(-?)([1-8]{1})([0-9]{6})\b/gi,"$1$2******")
}

const convertYYYYMMDDHHFormat = function (yyyymmddhh) {
  if(yyyymmddhh == undefined || yyyymmddhh == "") {
    return yyyymmddhh
  }
  if(yyyymmddhh.length < 10) {
    return yyyymmddhh
  }

  return yyyymmddhh.substring(0, 4) + '/' + yyyymmddhh.substring(4, 6) + '/' + yyyymmddhh.substring(6, 8) + ' ' + yyyymmddhh.substring(8, 10)
}

const convertYYYYMMDDHHMIFormat = function (yyyymmddhhmi) {
  if(yyyymmddhhmi == undefined || yyyymmddhhmi == '') {
    return yyyymmddhhmi
  }
  if(yyyymmddhhmi.length < 12) {
    return yyyymmddhhmi
  }

  return yyyymmddhhmi.substring(0, 4) + '/' + yyyymmddhhmi.substring(4, 6) + '/' + yyyymmddhhmi.substring(6, 8) + ' ' + yyyymmddhhmi.substring(8, 10) + ':' + yyyymmddhhmi.substring(10, 12)
}

const convertYYYYMMDDHHMISSFormat = function (yyyymmddhhmiss) {
  if(yyyymmddhhmiss == undefined || yyyymmddhhmiss == '') {
    return yyyymmddhhmiss
  }
  if(yyyymmddhhmiss.length < 14) {
    return yyyymmddhhmiss
  }

  return yyyymmddhhmiss.substring(0, 4) + '/' + yyyymmddhhmiss.substring(4, 6) + '/' + yyyymmddhhmiss.substring(6, 8) + ' ' + yyyymmddhhmiss.substring(8, 10) + ':' + yyyymmddhhmiss.substring(10, 12) + ':' + yyyymmddhhmiss.substring(12, 14)
}

const textFindLIkeCode = function (solutionCodeMap, text) {
  if (text == undefined || text == '') {
    return text
  }
  const find = Object.values(solutionCodeMap).find(solution => {
    return solution.cdNm.indexOf(text) !== -1
  })
 if(find === undefined){
   return text
 }
  return find.cdNameSpace
}

const authCodeToText = function (authData, authCd) {

  if (authCd === undefined || authCd === '') {
    return authCd
  }

  const authCorrect = authData.find((auth)=>{
    return auth.authCd === authCd
  })

  if (authCorrect == null|| authCorrect.length === 0) {
    return authCd
  }
  return authCorrect.authNm
}

export default {
  codeToText,
  convertDateToStringYYYYMMDDFormat,
  convertDateToStringHHFormat,
  convertYYYYMMFormat,
  convertYYYYMMDDFormat,
  convertHHMIFormat,
  convertYYYYMMDDHHFormat,
  convertRegistIdFormatWithMasking,
  convertYYYYMMDDHHMIFormat,
  convertYYYYMMDDHHMISSFormat,
  textFindLIkeCode,
  authCodeToText
}

// 개발 테스트시 API쪽 URL을 변경해야할 때 여기 값을 변경해주세요.

const defaultEthDomain = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'https://dev-etnershome-api.etners.com' : 'https://etnershome-api.etners.com'
const defaultFcmDomain = 'https://fcm-api.etnersplatform.com'

const ApiConfig = {
  ethDomain: sessionStorage.getItem('ethDomain') == null ? defaultEthDomain : sessionStorage.getItem('ethDomain'),
  fcmDomain: sessionStorage.getItem('fcmDomain') == null ? defaultEthDomain : sessionStorage.getItem('fcmDomain'),
  mode : process.env.NODE_ENV
}

//개발 모드에서 로컬 URL 및 포트로 변경하고자 할 때 사용
if(process.env.NODE_ENV !== 'production') {
  // ApiConfig.ethDomain = '//localhost:11060'
  ApiConfig.ethDomain = 'https://dev-etnershome-api.etners.com'
}

export default ApiConfig
